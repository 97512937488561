import React, { useCallback, useState } from 'react';
import {
  BigidColorsV2,
  BigidForm,
  BigidFormField,
  BigidFormRenderProps,
  BigidFormValidateOnTypes,
  BigidFormValues,
  BigidBody1,
} from '@bigid-ui/components';
import { useLocalTranslation } from '../../../translations';
import {
  getIsValueChanged,
  getIsCustomQueryStringValue,
  isProjectIdFieldVisible,
  isNonLegacyConnector,
  isCustomQueryStringFieldVisible,
  isSubFlatConnector,
  isDBTableFieldVisible,
} from '../../../utils/utils';
import { useDetailsStepFormConfig } from './useDetailsStepFormConfig';
import { DataSourceTypes, DefaultStepProps, WizardFormFields } from '../../../types/CorrelationSetsTypes';
import { AdvancedOptions } from './AdvancedOptions';
import { useCorrelationSetWizardContext } from '../../../contexts/correlationSetContext';
import { Loader } from '../../../components/Loader';
import styled from '@emotion/styled';

//STEP 1
export const FormContainer = styled.div`
  & fieldset {
    margin: 0 0 24px;
  }
`;

export const FieldsMaxWidthWrapper = styled.div`
  max-width: ${({ maxWidth }: { maxWidth?: string }) => (!maxWidth ? '100%' : maxWidth)};
`;

const SelectedDataTypeContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  & > fieldset {
    margin: 0 0 0 16px;

    & > fieldset {
      margin: 0;

      > label {
        display: none;
      }
    }

    > div label {
      margin: 0 8px 0 0;
    }
  }
`;

const StyledMandatoryMark = styled('span')`
  color: ${BigidColorsV2.red[600]};
  width: 10px;
  display: inline-block;
  font-size: 16px;
`;

interface RenderFormProps extends BigidFormRenderProps {
  fields: BigidFormField[];
}

const isFieldVisible =
  (selectedDsType: DataSourceTypes, isCustomQueryMode: boolean) => (fieldsName: WizardFormFields) => {
    switch (fieldsName) {
      case WizardFormFields.containerName:
        return isNonLegacyConnector(selectedDsType) && !isCustomQueryMode;

      case WizardFormFields.subContainerName:
        return isSubFlatConnector(selectedDsType) && !isCustomQueryMode;

      case WizardFormFields.isCustomQueryString:
        return isCustomQueryStringFieldVisible(selectedDsType);

      case WizardFormFields.db_table:
        return isDBTableFieldVisible(selectedDsType) && !isCustomQueryMode;

      case WizardFormFields.custom_query_string:
        return isCustomQueryStringFieldVisible(selectedDsType) && isCustomQueryMode;

      case WizardFormFields.projectId:
        return isProjectIdFieldVisible(selectedDsType) && !isCustomQueryMode;
    }
  };

const RenderForm = ({ renderField, getValues, setValue, fields }: RenderFormProps) => {
  const { t } = useLocalTranslation('wizard.detailsStep');
  const { identities_force_sample, isCustomQueryString, dsConnection } = getValues();
  const isCustomQueryMode = getIsCustomQueryStringValue(isCustomQueryString);

  const selectedDsType: DataSourceTypes = dsConnection?.[0]?.type;
  const isVisible = isFieldVisible(selectedDsType, isCustomQueryMode);

  return (
    <FormContainer>
      <FieldsMaxWidthWrapper maxWidth="440px">
        {renderField(WizardFormFields.name)}
        {renderField(WizardFormFields.dsConnection)}

        {/*containerName field*/}
        {isVisible(WizardFormFields.containerName) && renderField(WizardFormFields.containerName)}
        {/*subContainerName field*/}
        {isVisible(WizardFormFields.subContainerName) && renderField(WizardFormFields.subContainerName)}

        {isVisible(WizardFormFields.isCustomQueryString) && (
          <SelectedDataTypeContainer>
            <BigidBody1 size="medium">
              {t('selectDataLabel')}
              <StyledMandatoryMark>*</StyledMandatoryMark>
            </BigidBody1>
            {renderField(WizardFormFields.isCustomQueryString)}
          </SelectedDataTypeContainer>
        )}

        {isVisible(WizardFormFields.db_table) && renderField(WizardFormFields.db_table)}
      </FieldsMaxWidthWrapper>

      {/*custom_query_string field*/}
      {isVisible(WizardFormFields.custom_query_string) && renderField(WizardFormFields.custom_query_string)}

      {/*projectId field*/}
      <FieldsMaxWidthWrapper maxWidth="440px">
        {isVisible(WizardFormFields.projectId) && renderField(WizardFormFields.projectId)}
      </FieldsMaxWidthWrapper>

      <FieldsMaxWidthWrapper maxWidth="270px">
        <AdvancedOptions renderField={renderField} isUseSampleSelected={identities_force_sample} />
      </FieldsMaxWidthWrapper>
    </FormContainer>
  );
};

export const DetailsStep = ({ detailsStepFormControls }: DefaultStepProps) => {
  const { t } = useLocalTranslation('wizard.detailsStep');
  const { correlationSetFormData, isTestConnectionLoading } = useCorrelationSetWizardContext();
  const [formState, setFormState] = useState<BigidFormValues>(correlationSetFormData);

  const { fields } = useDetailsStepFormConfig({ formState });

  const handleFormChange = useCallback(
    async (values: BigidFormValues) => {
      const isFormChanged = getIsValueChanged(values, formState);

      if (isFormChanged) {
        setFormState(values as BigidFormValues);
      }
    },
    [formState],
  );

  const memoizedRenderForm = useCallback(
    (props: BigidFormRenderProps) => <RenderForm {...props} fields={fields} />,
    [],
  );

  return (
    <>
      {isTestConnectionLoading && <Loader loaderText={t('previewLoadingText')} />}
      <BigidForm
        key={correlationSetFormData.name}
        stateAndHandlersRef={detailsStepFormControls}
        onChange={handleFormChange}
        controlButtons={false}
        fields={fields}
        initialValues={correlationSetFormData}
        validateOn={BigidFormValidateOnTypes.SUBMIT}
        renderForm={memoizedRenderForm}
      />
    </>
  );
};
