import React, { useEffect, useState } from 'react';
import { findErrorKeyFromMessage, loadDefaultConfigsForWizard } from '../AutoDiscoveryWizard/autoDiscoveryWizardUtils';
import {
  AutoDiscoveryWizardContextState,
  DEFAULT_AUTO_DISCOVERY_CONFIG_STATE,
} from '../AutoDiscoveryWizard/autoDiscoveryWizardContext';
import { CloudProvider } from '../constants';

export interface UseDiscoveryConfigState {
  type: CloudProvider;
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>;
}

const useLoadDiscoveryParamsConfigIfNotPassed = (
  { type }: UseDiscoveryConfigState,
  discoveryConfigData: AutoDiscoveryWizardContextState,
  setDiscoveryConfigData: React.Dispatch<React.SetStateAction<AutoDiscoveryWizardContextState>>,
) => {
  const isConfigLoaded = !!discoveryConfigData.configData;

  useEffect(() => {
    const loadConfig = async () => {
      try {
        setDiscoveryConfigData(current => ({
          ...current,
          isLoadingPreset: true,
        }));
        const configs = await loadDefaultConfigsForWizard(type, discoveryConfigData.id);
        setDiscoveryConfigData(current => ({
          ...current,
          ...configs,
          type,
          isTypeNotFound: !configs?.configData && !configs?.configDataMultiply,
          isIdNotFound: discoveryConfigData?.id && !configs.preset,
          isLoadingPreset: false,
          ...(configs?.preset?.latestRun?.status_enum === 'ERROR'
            ? {
                errorMessageKey: findErrorKeyFromMessage(configs?.preset?.latestRun?.message),
                errorMessage: configs?.preset?.latestRun?.message,
              }
            : {}),
        }));
      } catch (err) {
        setDiscoveryConfigData(current => ({
          ...current,
          isLoadingPreset: false,
        }));
        console.error(err);
      }
    };
    if (!isConfigLoaded) {
      loadConfig();
    }
  }, [discoveryConfigData.id, type]);

  return isConfigLoaded;
};

export const useDiscoveryConfigState = ({ type, discoveryConfigDataRef }: UseDiscoveryConfigState) => {
  const [discoveryConfigData, setDiscoveryConfigData] = useState<AutoDiscoveryWizardContextState>({
    ...DEFAULT_AUTO_DISCOVERY_CONFIG_STATE,
    ...discoveryConfigDataRef.current,
    type,
    isDiscovery: false,
  });
  discoveryConfigDataRef.current = { ...discoveryConfigDataRef.current, ...discoveryConfigData };

  const isConfigLoaded = useLoadDiscoveryParamsConfigIfNotPassed(
    { type, discoveryConfigDataRef },
    discoveryConfigData,
    setDiscoveryConfigData,
  );

  return { discoveryConfigData, setDiscoveryConfigData, isConfigLoaded };
};
