import React, { Suspense } from 'react';
import { FooterSectionRoot } from './FooterSection';
import { DataSourceIconByDsType } from '@bigid-ui/icons';
import { useTheme } from '@mui/styles';
import { BigidBody1, BigidLoader } from '@bigid-ui/components';
import { useLocalTranslation } from '../../../../translations';
import { getDataSourceTypeDisplayName } from '../../../../../../utilities/dataSourcesUtils';

type FooterDsTypeProps = {
  dsType: string;
};
export const FooterDsType = ({ dsType }: FooterDsTypeProps) => {
  const { vars } = useTheme();
  const { t } = useLocalTranslation('card');

  return (
    <FooterSectionRoot>
      <Suspense fallback={<BigidLoader />}>
        <BigidBody1 color={vars.tokens.bigid.foregroundSecondary}>{t('dataSourceType')}</BigidBody1>
        <DataSourceIconByDsType width={24} height={24} dataSourceType={dsType} />
        <BigidBody1 color={vars.tokens.bigid.foregroundTertiary}>{getDataSourceTypeDisplayName(dsType)}</BigidBody1>
      </Suspense>
    </FooterSectionRoot>
  );
};
