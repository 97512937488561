import React, { useState, useEffect, useMemo } from 'react';
import type { BigidDropdownOption } from '@bigid-ui/components';

import { usePrivacyExecutiveDashboardContext } from '../../PrivacyExecutiveDashboardContext';
import { Dropdown } from '../helpers/HelperComponents';

export function useSeverityDropdown() {
  const [severity, setSeverity] = useState<string>();
  const { severityColorMapper, severityDropdownItems } = usePrivacyExecutiveDashboardContext();
  const showAllSeverities = !Object.keys(severityColorMapper || {})?.includes(severity);

  useEffect(() => {
    if (!severity && severityColorMapper) {
      setSeverity(Object.keys(severityColorMapper)[0]);
    }
  }, [severity, severityColorMapper]);

  const handleSeveritySelect = (options: BigidDropdownOption[]) => {
    setSeverity(options[0].id as string);
  };

  const SeverityDropdown = useMemo(() => {
    return () => (
      <Dropdown
        items={severityDropdownItems}
        onItemSelect={handleSeveritySelect}
        currentItem={severityDropdownItems?.filter(item => item.id === severity)}
      />
    );
  }, [severity, severityDropdownItems]);

  return { severity, showAllSeverities, SeverityDropdown };
}
