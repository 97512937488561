import React, { FC, useMemo } from 'react';
import {
  BigidAdvancedToolbarFilterUnion,
  BigidWidgetContainer,
  capitalizeFirstLetter,
  BigidBody1,
} from '@bigid-ui/components';
import { BigidLineChartV2DataItem, BigidLineChartV2 } from '@bigid-ui/visualisation';
import { BigidNoDataIllustration } from '@bigid-ui/icons';

import { useLocalTranslation } from '../translations';
import { RiskWidgetWrapper } from './styles/WidgetStyles';
import { useFetchWidgetData } from './hooks/useFetchWidgetData';
import { SeverityColorMapper, WidgetProps } from '../PrivacyExecutiveDashboardTypes';
import { useSeverityDropdown } from './hooks/useSeverityDropdown';
import { useWidgetMenu } from './hooks/useWidgetMenu';

export const DataRiskTrendWidget: FC<WidgetProps> = ({ title }) => {
  const { t } = useLocalTranslation('widgets');
  const { WidgetMenu } = useWidgetMenu();
  const { data, isLoading, severityColorMapper } = useFetchWidgetData(fetchDataRiskTrendsWidgetData);
  const { severity, showAllSeverities, SeverityDropdown } = useSeverityDropdown();

  const dataToRender = useMemo(() => {
    return showAllSeverities ? data : data?.map(item => ({ category: item.category, [severity]: item[severity] }));
  }, [data, severity, showAllSeverities]);

  return (
    <RiskWidgetWrapper>
      <BigidWidgetContainer
        content={
          <BigidLineChartV2
            legendProps={{ hideLegend: true }}
            colorDataMap={severityColorMapper}
            isLineSmoothed
            showChartCursor
            numberFormat="#."
            valueAxisTitle={t('dataRiskTrend.valueAxisTitle')}
            data={dataToRender}
            seriesLabelFormatter={capitalizeFirstLetter}
          />
        }
        titleConfig={{ title }}
        isLoading={isLoading}
        emptyStateConfig={{
          isEmpty: data?.length === 0,
          illustration: BigidNoDataIllustration,
          illustrationSize: 'small',
          description: <BigidBody1>{t('emptyState.defaultText')}</BigidBody1>,
        }}
        actionsSlot={
          <>
            <SeverityDropdown />
            <WidgetMenu />
          </>
        }
      />
    </RiskWidgetWrapper>
  );
};

const fetchDataRiskTrendsWidgetData = (
  activeFilters: BigidAdvancedToolbarFilterUnion[],
  severityColorMapper: SeverityColorMapper,
): Promise<BigidLineChartV2DataItem[]> => {
  console.log('activeFilters', activeFilters);
  return new Promise(resolve => {
    setTimeout(resolve, 3000, attachWidgetProps(data, severityColorMapper));
  });
};

const attachWidgetProps = (data: { category: string }[], severityColorMapper: SeverityColorMapper) => {
  return data.map(item => {
    return {
      ...item,
      ...Object.keys(severityColorMapper).reduce((acc, severity) => {
        acc[severity] = Math.floor(Math.random() * 100) + 1; // Random number 1 - 100
        return acc;
      }, {} as Record<string, number>),
    };
  });
};

const data = [
  {
    category: 'Jan',
  },
  {
    category: 'Feb',
  },
  {
    category: 'Mar',
  },
  {
    category: 'Apr',
  },
  {
    category: 'May',
  },
  {
    category: 'Jun',
  },
  {
    category: 'Jul',
  },
  {
    category: 'Aug',
  },
  {
    category: 'Sep',
  },
  {
    category: 'Oct',
  },
  {
    category: 'Nov',
  },
  {
    category: 'Dec',
  },
];
