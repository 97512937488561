import { DataSource } from '../../../utilities/dataSourcesUtils';

export const mapSupportedDataSourcesToOptions = ({
  type,
  name,
  displayName,
  keywords,
  modelProp,
  nameInDocs,
  status,
  templateName,
  isPermitted,
}: DataSource) => ({
  type,
  status,
  displayName,
  keywords,
  name,
  modelProp,
  nameInDocs,
  isPermitted,
  templateName,
});
