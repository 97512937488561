import React, { useEffect } from 'react';

import {
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
  DateRangeOption,
} from '@bigid-ui/components';

import { useDashboardToolbar } from '../../components/ExecutiveDashboard/hooks/useDashboardToolbar';
import { ExecutiveDashboard, DashboardType } from '../../components/ExecutiveDashboard';
import { getFixedT } from './translations';
import { WidgetWrapper } from './WidgetWrapper';
import { PrivacyExecutiveDashboardContextProvider } from './PrivacyExecutiveDashboardContext';
import {
  PrivacyExecutiveDashboardWidgets,
  FilterToolbarFields,
  PRIVACY_EXECUTIVE_DASHBOARD,
} from './PrivacyExecutiveDashboardTypes';
import { DashboardWrapper } from './widgets/styles/WidgetStyles';
import { fetchAssets, fetchLegalEntities, fetchVendors } from './services/dashboardService';
import { LegalEntitiesInterface } from '../LegalEntities/LegalEntitiesTypes';
import { ApplicationEntity } from '../ApplicationSetup/types';
import { VendorProject } from '../Vendors/VendorsTypes';

export const PrivacyExecutiveDashboard = () => {
  const { activeFilters, externalAppliedFilters, activeWidgetIds, toolbarActions, isOverviewTab } = useDashboardToolbar(
    widgetIds,
    toolbarFilters,
    PRIVACY_EXECUTIVE_DASHBOARD,
  );

  useEffect(() => {
    if (isOverviewTab) {
      toolbarActions.updateExternalFilters(defaultFilters);
    }
  }, [isOverviewTab, toolbarActions]);

  return (
    <DashboardWrapper>
      <ExecutiveDashboard
        dashboardId={PRIVACY_EXECUTIVE_DASHBOARD}
        dashboardType={DashboardType.PRIVACY}
        toolbarFilters={toolbarFilters}
        activeFilters={activeFilters}
        toolbarActions={toolbarActions}
        externalAppliedFilters={externalAppliedFilters}
        isSavedFiltersTabsDisplayed={true}
        activeWidgetIds={activeWidgetIds}
        widgetSelectionItems={widgetSelectionItems}
      >
        <PrivacyExecutiveDashboardContextProvider activeFilters={activeFilters} activeWidgetIds={activeWidgetIds}>
          <WidgetWrapper />
        </PrivacyExecutiveDashboardContextProvider>
      </ExecutiveDashboard>
    </DashboardWrapper>
  );
};

const [tDashboard, tWidgets] = ['dashboard', 'widgets'].map(getFixedT);

const widgetIds = Object.values(PrivacyExecutiveDashboardWidgets);

const widgetSelectionItems = widgetIds.map(widgetId => ({
  id: widgetId,
  label: tWidgets(`${widgetId}.title`),
}));

const defaultFilters: BigidAdvancedToolbarFilterUnion[] = [
  {
    type: BigidAdvancedToolbarFilterTypes.DATE_RANGE,
    id: FilterToolbarFields.TIME_RANGE,
    field: FilterToolbarFields.TIME_RANGE,
    title: tDashboard(`toolbarTitles.${FilterToolbarFields.TIME_RANGE}`),
    operator: 'in',
    parentId: FilterToolbarFields.TIME_RANGE,
    options: {
      currentRangeOptionSelected: 'lastSixMonths',
      excludedOptions: [DateRangeOption.NONE],
      pickersState: {
        dates: getDefaultRangeDates(),
        currentMode: 'from',
      },
    },
  },
];

const toolbarFilters: BigidAdvancedToolbarFilterUnion[] = [
  {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: FilterToolbarFields.LEGAL_ENTITIES,
    field: FilterToolbarFields.LEGAL_ENTITIES,
    title: tDashboard(`toolbarTitles.${FilterToolbarFields.LEGAL_ENTITIES}`),
    parentId: FilterToolbarFields.LEGAL_ENTITIES,
    operator: 'in',
    options: [],
    asyncOptionsFetch: asyncOptionsFetch(FilterToolbarFields.LEGAL_ENTITIES, fetchLegalEntities),
  },
  {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: FilterToolbarFields.VENDORS,
    field: FilterToolbarFields.VENDORS,
    title: tDashboard(`toolbarTitles.${FilterToolbarFields.VENDORS}`),
    parentId: FilterToolbarFields.VENDORS,
    operator: 'in',
    options: [],
    asyncOptionsFetch: asyncOptionsFetch(FilterToolbarFields.VENDORS, fetchVendors),
  },
  {
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    id: FilterToolbarFields.ASSETS,
    field: FilterToolbarFields.ASSETS,
    title: tDashboard(`toolbarTitles.${FilterToolbarFields.ASSETS}`),
    parentId: FilterToolbarFields.ASSETS,
    operator: 'in',
    options: [],
    asyncOptionsFetch: asyncOptionsFetch(FilterToolbarFields.ASSETS, fetchAssets),
  },
  ...defaultFilters,
];

function asyncOptionsFetch(
  field: FilterToolbarFields,
  fetcherFunction: () => Promise<LegalEntitiesInterface[] | ApplicationEntity[] | VendorProject[]>,
) {
  return async function () {
    try {
      const vendors = await fetcherFunction();
      return vendors.map(entity => {
        const id = '_id' in entity ? entity._id : entity.id;
        return {
          id,
          displayValue: entity.name,
          value: id,
        };
      });
    } catch {
      console.error(`Error while loading ${field} filters`);
    }
  };
}

function getDefaultRangeDates() {
  const today = new Date();
  const sixMonthsAgoDate = new Date(today);
  sixMonthsAgoDate.setMonth(sixMonthsAgoDate.getMonth() - 6); // Subtract 6 months from now
  return { from: sixMonthsAgoDate, until: today };
}
