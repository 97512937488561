import React, { useCallback, useMemo, useState } from 'react';
import { isPermitted } from '../../../../../../../services/userPermissionsService';
import { CATALOG_PERMISSIONS, TAGS_PERMISSIONS } from '@bigid/permissions';
import { getApplicationPreference } from '../../../../../../../services/appPreferencesService';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridProps } from '@bigid-ui/grid';
import { ColumnsGridRow, fetchColumnsRowsByObjectName } from './sidePanelColumnsService';
import { useQueries } from 'react-query';
import { getColumnsCount } from '../../../../../../DataCatalog/DataCatalogColumns';
import { AttributeMappingDialogProps } from '../../../../../../DataCatalog/DataCatalogColumns/modalDialogEditors/AttributeMappingDialog';
import { TagsDialogProps } from './modalDialogEditors/TagsDialog';
import { notificationService } from '../../../../../../../services/notificationService';
import { BigidIcon, BigidIconSize, BigidTagBaseProps, ColumnInfo, ToolbarProps } from '@bigid-ui/components';
import { getTagFormattedName } from '../../../../../../TagsManagement/TagsManagementUtils';
import { LocalOfferOutlined } from '@mui/icons-material';
import { noop } from 'lodash';
import { BusinessAttributeDialogProps } from './modalDialogEditors/BusinessAttributeDialog';
import { LinkedColumnsDialogProps } from './modalDialogEditors/LinkedColumnsDialog';
import { SimilarColumnsDialogProps } from './modalDialogEditors/SimilarColumnsDialog';
import { DataExplorerColumnsProps } from './DataExplorerColumns';

export type SelectedColumn = {
  fullyQualifiedName: string;
  fieldName: string;
  clusterId?: string;
};

export const useGetColumnsConfig = ({
  datasource,
  id: fullyQualifiedName,
  isExtendedView = false,
  isDataPreviewDisabled,
  rootPage,
}: DataExplorerColumnsProps) => {
  const { scannerType, value } = datasource;
  const {
    isEditManualAttributesPermitted,
    isReadTagsPermitted,
    isColumnTagsAssignmentPermitted,
    isEditBusinessAttributePermitted,
    confidenceLevelExplainTooltipEnabled,
    clusteringEnabled,
    isColumnClusteringEnabled,
    isColumnTabAdditionalMetaDataEnabled,
    isReadBusinessAttributePermitted,
    isReadLinkedColumnsPermitted,
  } = useMemo(
    () => ({
      isColumnTabAdditionalMetaDataEnabled: getApplicationPreference('COLUMN_TAB_ADDITIONAL_METADATA'),
      isColumnClusteringEnabled: getApplicationPreference('SHOW_STRUCTURED_CLUSTERING'),
      isEditManualAttributesPermitted: isPermitted(CATALOG_PERMISSIONS.EDIT_MANUAL_FIELDS.name),
      isReadTagsPermitted: isPermitted(TAGS_PERMISSIONS.READ.name),
      isColumnTagsAssignmentPermitted: isPermitted(CATALOG_PERMISSIONS.ASSIGN_TAG.name),
      isEditBusinessAttributePermitted: isPermitted(CATALOG_PERMISSIONS.EDIT_BUSINESS_ATTRIBUTES.name),
      confidenceLevelExplainTooltipEnabled: getApplicationPreference('CONFIDENCE_LEVEL_EXPLAIN_TOOLTIP_ENABLED'),
      clusteringEnabled: getApplicationPreference('CLUSTERING_ENABLED'),
      isReadBusinessAttributePermitted: isPermitted(CATALOG_PERMISSIONS.READ_BUSINESS_ATTRIBUTES.name),
      isReadLinkedColumnsPermitted: isPermitted(CATALOG_PERMISSIONS.INVESTIGATE.name),
    }),
    [],
  );

  const [attributeMappingDialogState, setAttributeMappingDialogState] = useState<AttributeMappingDialogProps>({
    fullyQualifiedName,
    columnName: undefined,
    isOpen: false,
    scannerType,
  });

  const [tagsDialogState, setTagsDialogState] = useState<TagsDialogProps>({
    columnName: undefined,
    tags: [],
    fullyQualifiedName,
    source: value,
    isOpen: false,
  });

  const [businessAttributeDialogState, setBusinessAttributeDialogState] = useState<BusinessAttributeDialogProps>({
    clusterId: undefined,
    columnName: undefined,
    fullyQualifiedName,
    businessAttribute: undefined,
    isOpen: false,
    dsName: value,
    dsType: scannerType,
    scanner_type_group: scannerType,
    rootPage,
  });

  const [linkedColumnsDialogState, setLinkedColumnsDialogState] = useState<LinkedColumnsDialogProps>({
    fullyQualifiedName,
    title: undefined,
    isOpen: false,
    rootPage,
  });

  const [similarColumnsDialogState, setSimilarColumnsDialogState] = useState<SimilarColumnsDialogProps>({
    columnName: undefined,
    fullyQualifiedName,
    clusterId: undefined,
    isOpen: false,
  });

  const defaultHiddenColumns = isExtendedView
    ? []
    : ['order', 'nullable', 'linkedColumnsIndicator', 'columnBusinessAttribute', 'fieldType'];
  const [selectedColumn, setSelectedColumn] = useState<ColumnsGridRow>(null);
  const [hideColumns, setHideColumns] = useState<string[]>(defaultHiddenColumns);

  const handleHideColumns = (columns: ColumnInfo[]) => {
    setHideColumns(columns.filter(column => !column.checked).map(column => column.name));
  };

  const [
    { data: rows, isFetching: isColumnsRowsLoading, refetch: refetchRows },
    { data: countData, isFetching: isColumnsCountLoading },
  ] = useQueries([
    {
      queryKey: ['columnsRows', fullyQualifiedName],
      queryFn: async () =>
        fetchColumnsRowsByObjectName({
          fullyQualifiedName,
          isEditManualAttributesPermitted,
          isColumnTagsAssignmentPermitted,
          isEditBusinessAttributePermitted,
          confidenceLevelExplainTooltipEnabled,
          clusteringEnabled,
        }),
    },
    {
      queryKey: ['columnsCount', fullyQualifiedName],
      queryFn: async () => getColumnsCount(fullyQualifiedName),
      placeholderData: { data: { count: 0 } },
    },
  ]);

  const closeEditAttributeMappingDialog = useCallback(() => {
    setAttributeMappingDialogState(prevState => ({
      ...prevState,
      columnName: undefined,
      title: undefined,
      isOpen: false,
    }));
  }, []);

  const attributeMappingDialogConfig: AttributeMappingDialogProps = useMemo(
    () => ({
      ...attributeMappingDialogState,
      onClose: closeEditAttributeMappingDialog,
    }),
    [attributeMappingDialogState, closeEditAttributeMappingDialog],
  );

  const closeTagsDialog = useCallback(() => {
    setTagsDialogState(prevState => ({
      ...prevState,
      columnName: undefined,
      isOpen: false,
    }));
  }, []);

  const closeLinkedColumnsDialog = useCallback(() => {
    setLinkedColumnsDialogState(prevState => ({
      ...prevState,
      columnName: undefined,
      isOpen: false,
    }));
  }, []);

  const closeBusinessAttributeDialog = useCallback(() => {
    setBusinessAttributeDialogState(prevState => ({
      ...prevState,
      columnName: undefined,
      businessAttribute: undefined,
      clusterId: undefined,
      isOpen: false,
    }));
  }, []);

  const closeSimilarColumnsDialog = useCallback(() => {
    setSimilarColumnsDialogState(prevState => ({
      ...prevState,
      clusterId: undefined,
      columnName: undefined,
      isOpen: false,
    }));
  }, []);

  const tagsDialogConfig: TagsDialogProps = useMemo(
    () => ({
      ...tagsDialogState,
      onClose: closeTagsDialog,
    }),
    [closeTagsDialog, tagsDialogState],
  );

  const linkedColumnsDialogConfig: LinkedColumnsDialogProps = useMemo(
    () => ({
      ...linkedColumnsDialogState,
      onClose: closeLinkedColumnsDialog,
    }),
    [linkedColumnsDialogState, closeLinkedColumnsDialog],
  );

  const businessAttributeDialogConfig: BusinessAttributeDialogProps = useMemo(
    () => ({
      ...businessAttributeDialogState,
      onClose: closeBusinessAttributeDialog,
    }),
    [businessAttributeDialogState, closeBusinessAttributeDialog],
  );

  const similarColumnsDialogConfig: SimilarColumnsDialogProps = useMemo(
    () => ({
      ...similarColumnsDialogState,
      onClose: closeSimilarColumnsDialog,
    }),
    [similarColumnsDialogState, closeSimilarColumnsDialog],
  );

  const count = countData?.data?.count || 0;

  const columnTagsIfPermitted: BigidGridColumn<ColumnsGridRow>[] = isReadTagsPermitted
    ? [
        {
          name: 'columnTags',
          title: 'Tags',
          sortingEnabled: false,
          getCellValue: ({ columnTags }) => columnTags,
          type: BigidGridColumnTypes.TAGS,
          width: 300,
          formatter: {
            onClick: ({ value, column, row }) => {
              return new Promise((resolve, reject) => {
                setTagsDialogState(prevState => {
                  return {
                    ...prevState,
                    columnName: row.column_name,
                    tags: row.tags,
                    isOpen: true,
                    onSubmit: (updatedTags: BigidTagBaseProps[], postSubmitMessage: string) => {
                      const newCellValue = {
                        ...value,
                        chips: {
                          ...value.chips,
                          value: updatedTags.map(({ name, value }) => ({
                            label: `${getTagFormattedName(name)} : ${value}`,
                            icon: <BigidIcon icon={LocalOfferOutlined} size={BigidIconSize.REGULAR_PLUS} />,
                          })),
                        },
                      };

                      if (postSubmitMessage) {
                        notificationService.success(postSubmitMessage);
                      }

                      closeTagsDialog();
                      refetchRows();
                      resolve({ row: { ...row, [column.name]: newCellValue }, shouldGridReload: true });
                    },
                    onClose: () => {
                      reject();
                    },
                  };
                });
              });
            },
          },
        },
      ]
    : [];

  const positionColumn: BigidGridColumn<ColumnsGridRow>[] = isColumnTabAdditionalMetaDataEnabled
    ? [
        {
          name: 'order',
          sortingEnabled: false,
          title: 'Position',
          getCellValue: ({ order }) => order?.toString() || '',
          type: BigidGridColumnTypes.TEXT,
        },
      ]
    : [];

  const nullableColumn: BigidGridColumn<ColumnsGridRow>[] = isColumnTabAdditionalMetaDataEnabled
    ? [
        {
          name: 'nullable',
          sortingEnabled: false,
          title: 'Can be null',
          getCellValue: ({ nullable }) => nullable?.toString() || '',
          type: BigidGridColumnTypes.TEXT,
        },
      ]
    : [];

  const linkedColumnsIfPermitted: BigidGridColumn<ColumnsGridRow>[] = isReadLinkedColumnsPermitted
    ? [
        {
          name: 'linkedColumnsIndicator',
          title: 'Direct links',
          sortingEnabled: false,
          getCellValue: ({ linkedColumnsIndicator }) => linkedColumnsIndicator,
          type: BigidGridColumnTypes.ENTITIES_COUNTER,
          formatter: {
            onClick: ({ value, row, column }) => {
              return new Promise((resolve, reject) => {
                setLinkedColumnsDialogState(prevState => {
                  return {
                    ...prevState,
                    columnName: row.column_name,
                    title: `${row.column_name} - Linked columns`,
                    isOpen: true,
                    onSubmit: (linkedColumnsNumber: number) => {
                      const newCellValue = {
                        ...value,
                        counter: {
                          ...value.counter,
                          counterIcon: { ...value.counter.counterIcon, label: linkedColumnsNumber },
                        },
                      };
                      closeLinkedColumnsDialog();
                      refetchRows();
                      resolve({ row: { ...row, [column.name]: newCellValue, shouldGridReload: false } });
                    },
                    onClose: () => {
                      reject();
                    },
                  };
                });
              });
            },
          },
        },
      ]
    : [];

  const columnBusinessAttributesIfEnabled: BigidGridColumn<ColumnsGridRow>[] =
    isColumnClusteringEnabled && isReadBusinessAttributePermitted
      ? [
          {
            name: 'columnBusinessAttribute',
            title: 'Business Term',
            getCellValue: ({ columnBusinessAttribute }) => columnBusinessAttribute,
            type: BigidGridColumnTypes.CHIPS,
            width: 250,
            formatter: {
              onClick: ({ value, row, column }) => {
                return new Promise((resolve, reject) => {
                  setBusinessAttributeDialogState(prevState => {
                    return {
                      ...prevState,
                      columnName: row.column_name,
                      clusterId: row.clusterId,
                      businessAttribute: value?.chips?.value,
                      fullyQualifiedName,
                      isOpen: true,
                      onApplyToColumn: () => {
                        closeBusinessAttributeDialog();
                        refetchRows();
                        resolve({ row: { ...row, [column.name]: value }, shouldGridReload: true });
                      },
                      onApplyToCluster: () => {
                        closeBusinessAttributeDialog();
                        refetchRows();
                        resolve({ row: { ...row, [column.name]: value }, shouldGridReload: true });
                      },
                      onClose: () => {
                        reject();
                      },
                    };
                  });
                });
              },
            },
          },
        ]
      : [];

  const handleRowClick = (row: ColumnsGridRow) => {
    setSelectedColumn(row);
  };

  const handleMasterDetailsClose = () => {
    setSelectedColumn(null);
  };

  const columns: BigidGridColumn<ColumnsGridRow>[] = [
    {
      name: 'column_name',
      title: 'Column',
      sortingEnabled: false,
      isListColumn: true,
      width: 220,
      type: BigidGridColumnTypes.TEXT,
      getCellValue: row => row.column_name,
    },
    {
      name: 'attributes',
      title: 'Attributes (confidence level)',
      getCellValue: ({ attributes }) => attributes,
      type: BigidGridColumnTypes.CHIPS,
      sortingEnabled: false,

      width: 324,
      formatter: {
        onClick: ({ value, row, column }) => {
          return new Promise((resolve, reject) => {
            setAttributeMappingDialogState(prevState => {
              return {
                ...prevState,
                columnName: row.column_name,
                attributes: value.chips.value,
                isOpen: true,
                onSubmit: () => {
                  closeEditAttributeMappingDialog();
                  refetchRows();
                  resolve({ row: { ...row, [column.name]: value }, shouldGridReload: true });
                },
                onClose: () => {
                  reject();
                },
              };
            });
          });
        },
      },
    },
    ...columnTagsIfPermitted,
    {
      name: 'fieldType',
      title: 'Data type',
      getCellValue: ({ fieldType }) => fieldType,
      type: BigidGridColumnTypes.TEXT,
    },
    ...positionColumn,
    ...nullableColumn,
    ...linkedColumnsIfPermitted,
    ...columnBusinessAttributesIfEnabled,
  ];

  const getHiddenColumnNames = (columns: BigidGridColumn<ColumnsGridRow>[], isDetailsMode: boolean) => {
    if (!isDetailsMode) {
      return hideColumns;
    }
    return columns.filter(column => !column.isListColumn).map(column => column.name);
  };

  const isListMode = !!selectedColumn && !isDataPreviewDisabled && !isExtendedView;
  const hiddenColumnNames = getHiddenColumnNames(columns, !!isListMode);

  const gridConfig: BigidGridProps<ColumnsGridRow> = {
    columns,
    totalRowsCount: count,
    onPagingChanged: noop,
    rows: rows || [],
    selectedRowIds: selectedColumn ? [selectedColumn.column_name] : [],
    loading: isColumnsRowsLoading || isColumnsCountLoading,
    onRowClick: handleRowClick,
    listMode: isListMode,
    hiddenColumnNames,
  };

  const columnsList = columns.reduce<ColumnInfo[]>((acc, column) => {
    const columnsToExcludeFromChooser = ['column_name'];
    if (!columnsToExcludeFromChooser.includes(column.name)) {
      return [
        ...acc,
        {
          checked: !hideColumns.includes(column.name),
          name: column.name,
          label: column.title,
          title: column.title,
        },
      ];
    }
    return acc;
  }, []);

  const toolbarConfig: ToolbarProps = {
    totalRows: gridConfig.totalRowsCount,
    isFetchingCount: isColumnsCountLoading,
    columns: columnsList,
    onColumnChange: handleHideColumns,
    shouldShowColumnChooser: !isExtendedView,
  };

  return {
    gridConfig,
    attributeMappingDialogConfig,
    tagsDialogConfig,
    count,
    handleMasterDetailsClose,
    selectedColumn,
    toolbarConfig,
    linkedColumnsDialogConfig,
    similarColumnsDialogConfig,
    businessAttributeDialogConfig,
    setSimilarColumnsDialogState,
  };
};
