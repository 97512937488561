import React, { FC, useEffect, useMemo } from 'react';
import { styled } from '@mui/material';
import {
  AdvancedToolbarOverrideValueStrategy,
  BigidAdvancedToolbarFilter,
  BigidLoader,
  PrimaryButton,
} from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { ExecutiveDashboardProps } from './services/executiveDashboardServices';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { useLocalTranslation } from './translations';
import { SavedFiltersTabs, ToolbarActions } from './components';
import { ExecutiveDashboardTrackingEvents, getBiEvent } from './ExecutiveDashboardTrackingUtil';
import { DashboardUpdateInfo } from './components/DashboardUpdateInfo';

const Root = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0px 0px 10px 8px;
  position: relative;
`;

const ChildrenWrapper = styled('div')`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const SavedViewsAndActionsContainer = styled('div')`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  position: relative;
`;

const ExportButtonWrapper = styled('div')`
  flex-basis: 102px;
  padding-bottom: 8px;
`;

const LoaderWrapper = styled('div')`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  h4 {
    font-size: 14px;
  }
  div:first-child {
    width: 200px;
  }
`;

const FilterToolbarWrapper = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.vars.palette.bigid.gray200}`,
  height: 'auto',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '12px 12px',
  borderRadius: '4px',
  boxShadow: '0px 1px 12px 0px rgba(0, 0, 0, 0.05)',
  marginBottom: '16px',
  '& div:first-child': {
    borderRadius: '4px',
  },
}));

const FiltersWrapper = styled('div')`
  flex-grow: 1;
`;

const ToolbarActionsWrapper = styled('div')`
  width: 163px;
  padding-left: 12px;
  align-self: flex-start;
`;

export const ExecutiveDashboard: FC<ExecutiveDashboardProps> = ({
  dataAid = 'ExecutiveDashboard',
  dashboardType,
  dashboardId,
  isExportButtonDisplayed,
  isExportButtonDisabled = false,
  isSavedFiltersTabsDisplayed,
  onExportButtonClick,
  toolbarFilters,
  activeFilters,
  activeWidgetIds,
  toolbarActions,
  widgetSelectionItems,
  externalAppliedFilters,
  isLoading,
  isEmptySystem,
  titleRightSideComponent,
  children,
}) => {
  const { t } = useLocalTranslation();
  const { updateActiveWidgetIds, updateFilters } = toolbarActions;

  const selectedOptionsConfig = useMemo(
    () => ({
      values: externalAppliedFilters,
      strategy: AdvancedToolbarOverrideValueStrategy.INIT_WITHOUT_RELOAD_OVERRIDE,
    }),
    [externalAppliedFilters],
  );

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: t(`titles.${dashboardType}`),
      ...(titleRightSideComponent && { rightSideComponentsContainer: titleRightSideComponent }),
    });
  }, [dashboardType, isExportButtonDisplayed, onExportButtonClick, t]);

  const isActionsOrSavedViewsDisplayed = isSavedFiltersTabsDisplayed || isExportButtonDisplayed;

  return (
    <Root data-aid={generateDataAid(dashboardId, ['dashboard', 'layout'])}>
      {isActionsOrSavedViewsDisplayed && (
        <SavedViewsAndActionsContainer>
          {isSavedFiltersTabsDisplayed && (
            <SavedFiltersTabs
              dashboardId={dashboardId}
              toolbarActions={toolbarActions}
              activeFilters={activeFilters}
              isExportButtonDisplayed={isExportButtonDisplayed}
              activeWidgetIds={activeWidgetIds}
              isEmptySystem={isEmptySystem}
            />
          )}
          {isExportButtonDisplayed && (
            <ExportButtonWrapper>
              <PrimaryButton
                disabled={isEmptySystem || isExportButtonDisabled}
                dataAid={generateDataAid(dataAid, ['export'])}
                size="medium"
                onClick={() => onExportButtonClick?.()}
                text={t('buttonLables.exportPdf')}
                bi={getBiEvent(
                  ExecutiveDashboardTrackingEvents.EXECUTIVE_DASHBOARD_EXPORT_PDF_CLICKED,
                  activeFilters,
                  activeWidgetIds,
                )}
              />
            </ExportButtonWrapper>
          )}
        </SavedViewsAndActionsContainer>
      )}
      <FilterToolbarWrapper>
        <FiltersWrapper>
          {!isEmptySystem && (
            <BigidAdvancedToolbarFilter
              selectedOptionsConfig={selectedOptionsConfig}
              onFiltersChange={updateFilters}
              filters={toolbarFilters}
              shouldAsyncFiltersReload={false}
            />
          )}
        </FiltersWrapper>
        <ToolbarActionsWrapper>
          <ToolbarActions
            updateActiveWidgetIds={updateActiveWidgetIds}
            activeWidgetIds={activeWidgetIds}
            widgetSelectionItems={widgetSelectionItems}
            isEmptySystem={isEmptySystem}
            bi={getBiEvent(
              ExecutiveDashboardTrackingEvents.EXECUTIVE_DASHBOARD_SAVE_VIEW_CLICKED,
              activeFilters,
              activeWidgetIds,
            )}
          />
        </ToolbarActionsWrapper>
      </FilterToolbarWrapper>
      {isLoading ? (
        <LoaderWrapper>
          <BigidLoader label={t('titles.pleaseWait')} position="relative" />
        </LoaderWrapper>
      ) : (
        <ChildrenWrapper>{children}</ChildrenWrapper>
      )}
    </Root>
  );
};
