import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  BigidColorsV2,
  BigidDropdown,
  BigidDropdownOption,
  BigidFieldRenderProps,
  BigidFormFieldLabelWrapper,
} from '@bigid-ui/components';
import { useLocalTranslation } from '../../../translations';
import { useCorrelationSetWizardContext } from '../../../contexts/correlationSetContext';
import { getUniqIdList } from '../../../utils/utils';
import { styled } from '@mui/material';
import { CompositeAttribute } from '../../../types/CorrelationSetsTypes';

const UniqIdBox = styled('div')`
  background-color: ${BigidColorsV2.gray[125]};
  padding: 16px;
  margin-top: 24px;
`;

export const UniqueId: FC<BigidFieldRenderProps> = ({ values, setValue, error }) => {
  const { correlationSetFormData } = useCorrelationSetWizardContext();
  const { t } = useLocalTranslation('wizard.uniqueId');

  const [dropdownValue, setDropdownValue] = useState<BigidDropdownOption[]>(correlationSetFormData.unique_id || []);

  const optionsList = useMemo(() => {
    const { composite_attributes } = values;
    const uniqIdList = getUniqIdList(composite_attributes || [], correlationSetFormData.attributes);
    return uniqIdList;
  }, [values.composite_attributes]);

  useEffect(() => {
    initialValueUniqIdIfDeletedFromComposite();
  }, [values.composite_attributes]);

  const onSelectHandler = (valueSelected: BigidDropdownOption[]) => {
    setValue(valueSelected);
    setDropdownValue(valueSelected);
  };

  const initialValueUniqIdIfDeletedFromComposite = () => {
    const combinedKey = dropdownValue.length > 0 ? dropdownValue[0].value : null;
    const parts: string[] = combinedKey ? combinedKey.split(',') : [];
    if (parts.length === 1) {
      // means we select a single value
      return;
    }

    const indexToRemove = (values?.composite_attributes as CompositeAttribute[])?.findIndex(
      compositeAttribute =>
        compositeAttribute.value.length === parts.length && // Ensure lengths match
        parts.every(part => compositeAttribute.value.some(item => item.value === part)), // Check all parts exist
    );
    if (indexToRemove === -1) {
      setDropdownValue([]);
      setValue('');
    }
  };

  return (
    <UniqIdBox>
      <BigidFormFieldLabelWrapper
        id="UniqueId"
        label={t('title')}
        isRequired
        error={error}
        errorIsShown={Boolean(error)}
        tooltipText={t('tooltip')}
      >
        <BigidDropdown
          onSelect={onSelectHandler}
          options={optionsList}
          value={dropdownValue}
          isSearchable
          placeholder={t('placeholderSearchbar')}
        />
      </BigidFormFieldLabelWrapper>
    </UniqIdBox>
  );
};
