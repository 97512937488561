import React, { useState, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { FormControl, FormLabel, Stack } from '@mui/material';
import {
  PrimaryButton,
  SecondaryButton,
  BigidDropZone,
  BigidDialog,
  BigidColorsV2,
  BigidBody1,
  BigidButtonIcon,
  TertiaryButton,
} from '@bigid-ui/components';
import { notificationService } from '../../services/notificationService';
import { createBranding, getBranding, updateBranding, updateBrandingInheritance } from './LegalEntitiesService';
import { Branding, BrandingData } from './LegalEntitiesTypes';
import { LegalEntitiesBrandingLogo } from './LegalEntitiesBrandingLogo';
import { BrandingWidget, InfoTooltip } from './LegalEntitiesCommonComponents';
import { BigidDeleteIcon } from '@bigid-ui/icons';
import { LegalEntitiesBrandingPreview } from './LegalEntitiesBrandingPreview';
import { transformSizeToReadableString } from './utils/common';
import { LegalEntitiesTrackingEvents, trackLegalEntities } from './utils/analytics';

const ContentWrapper = styled.div({
  display: 'flex',
});

const DialogFormWrapper = styled.div({
  padding: '8px 24px 16px 24px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});
const DialogPreviewWrapper = styled.div({
  width: '506px',
  borderLeft: `1px solid ${BigidColorsV2.gray[200]}`,
});

const ColorWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${BigidColorsV2.gray[200]};
  border-radius: 4px;
  padding: 2px 12px;
  gap: 8px;
  height: 40px;
`;

const ColorInputWrapper = styled.span`
  overflow: hidden;
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;

const ColorInput = styled.input`
  padding: 0;
  width: 200%;
  height: 200%;
  margin: -50%;
`;

const ColorPreview = styled.span`
  padding: 6px 30px 6px 0;
`;

const InheritWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  border-radius: 8px;
  padding: 12px;
  border: 1px solid ${BigidColorsV2.gray[200]};
`;

const InheritLogoTextWrapper = styled.div`
  display: flex;
  gap: 8px;
`;
const InheritedLogoTextWrapper = styled(InheritLogoTextWrapper)`
  justify-content: center;
`;

const LogoDialogPreviewWrapper = styled.div`
  display: flex;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid ${BigidColorsV2.gray[200]};
  width: 48px;
  height: 48px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const ErrorTextWrapper = styled.p({
  color: BigidColorsV2.red[600],
});

export interface LegalEntitiesEditBrandingDialogProps {
  isOpen: boolean;
  entityId: string | null;
  entityName: string;
  parentName: string;
  parentBrandingId: string;
  branding?: Branding | null;
  onClose?: () => void;
  onUpload?: () => void;
}

export interface LegalEntitiesEditBrandingDialog {
  brandingDialogProps: LegalEntitiesEditBrandingDialogProps;
  openBrandingDialog: (
    entityId: string,
    entityName: string,
    parentName: string,
    parentBrandingId: string,
    branding?: Branding,
  ) => Promise<boolean>;
}

interface Error {
  fieldName: string;
  message: string;
}

const importInitialState: LegalEntitiesEditBrandingDialogProps = {
  isOpen: false,
  entityId: null,
  entityName: null,
  branding: null,
  parentName: null,
  parentBrandingId: null,
};

export const useLegalEntitiesEditBrandingDialog = (): LegalEntitiesEditBrandingDialog => {
  const [brandingDialogProps, setBrandingDialogProps] =
    useState<LegalEntitiesEditBrandingDialogProps>(importInitialState);

  const openBrandingDialog = useCallback<LegalEntitiesEditBrandingDialog['openBrandingDialog']>(
    (entityId: string, entityName: string, parentName: string, parentBrandingId: string, branding?: Branding) => {
      return new Promise<boolean>(resolve => {
        setBrandingDialogProps({
          isOpen: true,
          entityId,
          entityName,
          branding,
          parentName,
          parentBrandingId,
          onUpload: () => {
            setBrandingDialogProps(importInitialState);
            resolve(true);
          },
          onClose: () => setBrandingDialogProps(importInitialState),
        });
      });
    },
    [],
  );

  return {
    openBrandingDialog,
    brandingDialogProps,
  };
};

const DEFAULT_COLOR = '#44189D';

export const LegalEntitiesEditBrandingDialog = ({
  isOpen,
  entityId,
  entityName,
  parentName,
  parentBrandingId,
  branding,
  onClose,
  onUpload,
}: LegalEntitiesEditBrandingDialogProps) => {
  const [logo, setLogo] = useState<File | null>();
  const [previewLogo, setPreviewLogo] = useState<File | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inherited, setInherited] = useState<boolean>(!!branding?.inherited);
  const [fetchedBranding, setBranding] = useState<BrandingData>();
  const [color, setColor] = useState<string>();
  const [errors, setErrors] = useState<Error[]>([]);
  const getError = (field: string) => errors.find(error => error.fieldName === field);
  const genericError = getError('generic');
  const nameToShow = branding?.inherited ? parentName : entityName;

  useEffect(() => {
    setInherited(!!branding?.inherited);
    if (!branding?.brandingId) {
      branding?.inherited === false && setColor(DEFAULT_COLOR);
      return;
    }

    fetchBranding(branding?.brandingId).then(data => {
      data?.color && setColor(data.color);
      setBranding(data);
    });
  }, [branding?.brandingId, branding?.inherited]);

  const attachLogo = useCallback(() => {
    if (!fetchedBranding?.scaledImage) {
      setPreviewLogo(undefined);
      return;
    }

    const base64String = fetchedBranding.scaledImage;
    const mimeType = fetchedBranding.contentType;
    const blob = base64ToBlob(base64String, mimeType);
    const file = new File([blob], `${nameToShow}_logo.jpeg`, { type: mimeType });
    setPreviewLogo(file);
  }, [fetchedBranding?.contentType, fetchedBranding?.scaledImage, nameToShow]);

  useEffect(() => {
    attachLogo();
  }, [attachLogo]);

  const handleOnDrop = useCallback(
    (files: File[], isRejectedFilesFound: boolean) => {
      if (isRejectedFilesFound) {
        setLogo(null);
        return;
      }
      const [file] = files;
      setLogo(file);
      setPreviewLogo(file);
      trackLegalEntities(LegalEntitiesTrackingEvents.BRANDING_CHOOSE_LOGO_CLICK);
      !color && setColor(DEFAULT_COLOR);
      setInherited(false);
      setErrors([]);
    },
    [color],
  );

  const resetStates = useCallback(() => {
    setLogo(undefined);
    setPreviewLogo(undefined);
    setColor(undefined);
    setErrors([]);
  }, []);

  const onCloseButtonClicked = () => {
    const isExistingBranding = !!branding?.brandingId;
    trackLegalEntities(
      isExistingBranding
        ? LegalEntitiesTrackingEvents.EDIT_BRANDING_DIALOG_CANCEL_CLICK
        : LegalEntitiesTrackingEvents.CREATE_BRANDING_DIALOG_CANCEL_CLICK,
    );
    onClose?.();
    resetStates();
  };

  const onUploadButtonClicked = useCallback(async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      if (!inherited && logo) {
        formData.append('logo', logo);
      }
      color && !inherited && formData.append('color', color);
      formData.append('inherited', String(inherited));
      const isExistingBranding = !!branding?.brandingId;
      trackLegalEntities(
        isExistingBranding
          ? LegalEntitiesTrackingEvents.EDIT_BRANDING_DIALOG_SAVE_CLICK
          : LegalEntitiesTrackingEvents.CREATE_BRANDING_DIALOG_SAVE_CLICK,
      );
      if (isExistingBranding) {
        if (!inherited && !logo && !previewLogo) {
          formData.append('logo', null);
        }
        if (branding?.inherited === inherited) {
          await updateBranding(entityId, branding?.brandingId, formData);
        } else {
          const cloneImages = !inherited && !!previewLogo;
          await updateBrandingInheritance(entityId, formData, cloneImages);
        }
      } else {
        await createBranding(entityId, formData);
      }
      onUpload?.();
      resetStates();
    } catch (err) {
      const errorMessage = `Failed to upload ${logo?.name}`;
      notificationService.error(errorMessage);
      console.error(`${errorMessage}: ${JSON.stringify(err?.response)}`);
      const { message } = err?.response?.data || {};
      if (message) {
        setErrors([{ fieldName: 'generic', message }]);
      }
    } finally {
      setIsLoading(false);
    }
  }, [branding?.brandingId, branding?.inherited, color, entityId, inherited, logo, onUpload, previewLogo, resetStates]);

  const logoToShow = logo || previewLogo;

  return (
    <BigidDialog
      isOpen={isOpen}
      title="Branding"
      onClose={onCloseButtonClicked}
      maxWidth="md"
      borderTop
      borderBottom
      hideContentPadding
      isLoading={isLoading}
      buttons={[
        {
          component: TertiaryButton,
          onClick: onCloseButtonClicked,
          text: 'Cancel',
        },
        {
          component: PrimaryButton,
          onClick: onUploadButtonClicked,
          text: 'Save',
          disabled: branding?.inherited && inherited,
        },
      ]}
    >
      <ContentWrapper>
        <DialogFormWrapper>
          <FormControl fullWidth margin="normal">
            <FormLabel>{'Logo'}</FormLabel>
            <BigidDropZone
              maxSize={2}
              accept={['.jpg', '.jpeg', '.png']}
              files={logoToShow ? [logoToShow] : []}
              multiple={false}
              onDrop={handleOnDrop}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormLabel>
              {'Color scheme'}
              {color === DEFAULT_COLOR && (
                <span style={{ color: BigidColorsV2.gray[500], marginLeft: '4px' }}>{'(default)'}</span>
              )}
            </FormLabel>
            <ColorWrapper>
              <ColorInputWrapper>
                <ColorInput
                  onChange={e => {
                    setColor(e.target.value);
                    setInherited(false);
                  }}
                  onBlur={() => {
                    trackLegalEntities(LegalEntitiesTrackingEvents.BRANDING_PICK_COLOR_CLICK);
                  }}
                  type="color"
                  value={color?.toUpperCase()}
                />
              </ColorInputWrapper>
              <ColorPreview>
                <BigidBody1>{color}</BigidBody1>
              </ColorPreview>
            </ColorWrapper>
          </FormControl>
          {parentName && (
            <Stack sx={{ flex: 1, justifyContent: inherited ? 'flex-end' : 'flex-start' }}>
              <FormControl fullWidth margin="normal">
                {inherited ? (
                  <InheritedLogoTextWrapper>
                    <BigidBody1>{`Inherited from parent`}</BigidBody1>
                    <InfoTooltip
                      title={<BrandingWidget brandingId={parentBrandingId} isTooltip parentName={parentName} />}
                      sx={{ marginLeft: '-4px' }}
                    />
                  </InheritedLogoTextWrapper>
                ) : (
                  <Stack>
                    <Stack direction="row" sx={{ alignItems: 'center', marginBottom: '24px' }}>
                      <Stack
                        sx={{
                          borderBottom: `1px solid ${BigidColorsV2.gray[300]}`,
                          width: '100%',
                        }}
                      />
                      <Stack sx={{ padding: '0 20px', bgcolor: BigidColorsV2.white }}>
                        <BigidBody1 size="small">{'OR'}</BigidBody1>
                      </Stack>
                      <Stack
                        sx={{
                          borderBottom: `1px solid ${BigidColorsV2.gray[300]}`,
                          width: '100%',
                        }}
                      />
                    </Stack>
                    <InheritWrapper>
                      <InheritLogoTextWrapper>
                        <LegalEntitiesBrandingLogo brandingId={parentBrandingId} />
                        <BigidBody1>{'Inherit from parent'}</BigidBody1>
                        <InfoTooltip
                          title={<BrandingWidget brandingId={parentBrandingId} isTooltip parentName={parentName} />}
                          sx={{ marginLeft: '-4px' }}
                        />
                      </InheritLogoTextWrapper>
                      <SecondaryButton
                        onClick={() => {
                          setInherited(true);
                          trackLegalEntities(LegalEntitiesTrackingEvents.BRANDING_DIALOG_INHERIT_FROM_PARENT_CLICK);
                          fetchBranding(parentBrandingId).then(data => {
                            setColor(data?.color ? data?.color : DEFAULT_COLOR);
                            setLogo(undefined);
                            setPreviewLogo(undefined);
                            setBranding(data);
                            attachLogo();
                          });
                        }}
                        size="small"
                        text="Inherit"
                      />
                    </InheritWrapper>
                  </Stack>
                )}
              </FormControl>
            </Stack>
          )}
          {genericError && <ErrorTextWrapper>{genericError.message}</ErrorTextWrapper>}
        </DialogFormWrapper>
        <DialogPreviewWrapper>
          <LegalEntitiesBrandingPreview logo={logoToShow} color={color} />
        </DialogPreviewWrapper>
      </ContentWrapper>
    </BigidDialog>
  );
};

const fetchBranding = async (brandingId: string): Promise<BrandingData> => {
  if (!brandingId) {
    return;
  }

  try {
    const branding = await getBranding(brandingId);
    return branding;
  } catch (err) {
    notificationService.error(`Failed to fetch Legal Entity branding`);
    console.error(`Failed to fetch Legal Entity branding: ${JSON.stringify(err?.response)}`);
  }
};

const base64ToBlob = (base64: string, mimeType: string) => {
  const byteCharacters = window.atob(base64.split(';base64,')[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
};
