import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PopoverOrigin } from '@mui/material';

import {
  BigidBody1,
  BigidButtonIcon,
  BigidColorsV2,
  BigidDropdown,
  BigidDropdownOption,
  BigidDropdownValue,
  BigidMenu,
  BigidMenuItemProps,
  BigidSeverityMarker,
  BigidWidgetList,
  BigidWidgetListItemProps,
} from '@bigid-ui/components';
import { BigidMoreActionIcon } from '@bigid-ui/icons';

import { notificationService } from '../../../../services/notificationService';
import { AllSeverities, PrivacyExecutiveDashboardWidgets, SeverityLevel } from '../../PrivacyExecutiveDashboardTypes';
import { useIsActiveWidget } from '../hooks/useIsActiveWidget';
import {
  DataSourceDropdownWrapper,
  DropdownWrapper,
  WidgetListHeaderWrapper,
  WidgetListWrapper,
} from '../styles/WidgetStyles';
import { fetchDataSources } from '../../services/dashboardService';
import { useLocalTranslation } from '../../translations';

const DEFAULT_ANCHOR_ORIGIN: Readonly<PopoverOrigin> = { vertical: 'bottom', horizontal: 'right' };
const DEFAULT_TRANSFORM_ORIGIN: Readonly<PopoverOrigin> = { vertical: 'top', horizontal: 'right' };

export const Menu = ({ menuItems, isEnabled }: { menuItems: BigidMenuItemProps[]; isEnabled?: boolean }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  if (!isEnabled) {
    return null;
  }

  return (
    <>
      <BigidButtonIcon
        icon={BigidMoreActionIcon}
        size="small"
        ref={buttonRef}
        onClick={() => setIsMenuOpened(!isMenuOpened)}
      />
      <BigidMenu
        items={menuItems}
        open={isMenuOpened}
        anchorEl={buttonRef.current as Element}
        onMenuClose={() => setIsMenuOpened(false)}
        anchorOrigin={DEFAULT_ANCHOR_ORIGIN}
        transformOrigin={DEFAULT_TRANSFORM_ORIGIN}
      />
    </>
  );
};

const DropdownSeverityStartIcon = ({ color, small }: { color: string; small?: boolean }) => (
  <span style={{ display: 'flex', justifyContent: 'center', ...(small ? { width: '16px' } : {}) }}>
    <BigidSeverityMarker level="custom" color={color} />
  </span>
);

interface DropdownProps {
  items: BigidDropdownOption[];
  currentItem: BigidDropdownValue;
  onItemSelect: (options: BigidDropdownOption[]) => void;
}

export const createSeverityDropdownItems = (severityLevels: SeverityLevel[]) => {
  return [
    {
      id: AllSeverities,
      value: AllSeverities,
      displayValue: AllSeverities,
    },
    ...severityLevels.map(level => ({
      id: level.severity,
      value: level.severity,
      displayValue: level.severity,
      color: level.color,
      icon: () => <DropdownSeverityStartIcon color={level.color} />,
    })),
  ];
};

export const Dropdown: FC<DropdownProps> = ({ items, onItemSelect, currentItem }) => {
  if (!items?.length) {
    return null;
  }

  return (
    <DropdownWrapper>
      <BigidDropdown options={items} onSelect={onItemSelect} value={currentItem} size="small" hasIconBefore />
    </DropdownWrapper>
  );
};

export const useDataSources = () => {
  const [options, setOptions] = useState<BigidDropdownOption[]>();

  useEffect(() => {
    (async () => {
      try {
        const ds = await fetchDataSources();
        setOptions(
          ds.map(ds => ({
            id: ds.name,
            value: ds.name,
            displayValue: ds.name,
            isSelected: false,
          })),
        );
      } catch (err) {
        notificationService.error(`Failed to fetch data sources`);
        console.error(`Failed to fetch data sources: ${JSON.stringify(err?.response)}`);
        setOptions([]);
      }
    })();
  }, []);

  const onItemSelect = useCallback((options: BigidDropdownOption[]) => {
    const ids = [...new Set(options.map(o => o.id))];
    setOptions(ds =>
      ds.map(d => ({
        ...d,
        isSelected: ids.includes(d.id),
      })),
    );
  }, []);

  const selectedOptions = useMemo(() => options?.filter(option => option.isSelected), [options]);

  const DSDropdown = useMemo(
    () => () => <DataSourceDropdown options={options} value={selectedOptions} onItemSelect={onItemSelect} />,
    [options, selectedOptions, onItemSelect],
  );

  return { dsSelectedOptions: selectedOptions, DataSourceDropdown: DSDropdown };
};

export interface DataSourceDropdownProps {
  options: BigidDropdownOption[];
  value: BigidDropdownValue;
  onItemSelect: (options: BigidDropdownOption[]) => void;
}

export const DataSourceDropdown: FC<DataSourceDropdownProps> = ({ options, value, onItemSelect }) => {
  const { t } = useLocalTranslation('widgets.dataDeletion');
  const buttonRef = useRef();

  return (
    <>
      <DataSourceDropdownWrapper ref={buttonRef as React.LegacyRef<HTMLDivElement>}>
        <BigidDropdown
          options={options}
          onSelect={onItemSelect}
          value={value}
          placeholder={t('allDataSources')}
          entityNamePlural={t('entityNamePlural')}
          entityNameSingular={t('entityNameSingular')}
          size="small"
          searchBarAutoFocus
          isSelectAll
          isMulti
          isSearchable
          isValueDisplayedAsChips
        />
      </DataSourceDropdownWrapper>
    </>
  );
};

export const RenderActiveWidgetWrapper: FC<{
  widgetId: PrivacyExecutiveDashboardWidgets;
  children: React.ReactNode;
}> = ({ widgetId, children }) => {
  const isActiveWidget = useIsActiveWidget(widgetId);
  return isActiveWidget ? <>{children}</> : null;
};

interface WidgetListProps {
  items?: BigidWidgetListItemProps[];
  headerTitleOne: string;
  headerTitleTwo: string;
  margin?: string;
}

export const WidgetListWithTitle: FC<WidgetListProps> = ({ items, headerTitleOne, headerTitleTwo, margin = '0' }) => {
  return (
    <>
      <WidgetListHeaderWrapper>
        <BigidBody1 size="small" color={BigidColorsV2.gray[500]}>
          <strong>{headerTitleOne}</strong>
        </BigidBody1>
        <BigidBody1 size="small" color={BigidColorsV2.gray[500]}>
          <strong>{headerTitleTwo}</strong>
        </BigidBody1>
      </WidgetListHeaderWrapper>
      {items?.length && (
        <WidgetListWrapper margin={margin}>
          <BigidWidgetList items={items} isOrdered={false} />
        </WidgetListWrapper>
      )}
    </>
  );
};
