import { BigidFormFieldTypes } from '@bigid-ui/components';
import { getUsersQuery } from '../../../../utilities/systemUsersUtils';
import { systemUsersService } from '../../../../services/angularServices';
import { getAvailableScopes } from '../hooks/useScopeOptions';
import { notificationService } from '../../../../services/notificationService';
import { getCopyOfCountries } from '../../../../../config/countries';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { isPermitted } from '../../../../services/userPermissionsService';
import { ACCESS_MANAGEMENT_PERMISSIONS } from '@bigid/permissions';
import { DsTypesEnum } from '../types';
import { normalizeUsersData } from '../fields/FormSystemUserSelectField';
import { BulkUpdateDataSourceFormField } from './DataSourceBulkUpdateContext';
import { FormTagsField } from '../fields/FormTagsField';

const getOwnerFieldProps = () => ({
  isSearchable: true,
  placeholder: 'Select Owner',
  isClearable: true,
  isMulti: true,
  isAsync: true,
  loadOptions: async (inputType: string) => {
    const query = getUsersQuery({ maxUsers: 50, searchString: inputType });
    const {
      data: { users },
    } = await systemUsersService.getAllSystemUsersByQuery(query);
    return normalizeUsersData(users);
  },
  shouldLoadInitialOptions: true,
  menuPosition: 'fixed',
});

interface GetDefaultFieldsProps {
  largeSelect: boolean;
}
export const getDefaultFields = ({ largeSelect }: GetDefaultFieldsProps): BulkUpdateDataSourceFormField[] => [
  {
    name: 'isReplaceOwners',
    type: BigidFormFieldTypes.RADIO,
    options: [
      { label: 'Add owners', value: false },
      { label: 'Replace owners', value: true },
    ],
    misc: {
      apiName: 'isReplaceOwners',
      ignoreVisibleInCheck: true,
      type: DsTypesEnum.boolean,
      enabled: true,
    },
  },
  {
    name: 'businessOwners',
    label: 'Business Owner',
    type: BigidFormFieldTypes.SELECT,
    isFieldsBySelection: true,
    fieldProps: getOwnerFieldProps(),
    misc: {
      type: DsTypesEnum.array,
      apiName: 'owners_v2',
      ignoreVisibleInCheck: true,
      enabled: true,
    },
  },
  {
    label: 'IT Data Owner',
    name: 'itOwners',
    isFieldsBySelection: true,
    type: BigidFormFieldTypes.SELECT,
    fieldProps: getOwnerFieldProps(),
    misc: {
      type: DsTypesEnum.array,
      apiName: 'owners_v2',
      ignoreVisibleInCheck: true,
      enabled: true,
    },
    isAddDivider: true,
  },
  {
    name: 'scope',
    label: 'Scope',
    isFieldsBySelection: true,
    type: BigidFormFieldTypes.SELECT,
    tooltipText: isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.MANAGE.name)
      ? largeSelect
        ? 'Large number of selected data sources - updating will take time'
        : 'If no scopes are selected, all of the scopes that are available to you by your roles assigned automatically'
      : `You don't have the required permissions to view this field, please contact the administrator to gain access`,
    misc: {
      type: DsTypesEnum.stringSelect,
      ignoreVisibleInCheck: true,
      enabled: true,
    },
    fieldProps: {
      isAsync: true,
      shouldLoadInitialOptions: true,
      isMulti: true,
      isSearchable: true,
      loadOptions: async () => {
        try {
          const { scopes } = await getAvailableScopes();
          return scopes.map(({ id, name }) => ({ label: name, value: id }));
        } catch (e) {
          notificationService.error('Failed to fetch scopes. See logs for more information.');
        }
      },
    },
  },
  {
    name: 'tags',
    label: 'Tags',
    isFieldsBySelection: true,
    tooltipText: 'You can add existing tags or create new ones',
    render: FormTagsField,
    isAddDivider: true,
    misc: {
      type: DsTypesEnum.tags,
      ignoreVisibleInCheck: true,
      enabled: true,
      apiName: 'tags',
    },
  },
  {
    name: 'location',
    label: 'Location',
    type: BigidFormFieldTypes.SELECT,
    isFieldsBySelection: true,
    fieldProps: {
      isMulti: false,
      isSearchable: true,
      placeholder: 'Select Location',
      isAsync: true,
      shouldLoadInitialOptions: true,
      loadOptions: async () =>
        getCopyOfCountries(getApplicationPreference('DISPLAY_CHINA_REGULATIONS'))
          .filter(country => !country.isState)
          .map(country => ({
            label: country['name'],
            value: country['name'],
            isSelected: false,
          })),
    },
    isAddDivider: true,
    misc: {
      type: DsTypesEnum.stringSelect,
      ignoreVisibleInCheck: true,
      enabled: true,
      apiName: 'location',
    },
  },
];
