import React, { FC, useRef, useEffect } from 'react';
import { BigidTextField, BigidDropdown, BigidDropdownOption, BigidInlineNotification } from '@bigid-ui/components';
import { BigidChevronDownIcon, BigidChevronRightIcon, BigidDeleteIcon } from '@bigid-ui/icons';
import { DsType } from './SetDsTypesDynamicCostModal';
import { noop } from 'lodash';
import { useLocalTranslation } from './translations';
import {
  CollapsibleContainer,
  Container,
  EqualContainer,
  HeaderContainer,
  LineContainer,
} from './DsTypeDynamicCostStyles';

const GB_VOLUME_UNIT = 'GB';
const TB_VOLUME_UNIT = 'TB';
const DEFAULT_COST = 10;
const PLACEHOLDER_COST = '1';
const PLACEHOLDER_CURRENCY = 'USD';
const VOLUME_OPTIONS: BigidDropdownOption[] = [
  { displayValue: TB_VOLUME_UNIT, id: 'tb', value: TB_VOLUME_UNIT },
  { displayValue: GB_VOLUME_UNIT, id: 'gb', value: GB_VOLUME_UNIT },
];

interface DsTypeDynamicCostProps {
  groupId: string;
  dsTypes: DsType[];
  onBlockChange: (blockId: string, updatedDs: DsType[]) => void;
  isDefault: boolean;
  isCollapsed: boolean;
  onToggleCollapse: () => void;
  existingGroupConfigs: { cost: number; volumeUnit: 'TB' | 'GB' }[];
  isNew?: boolean;
  onRemoveIfEmpty: (groupId: string) => void;
}

export const DsTypeDynamicCost: FC<DsTypeDynamicCostProps> = ({
  groupId,
  dsTypes,
  onBlockChange,
  isDefault,
  isCollapsed,
  onToggleCollapse,
  existingGroupConfigs,
  isNew,
  onRemoveIfEmpty,
}) => {
  const { t } = useLocalTranslation();
  const contentRef = useRef<HTMLDivElement>(null);
  const firstConfiguredDs = dsTypes.find(dsType => dsType.cost !== undefined && dsType.volumeUnit !== undefined);
  const currentCost = firstConfiguredDs?.cost ?? DEFAULT_COST;
  const currentVolumeUnit = firstConfiguredDs?.volumeUnit ?? TB_VOLUME_UNIT;

  const dropdownOptions: BigidDropdownOption[] = dsTypes.map(dsType => ({
    displayValue: dsType.friendlyName,
    id: dsType.serviceName,
    value: dsType.serviceName,
  }));

  const selectedOptions = dropdownOptions.filter(opt => {
    const dsType = dsTypes.find(dsType => dsType.serviceName === opt.value);
    return dsType?.cost !== undefined && dsType?.volumeUnit !== undefined;
  });

  useEffect(() => {
    if (isCollapsed) {
      contentRef.current.style.height = '0px';
    } else {
      contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
    }
  }, [isCollapsed, dsTypes]);

  const handleCostChange = (newCostValue: string) => {
    const parsedCost = parseFloat(newCostValue);
    const updated = dsTypes.map(dsType => {
      if (dsType.cost !== undefined && dsType.volumeUnit !== undefined) {
        return {
          ...dsType,
          cost: isNaN(parsedCost) ? undefined : parsedCost,
        };
      }
      return dsType;
    });
    onBlockChange(groupId, updated);
  };

  const handleVolumeUnitChange = (option: BigidDropdownOption) => {
    const updated = dsTypes.map(dsType => {
      if (dsType.cost !== undefined && dsType.volumeUnit !== undefined) {
        return { ...dsType, volumeUnit: option.value };
      }
      return dsType;
    });
    onBlockChange(groupId, updated);
  };

  const handleDropdownSelect = (selected: BigidDropdownOption[]) => {
    const selectedNames = selected.map(item => item.value);
    const updated = dsTypes.map(dsType => {
      const isNowSelected = selectedNames.includes(dsType.serviceName);

      if (isNowSelected) {
        const wasUnconfigured = dsType.cost === undefined || dsType.volumeUnit === undefined;
        if (wasUnconfigured) {
          return {
            ...dsType,
            cost: currentCost,
            volumeUnit: currentVolumeUnit,
          };
        }
      } else {
        const wasConfigured = dsType.cost !== undefined && dsType.volumeUnit !== undefined;
        if (wasConfigured) {
          return { ...dsType, cost: undefined, volumeUnit: undefined };
        }
      }
      return dsType;
    });

    onBlockChange(groupId, updated);

    if (!selected.length && !isNew) {
      onRemoveIfEmpty(groupId);
    }
  };

  const shouldShowNotification =
    isNew &&
    existingGroupConfigs.some(config => config.cost === currentCost && config.volumeUnit === currentVolumeUnit);

  return (
    <Container isCollapsed={isCollapsed}>
      {!isDefault && (
        <HeaderContainer>
          {isCollapsed ? (
            <BigidChevronRightIcon onClick={onToggleCollapse} />
          ) : (
            <BigidChevronDownIcon onClick={onToggleCollapse} />
          )}
          <BigidDropdown
            options={dropdownOptions}
            onSelect={handleDropdownSelect}
            value={selectedOptions}
            isMulti
            withChipsBar
            isSearchable
          />
          <BigidDeleteIcon
            onClick={() => {
              onRemoveIfEmpty(groupId);
            }}
          />
        </HeaderContainer>
      )}

      <CollapsibleContainer height="auto" ref={contentRef}>
        <LineContainer>
          <BigidTextField readOnly placeholder={PLACEHOLDER_COST} size="medium" type="text" onChange={noop} />
          <BigidDropdown
            options={VOLUME_OPTIONS}
            onSelect={opts => {
              if (opts.length) handleVolumeUnitChange(opts[0]);
            }}
            value={[
              {
                displayValue: currentVolumeUnit,
                id: currentVolumeUnit,
                value: currentVolumeUnit,
              },
            ]}
          />
        </LineContainer>

        <EqualContainer>Equal to</EqualContainer>

        <LineContainer>
          <BigidTextField
            size="medium"
            type="number"
            onChange={e => handleCostChange(e.target.value)}
            value={String(currentCost)}
          />
          <BigidTextField readOnly placeholder={PLACEHOLDER_CURRENCY} size="medium" type="text" onChange={noop} />
        </LineContainer>
      </CollapsibleContainer>

      {shouldShowNotification && (
        <BigidInlineNotification
          open
          type="info"
          text={[
            {
              subText: t(`modal.DuplicationNotificationMessage`),
            },
          ]}
        />
      )}
    </Container>
  );
};
