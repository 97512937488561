import React, { Fragment, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { BigidColors, BigidPaper, BigidTabsAndContent, BigidTabsAndContentProps } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { $state, $stateParams } from '../../../services/angularServices';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { getGroup, GroupRecord } from './GroupsService';
import { AciEntitySummarize } from '../InsightCharts/AciEntitySummarize';
import { GroupMembers } from './GroupMembers';
import { OpenAccessFiles } from '../Files/OpenAccessFiles';
import { AciEntityType } from '../InsightCharts/AccessIntelligenceInsightsService';
import { getApplicationPreference } from '../../../services/appPreferencesService';

const useStyles = makeStyles(() => ({
  paper: {
    margin: '16px 32px',
    height: 'calc(100% - 64px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  content: {
    padding: '8px 24px 16px 24px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  contentContainer: {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '4px 4px 0px 0px',
    boxShadow: BigidColors.containerShadow,
    flexFlow: 'column nowrap',
    flex: '1 1 auto',
    padding: '6px',
  },
}));

export const SingleGroup: FunctionComponent = () => {
  const classes = useStyles({});
  const [group, setGroup] = useState<GroupRecord>(null);
  const { external, sharedObjectsCount, dataSource, email, membersCount } = group || {};
  const { id } = $stateParams;
  const isAciSupportForFoldersEnabled: boolean =
    getApplicationPreference('ACI_SUPPORT_FOR_FOLDERS_ENABLED')?.toString() === 'true';

  useEffect(() => {
    async function getUserData() {
      const group = await getGroup(id);
      setGroup(group);
    }

    getUserData();
  }, [id]);

  useEffect(() => {
    pageHeaderService.setTitle({
      showBackButton: true,
      defaultFrom: {
        state: 'accessGovernance',
        params: { selectedTab: 'groups' },
      },
      breadcrumbs: [
        {
          label: 'Groups',
          onClick: () => $state.go('accessGovernance', { selectedTab: 'groups' }),
        },
        { label: group?.name },
      ],
    });
  }, [group]);

  const tabsAndContentConfig: BigidTabsAndContentProps = useMemo(
    () => ({
      classes: {
        contentContainer: classes.contentContainer,
      },
      tabProps: {
        tabs: [
          {
            label: 'Members',
            data: {
              component: GroupMembers,
              customProps: { email: group?.email, name: group?.name, dataSource: group?.dataSource },
            },
          },
          {
            label: isAciSupportForFoldersEnabled ? 'Objects' : 'Files',
            data: {
              component: OpenAccessFiles,
              customProps: {
                aciEntityId: `${group?.name},${group?.email}`,
                aciEntityType: AciEntityType.Groups,
                dataSource: group?.dataSource,
              },
            },
          },
        ],
      },
    }),
    [classes.contentContainer, group?.dataSource, group?.email, group?.name],
  );

  return (
    <Fragment>
      <AciEntitySummarize
        external={external}
        dataSource={dataSource}
        email={email}
        sharedObjectsCount={sharedObjectsCount}
        membersCount={membersCount}
        type={'group'}
      />
      <BigidPaper classes={{ root: classes.paper }}>
        <div className={classes.content}>
          <BigidTabsAndContent {...tabsAndContentConfig} />
        </div>
      </BigidPaper>
    </Fragment>
  );
};
