import { BigidDownloadIcon, BigidExportIcon, BigidGoToIcon, BigidHideIcon } from '@bigid-ui/icons';

import { getFixedT } from '../../translations';
import { FilterToolbarFields } from '../../PrivacyExecutiveDashboardTypes';
import { advancedToolbarFilterMinifier, BigidAdvancedToolbarFilterUnion } from '@bigid-ui/components';
import { RiskFilterIds } from '../../../RiskRegister/useRiskQueryFilter';

const t = getFixedT('components.menu');

type MenuItem = {
  id: string;
  label: string;
  startIcon: React.ComponentType;
  onClick?: () => void;
  predicate?: (widgetContext: HideMenuItemContext) => boolean; // Predicate function to filter items
};

type HideMenuItemContext = {
  canExportCsv?: boolean;
  canDownloadPdf?: boolean;
  canGoToLink?: boolean;
};

const baseMenuItems: Omit<MenuItem, 'onClick'>[] = [
  {
    id: 'csv',
    label: t('exportCsv'),
    startIcon: BigidExportIcon,
    predicate: ({ canExportCsv }: HideMenuItemContext) => canExportCsv,
  },
  {
    id: 'pdf',
    label: t('downloadPdf'),
    startIcon: BigidDownloadIcon,
    predicate: ({ canDownloadPdf }: HideMenuItemContext) => canDownloadPdf,
  },
  {
    id: 'hide',
    label: t('hideWidget'),
    startIcon: BigidHideIcon,
  },
  {
    id: 'go',
    label: t('goTo'),
    startIcon: BigidGoToIcon,
    predicate: ({ canGoToLink }: HideMenuItemContext) => canGoToLink,
  },
];

export function generateMenuItems(
  onClickHandlers: Record<string, () => void>,
  hideMenuItemContext: HideMenuItemContext,
) {
  return baseMenuItems
    .filter(item => (item.predicate ? item.predicate(hideMenuItemContext) : true))
    .map(item => ({
      ...item,
      onClick: onClickHandlers[item.id],
    }));
}

const dashboardFilterIdsToRiskRegisterFilterIdsMapper: Record<string, RiskFilterIds> = {
  [FilterToolbarFields.LEGAL_ENTITIES]: RiskFilterIds.LEGAL_ENTITIES,
  [FilterToolbarFields.ASSETS]: RiskFilterIds.ASSETS,
  [FilterToolbarFields.VENDORS]: RiskFilterIds.VENDORS,
};

export function getRiskFilterParam(activeFilters: BigidAdvancedToolbarFilterUnion[]) {
  const allowedToRedirectFilters = activeFilters.filter(f =>
    Object.keys(dashboardFilterIdsToRiskRegisterFilterIdsMapper).includes(f.field),
  );
  const filterString = JSON.stringify(
    allowedToRedirectFilters.map(advancedToolbarFilterMinifier.getOverrideValueMinified).map(f => ({
      ...f,
      id: dashboardFilterIdsToRiskRegisterFilterIdsMapper[f.id],
    })),
  );
  return filterString;
}
