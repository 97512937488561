import React, { FC } from 'react';
import { BigidSidePanel, PrimaryButton } from '@bigid-ui/components';
import { GlossaryItem } from '../../../../administration/generalSettings/businessGlossary/businessGlossary.service';
import { styled } from '@mui/material';
import { GlossaryItemForm } from '../GlossaryItemForm/GlossaryItemForm';
import { useGlossaryItemForm } from '../GlossaryItemForm/useGlossaryItemForm';

const SidePanelBasicInfo = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type GlossaryItemSidePanelProps = {
  onSave: () => void;
  onClose: () => void;
  glossaryItem?: GlossaryItem;
};

export const GlossaryItemSidePanel: FC<GlossaryItemSidePanelProps> = ({ onSave, onClose, glossaryItem }) => {
  const { isSubmitting, handleSubmit, handleClose, formControlsRef } = useGlossaryItemForm({
    onSubmit: onSave,
    onClose,
  });
  const isOpen = !!glossaryItem;

  return (
    <BigidSidePanel
      open={isOpen}
      isLoading={isSubmitting}
      maxWidth="medium"
      title={glossaryItem?.name}
      onClose={handleClose}
      content={<GlossaryItemForm glossaryItem={glossaryItem} formControlsRef={formControlsRef} withPadding />}
      basicInfo={
        <SidePanelBasicInfo>
          <div>Type: {glossaryItem?.type}</div>
          <PrimaryButton onClick={handleSubmit} size="medium" text="Save" />
        </SidePanelBasicInfo>
      }
    />
  );
};
