import React from 'react';
import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { replace, startCase } from 'lodash';
import { EnumerationStatuses, SubScanGridRow, SubscanStage } from './ScanInsightTypes';
import { styled } from '@mui/material';
import {
  CompletedParentScanState,
  FilterColor,
  MapScanStatusToProgressStatus,
  ParentScanType,
  SubScanStates,
} from '../ScanTypes';
import {
  ASSESSMENT_SCAN_STOPPING_REASON_THRESHOLD_FOUND,
  calculateProgressBarPercentage,
  calculateSubScanRightFilter,
  getTheRequiredColorsToChip,
  getChipPropsByState,
  SCANS_NOT_EFFECTING_STATE,
  showProgressBar,
} from '../ScanUtils';
import { BigidHelpIcon } from '@bigid-ui/icons';
import { BigidTooltip } from '@bigid-ui/components';
import { getFixedT } from '../translations';

const BLANK = 'Blank';
const SCAN_TOOLTIP =
  'This is a post-scan task that runs after the scan is completed. It does not affect the scan state.';
const ProgressBarRightContent = styled('div')`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const TooltipWrapper = styled(BigidTooltip)`
  align-items: center;
  font-size: 0.75rem;
`;

const t = getFixedT('subScanConfiguration');
function initialSubscanGridColumns(
  parentScanType: ParentScanType,
  setIsCompleted: any,
): BigidGridColumn<SubScanGridRow>[] {
  return [
    {
      name: 'name',
      title: 'Sub Scan Name',
      sortingEnabled: true,
      disableTooltip: true,
      getCellValue: row => {
        return replace(row?.name, 'GenericScanner ', '');
      },
      type: BigidGridColumnTypes.TEXT,
      width: 400,
    },
    {
      name: 'type',
      title: 'Stage',
      sortingEnabled: true,
      getCellValue: row => {
        if (row.type === SubscanStage.DATA_SOURCE_SCAN) {
          return startCase(row.type) + ' - ' + startCase('data_source_scan');
        }
        return startCase(row.type);
      },
      type: BigidGridColumnTypes.TEXT,
      width: 275,
    },
    {
      name: 'state',
      title: 'Status',
      sortingEnabled: false,
      disableTooltip: true,
      getCellValue: ({ state, type }) => {
        let chipProps = getChipPropsByState(state);
        if (SCANS_NOT_EFFECTING_STATE.includes(type)) {
          chipProps = {
            ...chipProps,
            icon: <BigidHelpIcon />,
            iconPlacement: 'right',
            tooltipProps: { title: SCAN_TOOLTIP },
          };
        }

        return {
          chip: { ...chipProps },
        };
      },
      type: BigidGridColumnTypes.CHIP,
      width: 250,
    },
    {
      name: 'parts',
      title: 'Parts',
      width: 350,
      getCellValue: row => {
        const {
          parts: { total, complete, failed },
          type,
          state,
          isFailedObjects,
          enumerationStatus,
          isRetryAvailable,
          isRbacPermitted,
        } = row;
        return showProgressBar(type)
          ? {
              progressBar: {
                percentage: calculateProgressBarPercentage({
                  complete,
                  failed,
                  total,
                  type,
                  state,
                  isSubScan: true,
                  parentScanType,
                }),
                status: MapScanStatusToProgressStatus[state],
                width: '200px',
                size: 'large',
                color: state === CompletedParentScanState.FAILED ? FilterColor.RED : FilterColor.DEFAULT,
                ...(parentScanType === ParentScanType.ASSESSMENT_SCAN &&
                (state === CompletedParentScanState.COMPLETED ||
                  state === CompletedParentScanState.COMPLETED_WITH_FAILURES) &&
                failed + complete < total
                  ? {
                      rightFilter: (
                        <ProgressBarRightContent>
                          <span>
                            {calculateSubScanRightFilter({
                              complete,
                              failed,
                              total,
                              type,
                              state,
                              isFailedObjects,
                              scan: row,
                              isShouldDisplayRetryButton:
                                isRetryAvailable && isRbacPermitted && type === SubscanStage.DATA_SOURCE_SCAN,
                              setIsCompleted,
                            })}
                          </span>
                          <BigidTooltip title={ASSESSMENT_SCAN_STOPPING_REASON_THRESHOLD_FOUND}>
                            <div>
                              <BigidHelpIcon />
                            </div>
                          </BigidTooltip>
                        </ProgressBarRightContent>
                      ),
                    }
                  : {
                      rightFilter: calculateSubScanRightFilter({
                        complete,
                        failed,
                        total,
                        type,
                        state,
                        isFailedObjects,
                        enumerationStatus,
                        scan: row,
                        isShouldDisplayRetryButton:
                          isRetryAvailable && isRbacPermitted && type === SubscanStage.DATA_SOURCE_SCAN,
                        setIsCompleted,
                      }),
                    }),
              },
            }
          : null;
      },
      type: BigidGridColumnTypes.PROGRESS_BAR,
    },
    {
      name: 'startedAt',
      title: 'Started At',
      getCellValue: row => row.startedAt,
      type: BigidGridColumnTypes.DATE,
      sortingEnabled: false,
      width: 200,
    },
    {
      name: 'updated_at',
      title: 'Updated At',
      getCellValue: row => row.updatedAt,
      type: BigidGridColumnTypes.DATE,
      sortingEnabled: true,
      width: 200,
    },
    {
      name: '_id',
      title: 'Scan Id',
      getCellValue: row => row._id,
      type: BigidGridColumnTypes.TEXT,
      isHiddenByDefault: true,
      width: 300,
    },
    {
      name: 'enumerationState',
      title: 'Enumeration Status',
      getCellValue: row => {
        if (row.enumerationStatus) {
          return {
            chip: {
              label: row.enumerationStatus,
              ...getTheRequiredColorsToChip(row.enumerationStatus),
            },
          };
        }
      },
      type: BigidGridColumnTypes.CHIP,
      isHiddenByDefault: true,
      width: 300,
    },
    {
      name: 'isDifferential',
      title: 'Is Differential',
      getCellValue: row => {
        if (row.type !== SubscanStage.DATA_SOURCE_SCAN) {
          return;
        }
        return (row.isDifferential ? t('yes') : t('no')) as string;
      },
      type: BigidGridColumnTypes.TEXT,
      isHiddenByDefault: false,
      width: 200,
    },
  ];
}

function initialSubscanGridColumnsForLabeling(parentScanType: ParentScanType): BigidGridColumn<SubScanGridRow>[] {
  return [
    {
      name: 'name',
      title: 'Sub Scan Name',
      sortingEnabled: true,
      getCellValue: row => {
        return replace(row?.name, 'GenericScanner ', '');
      },
      type: BigidGridColumnTypes.TEXT,
      width: 400,
    },
    {
      name: 'type',
      title: 'Stage',
      sortingEnabled: true,
      getCellValue: row => {
        if (row.type === SubscanStage.DATA_SOURCE_SCAN) {
          return startCase(row.type) + ' - ' + startCase('data_source_scan');
        }
        return startCase(row.type);
      },
      type: BigidGridColumnTypes.TEXT,
      width: 275,
    },
    {
      name: 'state',
      title: 'Status',
      sortingEnabled: false,
      getCellValue: row => startCase(row.state),
      type: BigidGridColumnTypes.TEXT,
      width: 250,
    },
    {
      name: 'parts',
      title: 'Parts',
      width: 350,
      getCellValue: row => {
        const {
          parts: { total, complete, failed },
          type,
          state,
          isFailedObjects,
          enumerationStatus,
          isRetryAvailable,
          isRbacPermitted,
        } = row;

        return showProgressBar(type)
          ? {
              progressBar: {
                percentage: calculateProgressBarPercentage({
                  complete,
                  failed,
                  total,
                  type,
                  state,
                  isSubScan: true,
                  parentScanType,
                }),
                status: MapScanStatusToProgressStatus[state],
                width: '200px',
                size: 'large',
                color: state === CompletedParentScanState.FAILED ? FilterColor.RED : FilterColor.DEFAULT,
                ...(parentScanType === ParentScanType.ASSESSMENT_SCAN &&
                (state === CompletedParentScanState.COMPLETED ||
                  state === CompletedParentScanState.COMPLETED_WITH_FAILURES) &&
                failed + complete < total
                  ? {
                      rightFilter: (
                        <ProgressBarRightContent>
                          <span>
                            {calculateSubScanRightFilter({
                              complete,
                              failed,
                              total,
                              type,
                              state,
                              isFailedObjects,
                              enumerationStatus,
                              scan: row,
                              isShouldDisplayRetryButton:
                                isRetryAvailable && isRbacPermitted && type === SubscanStage.DATA_SOURCE_SCAN,
                            })}
                          </span>
                          <BigidTooltip title={ASSESSMENT_SCAN_STOPPING_REASON_THRESHOLD_FOUND}>
                            <div>
                              <BigidHelpIcon />
                            </div>
                          </BigidTooltip>
                        </ProgressBarRightContent>
                      ),
                    }
                  : {
                      rightFilter: calculateSubScanRightFilter({
                        complete,
                        failed,
                        total,
                        type,
                        state,
                        isFailedObjects,
                        enumerationStatus,
                        scan: row,
                        isShouldDisplayRetryButton:
                          isRetryAvailable && isRbacPermitted && type === SubscanStage.DATA_SOURCE_SCAN,
                      }),
                    }),
              },
            }
          : null;
      },
      type: BigidGridColumnTypes.PROGRESS_BAR,
    },
    {
      name: 'startedAt',
      title: 'Started At',
      getCellValue: row => row.startedAt,
      type: BigidGridColumnTypes.DATE,
      sortingEnabled: false,
      width: 200,
    },
    {
      name: 'updated_at',
      title: 'Updated At',
      getCellValue: row => row.updatedAt,
      type: BigidGridColumnTypes.DATE,
      sortingEnabled: true,
      width: 200,
    },
    {
      name: '_id',
      title: 'Scan Id',
      getCellValue: row => row._id,
      type: BigidGridColumnTypes.TEXT,
      isHiddenByDefault: true,
      width: 300,
    },
  ];
}

export const getInitialSubScanGridColumns = (type: ParentScanType, setIsCompleted: any) => {
  if (type === ParentScanType.DS_TAG) {
    return initialSubscanGridColumnsForLabeling(type);
  }
  return initialSubscanGridColumns(type, setIsCompleted);
};

const initialScanInsightFilters: BigidGridWithToolbarProps<SubScanGridRow>['filterToolbarConfig'] = {
  filters: [
    {
      title: 'Status',
      field: 'state',
      operator: 'in',
      options: [...Object.values(SubScanStates)].map(state => ({
        label: startCase(state),
        value: state,
        isSelected: false,
      })),
      value: [],
      isSelected: true,
      listWidth: 400,
    },
    {
      title: 'Stage',
      field: 'type',
      operator: 'in',
      options: Object.values(SubscanStage).map(type => ({
        label: type === SubscanStage.DATA_SOURCE_SCAN ? startCase('data_source_scan') : startCase(type),
        value: type,
        isSelected: false,
      })),
      value: [],
      isSelected: true,
      listWidth: 400,
    },
    {
      title: 'Enumeration Status',
      field: 'enumerationState',
      operator: 'in',
      options: [...Object.values(EnumerationStatuses), BLANK].map(type => ({
        label: startCase(type),
        value: type !== BLANK ? type : null,
        isSelected: false,
      })),
      value: [],
      listWidth: 400,
    },
  ],
  searchConfig: {
    searchFilterKeys: ['name'],
    initialValue: '',
    placeholder: 'Sub Scan Name',
    operator: 'textSearch',
    autoSubmit: true,
  },
};

const initialScanInsightFiltersForLabeling: BigidGridWithToolbarProps<SubScanGridRow>['filterToolbarConfig'] = {
  filters: [
    {
      title: 'Status',
      field: 'state',
      operator: 'in',
      options: [...Object.values(SubScanStates)].map(state => ({
        label: startCase(state),
        value: state,
        isSelected: false,
      })),
      value: [],
      isSelected: true,
      listWidth: 400,
    },
    {
      title: 'Stage',
      field: 'type',
      operator: 'in',
      options: Object.values(SubscanStage).map(type => ({
        label: type === SubscanStage.DATA_SOURCE_SCAN ? startCase('data_source_scan') : startCase(type),
        value: type,
        isSelected: false,
      })),
      value: [],
      isSelected: true,
      listWidth: 400,
    },
  ],
  searchConfig: {
    searchFilterKeys: ['name'],
    initialValue: '',
    placeholder: 'Sub Scan Name',
    operator: 'textSearch',
    autoSubmit: true,
  },
};

export async function getInitialSubScanFilters(type: ParentScanType) {
  if (type === ParentScanType.DS_TAG) {
    return initialScanInsightFiltersForLabeling;
  }
  return initialScanInsightFilters;
}
