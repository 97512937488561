import React, { FC, useMemo, useState } from 'react';

import {
  BigidAdvancedToolbarFilterUnion,
  BigidBody1,
  BigidToggleButton,
  BigidWidgetContainer,
} from '@bigid-ui/components';
import { BigidColumnChart, BigidDonutChart } from '@bigid-ui/visualisation';
import { BigidNoDataIllustration } from '@bigid-ui/icons';

import { useLocalTranslation } from '../translations';
import {
  Categories,
  DonutChartData,
  PerformanceColors,
  PerformanceOperationalSuccessWidgetApiData,
  PerformanceOperationalSuccessWidgetData,
  WidgetProps,
} from '../PrivacyExecutiveDashboardTypes';
import { Dropdown, Menu } from './helpers/HelperComponents';
import {
  DonutLegendMarkerGrayWrapper,
  DonutLegendMarkerWrapper,
  PerformanceOperationalSuccessWrapper,
} from './styles/WidgetStyles';
import { useFetchWidgetData } from './hooks/useFetchWidgetData';
import { generateMenuItems } from './helpers/generateMenuItems';

const TABS = [
  { label: 'Closed', value: 'closed' },
  { label: 'Open', value: 'open' },
];

const dropdownCategories = Object.values(Categories).map(category => ({
  id: category as string,
  value: category as string,
  displayValue: category as string,
}));

export const PerformanceOperationalSuccessWidget: FC<WidgetProps> = ({ title }) => {
  const { t } = useLocalTranslation('widgets');
  const { data, isLoading } = useFetchWidgetData(fetchPerformanceOperationalSuccessWidgetData);
  const [currentTab, setCurrentTab] = useState(0);
  const [category, setCategory] = useState(dropdownCategories[0]);
  const { donut, chart } = data?.[currentTab] || {};
  const chartData = chart?.[category.value] || [];

  const menuItems = useMemo(() => {
    const onClickHandlers = {
      csv: () => console.log('Export CSV'), // TODO: add a onClick handler
      pdf: () => console.log('Download PDF'), // TODO: add a onClick handler
      hide: () => console.log('Hide Widget'), // TODO: add a onClick handler
      go: () => console.log('Go To'), // TODO: add a onClick handler
    };

    const hideMenuItemContext = {
      canExportCsv: true, // TODO: add a condition
      canDownloadPdf: true, // TODO: add a condition
      canGoToLink: true, // TODO: add a condition
    };

    const menuItems = generateMenuItems(onClickHandlers, hideMenuItemContext);
    return menuItems;
  }, []);

  return (
    <>
      <BigidWidgetContainer
        content={
          <PerformanceOperationalSuccessWrapper>
            <BigidDonutChart
              entityName={TABS[currentTab].label}
              data={donut || []}
              legendProps={{
                legendConfig: { orientation: 'column' },
                legendItemMarkerType: 'checkbox',
                legendLabelFormatter: ({ category, percentageValue, value }: DonutChartData) => (
                  <DonutLegendMarkerWrapper>
                    <span>{category}</span>
                    <span>
                      {`${percentageValue}%`}
                      <DonutLegendMarkerGrayWrapper>{` (${value})`}</DonutLegendMarkerGrayWrapper>
                    </span>
                  </DonutLegendMarkerWrapper>
                ),
              }}
              numberFormat="#."
            />
            <BigidColumnChart
              colorDataMap={PerformanceColors}
              data={chartData}
              mode="stacked"
              legendProps={{ hideLegend: true }}
            />
          </PerformanceOperationalSuccessWrapper>
        }
        titleConfig={{
          title,
        }}
        actionsSlot={
          <>
            <Dropdown
              items={dropdownCategories}
              onItemSelect={([option]) => setCategory(option)}
              currentItem={[category]}
            />
            <BigidToggleButton
              size="small"
              toggleButtons={TABS}
              initialSelected={TABS[currentTab].value}
              onChange={(_, newValue) => setCurrentTab(+(newValue === TABS[1].value))}
            />
            <Menu menuItems={menuItems} />
          </>
        }
        isLoading={isLoading}
        emptyStateConfig={{
          isEmpty: data?.length === 0,
          illustration: BigidNoDataIllustration,
          illustrationSize: 'small',
          description: <BigidBody1>{t('emptyState.defaultText')}</BigidBody1>,
        }}
      />
    </>
  );
};

const fetchPerformanceOperationalSuccessWidgetData = (
  activeFilters: BigidAdvancedToolbarFilterUnion[],
): Promise<PerformanceOperationalSuccessWidgetData[]> => {
  console.log('activeFilters', activeFilters);
  return new Promise(resolve => {
    setTimeout(resolve, 1000, attachAdditionalWidgetProps(data));
  });
};

const attachAdditionalWidgetProps = (
  data: PerformanceOperationalSuccessWidgetApiData[],
): PerformanceOperationalSuccessWidgetData[] => {
  return data.map(d => ({
    ...d,
    donut: d.donut.map(item => {
      return {
        ...item,
        color: PerformanceColors[item.category as keyof typeof PerformanceColors],
        isActive: true,
      };
    }),
    chart: d.chart.reduce((acc, item) => {
      acc[item.category] = item.data.map(item => ({
        category: item.type,
        ...item.data.reduce((acc, item) => {
          acc[item.category] = item.value;
          return acc;
        }, {} as Record<string, number>),
      }));
      return acc;
    }, {} as PerformanceOperationalSuccessWidgetData['chart']),
  }));
};

const data = [
  {
    type: 'Open',
    donut: [
      {
        category: 'On time',
        value: 200,
        percentageValue: 55,
      },
      {
        category: 'Almost overdue',
        value: 100,
        percentageValue: 35,
      },
      {
        category: 'Overdue',
        value: 20,
        percentageValue: 10,
      },
    ],
    chart: [
      {
        category: 'All data',
        data: [
          {
            type: 'Requests',
            data: [
              {
                category: 'On time',
                value: 56,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'Assessments',
            data: [
              {
                category: 'On time',
                value: 45,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'Processes',
            data: [
              {
                category: 'On time',
                value: 45,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 65,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 35,
                percentageValue: 10,
              },
            ],
          },
        ],
      },
      {
        category: 'Requests',
        data: [
          {
            type: 'View',
            data: [
              {
                category: 'On time',
                value: 130,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 56,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'Edit',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'Delete',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'Appeal',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
        ],
      },
      {
        category: 'Assessments',
        data: [
          {
            type: 'PIA',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'Vendor assessment',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'AI assessment',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'DPIA',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'Other',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
        ],
      },
      {
        category: 'Processes',
        data: [
          {
            type: 'RoPa',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'Other',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'Closed',
    donut: [
      {
        category: 'On time',
        value: 200,
        percentageValue: 55,
      },
      {
        category: 'Almost overdue',
        value: 100,
        percentageValue: 35,
      },
      {
        category: 'Overdue',
        value: 20,
        percentageValue: 10,
      },
    ],
    chart: [
      {
        category: 'All data',
        data: [
          {
            type: 'Requests',
            data: [
              {
                category: 'On time',
                value: 56,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'Assessments',
            data: [
              {
                category: 'On time',
                value: 45,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'Processes',
            data: [
              {
                category: 'On time',
                value: 45,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 65,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 35,
                percentageValue: 10,
              },
            ],
          },
        ],
      },
      {
        category: 'Requests',
        data: [
          {
            type: 'View',
            data: [
              {
                category: 'On time',
                value: 34,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 67,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'Edit',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'Delete',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'Appeal',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
        ],
      },
      {
        category: 'Assessments',
        data: [
          {
            type: 'PIA',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'Vendor assessment',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'AI assessment',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'DPIA',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'Other',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
        ],
      },
      {
        category: 'Processes',
        data: [
          {
            type: 'RoPa',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
          {
            type: 'Other',
            data: [
              {
                category: 'On time',
                value: 200,
                percentageValue: 55,
              },
              {
                category: 'Almost overdue',
                value: 100,
                percentageValue: 35,
              },
              {
                category: 'Overdue',
                value: 20,
                percentageValue: 10,
              },
            ],
          },
        ],
      },
    ],
  },
];
