import React from 'react';
import { DsTypeDynamicCost } from './DsTypeDynamicCost';
import { DsGroup, DsType } from './SetDsTypesDynamicCostModal';

interface DsTypeGroupProps {
  dsGroups: DsGroup[];
  allDsTypes: DsType[];
  handleDsTypeGroupChange: (groupId: string, updatedDsTypes: DsType[]) => void;
  handleToggleCollapse: (groupId: string) => void;
  existingGroupConfigs: { cost: number; volumeUnit: 'TB' | 'GB' }[];
  handleRemoveIfEmpty: (groupId: string) => void;
}

export const DsTypeGroup: React.FC<DsTypeGroupProps> = ({
  dsGroups,
  allDsTypes,
  handleDsTypeGroupChange,
  handleToggleCollapse,
  existingGroupConfigs,
  handleRemoveIfEmpty,
}) => {
  return (
    <>
      {dsGroups.map(group => {
        const configuredDsTypes = allDsTypes.filter(dsType => group.configured.includes(dsType.serviceName));
        const unconfiguredDsTypes = allDsTypes.filter(
          dsType =>
            (dsType.cost === undefined || dsType.volumeUnit === undefined) &&
            !group.configured.includes(dsType.serviceName),
        );
        const combinedDsTypes = [...configuredDsTypes, ...unconfiguredDsTypes];

        return (
          <div key={group.id}>
            <DsTypeDynamicCost
              groupId={group.id}
              dsTypes={combinedDsTypes}
              onBlockChange={handleDsTypeGroupChange}
              isDefault={false}
              isCollapsed={group.isCollapsed}
              onToggleCollapse={() => handleToggleCollapse(group.id)}
              existingGroupConfigs={existingGroupConfigs}
              onRemoveIfEmpty={handleRemoveIfEmpty}
              isNew={!!group.isNew}
            />
          </div>
        );
      })}
    </>
  );
};
