export const getMessageFromImportError = (error: any) => {
  if (error.response?.data) {
    const responseError = error.response?.data;
    if (responseError.writeErrors?.length > 0) {
      const mongoError = responseError.writeErrors[0];
      if (mongoError.code === 11000 && mongoError.errmsg?.includes('index: type_1_name_1')) {
        return `Item with name "${mongoError.op?.u?.$set.name}" and type "${mongoError.op?.u?.$set.type}" already exists with another id.`;
      }
      return 'An error happened while updating data in the database.';
    }
    if (responseError.code === 'CSV_RECORD_DONT_MATCH_COLUMNS_LENGTH') {
      return `Line ${responseError.lines} has a different number of columns.`;
    }
  }

  return 'Error occurred while importing Business Glossary';
};
