import { CloudProvider } from './types';
import { DiscoveryAuthTypes } from './auth';

export interface ErrorHintContent {
  title: string;
  options: {
    title: string;
    content: string;
  }[];
}

export enum DiscoveryErrors {
  NAME_DUPLICATION = 'nameDuplication',
  PERMISSIONS_COMMON = 'common',
  AWS_WRONG_CREDENTIALS_COMMON = 'awsWrongCredentialsCommon',
  AWS_VALIDATION = 'awsNotValidCredentialsCommon',
  AWS_WRONG_PERMISSIONS = 'awsWrongPermissionsCommon',
  AZURE_WRONG_CREDENTIALS_COMMON = 'azureWrongCredentialsCommon',
  AZURE_FAILED_READ_PERM = 'azureFailedReadPerm',
  PRESET_NOT_VALID = 'presetNotValid',
  APP_SETUP_NOT_VALID = 'appSetupNotValid',
  JSON_NOT_VALID = 'jsonNotValid',
  CERT_SELF_SIGNED = 'certSelfSigned',
  ASSUME_ROLE_NOT_AUTHORIZED = 'assumeRoleNotAuthorized',
  SAAS_EXTERNAL_ID_IS_NOT_INITIALIZED = 'SAASExternalIDSsNotInitialized',
  SIGNATURE = 'signature',
  EXTERNAL_IAM_ROLE_ARN = 'externalIamRoleArn',
  NOT_FOUND = '',
}

export const ERRORS_MESSAGES_TO_KEY = [
  {
    messages: ['An action failed. Authentication failed. Invalid AWS credentials'],
    key: DiscoveryErrors.AWS_WRONG_CREDENTIALS_COMMON,
  },
  {
    messages: ['E11000 duplicate key error collection'],
    key: DiscoveryErrors.NAME_DUPLICATION,
  },
  {
    messages: ['An action failed. Bad request: [validation'],
    key: DiscoveryErrors.AWS_VALIDATION,
  },
  {
    messages: ['An action failed. Permissions validation failed'],
    key: DiscoveryErrors.AWS_WRONG_PERMISSIONS,
  },
  {
    messages: ['An action failed. Failed to read Azure permissions'],
    key: DiscoveryErrors.AZURE_FAILED_READ_PERM,
  },
  {
    messages: ['The Preset is not valid.'],
    key: DiscoveryErrors.PRESET_NOT_VALID,
  },
  {
    messages: ['Failed to call application execution endpoint'],
    key: DiscoveryErrors.APP_SETUP_NOT_VALID,
  },
  {
    messages: [
      'An action failed. Unexpected token',
      'Expected property name or',
      'An action failed. No key or keyFile set',
      'The incoming JSON object does not contain a',
    ],
    key: DiscoveryErrors.JSON_NOT_VALID,
  },
  {
    messages: ['self-signed certificate in certificate chain'],
    key: DiscoveryErrors.CERT_SELF_SIGNED,
  },
  {
    messages: ['is not authorized to perform: sts:AssumeRole on resource:'],
    key: DiscoveryErrors.ASSUME_ROLE_NOT_AUTHORIZED,
  },
  {
    messages: ['SAAS External ID is not initialized'],
    key: DiscoveryErrors.SAAS_EXTERNAL_ID_IS_NOT_INITIALIZED,
  },
  {
    messages: ['The request signature we calculated does not match the signature you provided'],
    key: DiscoveryErrors.SIGNATURE,
  },
  {
    messages: ['An action failed. External IAM role ARN'],
    key: DiscoveryErrors.EXTERNAL_IAM_ROLE_ARN,
  },
];

const ERROR_HINT_AWS_CREDENTIALS: ErrorHintContent = {
  title: 'Unable to authorize user due to invalid credentials.',
  options: [
    {
      title: 'Check if you enter correct credentials',
      content: 'Open your AWS console and get correct credentials',
    },
  ],
};

const ERROR_HINT_AWS_IAM: ErrorHintContent = {
  title: 'Invalid External IAM role ARN',
  options: [
    {
      title: 'Verify your IAM user credentials',
      content: 'Open your AWS console and get correct credentials',
    },
    {
      title: 'Verify your IAM user permissions',
      content: 'Open your AWS console and set correct permissions',
    },
  ],
};

const PERMISSIONS_COMMON_ERROR: ErrorHintContent = {
  title: 'Check permissions error',
  options: [
    {
      title: 'Problems with checking permissions',
      content: 'Check whether you have completed all the prerequisite steps',
    },
  ],
};

const NAME_DUPLICATION_ERROR: ErrorHintContent = {
  title: 'A preset with this name already exists',
  options: [
    {
      title: 'Rename',
      content: 'Try a different name or add characters to the current one',
    },
  ],
};

const PRESET_NOT_VALID_ERROR: ErrorHintContent = {
  title: 'The configuration cannot be saved',
  options: [
    {
      title: 'Fill in all required fields',
      content: 'The configuration cannot be saved because one of the fields does not meet the requirements.',
    },
  ],
};

const APP_SETUP_NOT_VALID_ERROR: ErrorHintContent = {
  title: 'Failed to call application execution endpoint',
  options: [
    {
      title: 'Edit app setup',
      content: 'Try Edit App and enter the correct BigID Base URL.',
    },
  ],
};

const JSON_NOT_VALID_ERROR: ErrorHintContent = {
  title: 'Failed to parse field value',
  options: [
    {
      title: 'Check if you enter correct JSON',
      content: '',
    },
  ],
};

const BAD_PARAMS_COMMON_ERROR: ErrorHintContent = {
  title: 'The provided parameters could not be validated.',
  options: [
    {
      title: 'Check entered parameters',
      content: '',
    },
  ],
};

const WRONG_PERMISSIONS_COMMON_ERROR: ErrorHintContent = {
  title: 'Permission validation error.',
  options: [
    {
      title: 'Check permissions',
      content: 'This is likely due to insufficient permissions.',
    },
  ],
};
const SELF_SIGNED_ERROR: ErrorHintContent = {
  title: 'Certificate Error.',
  options: [
    {
      title: 'Fix Self-signed certificate in certificate chain',
      content: 'Add a properly signed certificate for the API',
    },
    {
      title: 'Disable validation',
      content: 'Set Ignore Certificate Validation to true',
    },
  ],
};

const ERROR_HINT_ASSUME_ROLE_NOT_AUTHORIZED: ErrorHintContent = {
  title: 'Insufficient privileges to assume the specified role',
  options: [
    {
      title: 'Check and add the necessary permissions ',
      content: 'In the IAM policy so that the user can perform sts:AssumeRole on the specified resource.',
    },
    {
      title: "Verify or update the role's Trust Relationship",
      content: 'Under the "Trust relationships" tab to include the account/role that is making the call.',
    },
  ],
};

const ERROR_HINT_SAAS: ErrorHintContent = {
  title: 'SAAS External ID is not initialized',
  options: [],
};

const ERROR_HINT_SIGNATURE: ErrorHintContent = {
  title: 'The request signature we calculated does not match the signature you provided.',
  options: [
    {
      title: 'Check your AWS Secret Access Key and signing method. ',
      content: 'Consult the service documentation for details',
    },
  ],
};

const ERROR_HINT_ARN: ErrorHintContent = {
  title: 'External IAM role ARN is invalid',
  options: [
    {
      title: 'Check your External IAM role ARN',
      content: 'Consult the service documentation for details',
    },
  ],
};

export const COMMON_FOR_ALL_TYPES_ERRORS: { [key in DiscoveryErrors]?: ErrorHintContent } = {
  [DiscoveryErrors.APP_SETUP_NOT_VALID]: APP_SETUP_NOT_VALID_ERROR,
  [DiscoveryErrors.PERMISSIONS_COMMON]: PERMISSIONS_COMMON_ERROR,
  [DiscoveryErrors.NAME_DUPLICATION]: NAME_DUPLICATION_ERROR,
  [DiscoveryErrors.PRESET_NOT_VALID]: PRESET_NOT_VALID_ERROR,
};

export const ERRORS_HELP_MAPPING: {
  [key in CloudProvider]?: {
    [key in DiscoveryAuthTypes]?: {
      [key in DiscoveryErrors]?: ErrorHintContent;
    };
  };
} = {
  [CloudProvider.AWS]: {
    [DiscoveryAuthTypes.IAM_ROLE]: {
      [DiscoveryErrors.AWS_WRONG_CREDENTIALS_COMMON]: ERROR_HINT_AWS_IAM,
      [DiscoveryErrors.AWS_VALIDATION]: BAD_PARAMS_COMMON_ERROR,
      [DiscoveryErrors.AWS_WRONG_PERMISSIONS]: WRONG_PERMISSIONS_COMMON_ERROR,
      [DiscoveryErrors.ASSUME_ROLE_NOT_AUTHORIZED]: ERROR_HINT_ASSUME_ROLE_NOT_AUTHORIZED,
      [DiscoveryErrors.SAAS_EXTERNAL_ID_IS_NOT_INITIALIZED]: ERROR_HINT_SAAS,
      [DiscoveryErrors.SIGNATURE]: ERROR_HINT_SIGNATURE,
      [DiscoveryErrors.EXTERNAL_IAM_ROLE_ARN]: ERROR_HINT_ARN,
    },
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: {
      [DiscoveryErrors.AWS_WRONG_CREDENTIALS_COMMON]: ERROR_HINT_AWS_IAM,
      [DiscoveryErrors.AWS_VALIDATION]: BAD_PARAMS_COMMON_ERROR,
      [DiscoveryErrors.AWS_WRONG_PERMISSIONS]: WRONG_PERMISSIONS_COMMON_ERROR,
      [DiscoveryErrors.ASSUME_ROLE_NOT_AUTHORIZED]: ERROR_HINT_ASSUME_ROLE_NOT_AUTHORIZED,
      [DiscoveryErrors.SAAS_EXTERNAL_ID_IS_NOT_INITIALIZED]: ERROR_HINT_SAAS,
      [DiscoveryErrors.SIGNATURE]: ERROR_HINT_SIGNATURE,
      [DiscoveryErrors.EXTERNAL_IAM_ROLE_ARN]: ERROR_HINT_ARN,
    },
    [DiscoveryAuthTypes.CREDENTIALS]: {
      [DiscoveryErrors.AWS_WRONG_CREDENTIALS_COMMON]: ERROR_HINT_AWS_CREDENTIALS,
      [DiscoveryErrors.AWS_VALIDATION]: BAD_PARAMS_COMMON_ERROR,
      [DiscoveryErrors.AWS_WRONG_PERMISSIONS]: WRONG_PERMISSIONS_COMMON_ERROR,
      [DiscoveryErrors.ASSUME_ROLE_NOT_AUTHORIZED]: ERROR_HINT_ASSUME_ROLE_NOT_AUTHORIZED,
      [DiscoveryErrors.SIGNATURE]: ERROR_HINT_SIGNATURE,
    },
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: {
      [DiscoveryErrors.AWS_WRONG_CREDENTIALS_COMMON]: ERROR_HINT_AWS_CREDENTIALS,
      [DiscoveryErrors.AWS_VALIDATION]: BAD_PARAMS_COMMON_ERROR,
      [DiscoveryErrors.AWS_WRONG_PERMISSIONS]: WRONG_PERMISSIONS_COMMON_ERROR,
      [DiscoveryErrors.ASSUME_ROLE_NOT_AUTHORIZED]: ERROR_HINT_ASSUME_ROLE_NOT_AUTHORIZED,
      [DiscoveryErrors.SIGNATURE]: ERROR_HINT_SIGNATURE,
    },
  },
  [CloudProvider.AZURE]: {
    [DiscoveryAuthTypes.IAM_ROLE]: {
      [DiscoveryErrors.AZURE_WRONG_CREDENTIALS_COMMON]: PERMISSIONS_COMMON_ERROR,
      [DiscoveryErrors.AZURE_FAILED_READ_PERM]: PERMISSIONS_COMMON_ERROR,
    },
    [DiscoveryAuthTypes.CREDENTIALS]: {
      [DiscoveryErrors.AZURE_WRONG_CREDENTIALS_COMMON]: PERMISSIONS_COMMON_ERROR,
      [DiscoveryErrors.AZURE_FAILED_READ_PERM]: PERMISSIONS_COMMON_ERROR,
    },
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: {
      [DiscoveryErrors.AZURE_WRONG_CREDENTIALS_COMMON]: PERMISSIONS_COMMON_ERROR,
      [DiscoveryErrors.AZURE_FAILED_READ_PERM]: PERMISSIONS_COMMON_ERROR,
    },
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: {
      [DiscoveryErrors.AZURE_WRONG_CREDENTIALS_COMMON]: PERMISSIONS_COMMON_ERROR,
      [DiscoveryErrors.AZURE_FAILED_READ_PERM]: PERMISSIONS_COMMON_ERROR,
    },
  },
  [CloudProvider.GCP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: {
      [DiscoveryErrors.PRESET_NOT_VALID]: PRESET_NOT_VALID_ERROR,
      [DiscoveryErrors.APP_SETUP_NOT_VALID]: APP_SETUP_NOT_VALID_ERROR,
      [DiscoveryErrors.JSON_NOT_VALID]: JSON_NOT_VALID_ERROR,
    },
  },
  [CloudProvider.ONTAP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: {
      [DiscoveryErrors.PRESET_NOT_VALID]: PRESET_NOT_VALID_ERROR,
      [DiscoveryErrors.APP_SETUP_NOT_VALID]: APP_SETUP_NOT_VALID_ERROR,
      [DiscoveryErrors.CERT_SELF_SIGNED]: SELF_SIGNED_ERROR,
    },
  },
};
