import {
  BigidCaption,
  BigidChipIconType,
  BigidColors,
  BigidConfidenceIndicator,
  BigidConfidenceLevel,
  BigidContentItem,
  BigidEditableChipAreaEntity,
  BigidFilter,
  BigidHighlightIndicator,
  BigidIcon,
  BigidIconSize,
  BigidIconSourceType,
  BigidPaper,
  BigidStatusBadge,
  BigidStatusBadgeSize,
  BigidStatusBadgeType,
  BigidTagBaseProps,
  BigidToolbarGlobalActionButton,
  BigidTooltip,
  objectToQueryString,
  QueryParams,
  SecondaryButton,
  BigidColorSchemesVars,
  entityEventsEmitter,
  EntityEvents,
} from '@bigid-ui/components';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridRow,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  ChipsFormatterProps,
  EntitiesCounterFormatterProps,
  TagsFormatterProps,
} from '@bigid-ui/grid';
import makeStyles from '@mui/styles/makeStyles';
import { EmojiObjectsOutlined, LocalOfferOutlined } from '@mui/icons-material';
import { CATALOG_PERMISSIONS, TAGS_PERMISSIONS } from '@bigid/permissions';
import classNames from 'classnames';
import { capitalize, isEqual } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import linkIcon from '../../../assets/icons/link.svg';
import { ConfidenceLevelExplanation } from '../../../components/ConfidenceLevelExplanation/ConfidenceLevelExplanation';
import { notificationService } from '../../../services/notificationService';
import { isPermitted } from '../../../services/userPermissionsService';
import { DataCatalogRecord } from '../DataCatalogService';
import {
  AttributeRanked,
  ColumnBusinessAttribute,
  ColumnBusinessAttributePopulatedBy,
  DataCatalogObjectColumn,
  getColumnsByObjectName,
  getColumnsCount,
} from './DataCatalogColumnsService';
import { AttributeMappingDialog, AttributeMappingDialogProps } from './modalDialogEditors/AttributeMappingDialog';
import { BusinessAttributeDialog, BusinessAttributeDialogProps } from './modalDialogEditors/BusinessAttributeDialog';
import { LinkedColumnsDialog, LinkedColumnsDialogProps } from './modalDialogEditors/LinkedColumnsDialog';
import { SimilarColumnsDialog, SimilarColumnsDialogProps } from './modalDialogEditors/SimilarColumnsDialog';
import { TagsDialog, TagsDialogProps } from './modalDialogEditors/TagsDialog';
import { ColumnWidgetsWrapper } from './widgets/ColumnWidgetsWrapper';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { BigidAiIcon, BigidFlowIcon, BigidTermIcon } from '@bigid-ui/icons';
import { CatalogEventsEnum } from '../events';
import { getTagFormattedName, getTagIcon } from '../../TagsManagement/TagsManagementUtils';
import { analyticsService } from '../../../services/analyticsService';
import { useLocalTranslation } from '../translations';
import { TagAssignmentTarget } from '../../TagsManagement/TagsManagementService';
import { PagesWithSidePanel } from '../../DataExplorerSearchResults/SearchGrid/GridComponents/sidePanels/CatalogSidePanel/CatalogSidePanel';
import { isBusinessTermsEnabled } from '../../../utilities/featureFlagUtils';
import { BusinessAttributeSuggestionsDialog } from './modalDialogEditors/BusinessAttributeSuggestionsDialog';

const COLUMN_WIDGET_WIDTH = 335;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
  },
  //TODO: temporal solution ,remove when BigidGrid is replaced with BigidGridWithToolbar
  toolbarWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 5px 10px 5px',
    height: '45px',
  },
  lineageTriggerIconWrapper: {
    display: 'flex',
  },
  gridWrapper: {
    display: 'flex',
    padding: '0 5px 5px 5px',
    height: 'calc(100% - 45px)',
  },
  grid: {
    display: 'flex',
    width: '100%',
  },
  shrinkGrid: {
    width: `calc(100% - ${COLUMN_WIDGET_WIDTH}px)`,
    paddingRight: '16px',
  },
  columnName: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export type ColumnsGridColumn = Omit<DataCatalogObjectColumn, 'tags'> &
  BigidGridRow & {
    columnTags?: TagsFormatterProps;
    attributes?: ChipsFormatterProps;
    linkedColumnsIndicator?: EntitiesCounterFormatterProps;
    columnBusinessAttribute?: ChipsFormatterProps;
  };

const getBusinessAttributeIcon = (populatedBy: ColumnBusinessAttributePopulatedBy): BigidChipIconType => {
  if (populatedBy === ColumnBusinessAttributePopulatedBy.AUTO) {
    return <BigidHighlightIndicator text="Auto populated Business Attribute" color={BigidColors.orange[600]} />;
  }

  return undefined;
};

const getBusinessAttributeFormatted = (
  businessAttribute: ColumnBusinessAttribute,
  isPermitted: boolean,
): ChipsFormatterProps => {
  const attribute: ChipsFormatterProps = {
    chips: {
      value: [],
      placeholder: 'Add business term',
      showPlaceholderOnlyOnHover: true,
      isDisabled: !isPermitted,
    },
  };

  if (Object.keys(businessAttribute).length > 0) {
    const { friendlyName, totalSuggestionsCount, populatedBy } = businessAttribute;

    if (friendlyName) {
      attribute.chips = {
        ...attribute.chips,
        value: [
          {
            label: friendlyName,
            iconPlacement: 'left',
            icon: getBusinessAttributeIcon(populatedBy),
          },
        ],
      };
    } else if (totalSuggestionsCount) {
      attribute.chips = {
        ...attribute.chips,
        value: [
          {
            label: totalSuggestionsCount.toString(),
            iconPlacement: 'right',
            icon: (
              <BigidIcon
                icon={EmojiObjectsOutlined}
                size={BigidIconSize.REGULAR_PLUS}
                color={BigidColors.orange[600]}
              />
            ),
            variant: 'outlined',
            outline: 'dashed',
          },
        ],
      };
    }
  }

  return attribute;
};

export const DataCatalogColumns: FunctionComponent<BigidContentItem & DataCatalogRecord> = ({
  fullyQualifiedName,
  source,
  name,
  scannerType,
  scanner_type_group,
  rootPage,
}) => {
  const classes = useStyles({});

  const [selectedItem, setSelectedItem] = useState<BigidContentItem>(null);
  const [externalFilter, setExternalFilter] = useState<BigidFilter>([]);
  const [isColumnWidgetEnabled, setIsColumnWidgetEnabled] = useState<boolean>(false);
  const [attributeMappingDialogState, setAttributeMappingDialogState] = useState<AttributeMappingDialogProps>({
    fullyQualifiedName,
    columnName: undefined,
    isOpen: false,
    scannerType,
  });
  const [linkedColumnsDialogState, setLinkedColumnsDialogState] = useState<LinkedColumnsDialogProps>({
    fullyQualifiedName,
    title: undefined,
    isOpen: false,
  });
  const [tagsDialogState, setTagsDialogState] = useState<TagsDialogProps>({
    columnName: undefined,
    tags: [],
    fullyQualifiedName,
    source,
    isOpen: false,
  });
  const [businessAttributeDialogState, setBusinessAttributeDialogState] = useState<BusinessAttributeDialogProps>({
    clusterId: undefined,
    columnName: undefined,
    fullyQualifiedName,
    businessAttribute: undefined,
    isOpen: false,
    dsName: source,
    dsType: scannerType,
    scanner_type_group,
    rootPage,
  });
  const [similarColumnsDialogState, setSimilarColumnsDialogState] = useState<SimilarColumnsDialogProps>({
    columnName: undefined,
    fullyQualifiedName,
    clusterId: undefined,
    isOpen: false,
  });

  const [businessAttributeSuggestionsDialogState, setBusinessAttributeSuggestionsDialogState] = useState<{
    isOpen: boolean;
    row?: ColumnsGridColumn;
  }>({ isOpen: false, row: undefined });

  const handleBusinessAttributeSuggestionsDialogClose = () => {
    setBusinessAttributeSuggestionsDialogState({ isOpen: false });
  };

  const handleBusinessAttributesUpdate = () => {
    setBusinessAttributeSuggestionsDialogState({ isOpen: false });
    entityEventsEmitter.emit(EntityEvents.RELOAD);
  };

  const { t } = useLocalTranslation('DataCatalogColumns');

  const {
    isReadLinkedColumnsPermitted,
    isReadManualAttributesPermitted,
    isEditManualAttributesPermitted,
    isReadTagsPermitted,
    isColumnTagsAssignmentPermitted,
    isColumnClusteringEnabled,
    isReadBusinessAttributePermitted,
    isEditBusinessAttributePermitted,
    confidenceLevelExplainTooltipEnabled,
    clusteringEnabled,
    isColumnTabAdditionalMetaDataEnabled,
    businessTermsEnabled,
  } = useMemo(
    () => ({
      isReadLinkedColumnsPermitted: isPermitted(CATALOG_PERMISSIONS.INVESTIGATE.name),
      isReadManualAttributesPermitted: isPermitted(CATALOG_PERMISSIONS.READ_MANUAL_FIELDS.name),
      isEditManualAttributesPermitted: isPermitted(CATALOG_PERMISSIONS.EDIT_MANUAL_FIELDS.name),
      isColumnProfilingAvailable: getApplicationPreference('COLUMN_PROFILING_ENABLED'),
      isReadTagsPermitted: isPermitted(TAGS_PERMISSIONS.READ.name),
      isColumnTagsAssignmentPermitted: isPermitted(CATALOG_PERMISSIONS.ASSIGN_TAG.name),
      isColumnClusteringEnabled: getApplicationPreference('SHOW_STRUCTURED_CLUSTERING'),
      isReadBusinessAttributePermitted: isPermitted(CATALOG_PERMISSIONS.READ_BUSINESS_ATTRIBUTES.name),
      isEditBusinessAttributePermitted: isPermitted(CATALOG_PERMISSIONS.EDIT_BUSINESS_ATTRIBUTES.name),
      confidenceLevelExplainTooltipEnabled: getApplicationPreference('CONFIDENCE_LEVEL_EXPLAIN_TOOLTIP_ENABLED'),
      clusteringEnabled: getApplicationPreference('CLUSTERING_ENABLED'),
      isColumnTabAdditionalMetaDataEnabled: getApplicationPreference('COLUMN_TAB_ADDITIONAL_METADATA'),
      businessTermsEnabled: isBusinessTermsEnabled(),
    }),
    [],
  );

  useEffect(() => {
    const trackData = {
      fullyQualifiedName,
      dsType: scannerType,
      dsName: source,
    };

    analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_COLUMNS_TAB, trackData);
  }, [fullyQualifiedName, scannerType, source]);

  const excludedAttributeTypes = ['ClassificationMd', 'Classification', 'Manual', 'Enrichment Attribute'];

  const getGridData = (data: DataCatalogObjectColumn[], fullyQualifiedName: string): ColumnsGridColumn[] => {
    return data.map((column: DataCatalogObjectColumn, index: number) => {
      const {
        column_name,
        attribute_list = [],
        isPrimary = false,
        isProfiled = false,
        fieldType,
        linkedColumns = 0,
        tags = [],
        businessAttribute = {},
        clusterId,
        order,
        nullable,
      } = column;

      const attributes: ChipsFormatterProps = {
        chips: {
          value: attribute_list.map((attribute: AttributeRanked) => {
            const {
              attribute_name,
              attribute_type,
              rank,
              attribute_id,
              calc_confidence_level,
              attribute_original_type,
            } = attribute;

            const isBusinessTerm = attribute_type === 'Business Term' || attribute_original_type === 'Business Term';
            const isBusinessTermAssignedByAi = attribute_type === 'Business Term';

            let confidenceLevelScore;

            if (calc_confidence_level && !isBusinessTerm) {
              confidenceLevelScore = (calc_confidence_level * 100).toFixed(0) + '%';
            }

            let confidenceLevelIndication;

            if (confidenceLevelScore && rank) {
              confidenceLevelIndication = `${capitalize(rank)} - ${confidenceLevelScore}`;
            } else if (rank) {
              confidenceLevelIndication = capitalize(rank);
            }

            let chipLabel;

            let attributeDiaplayName = attribute_name;
            if (isBusinessTerm) {
              attributeDiaplayName = attribute_name?.startsWith('businessTerm.')
                ? attribute_name.replace('businessTerm.', '')
                : attribute_name;
            }

            if (confidenceLevelScore) {
              chipLabel = `${attributeDiaplayName} (${confidenceLevelScore})`;
            } else {
              chipLabel = attributeDiaplayName;
            }
            if (isBusinessTermAssignedByAi) {
              chipLabel = (
                <div style={{ display: 'flex' }}>
                  <div style={{ marginRight: 5, maxWidth: 225, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {attributeDiaplayName}
                  </div>
                  <BigidAiIcon color="#757575" />
                </div>
              ) as any;
            }

            let chipTitle;

            if (confidenceLevelIndication) {
              chipTitle = `${attribute_name} (${confidenceLevelIndication})`;
            } else {
              chipTitle = attribute_name;
            }

            let chipIcon;

            if (rank) {
              chipIcon = <BigidConfidenceIndicator level={rank.toLowerCase() as BigidConfidenceLevel} />;
            }
            if (isBusinessTerm) {
              chipIcon = <BigidTermIcon />;
            }

            const shouldDisplayTooltip = confidenceLevelExplainTooltipEnabled && clusteringEnabled;

            const entity: BigidEditableChipAreaEntity = {
              id: attribute_id,
              label: chipLabel,
              icon: chipIcon,
              title: chipTitle,
              ...(shouldDisplayTooltip && {
                tooltipProps:
                  !excludedAttributeTypes.includes(attribute_type) && calc_confidence_level !== undefined
                    ? {
                        width: '400px',
                        title: (
                          <ConfidenceLevelExplanation
                            item={{
                              fullyQualifiedName,
                              fieldName: column.column_name,
                              attribute_type: attribute_type,
                              attribute_name: attribute_name,
                              confidence_level: calc_confidence_level,
                            }}
                          />
                        ),
                      }
                    : undefined,
              }),
            };

            return entity;
          }),
          isDisabled: !isEditManualAttributesPermitted,
          placeholder: 'Add attributes',
          showPlaceholderOnlyOnHover: true,
          entityMaxWidth: businessTermsEnabled ? 300 : undefined,
        },
      };

      const columnTags: TagsFormatterProps = {
        tags: {
          value: tags.map(({ tagName, tagValue, tagType, properties }) => ({
            name: getTagFormattedName(tagName),
            value: tagValue,
            iconDescription: tagType === TagAssignmentTarget.column ? tagType : undefined,
            icon: getTagIcon(properties, tagType),
          })),
          isDisabled: !isColumnTagsAssignmentPermitted,
          isAutoFit: false,
          placeholder: 'Add tag',
          showPlaceholderOnlyOnHover: true,
        },
      };

      const linkedColumnsIndicator: EntitiesCounterFormatterProps = {
        counter: {
          counterIcon: {
            icon: linkIcon,
            type: BigidIconSourceType.CUSTOM,
            size: BigidIconSize.MEDIUM,
            label: linkedColumns,
          },
          placeholder: 'Link column',
          showPlaceholderOnlyOnHover: true,
        },
      };

      const columnBusinessAttribute = getBusinessAttributeFormatted(
        businessAttribute,
        isEditBusinessAttributePermitted,
      );

      return {
        id: index,
        attribute_list,
        column_name,
        attributes,
        columnTags,
        tags,
        fieldType,
        isPrimary,
        isProfiled,
        linkedColumnsIndicator,
        columnBusinessAttribute,
        clusterId,
        order,
        nullable,
        businessAttribute,
      };
    });
  };

  const closeEditAttributeMappingDialog = useCallback(() => {
    setAttributeMappingDialogState(prevState => ({
      ...prevState,
      columnName: undefined,
      title: undefined,
      isOpen: false,
    }));
  }, []);

  const closeLinkedColumnsDialog = useCallback(() => {
    setLinkedColumnsDialogState(prevState => ({
      ...prevState,
      columnName: undefined,
      isOpen: false,
    }));
  }, []);

  const closeTagsDialog = useCallback(() => {
    setTagsDialogState(prevState => ({
      ...prevState,
      columnName: undefined,
      isOpen: false,
    }));
  }, []);

  const closeBusinessAttributeDialog = useCallback(() => {
    setBusinessAttributeDialogState(prevState => ({
      ...prevState,
      columnName: undefined,
      businessAttribute: undefined,
      clusterId: undefined,
      isOpen: false,
    }));
  }, []);

  const closeSimilarColumnsDialog = useCallback(() => {
    setSimilarColumnsDialogState(prevState => ({
      ...prevState,
      clusterId: undefined,
      columnName: undefined,
      isOpen: false,
    }));
  }, []);

  const handleGridRowClick = useCallback(
    (row: BigidGridRow): void => {
      const newSelection = !isEqual(selectedItem, row) ? row : null;
      setSelectedItem(newSelection as BigidContentItem);
      setIsColumnWidgetEnabled(!!newSelection);
    },
    [selectedItem],
  );

  const handleViewSimilarColumnsClick = useCallback((): void => {
    const { column_name, clusterId } = selectedItem;
    setSimilarColumnsDialogState({ clusterId, columnName: column_name, fullyQualifiedName, isOpen: true });

    const trackData = {
      fullyQualifiedName,
      dsType: scannerType,
      dsName: source,
      scanner_type_group,
    };

    analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_VIEW_SIMILAR_COLUMNS_EVENT, trackData);
  }, [fullyQualifiedName, selectedItem, source, scannerType, scanner_type_group]);

  const handleColumnWidgetClose = (): void => {
    setIsColumnWidgetEnabled(false);
  };

  const attributesColumnIfPermitted: BigidGridColumn<ColumnsGridColumn>[] = isReadManualAttributesPermitted
    ? [
        {
          name: 'attributes',
          title: 'Attributes (confidence level)',
          getCellValue: ({ attributes }) => attributes,
          type: BigidGridColumnTypes.CHIPS,
          width: 400,
          formatter: {
            onClick: ({ value, row, column }) => {
              return new Promise((resolve, reject) => {
                setAttributeMappingDialogState(prevState => {
                  return {
                    ...prevState,
                    columnName: row.column_name,
                    attributes: value.chips.value,
                    isOpen: true,
                    onSubmit: () => {
                      closeEditAttributeMappingDialog();
                      resolve({ row: { ...row, [column.name]: value }, shouldGridReload: true });
                    },
                    onClose: () => {
                      reject();
                    },
                  };
                });
              });
            },
          },
        },
      ]
    : [];

  const columnTagsIfPermitted: BigidGridColumn<ColumnsGridColumn>[] = isReadTagsPermitted
    ? [
        {
          name: 'columnTags',
          title: 'Tags',
          getCellValue: ({ columnTags }) => columnTags,
          type: BigidGridColumnTypes.TAGS,
          width: 400,
          formatter: {
            onClick: ({ value, column, row }) => {
              return new Promise((resolve, reject) => {
                setTagsDialogState(prevState => {
                  return {
                    ...prevState,
                    columnName: row.column_name,
                    tags: row.tags,
                    isOpen: true,
                    onSubmit: (updatedTags: BigidTagBaseProps[], postSubmitMessage: string) => {
                      const newCellValue = {
                        ...value,
                        chips: {
                          ...value.chips,
                          value: updatedTags.map(({ name, value }) => ({
                            label: `${getTagFormattedName(name)} : ${value}`,
                            icon: <BigidIcon icon={LocalOfferOutlined} size={BigidIconSize.REGULAR_PLUS} />,
                          })),
                        },
                      };

                      if (postSubmitMessage) {
                        notificationService.success(postSubmitMessage);
                      }

                      closeTagsDialog();
                      resolve({ row: { ...row, [column.name]: newCellValue }, shouldGridReload: true });
                    },
                    onClose: () => {
                      reject();
                    },
                  };
                });
              });
            },
          },
        },
      ]
    : [];

  const positionColumn: BigidGridColumn<ColumnsGridColumn>[] = isColumnTabAdditionalMetaDataEnabled
    ? [
        {
          name: 'order',
          title: 'Position',
          getCellValue: ({ order }) => order?.toString() || '',
          type: BigidGridColumnTypes.TEXT,
        },
      ]
    : [];

  const nullableColumn: BigidGridColumn<ColumnsGridColumn>[] = isColumnTabAdditionalMetaDataEnabled
    ? [
        {
          name: 'nullable',
          title: 'Can be null',
          getCellValue: ({ nullable }) => nullable?.toString() || '',
          type: BigidGridColumnTypes.TEXT,
        },
      ]
    : [];

  const linkedColumnsIfPermitted: BigidGridColumn<ColumnsGridColumn>[] = isReadLinkedColumnsPermitted
    ? [
        {
          name: 'linkedColumnsIndicator',
          title: 'Direct links',
          getCellValue: ({ linkedColumnsIndicator }) => linkedColumnsIndicator,
          type: BigidGridColumnTypes.ENTITIES_COUNTER,
          formatter: {
            onClick: ({ value, row, column }) => {
              return new Promise((resolve, reject) => {
                setLinkedColumnsDialogState(prevState => {
                  return {
                    ...prevState,
                    columnName: row.column_name,
                    title: `${row.column_name} - Linked columns`,
                    isOpen: true,
                    onSubmit: (linkedColumnsNumber: number) => {
                      const newCellValue = {
                        ...value,
                        counter: {
                          ...value.counter,
                          counterIcon: { ...value.counter.counterIcon, label: linkedColumnsNumber },
                        },
                      };
                      closeLinkedColumnsDialog();
                      resolve({ row: { ...row, [column.name]: newCellValue, shouldGridReload: false } });
                    },
                    onClose: () => {
                      reject();
                    },
                  };
                });
              });
            },
          },
        },
      ]
    : [];

  const columnBusinessAttributesIfEnabled: BigidGridColumn<ColumnsGridColumn>[] =
    isColumnClusteringEnabled && isReadBusinessAttributePermitted && !businessTermsEnabled
      ? [
          {
            name: 'columnBusinessAttribute',
            title: 'Business Term',
            getCellValue: ({ columnBusinessAttribute }) => columnBusinessAttribute,
            type: BigidGridColumnTypes.CHIPS,
            width: 250,
            formatter: {
              onClick: ({ value, row, column }) => {
                return new Promise((resolve, reject) => {
                  setBusinessAttributeDialogState(prevState => {
                    return {
                      ...prevState,
                      columnName: row.column_name,
                      clusterId: row.clusterId,
                      businessAttribute: value?.chips?.value,
                      fullyQualifiedName,
                      isOpen: true,
                      onApplyToColumn: () => {
                        closeBusinessAttributeDialog();
                        resolve({ row: { ...row, [column.name]: value }, shouldGridReload: true });
                      },
                      onApplyToCluster: () => {
                        closeBusinessAttributeDialog();
                        resolve({ row: { ...row, [column.name]: value }, shouldGridReload: true });
                      },
                      onClose: () => {
                        reject();
                      },
                    };
                  });
                });
              },
            },
          },
        ]
      : [];

  const columnBusinessAttributesSuggestionsIfEnabled: BigidGridColumn<ColumnsGridColumn>[] =
    isReadBusinessAttributePermitted && businessTermsEnabled
      ? [
          {
            name: 'columnBusinessAttributeSuggestions',
            title: 'Business term suggestions',
            getCellValue: ({ businessAttribute }) => ({
              chips: {
                value: businessAttribute?.totalSuggestionsCount
                  ? [
                      {
                        id: 'businessAttributeSuggestionsCount',
                        label: String(businessAttribute?.totalSuggestionsCount),
                        icon: <BigidAiIcon color={BigidColorSchemesVars.light.primary900} />,
                        color: BigidColorSchemesVars.light.primary900,
                        bgColor: BigidColorSchemesVars.light.primary100,
                        variant: 'filled',
                      },
                    ]
                  : [],
                placeholder: 'Add business term',
                showPlaceholderOnlyOnHover: true,
                isDisabled: !isPermitted || !businessAttribute?.totalSuggestionsCount,
              },
            }),
            formatter: {
              onClick: ({ value, row, column }) => {
                setBusinessAttributeSuggestionsDialogState({ isOpen: true, row });
                return Promise.resolve({});
              },
            },
            type: BigidGridColumnTypes.CHIPS,
            width: 200,
          },
        ]
      : [];

  //TODO: temporal solution ,remove when BigidGridWithToolbar will support icons on the right side of the screen
  const handleCollectionLineageTriggerClick = (): void => {
    const trackData = {
      fullyQualifiedName,
      dsType: scannerType,
      dsName: source,
      scanner_type_group,
    };
    analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_VIEW_SIMILAR_COLUMNS_LINKED_COLUMNS, trackData);
    setLinkedColumnsDialogState(prevState => {
      return {
        ...prevState,
        title: `${name} - Linked columns (full table view)`,
        isOpen: true,
        columnName: undefined,
        onSubmit: () => {
          setExternalFilter([]);
          closeLinkedColumnsDialog();
        },
      };
    });
  };

  const gridConfig: BigidGridWithToolbarProps<ColumnsGridColumn> = {
    externalFilter,
    fetchData: async queryComponents => {
      const query = objectToQueryString({
        ...(queryComponents as QueryParams),
        object_name: fullyQualifiedName,
        requireTotalCount: false,
      });
      const { data } = await getColumnsByObjectName(query);

      let totalCount: number;
      if (queryComponents.requireTotalCount) {
        const {
          data: { count },
        } = await getColumnsCount(fullyQualifiedName);
        totalCount = count;
      }

      return {
        totalCount,
        data: getGridData(data, fullyQualifiedName),
      };
    },
    entityName: 'items',
    showSortingControls: false,
    onRowClick: handleGridRowClick,
    columns: [
      {
        name: 'column_name',
        title: 'Column name',
        type: BigidGridColumnTypes.CUSTOM,
        disableTooltip: true,
        getCellValue: ({ column_name, isPrimary }) => {
          return isPrimary ? (
            <span className={classes.columnName}>
              {column_name}
              <BigidTooltip title={t('primaryBadgeToolTip')}>
                <span>
                  <BigidStatusBadge
                    label={t('primaryBadgeLabel')}
                    type={BigidStatusBadgeType.INFO}
                    size={BigidStatusBadgeSize.SMALL}
                  />
                </span>
              </BigidTooltip>
            </span>
          ) : (
            column_name
          );
        },
      },
      ...positionColumn,
      ...columnBusinessAttributesIfEnabled,
      ...columnBusinessAttributesSuggestionsIfEnabled,
      {
        name: 'fieldType',
        title: 'Data type',
        getCellValue: ({ fieldType }) => fieldType,
        type: BigidGridColumnTypes.TEXT,
      },
      ...attributesColumnIfPermitted,
      ...columnTagsIfPermitted,
      ...linkedColumnsIfPermitted,
      ...nullableColumn,
    ],
    //: TODO: after the BigidGridWithToolbar will support icons on the right side on the screen uncomment that and add the new property to make it on the right and not in the left
    // toolbarActions: [
    //   {
    //     label: 'Linked columns',
    //     icon: BigidFlowIcon,
    //     tooltip: 'Linked columns (full table view)',
    //     type: ToolbarActionType.ACTION_ICON,
    //     isGlobal: true,
    //     execute: async () => {
    //       return new Promise((resolve, reject) => {
    //         setLinkedColumnsDialogState(prevState => {
    //           return {
    //             ...prevState,
    //             title: `${name} - Linked columns (full table view)`,
    //             isOpen: true,
    //             columnName: undefined,
    //             onSubmit: () => {
    //               setExternalFilter([]);
    //               closeLinkedColumnsDialog();
    //             },
    //           };
    //         });
    //       });
    //     },
    //     show: () => true,
    //   },
    // ],
  };

  const attributeMappingDialogConfig: AttributeMappingDialogProps = useMemo(
    () => ({
      ...attributeMappingDialogState,
      onClose: closeEditAttributeMappingDialog,
    }),
    [attributeMappingDialogState, closeEditAttributeMappingDialog],
  );

  const tagsDialogConfig: TagsDialogProps = useMemo(
    () => ({
      ...tagsDialogState,
      onClose: closeTagsDialog,
    }),
    [closeTagsDialog, tagsDialogState],
  );

  const linkedColumnsDialogConfig: LinkedColumnsDialogProps = useMemo(
    () => ({
      ...linkedColumnsDialogState,
      onClose: closeLinkedColumnsDialog,
    }),
    [linkedColumnsDialogState, closeLinkedColumnsDialog],
  );

  const businessAttributeDialogConfig: BusinessAttributeDialogProps = useMemo(
    () => ({
      ...businessAttributeDialogState,
      onClose: closeBusinessAttributeDialog,
    }),
    [businessAttributeDialogState, closeBusinessAttributeDialog],
  );

  const similarColumnsDialogConfig: SimilarColumnsDialogProps = useMemo(
    () => ({
      ...similarColumnsDialogState,
      onClose: closeSimilarColumnsDialog,
    }),
    [similarColumnsDialogState, closeSimilarColumnsDialog],
  );

  useEffect(() => {
    setExternalFilter([]);
    setSelectedItem(null);
    setIsColumnWidgetEnabled(false);
    setAttributeMappingDialogState({
      fullyQualifiedName,
      columnName: '',
      isOpen: false,
      scannerType,
    });
    setLinkedColumnsDialogState({
      fullyQualifiedName,
      columnName: undefined,
      title: '',
      isOpen: false,
    });
    setBusinessAttributeDialogState({
      fullyQualifiedName,
      clusterId: undefined,
      businessAttribute: undefined,
      columnName: undefined,
      isOpen: false,
      dsName: source,
      dsType: scannerType,
      scanner_type_group,
      rootPage,
    });
    setSimilarColumnsDialogState({
      clusterId: undefined,
      columnName: undefined,
      fullyQualifiedName,
      isOpen: false,
    });
  }, [fullyQualifiedName, source, scannerType, scanner_type_group]);

  useEffect(() => {
    setTagsDialogState({
      fullyQualifiedName,
      tags: [],
      columnName: undefined,
      isOpen: false,
      source,
    });
  }, [fullyQualifiedName, source]);

  const isSimilarColumnsAvailable = Boolean(selectedItem?.clusterId);

  return (
    <div className={classes.root}>
      {/* TODO: temporal solution ,remove when BigidGridWithToolbar will support icons on the right side of the screen */}
      <div className={classes.toolbarWrapper}>
        <BigidToolbarGlobalActionButton
          dataAid="DataCatalogColumnsCollectionLineageTrigger"
          onClick={handleCollectionLineageTriggerClick}
        >
          <BigidTooltip title={'Linked columns (full table view)'} enterDelay={1000} placement="top">
            <div className={classes.lineageTriggerIconWrapper}>
              <BigidIcon icon={BigidFlowIcon} size={BigidIconSize.MEDIUM} type={BigidIconSourceType.CUSTOM} />
            </div>
          </BigidTooltip>
        </BigidToolbarGlobalActionButton>
      </div>
      <div className={classes.gridWrapper}>
        <div className={classNames(classes.grid, isColumnWidgetEnabled && classes.shrinkGrid)}>
          <BigidPaper>
            <BigidGridWithToolbar {...gridConfig} />
          </BigidPaper>
        </div>
        {isColumnWidgetEnabled && (
          <ColumnWidgetsWrapper
            fullyQualifiedName={fullyQualifiedName}
            columnName={selectedItem.column_name}
            isProfiled={selectedItem.isProfiled}
            onClose={handleColumnWidgetClose}
            dataAid="DataCatalogColumns-column-widget"
            preferenceId="DataCatalogColumnPreviewPreferences"
            fieldType={selectedItem.fieldType?.toLowerCase()}
            dsType={scannerType}
            dsName={source}
            scannerType={selectedItem.scanner_type_group}
            rootPage={rootPage}
          >
            {isColumnClusteringEnabled &&
              (isSimilarColumnsAvailable ? (
                <SecondaryButton
                  size="medium"
                  onClick={handleViewSimilarColumnsClick}
                  dataAid="DataCatalogColumns-column-profiling-view-similar-columns"
                  text="View Similar Columns"
                />
              ) : (
                <BigidCaption>Similar Columns are unavailable</BigidCaption>
              ))}
          </ColumnWidgetsWrapper>
        )}
      </div>
      <AttributeMappingDialog {...attributeMappingDialogConfig} />
      <LinkedColumnsDialog {...linkedColumnsDialogConfig} />
      <TagsDialog {...tagsDialogConfig} />
      {businessAttributeDialogConfig.isOpen && (
        <BusinessAttributeDialog {...businessAttributeDialogConfig} rootPage={rootPage} />
      )}
      {similarColumnsDialogConfig.isOpen && <SimilarColumnsDialog {...similarColumnsDialogConfig} />}
      {businessAttributeSuggestionsDialogState.isOpen && (
        <BusinessAttributeSuggestionsDialog
          columnName={businessAttributeSuggestionsDialogState.row.column_name}
          fullyQualifiedName={fullyQualifiedName}
          isOpen={businessAttributeSuggestionsDialogState.isOpen}
          onClose={handleBusinessAttributeSuggestionsDialogClose}
          onAttributesUpdate={handleBusinessAttributesUpdate}
          attributeList={businessAttributeSuggestionsDialogState.row.attribute_list}
        />
      )}
    </div>
  );
};
