import React, { FC } from 'react';
import { BigidImportDialog, EntityEvents, entityEventsEmitter, TertiaryButton } from '@bigid-ui/components';
import { BigidDownloadIcon, BigidExternalLinkIcon } from '@bigid-ui/icons';
import { docsUrls } from '../../../config/publicUrls';
import { httpService } from '../../../services/httpService';
import { useLocalTranslation } from '../translations';
import { notificationService } from '../../../services/notificationService';
import { getMessageFromImportError } from './importGlossaryDialogUtils';
import { NotificationWithSkippedItems } from './NotificationWithSkippedItems';

type ImportGlossaryDialogProps = {
  onImportDialogClose: () => void;
  isImportDialogOpen: boolean;
  setIsExportInProgress: (status: boolean) => void;
};

export const ImportGlossaryDialog: FC<ImportGlossaryDialogProps> = ({
  onImportDialogClose,
  isImportDialogOpen,
  setIsExportInProgress,
}) => {
  const { t } = useLocalTranslation('BusinessGlossary.importDialog');

  const handleDownloadTemplate = () => {
    httpService.downloadStaticFile('import-glossary-items-template.csv');
  };

  const handleOpenDocumentation = () => {
    window.open(docsUrls.BUSINESS_GLOSSARY_DOC, '_blank');
  };

  const handleImportFile = async (file: File) => {
    onImportDialogClose();
    notificationService.loading('Importing in progress', { shouldAutoHide: false });
    setTimeout(async () => {
      setIsExportInProgress(true);
      try {
        const response = await httpService.multipart('post', 'business_glossary_items/import_from_file', {
          files: {
            fileToImport: file,
          },
        });
        if (response.data.validItems > 0) {
          const message = t('successfulImportNotification', {
            upsertedCount: response.data.upsertedCount,
            modifiedCount: response.data.modifiedCount,
          });
          if (response.data.skippedRows?.length > 0) {
            notificationService.warning('', {
              contentElement: (
                <NotificationWithSkippedItems message={message} skippedRows={response.data.skippedRows} />
              ),
              shouldAutoHide: false,
            });
          } else {
            notificationService.success(message, { duration: 5000 });
          }
          entityEventsEmitter.emit(EntityEvents.RELOAD);
        } else {
          notificationService.warning(t('successfulEmptyImportNotification'));
        }
      } catch (err) {
        const errMsg = getMessageFromImportError(err);
        notificationService.error(errMsg, { shouldAutoHide: false });
        console.error(`${errMsg} ${err}`);
      } finally {
        setIsExportInProgress(false);
      }
    }, 500);
  };

  return (
    <BigidImportDialog
      isOpen={isImportDialogOpen}
      onClose={onImportDialogClose}
      onImportClicked={handleImportFile}
      title={t('title')}
      description={t('description')}
      accept={['.csv']}
      additionalDialogButtons={[
        {
          component: ({ children, ...restProps }) => (
            <TertiaryButton {...restProps} startIcon={<BigidDownloadIcon />} text={children as string} />
          ),
          text: t('downloadTemplateButton'),
          onClick: handleDownloadTemplate,
          alignment: 'left',
        },
        {
          component: ({ children, ...restProps }) => (
            <TertiaryButton {...restProps} startIcon={<BigidExternalLinkIcon />} text={children as string} />
          ),
          text: t('documentationButton'),
          onClick: handleOpenDocumentation,
          alignment: 'left',
        },
      ]}
    />
  );
};
