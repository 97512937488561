import styled from '@emotion/styled';
import { BigidColorsV2 } from '@bigid-ui/colors';
import { BigidBody1 } from '@bigid-ui/components';

/*
 * Grid empty state styles
 */

export const EmptyStateWrapper = styled('div')`
  width: 100%;
  height: calc(100% - 42px);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-size: 14px;
`;

export const Flex = styled.div`
  display: flex;
`;

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`;

/**
 * Main
 */

export const LegalEntityOverviewWrapper = styled(Flex)`
  padding: 24px;
  width: 100%;
`;

export const EntityMainWrapper = styled(FlexColumn)`
  flex-wrap: nowrap;
  justify-content: stretch;
  overflow: auto;
  padding-right: 16px;
  gap: 16px;
  flex-grow: 1;
  border-right: 1px solid ${BigidColorsV2.gray[200]};
`;

export const EntityDetailsWrapper = styled(Flex)<{ fullWidth?: boolean }>(({ theme }) => ({
  flexFlow: 'row wrap',
  marginBottom: theme.spacing(3),
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  gap: theme.spacing(2),
  backgroundColor: BigidColorsV2.gray[125],
}));

export const EntityDetailsSectionWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: calc((100% / 4) - (48px / 4));
  padding: 8px 0;
`;

export const EntityDetailsSectionWrapperFullWidth = styled(EntityDetailsSectionWrapper)<{ buttonWidth?: number }>`
  width: ${p => (p?.buttonWidth ? `calc(100% - ${p.buttonWidth}px` : '100%')};
`;

export const DetailsBold = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5;
`;

export const EntityDetailsSectionDescriptionWrapper = styled(DetailsBold)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * Aside
 */

export const FlexWrapperHidden = styled(FlexColumn)`
  overflow: hidden;
`;

export const EntityDetailsAsideItemWrapper = styled(Flex)`
  align-items: center;
  gap: 12px;
  min-height: 30px;
  overflow: hidden;
`;

export const EntityDetailsAsideWrapper = styled(FlexColumn)`
  flex-wrap: nowrap;
  justify-content: stretch;
  overflow: auto;
  padding-left: 16px;
  width: 365px;
  min-width: 365px;
  gap: 8px;
`;

export const AsideSectionHeaderWrapper = styled(Flex)<{ paddingTop?: boolean }>(({ theme, paddingTop }) => ({
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: paddingTop ? theme.spacing(3) : 0,
}));

export const AsideSectionLabelWrapper = styled(Flex)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

export const AsideSectionContentWrapper = styled(FlexColumn)`
  overflow: auto;
  width: 100%;
`;

export const AsideSectionBrandingWrapper = Flex;

export const AsideSectionBrandingLogoWrapper = styled('div')<{ isTooltip: boolean }>(
  ({ theme: { spacing: s }, isTooltip }) => ({
    padding: `${s(3 / 4)}`,
    borderRadius: `${s(3 / 4)}`,
    border: `1px solid ${BigidColorsV2.gray[200]}`,
    margin: isTooltip ? `${s(2)} ${s(3)} ${s(3)} ${s(1.5)}` : `${s(1)} ${s(3)} ${s(1)} 0`,
    width: '72px',
    height: '72px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

export const ColorSchemeWrapper = styled(FlexColumn)(({ theme }) => ({
  justifyContent: 'center',
  gap: theme.spacing(1),
  paddingRight: theme.spacing(2),
}));

export const ColorSchemeTextWrapper = styled(BigidBody1)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));

export const ColorRectangle = styled('div')(({ theme, color }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  borderRadius: theme.spacing(1 / 4),
  backgroundColor: color,
}));

export const ColorRectangleWrapper = styled(Flex)(({ theme }) => ({
  alignItems: 'center',
  gap: theme.spacing(1.5),
}));

export const BrandingLogo = styled('img')`
  width: 60px;
  height: 60px;
  object-fit: contain;
`;

export const BrandingLogoSmall = styled('img')`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

export const BrandingLogoWrapper = styled.div`
  padding: 5px;
  border: 1px solid ${BigidColorsV2.gray[200]};
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const TooltipWrapper = styled(Flex)<{sx ?: {marginLeft : string}}>(
    ({ sx,theme }) => ({
      alignItems: 'center',
      marginLeft: sx?.marginLeft ? sx?.marginLeft : theme.spacing(1),
}));

export const ContentCardWrapper = styled(FlexColumn)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px dashed ${BigidColorsV2.gray[200]}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  cursor: 'pointer',
  minHeight: '170px',
  '&:hover': {
    borderColor: theme.vars.palette.bigid.primary600,
  },
}));

export const ContentCardTextWrapper = styled(Flex)(({ theme }) => ({
  alignItems: 'center',
  gap: '3px',
  margin: `${theme.spacing(1)} 0`,
}));

export const LabelBadgeGroupWrapper = styled(Flex)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${BigidColorsV2.gray[200]}`,
  padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1.5)}`,
  cursor: 'pointer',
  minHeight: theme.spacing(6),
  '&:hover': {
    backgroundColor: BigidColorsV2.gray[100],
  },
}));

export const LabelBadgeGroupWrapperNoBorder = styled(LabelBadgeGroupWrapper)(({ theme }) => ({
  borderBottom: 0,
  paddingLeft: theme.spacing(2),
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const PageWithTabsWrapper = styled(Flex)(({ theme }) => ({
  height: '100%',
  marginBottom: theme.spacing(1.5),
}));

export const TabsWrapper = styled(Flex)(({ theme: { spacing: s } }) => ({
  alignItems: 'end',
  padding: `0 ${s(2)}`,
  minHeight: '48px',
}));

export const ContentWrapper = styled(Flex)`
  border-top: 1px solid ${BigidColorsV2.gray[200]};
  height: 100%;

  & * {
    -ms-overflow-style: none !important;
  }

  & *::-webkit-scrollbar {
    width: 0 !important;
  }
`;

export const FitContentWrapper = styled.div`
  width: fit-content;
`;

export const FlexWithGap = styled(Flex)(({ theme }) => ({
  gap: theme.spacing(1),
}));

export const TruncatedText = styled.span`
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TruncatedTextAside = styled(TruncatedText)`
  display: flex;
  align-items: center;
  gap: 6px;
  padding-right: 16px;
  font-size: 16px;
  font-weight: 400;
`;
