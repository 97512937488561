import React from 'react';
import { GuideState, ListType } from '../../../types/CorrelationSetsTypes';
import { useLocalTranslation } from '../../../translations';
import { GuideContent } from '../../GuideContent/GuideContent';

interface ResultGuideContentProps {
  contentTitle: string;
  guideState: GuideState;
}

export const ResultGuideContent = ({ contentTitle, guideState }: ResultGuideContentProps) => {
  const { t } = useLocalTranslation('wizard.guideContent.resultsStep');

  const guideDataDetails = [
    {
      title: t('title1'),
      listStyle: ListType.bullet,
      data: [
        {
          title: t('titleData1'),
          text: t('textData1'),
        },
        {
          title: t('titleData2'),
          text: t('textData2'),
        },
        {
          title: t('titleData3'),
          text: t('textData3'),
        },
        {
          title: t('titleData4'),
          text: t('textData4'),
        },
        {
          title: t('titleData5'),
          text: t('textData5'),
        },
      ],
    },
  ];

  return <GuideContent content={guideDataDetails} />;
};
