import React, { useEffect, useState } from 'react';
import { BigidInfoIcon } from '@bigid-ui/icons';
import { notificationService } from '../../services/notificationService';
import {
  AsideSectionBrandingLogoWrapper,
  AsideSectionBrandingWrapper,
  ColorSchemeWrapper,
  ColorSchemeTextWrapper,
  ColorRectangle,
  ColorRectangleWrapper,
  TooltipWrapper,
} from './LegalEntitiesCommonStyles';
import { LegalEntitiesBrandingLogo } from './LegalEntitiesBrandingLogo';
import { getBranding } from './LegalEntitiesService';
import { BrandingData } from './LegalEntitiesTypes';
import { BigidBody1, BigidTooltip } from '@bigid-ui/components';

export const BrandingWidget = ({
  brandingId,
  isTooltip,
  shouldRefetch,
  parentName,
}: {
  brandingId: string;
  isTooltip?: boolean;
  shouldRefetch?: boolean;
  parentName?: string;
}) => {
  const [brandingData, setBrandingData] = useState<BrandingData>();

  useEffect(() => {
    (async () => {
      const branding = await fetchBranding(brandingId);
      setBrandingData(branding);
    })();
  }, [brandingId]);

  return (
    <>
      {parentName && <BigidBody1 sx={{ margin: '12px 0 0 12px' }}>{parentName}</BigidBody1>}
      <AsideSectionBrandingWrapper>
        <AsideSectionBrandingLogoWrapper isTooltip={isTooltip}>
          <LegalEntitiesBrandingLogo brandingId={brandingId} bigLogo shouldRefetch={shouldRefetch} />
        </AsideSectionBrandingLogoWrapper>
        <ColorSchemeWrapper>
          <ColorSchemeTextWrapper>{'Color scheme'}</ColorSchemeTextWrapper>
          <ColorRectangleWrapper>
            {brandingData?.color ? (
              <>
                <ColorRectangle color={brandingData?.color} />
                <BigidBody1>{brandingData?.color}</BigidBody1>
              </>
            ) : (
              <BigidBody1>{'Color not defined'}</BigidBody1>
            )}
          </ColorRectangleWrapper>
        </ColorSchemeWrapper>
      </AsideSectionBrandingWrapper>
    </>
  );
};

export const InfoTooltip = ({ title, sx }: { title: React.ReactNode; sx?: { marginLeft: string } }) => {
  return (
    <BigidTooltip placement="top" title={title}>
      <TooltipWrapper sx={sx}>
        <BigidInfoIcon size="small" />
      </TooltipWrapper>
    </BigidTooltip>
  );
};

const fetchBranding = async (brandingId: string) => {
  if (!brandingId) {
    return;
  }

  try {
    const branding = await getBranding(brandingId);
    return branding;
  } catch (err) {
    notificationService.error(`Failed to fetch Legal Entity branding`);
    console.error(`Failed to fetch Legal Entity branding: ${JSON.stringify(err?.response)}`);
  }
};
