import React from 'react';
import { AttributeClearValue, AttributeClearValueProps } from './AttributeClearValue';
import { SidePanelMasterDetails } from '../components/ColumnsMasterDetails';

type AttributesMasterDetailsPreviewProps = AttributeClearValueProps & {
  onClose: () => void;
};

export const AttributesMasterDetailsPreview = (props: AttributesMasterDetailsPreviewProps) => {
  const { selectedItem, onClose, rootPage } = props;

  return (
    <SidePanelMasterDetails onClose={onClose} title={selectedItem.attribute_name}>
      <AttributeClearValue {...props} rootPage={rootPage} />
    </SidePanelMasterDetails>
  );
};
