import React, { useState } from 'react';
import styled from '@emotion/styled';
import { GuideContentData, GuideContentProps, ListType } from '../../types/CorrelationSetsTypes';
import {
  AccordionSummarySizeEnum,
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidHeading6,
} from '@bigid-ui/components';

const OrderList = styled('ul')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const NumberList = styled('ol')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const GuideContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const GuideContent = ({ content }: GuideContentProps) => {
  const [expandedIndices, setExpandedIndices] = useState([0]);

  const handleAccordionChange = (index: number) => {
    setExpandedIndices(prevIndices =>
      prevIndices.includes(index) ? prevIndices.filter(i => i !== index) : [...prevIndices, index],
    );
  };

  return (
    <GuideContentContainer>
      {content.map((stepContent, index) => (
        <BigidAccordion
          key={index}
          hasBorder
          hasShadow
          expanded={expandedIndices.includes(index)}
          onChange={() => handleAccordionChange(index)}
        >
          <BigidAccordionSummary size={AccordionSummarySizeEnum.large}>
            <BigidHeading6>{stepContent.title}</BigidHeading6>
          </BigidAccordionSummary>

          {stepContent.listStyle === ListType.none && (
            <BigidAccordionDetails>{stepContent.data as string}</BigidAccordionDetails>
          )}

          {stepContent.listStyle === ListType.bullet && (
            <BigidAccordionDetails>
              <OrderList>
                {(stepContent.data as GuideContentData[]).map((stepData, index) => (
                  <li key={index}>
                    <b>{stepData.title}</b>
                    <span>{stepData.text}</span>
                  </li>
                ))}
              </OrderList>
            </BigidAccordionDetails>
          )}

          {stepContent.listStyle === ListType.number && (
            <BigidAccordionDetails>
              <NumberList>
                {(stepContent.data as GuideContentData[]).map((stepData, index) => (
                  <li key={index}>
                    <b>{stepData.title}</b>
                    <span>{stepData.text}</span>
                  </li>
                ))}
              </NumberList>
            </BigidAccordionDetails>
          )}
        </BigidAccordion>
      ))}
    </GuideContentContainer>
  );
};
