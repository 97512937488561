import React from 'react';
import { GuideState, ListType } from '../../../types/CorrelationSetsTypes';
import { useLocalTranslation } from '../../../translations';
import { GuideContent } from '../../GuideContent/GuideContent';

interface DetailsGuideContentProps {
  contentTitle: string;
  guideState: GuideState;
}

export const PreviewGuideContent = ({ contentTitle, guideState }: DetailsGuideContentProps) => {
  const { t } = useLocalTranslation('wizard.guideContent.previewStep');

  const guideDataPreview = [
    {
      title: t('title1'),
      listStyle: ListType.none,
      data: t('text1'),
    },
    {
      title: t('title2'),
      listStyle: ListType.none,
      data: t('text2'),
    },
    {
      title: t('title3'),
      listStyle: ListType.none,
      data: t('text3'),
    },
  ];

  return <GuideContent content={guideDataPreview} />;
};
