import { runScanActionDialog } from '../ScanService';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import {
  BigidFieldFilterOperator,
  BigidLoader,
  BigidPaper,
  EntityEvents,
  entityEventsEmitter,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { $state } from '../../../services/angularServices';
import { getInitialActiveScansGridColumns } from './ActiveScansGridConfiguration';
import { ActiveScanState, ScanActions, ScansGridRow, SSEType } from '../ScanTypes';
import {
  fetchScanData,
  shouldShowScanAction,
  getInitialScanFilters,
  subscribeToSSEScanUpdates,
  STOP_DISABLED_LABEL,
  PAUSED_DISABLED_LABEL,
  isPollingEnable,
  subscribeToSSEScanUpdatesRelatedPolling,
} from '../ScanUtils';
import { BigidPauseIcon, BigidResumeIcon, BigidStopIcon } from '@bigid-ui/icons';
import { ScanViewEnum } from '../ScansEventTrackerUtils';
import { NoDataContentActiveScans } from './NoDataContentActiveScans';
import { httpService } from '../../../services/httpService';
import { DataSourcesResponse } from '../../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { getApplicationPreference } from '../../../services/appPreferencesService';

const useStyles = makeStyles({
  gridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: 'calc(100% - 72px)',
    maxHeight: 'calc(100% - 72px)',
    overflow: 'hidden',
  },
});

// Need to add backend support to those filters
const ACTIVE_STATES_FILTER = [
  ActiveScanState.IN_PROGRESS,
  ActiveScanState.NOT_RUNNING,
  ActiveScanState.PAUSE_REQUESTED,
  ActiveScanState.PAUSED,
  ActiveScanState.RESUME_REQUESTED,
  ActiveScanState.PENDING,
  ActiveScanState.STOP_REQUESTED,
];

const ACTIVE_STATES = [ActiveScanState.STARTED];
const defaultFilter = [{ field: 'state', operator: 'in' as BigidFieldFilterOperator, value: ACTIVE_STATES }];

export const ActiveScans: FC = () => {
  const classes = useStyles();
  const [totalCountDataSources, setTotalCountDataSources] = useState<number>();
  const [filter, setFilter] = useState({});

  const isPollingEnableFF = isPollingEnable();

  const updateTotalCountDS = async () => {
    const { data } = await httpService.fetch<{ data: DataSourcesResponse }>('ds-connections?limit=1');
    const { totalCount } = data.data;
    setTotalCountDataSources(totalCount);

    return data;
  };

  useEffect(() => {
    let unregister: any;

    if (!isPollingEnableFF) {
      unregister = subscribeToSSEScanUpdates(SSEType.PARENT, ScanViewEnum.ACTIVITY_QUEUE);
    } else {
      unregister = subscribeToSSEScanUpdatesRelatedPolling(SSEType.PARENT, ScanViewEnum.ACTIVITY_QUEUE);
    }

    return () => {
      if (unregister) {
        unregister();
      }
    };
  }, [isPollingEnableFF]);

  useEffect(() => {
    if (!isPollingEnableFF) {
      return;
    }

    let isUpdating = false;
    const interval = setInterval(async () => {
      if (isUpdating) {
        return;
      }

      isUpdating = true;
      try {
        const response = await fetchScanData(filter, defaultFilter);
        entityEventsEmitter.emit(EntityEvents.UPDATE, response.data, undefined, false);
      } catch (error) {
        console.error('Error while updating scans:', error);
      } finally {
        isUpdating = false;
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [filter, isPollingEnableFF]);

  const initialActiveScansGridColumns = useMemo(getInitialActiveScansGridColumns, []);
  const getInitialFilterToolbarConfig = useCallback(() => getInitialScanFilters(ACTIVE_STATES_FILTER), []);
  const { isReady, gridColumns, preferences, updatePreferences, filterToolbarConfig } = useUserPreferences({
    stateName: $state.$current.name,
    initialGridColumns: initialActiveScansGridColumns,
    getInitialFilterToolbarConfig,
  });
  const pageSize = getApplicationPreference('PAGE_SIZE_ACTIVE_SCANS_FF') || 200;

  const config: BigidGridWithToolbarProps<ScansGridRow> = useMemo(
    () => ({
      pageSize: isPollingEnableFF ? Math.floor(pageSize / 2) : 100,
      ...(isPollingEnableFF ? { totalRowsThreshold: pageSize } : {}),
      entityName: 'Scans',
      defaultSorting: preferences?.grid?.sort || [{ field: 'startTime', order: 'desc' }],
      showSelectionColumn: true,
      showSortingControls: true,
      showSelectAll: false,
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      filterToolbarConfig,
      fetchData: async queryComponents => {
        if (isPollingEnableFF) {
          setFilter(queryComponents);
        }
        const response = await fetchScanData(queryComponents, defaultFilter, ScanViewEnum.ACTIVITY_QUEUE);

        if (response?.totalCount === 0 || !totalCountDataSources) {
          await updateTotalCountDS();
        }

        return response;
      },
      columns: gridColumns,
      toolbarActions: [
        {
          label: 'Pause',
          icon: BigidPauseIcon,
          execute: async actionData => runScanActionDialog(actionData, ScanActions.PAUSE),
          disable: () => false,
          isInline: true,
          hideActionInToolBar: true,
          show: ({ selectedRows: [{ isPauseAvailable, isRbacPermitted }] }) => isPauseAvailable && isRbacPermitted,
        },
        {
          label: PAUSED_DISABLED_LABEL,
          icon: () => <BigidPauseIcon staticMode={true} disabled={true} dataAid="GridInlineActions-icon-Pause" />,
          execute: async _actionData => ({}),
          isInline: true,
          hideActionInToolBar: true,
          show: ({ selectedRows: [{ isPauseAvailable, isResumeAvailable, isRbacPermitted }] }) => {
            return isRbacPermitted && !isResumeAvailable && !isPauseAvailable;
          },
        },
        {
          label: 'Resume',
          icon: BigidResumeIcon,
          execute: async actionData => runScanActionDialog(actionData, ScanActions.RESUME),
          disable: () => false,
          isInline: true,
          hideActionInToolBar: true,
          show: ({ selectedRows: [{ isResumeAvailable, isRbacPermitted }] }) => isResumeAvailable && isRbacPermitted,
        },
        {
          label: 'Stop',
          icon: BigidStopIcon,
          execute: async actionData => runScanActionDialog(actionData, ScanActions.STOP),
          disable: () => false,
          isInline: true,
          hideActionInToolBar: true,
          show: ({ selectedRows: [{ state, isStopAvailable, isRbacPermitted }] }) =>
            isStopAvailable &&
            isRbacPermitted &&
            shouldShowScanAction({
              state,
              stateCondition: [ActiveScanState.STOP_REQUESTED],
              notEqual: true,
            }),
        },
        {
          label: STOP_DISABLED_LABEL,
          icon: () => <BigidStopIcon staticMode={true} disabled={true} dataAid="GridInlineActions-icon-Stop" />,
          execute: async _actionData => ({}),
          disable: () => true,
          isInline: true,
          hideActionInToolBar: true,
          show: ({ selectedRows: [{ isStopAvailable, isRbacPermitted }] }) => !isStopAvailable && isRbacPermitted,
        },
      ],
    }),
    [filterToolbarConfig, gridColumns, preferences?.grid?.sort, updatePreferences],
  );

  return (
    <div className={classes.gridWrapper}>
      <BigidPaper>
        {!isReady && <BigidLoader />}
        {isReady && (
          <BigidGridWithToolbar
            {...config}
            noDataContent={<NoDataContentActiveScans totalCountDataSources={totalCountDataSources} />}
          />
        )}
      </BigidPaper>
    </div>
  );
};
