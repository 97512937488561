export const docsUrls = Object.freeze({
  DS_DOCS_URL_PREFIX: 'https://go.bigid.com/ds-',
  PRIVACY_PORTAL: 'https://go.bigid.com/docs-privacy-portal',
  LABELING_MIP: 'https://go.bigid.com/docs-labeling-mip',
  CONFIGURING_RISK: 'https://go.bigid.com/docs-configuring-risk',
  POLICIES: 'https://go.bigid.com/docs-policies',
  DATA_CLUSTERING: 'https://go.bigid.com/docs-data-clustering',
  REPORTS: 'https://go.bigid.com/docs-reports',
  DOCS: 'https://go.bigid.com/docs-home',
  CREDENTIALS_SECTION_HASHI_CORP: 'https://go.bigid.com/docs-hashicorp',
  BREACH_RESPONSE: 'https://go.bigid.com/docs-breach-response',
  DATA_FLOWS_TOOL: 'https://go.bigid.com/docs-data-flows-tool',
  DATA_CATALOG_OBJECT_DETAILS: 'https://go.bigid.com/docs-data-catalog-1-object-details',
  DATA_ACCESS_GOVERNANCE: 'https://go.bigid.com/docs-data-access-governance',
  SECONDARY_DATA_SOURCE_CONNECTIONS_SAP_DATA_HUB: 'https://go.bigid.com/docs-sap-data-hub',
  CREDENTIALS_SECTION_THYCOTIC: 'https://go.bigid.com/docs-thycotic-secret-server',
  CLASSIFIERS: 'https://go.bigid.com/docs-classifiers',
  CREDENTIALS_SECTION_CYBER_ARK: 'https://go.bigid.com/docs-cyberark',
  OVERVIEW: 'https://go.bigid.com/docs-overview',
  IDENTITY_LINEAGE: 'https://go.bigid.com/docs-identity-lineage',
  MANAGING_SCANS: 'https://go.bigid.com/docs-managing-scans',
  DATA_SOURCES: 'https://go.bigid.com/docs-data-sources',
  DATA_SOURCES_ONBOARDING: 'https://docs.bigid.com/docs/data-source-interface',
  CONSENT_GOVERNANCE_OVERVIEW: 'https://go.bigid.com/docs-consent-governance-overview',
  SUBJECT_ACCESS_REQUEST: 'https://go.bigid.com/docs-subject-access-request',
  INVENTORY: 'https://go.bigid.com/docs-inventory',
  INSTALLING_AZURE_INTEGRATION_APPLICATION: 'https://go.bigid.com/docs-installing-the-azure-integration-application',
  DATA_INSIGHTS_STUDIO: 'https://docs.bigid.com/docs/data-insights-studio',
  DATA_PIPELINE: 'https://docs.bigid.com/docs/data-pipeline-1',
  DELETE_POLICY_READ_MORE: 'https://go.bigid.com/docs-creating-new-policy-edit-policy',
  SCANS_DOCUMENTATION: 'https://docs.bigid.com/docs/scans',
  SCANS_BEST_PRACTICE: 'https://docs.bigid.com/docs/scanning-best-practices',
  DISCOVERY_INSIGHTS: 'https://docs.bigid.com/v1/docs/en/data-overview',
  NEW_DATA_SOURCE_CONNECTION: 'https://docs.bigid.com/v1/docs/creating-a-new-data-source-connection',
  SENSITIVITY_CLASSIFICATION: 'https://docs.bigid.com/docs/sensitivity-classification-interface?highlight=sensitivity',
  NEW_DATA_SOURCE_CONNECTION_SCAN_SETTINGS:
    'https://docs.bigid.com/v1/docs/creating-a-new-data-source-connection#scan-settings-tab',
  SUPPORTED_DATA_SOURCES: 'https://docs.bigid.com/docs/supported-data-sources',
  CLASSIFIER_TUNING: 'https://docs.bigid.com/v1/docs/classifier-tuning',
  CLASSIFIER_TUNING_PREVIEW_LIMITATION: 'https://go.bigid.com/attribute-preview-limitation',
  CORRELATION: 'https://go.bigid.com/more-correlation',
  CLUSTER_ANALYSIS: 'https://go.bigid.com/more-clusters',
  REPORTS_AND_LOGS: 'https://go.bigid.com/more-reports',
  CORRELATION_SETS: 'https://go.bigid.com/more-cls',
  SCAN_WINDOWS: 'https://go.bigid.com/more-scan-windows',
  SAVED_QUERIES: 'https://go.bigid.com/more-queries',
  CREDENTIALS: 'https://go.bigid.com/more-credentials',
  ACTION_CENTER_ACTIONS: 'https://go.bigid.com/more-actions',
  CERTIFICATES: 'https://go.bigid.com/certificates',
  APPLICATIONS_MANAGEMENT: 'https://go.bigid.com/apps',
  RISK_CONFIGURATION: 'https://docs.bigid.com/v1/docs/en/risk-configuration',
  SCAN_TEMPLATES: 'https://docs.bigid.com/docs/en/scan-templates',
  SCAN_TEMPLATE_CLASSIFICATION: 'https://docs.bigid.com/docs/scan-templates#step-2-classification',
  CONVERTING_TO_SCAN: 'https://docs.bigid.com/v1/docs/saved-scans#convert-to-scan',
  SAVED_SCANS: 'https://docs.bigid.com/v1/docs/saved-scans',
  AUTO_DISCOVERY_AWS_DOCS: 'https://docs.bigid.com/docs/aws-onboarding-overview',
  AUTO_DISCOVERY_AZURE_DOCS: 'https://docs.bigid.com/docs/azure-onboarding-wizard',
  AUTO_DISCOVERY_GCP_DOCS: 'https://docs.bigid.com/docs/gcp-onboarding',
  AUTO_DISCOVERY_ONTAP_DOCS: 'https://docs.bigid.com/docs/netapp-onboarding',
  AUTO_DISCOVERY_GCP_PREREQUISITES: 'https://docs.bigid.com/docs/google-data-sources-minimum-permissions',
  AZURE_APP_REGISTRATION: 'https://docs.bigid.com/v1/docs/azure-app-registration',
  AZURE_MANAGED_IDENTITY: 'https://docs.bigid.com/v1/docs/managed-identity-authentication',
  AZURE_CLIENT_SECRET: 'https://docs.bigid.com/v1/docs/azure-client-secret',
  AZURE_CLIENT_ID: 'https://docs.bigid.com/v1/docs/azure-client-id-tenant-id',
  NAVIGATION_PANEL: 'https://docs.bigid.com/docs/navigation-panel',
  REGEX_CLASSIFIERS_DOC: 'https://docs.bigid.com/docs/regex#basics-of-creating-regex-classifiers-in-bigid',
  NER_CLASSIFIERS_DOC: 'https://docs.bigid.com/docs/ner-classification-1',
  EA_PROGRAM_DOC: 'https://go.bigid.com/early-adopter-opt-in',
  COLLABORATION_DOC: 'https://docs.bigid.com/docs/inviting-collaborators',
  WORKSPACES_DOC: 'https://docs.bigid.com/v1/docs/workspaces',
  CLASSIFIERS_MANAGEMENT_DOC: 'https://docs.bigid.com/docs/classifier-management',
  CLASSIFICATION_DOC: 'https://docs.bigid.com/docs/out-of-the-box-classifiers',
  MANAGED_VOLUME_DOC: 'https://go.bigid.com/managed-volume',
  BUSINESS_GLOSSARY_DOC: 'https://docs.bigid.com/docs/business-glossary',
  LOGICAL_DATA_SOURCE_COUNT_DOC: 'https://go.bigid.com/logical-data-source-count',
  CREATE_SERVICE_ACCOUNT:
    'https://docs.bigid.com/docs/configuring-gcp-data-sources-for-auto-discovery-1#create-a-gcp-service-account',
  GCP_PERM: 'https://docs.bigid.com/docs/google-data-sources-minimum-permissions-1',
  ENABLE_GCP_SERVICE_API:
    'https://docs.bigid.com/docs/configuring-gcp-data-sources-for-auto-discovery-1#enable-api-and-services-for-a-gcp-project',
  CREATE_SERVICE_ACC_KEY: 'https://docs.bigid.com/docs/create-service-account-key',
  CONFIGURE_WORKLOAD_IDENTITY: 'https://docs.bigid.com/docs/configure-workload-identity',
  DSPM_DOC: 'https://go.bigid.com/dspm-express',
  AI_DATA_SECURITY_DOC: 'https://go.bigid.com/ai-data-security-governance',
  SNAPSHOT_SCANNING_DOC: 'https://go.bigid.com/dual-scanning',
  PRIVACY_RISK_MANAGEMENT_DOC: 'https://go.bigid.com/risk-management',
  CATALOG_EXPLORATION_DOC: 'https://docs.bigid.com/docs/metadata-search-1',
  COMPLIANCE_DASHBOARD_DOC: 'https://docs.bigid.com/docs/compliance-dashboard',
  UNIVERSAL_CONSENT_DOC: 'https://docs.bigid.com/docs/universal-consent',
  DYNAMIC_FORMS_DOC: 'https://docs.bigid.com/docs/portal-editor-new',
  CORRELATION_SET_BEST_PRACTICE: 'https://docs.bigid.com/docs/correlation-set-best-practices',
});

export const publicUrls = Object.freeze({
  LAUNCHPAD_SUPPORT_SAP_INCIDENT_CREATE: 'https://go.bigid.com/sap-incident-create',
  BIGID_PRIVACY_NOTICE: 'https://go.bigid.com/privacy-notice',
  SUPPORT_BIGID: 'https://go.bigid.com/bigid-support',
  BIGID_SMALLID: 'https://go.bigid.com/smallid-website',
  PRIVACY_PORTAL_APP: 'https://go.bigid.com/privacy-portal-app',
  BIGIDPRIVACY_CLOUD_PRM_LOGIN: 'https://go.bigid.com/privacy-cloud-login',
  DOCS_AWS_IAM_USERGUIDE: 'https://go.bigid.com/aws-iam',
  BIGID_CLOUD_LOOKER: 'https://go.bigid.com/looker-website',
  SUPPORT_SAP: 'https://go.bigid.com/sap-support',
  SMALLID_GETTING_STARTED_HELP: 'https://bigid.chilipiper.com/book/smallid',
  SMALLID_GETTING_STARTED_DOC: 'https://docs.bigid.com/docs/getting-started-discovery-on-demand',
  SMALLID_GETTING_STARTED_VIDEO: 'https://www.smallid.com/product-videos/',
  SMALLID_GETTING_STARTED_CLASSIFIERS_DOC: 'https://docs.bigid.com/docs/data-discovery-wizard-1',
  SMALLID_GETTING_STARTED_SCAN_TYPES_DOC: 'https://docs.bigid.com/docs/create-a-new-scan-profile',
  SALESFORCE_TEST_URL: 'https://test.salesforce.com',
  SERVICENOW_TEST_URL: 'https://test.servicenow.com',
  SCAN_TEMPLATES_CORRELATION: 'https://docs.bigid.com/docs/scan-templates#step-3-correlation',
  AUTO_DISCOVERY_AWS_CONFIG: 'https://aws.amazon.com/config/',
  AZURE_PORTAL: 'https://portal.azure.com/',
  AWS_TEMPLATE_BASE: 'https://us-east-1.console.aws.amazon.com/cloudformation/',
  AWS_TEMPLATE_LATEST: 'https://bigid-cloudformation-templates.s3.amazonaws.com/bigid-saas-roles-latest.yaml',
  AMAZON_BLOG: 'https://aws.amazon.com/blogs/',
  AWS_NAMING_RULES_URL: 'https://docs.aws.amazon.com/AmazonS3/latest/userguide/bucketnamingrules.html',
  GOOGLE_CLOUD_CONSOLE: 'https://console.cloud.google.com',
  HANDLING_LARGE_SCALE_CORRELATION_SETS:
    'https://docs.bigid.com/docs/testing-the-correlation-set#handling-large-scale-correlation-sets',
  DROPBOX_ACCESS_CODE_URL: 'https://www.dropbox.com/oauth2/authorize',
});

export const appsUrls = Object.freeze({
  AWS_LOCAL_URL: 'http://bigid-aws.local/api',
  AZURE_LOCAL_URL: 'http://bigid-azure.local/api',
  GCP_LOCAL_URL: 'http://bigid-gcp.local/api',
  ONTAP_LOCAL_URL: 'http://bigid-ontap.local/api',
  DATA_STEWARDSHIP: 'https://bigid.com/perspective/data-stewardship-app/',
});
