import { useEffect, useMemo, useState } from 'react';
import { $rootScope } from 'ngimport';
import { $state } from '../../../services/angularServices';
import { debounce } from 'lodash';

export const useLocationChange = () => {
  const [stateParams, setStateParams] = useState({ ...$state.params });

  useEffect(() => {
    const handleLocationChange = debounce(() => {
      setStateParams({ ...$state.params });
    }, 100);
    return $rootScope.$on('$locationChangeSuccess', handleLocationChange);
  }, []);

  return useMemo(
    () => ({
      stateParams,
    }),
    [stateParams],
  );
};
