import React, { useEffect } from 'react';
import { PrimaryButton } from '@bigid-ui/components';
import { pageHeaderService } from '../../../../../common/services/pageHeaderService';
import { useLocalTranslation } from '../../translations';
import { getPermissions } from '../utils';
import { BigidAddIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { EmailTemplateTrackingEvents } from '../../emailTemplateTrackingUtils';

interface UsePageHeader {
  handleBrandAdd: () => void;
  shouldShowAction: boolean;
}

export const usePageHeader = ({ handleBrandAdd, shouldShowAction }: UsePageHeader) => {
  const { t } = useLocalTranslation();
  const { isCreatePermitted, isEditPermitted } = getPermissions();

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: t('Wrapper.emailTemplates'),
      rightSideComponentsContainer: shouldShowAction && (
        <PrimaryButton
          disabled={!isCreatePermitted || !isEditPermitted}
          text={t('buttons.addBrand')}
          size="large"
          dataAid={generateDataAid('BigidLayoutMasterDetails', ['add-new-brand'])}
          onClick={handleBrandAdd}
          startIcon={<BigidAddIcon />}
          bi={{ eventType: EmailTemplateTrackingEvents.EMAIL_TEMPLATES_BRANDS_ADD_BRAND_CLICK }}
        />
      ),
    });
  }, [handleBrandAdd, isCreatePermitted, isEditPermitted, shouldShowAction, t]);
};
