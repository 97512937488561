import React from 'react';
import { CorrelationSetsWizardSteps, DefaultStepProps, IdConnectionState } from '../types/CorrelationSetsTypes';
import { guideStepsContent } from '../CorrelationSetWizard/CorrelationSetWizard';
import { useCorrelationSetWizardContext } from '../contexts/correlationSetContext';
import { CorrelationSetGuide } from './CorrelationSetGuide';
import styled from '@emotion/styled';
import { CalculatingStatisticsLoader } from './CalculatingStatisticsLoader';

export const CorrelationSetStepsWrapperStyled = styled.div`
  display: flex;
  height: 100%;
  font-size: 14px;
  border-top: ${({ theme }) => `1px solid ${theme.vars.palette.bigid.gray200}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.vars.palette.bigid.gray200}`};
`;

export const ColumnRight = styled.div`
  padding: 16px;
  width: 470px;
  border-left: ${({ theme }) => `1px solid ${theme.vars.palette.bigid.gray200}`};
  overflow-y: auto;
`;

export const ColumnLeft = styled.div`
  padding: 16px;
  flex: 1;
  overflow-y: auto;
`;

interface CorrelationSetStepsWrapperProps {
  leftColumn: React.ReactNode;
  rightColumn: React.ReactNode;
}

export const CorrelationSetStepsWrapper = ({ leftColumn, rightColumn }: CorrelationSetStepsWrapperProps) => {
  return (
    <CorrelationSetStepsWrapperStyled>
      <ColumnLeft>{leftColumn}</ColumnLeft>
      <ColumnRight>{rightColumn}</ColumnRight>
    </CorrelationSetStepsWrapperStyled>
  );
};

export const withGuideRightComponent =
  (Component: React.ComponentType<DefaultStepProps>) => (props: DefaultStepProps) => {
    const { activeStepId, id } = props;
    const { title, contentComponent: ContentComponent } = guideStepsContent[activeStepId];
    const { guideState, correlationSetFormData } = useCorrelationSetWizardContext();

    if (
      correlationSetFormData.state === IdConnectionState.ANALYZING &&
      activeStepId === CorrelationSetsWizardSteps.RESULTS
    ) {
      return <CalculatingStatisticsLoader />;
    }

    return (
      <CorrelationSetStepsWrapper
        leftColumn={<Component {...props} />}
        rightColumn={
          <CorrelationSetGuide
            headerTitle="Correlation set guide"
            contentComponent={<ContentComponent guideState={guideState} contentTitle={title} />}
          />
        }
      />
    );
  };
