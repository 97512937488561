import {
  BigidDropdownOption,
  BigidFormField,
  BigidFormFieldTypes,
  BigidFormProps,
  BigidFormValidateOnTypes,
} from '@bigid-ui/components';
import {
  businessGlossaryService,
  GlossaryItem,
  GlossaryItemType,
} from '../../../../administration/generalSettings/businessGlossary/businessGlossary.service';
import { FormRichTextField } from './FormRichTextField';
import { isBusinessTermsEnabled } from '../../../utilities/featureFlagUtils';

const NOT_ALLOWED_NAME_CHARS = "Name can't contain these symbols < >";
const ALLOWED_DESCRIPTION_CHARS = 'You can only use the following symbols  ! ? . , : - _';

const nameRegex = /^(?!.*[&$<>*^#]).+$/;
const descriptionRegex = /^(?!.*[&$<>^#]).*$/;
const injectionsRegex = new RegExp('^[@=+-]|,[@=+-]');

export const getDropdownOption = (value: string): BigidDropdownOption => ({
  id: value,
  value: value,
  displayValue: value,
});

export const getDropdownOptions = (values: string[]): BigidDropdownOption[] => {
  if (!values) return [];
  return values.sort().map(getDropdownOption);
};

export const getGlossaryTypeOptions = (): BigidDropdownOption[] => [
  getDropdownOption(GlossaryItemType.PersonalDataItem),
  getDropdownOption(GlossaryItemType.PurposeOfProcessing),
  getDropdownOption(GlossaryItemType.PersonalDataCategory),
  ...(isBusinessTermsEnabled() ? [getDropdownOption(GlossaryItemType.BusinessTerm)] : []),
];

export const getFormInitialValues = (glossaryItem?: GlossaryItem): BigidFormProps['initialValues'] => ({
  _id: glossaryItem?._id,
  name: glossaryItem?.name,
  type: getGlossaryTypeOptions().filter(({ value }) => value === glossaryItem?.type),
  glossary_id: glossaryItem?.glossary_id,
  description: {
    markdown: glossaryItem?.description ?? '',
  },
  owner: glossaryItem?.owner
    ? [{ id: glossaryItem.owner, value: glossaryItem.owner, displayValue: glossaryItem.owner }]
    : [],
  domain: glossaryItem?.domain
    ? [{ id: glossaryItem.domain, value: glossaryItem.domain, displayValue: glossaryItem.domain }]
    : [],
  sub_domain: glossaryItem?.sub_domain
    ? [{ id: glossaryItem.sub_domain, value: glossaryItem.sub_domain, displayValue: glossaryItem.sub_domain }]
    : [],
  report_order: glossaryItem?.report_order ?? '',
});

export const validateName = (value: string) => {
  if (!value.trim()) {
    return 'Please enter name';
  }
  if (!nameRegex.test(value) || injectionsRegex.test(value)) {
    return NOT_ALLOWED_NAME_CHARS;
  }
  return false;
};

export const validateType = (selectedOption: BigidDropdownOption[]) => {
  if (!selectedOption?.length || !businessGlossaryService.isValidType(selectedOption[0].value)) {
    return 'Please select the Type';
  }
  return false;
};

export const validateDescription = (value: any) => {
  const description = value.markdown ?? '';

  if (!descriptionRegex.test(description) || injectionsRegex.test(description)) {
    return ALLOWED_DESCRIPTION_CHARS;
  }

  if (!value) {
    return 'Please enter name';
  }
  if (!nameRegex.test(value) || injectionsRegex.test(value)) {
    return NOT_ALLOWED_NAME_CHARS;
  }
  return false;
};

export const validateGlossaryId = (value: string) => {
  if (value && !value.match(/^[a-zA-Z0-9_-]+$/)) {
    return 'Please enter unique id using [a-z0-9_-] characters only.';
  }
  return false;
};

export const isReportOrderValid = (value: string) => {
  if (value === '' || value === null || value === undefined) {
    return true;
  }
  const numericValue = parseInt(value);
  return numericValue >= 1 && numericValue <= 1000;
};

export const validateReportOrder = (value: string) => {
  if (!isReportOrderValid(value)) {
    return `Report Order should be a number between 1 and 1000`;
  }
  return false;
};

export const getFormFields = ({
  isCreateMode,
  owners,
  domains,
  subDomains,
}: {
  isCreateMode: boolean;
  owners: string[];
  domains: string[];
  subDomains: string[];
}): BigidFormField[] => [
  {
    type: BigidFormFieldTypes.TEXT,
    name: 'name',
    label: 'Display Name',
    isRequired: true,
    misc: {
      placeholder: 'Type name',
      fullWidth: true,
    },
    validate: validateName,
    validateOn: BigidFormValidateOnTypes.CHANGE,
  },
  {
    type: BigidFormFieldTypes.DROP_DOWN,
    name: 'type',
    label: 'Type',
    isRequired: isCreateMode,
    dropDownOptions: getGlossaryTypeOptions(),
    misc: {
      placeholder: 'Select type',
      fullWidth: true,
    },
    validate: validateType,
  },
  {
    render: FormRichTextField,
    name: 'description',
    label: 'Description',
    isRequired: false,
    misc: {
      fullWidth: true,
    },
    validate: validateDescription,
  },
  {
    type: BigidFormFieldTypes.DROP_DOWN,
    name: 'owner',
    label: 'Owner',
    isRequired: false,
    dropDownOptions: getDropdownOptions(owners),
    fieldProps: {
      isSearchable: true,
      isCreatable: true,
      onCreate: (value: string): BigidDropdownOption => ({ id: value, value, displayValue: value }),
    },
    misc: {
      placeholder: 'Select owner',
      fullWidth: true,
    },
  },
  {
    type: BigidFormFieldTypes.DROP_DOWN,
    name: 'domain',
    label: 'Domain',
    isRequired: false,
    dropDownOptions: getDropdownOptions(domains),
    fieldProps: {
      isSearchable: true,
      isCreatable: true,
      onCreate: (value: string): BigidDropdownOption => ({ id: value, value, displayValue: value }),
    },
    misc: {
      placeholder: 'Select domain',
      fullWidth: true,
    },
  },
  {
    type: BigidFormFieldTypes.DROP_DOWN,
    name: 'sub_domain',
    label: 'Sub-domain',
    isRequired: false,
    dropDownOptions: getDropdownOptions(subDomains),
    fieldProps: {
      isSearchable: true,
      isCreatable: true,
      onCreate: (value: string): BigidDropdownOption => ({ id: value, value, displayValue: value }),
    },
    misc: {
      placeholder: 'Select sub-domain',
      fullWidth: true,
    },
  },
  {
    validateOn: BigidFormValidateOnTypes.CHANGE,
    type: BigidFormFieldTypes.TEXT,
    name: 'glossary_id',
    label: 'Glossary Id',
    isRequired: false,
    misc: {
      placeholder: 'Insert unique id for the item',
      fullWidth: true,
    },
    tooltipText: isCreateMode ? 'Provide unique id for this glossary item or it will be auto generated.' : undefined,
    validate: validateGlossaryId,
  },
  {
    type: BigidFormFieldTypes.NUMBER,
    validate: validateReportOrder,
    name: 'report_order',
    label: 'Order in PDF Reports',
    validateOn: BigidFormValidateOnTypes.CHANGE,
    misc: {
      min: 1,
      max: 1000,
      step: 1,
      fullWidth: true,
    },
  },
];

export const getMessageFromUpdateItemError = (error: any, isCreateMode: boolean) => {
  if (error.data?.includes('index: type_1_name_1')) {
    return 'This name is already taken';
  }
  if (error.response?.status === 409) {
    return error.response.data;
  }
  return `Failed to ${isCreateMode ? 'create new' : 'update'} Glossary Item.`;
};
