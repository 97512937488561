import { isNewGlossaryPageEnabled } from '../../utilities/featureFlagUtils';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import { GlossaryItem } from '../../../administration/generalSettings/businessGlossary/businessGlossary.service';
import { uniq } from 'lodash';

export const navigateToOldPageIfFFOff = () => {
  const isNewAttributePageEnabled = isNewGlossaryPageEnabled();

  if (!isNewAttributePageEnabled) {
    $state.go(CONFIG.states.GENERAL_SETTINGS);
  }
};

export const getAllGlossaryOwners = (items: GlossaryItem[]) => uniq(items.map(item => item.owner)).filter(Boolean);

export const getAllGlossaryDomains = (items: GlossaryItem[]) => uniq(items.map(item => item.domain)).filter(Boolean);

export const getAllGlossarySubDomains = (items: GlossaryItem[]) =>
  uniq(items.map(item => item.sub_domain)).filter(Boolean);
