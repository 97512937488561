import { chunk } from 'lodash';

import { httpService } from '../../../services/httpService';
import { notificationService } from '../../../services/notificationService';
import { getFixedT } from '../translations';

const t = getFixedT('DataSource.ObjectDetails');
export const fetchQuery = async (scanIds: string[], fqn: string) => {
  const chunkSize = 100;
  try {
    const deleteQueries: string[] = [];
    const selectQueries: string[] = [];
    const fetchFunctions = chunk(scanIds, chunkSize).map(async scanIds => {
      let hasMore = true;
      let offset = 0;
      while (hasMore) {
        const {
          data: {
            data: { queries = [] },
          },
        } = await httpService.fetch<{ data: { queries: Array<{ deleteQuery: string; selectQuery: string }> } }>(
          `data-minimization/dsar-queries?includeSelectQuery=true&requestIds=${scanIds}&fullyQualifiedObjectName=${fqn}&limit=${chunkSize}&offset=${offset}&`,
        );
        queries.forEach(item => {
          deleteQueries.push(item.deleteQuery);
          selectQueries.push(item.selectQuery);
        });
        hasMore = queries.length >= chunkSize;
        offset += 1;
      }
    });

    await Promise.all(fetchFunctions);

    return {
      deleteQueries: deleteQueries.join(';'),
      selectQueries: selectQueries.join(';'),
    };
  } catch (e) {
    console.error(e);
    notificationService.error(t('couldNotFetchQuery'));
    return {
      deleteQueries: '',
      selectQueries: '',
    };
  }
};
