import React, { FC, useMemo, useState, memo } from 'react';
import { BigidLoader, BigidPaper, BigidToolbarActionProps, ToolbarActionType } from '@bigid-ui/components';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
} from '@bigid-ui/grid';
import styled from '@emotion/styled';
import {
  businessGlossaryService,
  GlossaryItem,
} from '../../../../administration/generalSettings/businessGlossary/businessGlossary.service';
import { gridClientSideFiltering, getInitialFilterToolbarConfig } from './glossaryGridUtils';
import { notificationService } from '../../../services/notificationService';
import { isPermitted } from '../../../services/userPermissionsService';
import { GENERAL_SETTINGS_PERMISSIONS } from '@bigid/permissions';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { BigidDeleteIcon, BigidExportIcon } from '@bigid-ui/icons';
import { useLocalTranslation } from '../translations';
import { downloadFile } from '../../../services/downloadFile';

const USER_PREFS_ID = 'business-glossary-new';

export const GridWrapper = styled('div')`
  width: 100%;
  display: flex;
  position: relative;
  height: calc(100% - 24px);
  max-height: calc(100% - 24px);
  overflow: hidden;
`;

type GlossaryGridProps = {
  onGlossaryItemSelect: (glossaryItem: GlossaryItem) => void;
  setIsExportInProgress: (status: boolean) => void;
};

export const GlossaryGrid: FC<GlossaryGridProps> = memo(({ onGlossaryItemSelect, setIsExportInProgress }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useLocalTranslation('BusinessGlossary.grid');

  const columns: BigidGridColumn<GlossaryItem>[] = useMemo(
    () =>
      [
        {
          name: 'name',
          title: t('displayNameColumn'),
          getCellValue: row => row.name,
          type: BigidGridColumnTypes.TEXT,
        },
        {
          name: 'type',
          title: t('typeColumn'),
          getCellValue: row => row.type,
          type: BigidGridColumnTypes.TEXT,
        },
        {
          name: 'description',
          title: t('descriptionColumn'),
          getCellValue: ({ description }) => (typeof description === 'string' ? description : description),
          type: BigidGridColumnTypes.TEXT,
        },
        {
          name: 'domain',
          title: t('domainColumn'),
          getCellValue: row => row.domain,
          type: BigidGridColumnTypes.TEXT,
        },
        {
          name: 'owner',
          title: t('ownerColumn'),
          getCellValue: row => row.owner,
          type: BigidGridColumnTypes.TEXT,
        },
        {
          name: 'glossary_id',
          title: t('glossaryIdColumn'),
          getCellValue: row => row.glossary_id,
          type: BigidGridColumnTypes.TEXT,
        },
        {
          name: 'report_order',
          title: t('reportOrderColumn'),
          getCellValue: row => row.report_order,
          type: BigidGridColumnTypes.TEXT,
        },
      ] as BigidGridColumn<GlossaryItem>[],
    [],
  );

  const { isReady, gridColumns, filterToolbarConfig } = useUserPreferences({
    stateName: USER_PREFS_ID,
    initialGridColumns: columns,
    getInitialFilterToolbarConfig,
  });

  const gridWithToolbarConfig: BigidGridWithToolbarProps<GlossaryItem> = useMemo(() => {
    if (isReady) {
      return {
        showSortingControls: true,
        filterToolbarConfig,
        pageSize: 20000,
        columns: gridColumns,
        entityName: 'Items',
        onRowClick: onGlossaryItemSelect,
        rowClickShouldKeepSelection: true,
        fetchData: async (queryComponents: BigidGridQueryComponents) => {
          let data: GlossaryItem[] = [];
          try {
            const glossaryItems = await businessGlossaryService.getGlossaryItems();
            data = gridClientSideFiltering(glossaryItems, queryComponents);
          } catch (err) {
            const errMsg = `Error occurred while fetching Business Glossary`;
            notificationService.error(errMsg);
            console.error(`${errMsg} ${err}`);
          }
          return { data, totalCount: data.length };
        },
        toolbarActions: [
          {
            label: t('deleteAction'),
            icon: BigidDeleteIcon,
            type: ToolbarActionType.TERTIARY,
            execute: async ({ selectedRowIds }) => {
              if (
                await showConfirmationDialog({
                  entitiesCount: selectedRowIds.length,
                  entityNameSingular: 'Glossary Item',
                  entityNamePlural: 'Glossary Items',
                })
              ) {
                try {
                  setIsLoading(true);
                  await businessGlossaryService.deleteGlossaryItems(selectedRowIds as string[]);
                  return { shouldGridReload: true, shouldClearSelection: true };
                } catch {
                  notificationService.error(t('deleteItemsError'));
                } finally {
                  setIsLoading(false);
                }
              }
              return {};
            },
            show: ({ selectedRowIds }) => {
              return Boolean(
                selectedRowIds.length && isPermitted(GENERAL_SETTINGS_PERMISSIONS.DELETE_BUSINESS_GLOSSARY.name),
              );
            },
          },
          {
            label: t('exportAction'),
            icon: BigidExportIcon,
            type: ToolbarActionType.TERTIARY,
            execute: async ({ selectedRowIds }) => {
              try {
                setIsExportInProgress(true);
                const response = await businessGlossaryService.getGlossaryDataExportByIds(selectedRowIds as string[]);
                downloadFile(response, 'attachment/csv', 'glossaryData.csv');
                return { shouldGridReload: false, shouldClearSelection: true };
              } catch (err) {
                const errMsg = t('exportActionError');
                notificationService.error(errMsg);
                console.error(`${errMsg} ${err}`);
              } finally {
                setIsExportInProgress(false);
              }
              return {};
            },
            show: ({ selectedRowIds }) => {
              return Boolean(
                selectedRowIds.length && isPermitted(GENERAL_SETTINGS_PERMISSIONS.EXPORT_BUSINESS_GLOSSARY.name),
              );
            },
          },
        ] as BigidToolbarActionProps[],
      };
    }
  }, [filterToolbarConfig, gridColumns, isReady]);

  return (
    <GridWrapper>
      <BigidPaper>
        {(!isReady || isLoading) && <BigidLoader />}
        {isReady && <BigidGridWithToolbar {...gridWithToolbarConfig} />}
      </BigidPaper>
    </GridWrapper>
  );
});
