import { SessionStorageItem, sessionStorageService } from '../../common/services/sessionStorageService';
import { emailSetupService } from './emailSetupService';
import { httpService } from './httpService';
import { notificationService } from './notificationService';
import { SystemEvents, systemEventsEmitter } from './systemEvents';

const DEFAULT_TIMEZONE = 'Etc/UTC';

type AppPreferencesBoolean = 'true' | 'false';

export interface AppPreferences {
  CLUSTERING_ENABLED: AppPreferencesBoolean;
  ACI_ENABLED: AppPreferencesBoolean;
  ACI_DASHBOARD_UI_V2_ENABLED: AppPreferencesBoolean;
  ACI_SUPPORT_FOR_FOLDERS_ENABLED: AppPreferencesBoolean;
  BIGIDME_AGENT_ENABLED: AppPreferencesBoolean;
  SHOW_DASHBOARD_PAGE: AppPreferencesBoolean;
  SHOW_ATTRIBUTES_ENRICHMENT_PAGE: AppPreferencesBoolean;
  USE_DSAR_REPORT_TEMPLATES: AppPreferencesBoolean;
  SHOW_DSAR_SNAKE: AppPreferencesBoolean;
  DSAR_SHOW_FILES_RETRIEVAL: AppPreferencesBoolean;
  SHOW_DSAR_LEGACY_VIEWS: AppPreferencesBoolean;
  DSAR_USE_CATALOG_COLUMNS: AppPreferencesBoolean;
  DSAR_ALLOW_CSV_SUMMARY_REPORT: AppPreferencesBoolean;
  SHOW_OLD_BUSINESS_GLOSSARY_PAGE: AppPreferencesBoolean;
  HIDE_ATTRIBUTE_PURPOSE_OF_USE: AppPreferencesBoolean;
  TIME_ZONE: string;
  REPORT_LANGUAGE: string;
  SCAN_RESULTS_QUERY_LIMIT: string;
  applications: { application_id: number; application_name: string }[];
  LABELER_ENABLED: AppPreferencesBoolean;
  METADATA_CLASSIFIER_ENABLED: AppPreferencesBoolean;
  ENTITY_LEVEL_TAGGING_SUPPORTED?: any;
  RESIDENCY_STATES_FIRST: AppPreferencesBoolean;
  UI_IDLE_TIMEOUT_IN_MINUTES: string;
  UI_DATE_TIME_LOCALE: string;
  DIM_ENABLED: AppPreferencesBoolean;
  ENABLE_OUTLOOK_ALPHA: AppPreferencesBoolean;
  SHOW_STRUCTURED_CLUSTERING: AppPreferencesBoolean;
  KAFKA_CONNECT_ENABLED: AppPreferencesBoolean;
  SLACK_FILES_ENABLED: AppPreferencesBoolean;
  GENERIC_CLOUD_CONNECTOR_ENABLED: AppPreferencesBoolean;
  SPARK_CONNECTORS_ENABLED: AppPreferencesBoolean;
  LEGACY_S3_CONNECTOR_ENABLED: AppPreferencesBoolean;
  LEGACY_GCS_CONNECTOR_ENABLED: AppPreferencesBoolean;
  LEGACY_NFS_CONNECTOR_ENABLED: AppPreferencesBoolean;
  LEGACY_CUSTOM_CONNECTOR_ENABLED: AppPreferencesBoolean;
  LEGACY_GMAIL_CONNECTOR_ENABLED: AppPreferencesBoolean;
  LEGACY_OUTLOOK_CONNECTOR_ENABLED: AppPreferencesBoolean;
  LEGACY_SHAREPOINT_ONLINE_CONNECTOR_ENABLED: AppPreferencesBoolean;
  LEGACY_CSV_CONNECTOR_ENABLED: AppPreferencesBoolean;
  LEGACY_CONFLUENCE_CONNECTOR_ENABLED: AppPreferencesBoolean;
  LEGACY_GITHUB_CONNECTOR_ENABLED: AppPreferencesBoolean;
  LEGACY_ZENDESK_CONNECTOR_ENABLED: AppPreferencesBoolean;
  LEGACY_ATHENA_CONNECTOR_ENABLED: AppPreferencesBoolean;
  LEGACY_SHAREFILE_CONNECTOR_ENABLED: AppPreferencesBoolean;
  LEGACY_WORKDAY_CONNECTOR_ENABLED: AppPreferencesBoolean;
  LEGACY_VERTICA_CONNECTOR_ENABLED: AppPreferencesBoolean;
  LEGACY_EXCHANGE_CONNECTOR_ENABLED: AppPreferencesBoolean;
  LEGACY_AZURE_CONNECTORS_ENABLED: AppPreferencesBoolean;
  LEGACY_SMB_ENABLED: AppPreferencesBoolean;
  LEGACY_MICROSOFT_TEAMS_ENABLED: AppPreferencesBoolean;
  LEGACY_SAP_SUCCESS_FACTORS_ENABLED: AppPreferencesBoolean;
  LEGACY_SLACK_ENABLED: AppPreferencesBoolean;
  LEGACY_MICROSOFT_DYNAMICS_ENABLED: AppPreferencesBoolean;
  LEGACY_TABLEAU_ENABLED: AppPreferencesBoolean;
  LEGACY_JIRA_ENABLED: AppPreferencesBoolean;
  LEGACY_SERVICENOW_ENABLED: AppPreferencesBoolean;
  LEGACY_GITLAB_ENABLED: AppPreferencesBoolean;
  LEGACY_REDIS_ENABLED: AppPreferencesBoolean;
  LEGACY_EXCHANGE_ENABLED: AppPreferencesBoolean;
  LEGACY_INFORMIX_ENABLED: AppPreferencesBoolean;
  LEGACY_BOX_ENABLED: AppPreferencesBoolean;
  LEGACY_HBASE_ENABLED: AppPreferencesBoolean;
  LEGACY_SHAREPOINT_ENABLED: AppPreferencesBoolean;
  LEGACY_GOOGLEDRIVE_ENABLED: AppPreferencesBoolean;
  LEGACY_ONEDRIVE_ENABLED: AppPreferencesBoolean;
  LEGACY_GCP_STRUCTURED_CONNECTORS_ENABLED: AppPreferencesBoolean;
  SERVICENOW_STRUCTURED_CONNECTOR_ENABLED: AppPreferencesBoolean;
  GENERIC_CLOUD_CONNECTOR_RESOURCE_ADDRESS: string;
  GENERIC_CLOUD_CONNECTOR_IDP_URL: string;
  GENERIC_CLOUD_CONNECTOR_CLIENT_SECRET_KEY: string;
  GENERIC_CLOUD_CONNECTOR_CLIENT_ID_KEY: string;
  GENERIC_CLOUD_CONNECTOR_AUDIENCE: string;
  GRA_V2_ENABLED: AppPreferencesBoolean;
  ITERABLE_CONNECTOR_ENABLED: AppPreferencesBoolean;
  MYSQL_V2_CONNECTOR_ENABLED: AppPreferencesBoolean;
  MSSQL_V2_CONNECTOR_ENABLED: AppPreferencesBoolean;
  CUSTOM_PROXY_CONNECTOR_ENABLED: AppPreferencesBoolean;
  ZENDESK_SELL_CONNECTOR_ENABLED: AppPreferencesBoolean;
  BITBUCKET_CLOUD_CONNECTOR_ENABLED: AppPreferencesBoolean;
  DROPBOX_CONNECTOR_ENABLED: AppPreferencesBoolean;
  SAP_BUSINESS_SUITE_V2_CONNECTOR_ENABLED: AppPreferencesBoolean;
  ZOHO_CRM_CONNECTOR_ENABLED: AppPreferencesBoolean;
  VERTICA_CONNECTOR_ENABLED: AppPreferencesBoolean;
  SMB_V2_CONNECTOR_ENABLED: AppPreferencesBoolean;
  WORKDAY_V2_CONNECTORS_ENABLED: AppPreferencesBoolean;
  WORKDAY_REST_ENABLED: string;
  WORKDAY_REPORTS_ENABLED: string;
  OPENTEXT_CONNECTOR_ENABLED: AppPreferencesBoolean;
  GITHUB_CLOUD_CONNECTOR_ENABLED: AppPreferencesBoolean;
  GITHUB_ON_PREM_CONNECTOR_ENABLED: AppPreferencesBoolean;
  FILENET_CONNECTOR_ENABLED: AppPreferencesBoolean;
  COUCHDB_CONNECTOR_ENABLED: AppPreferencesBoolean;
  TRAY_CONNECTORS_ENABLED: AppPreferencesBoolean;
  UNIX_SFTP_CONNECTOR_ENABLED: AppPreferencesBoolean;
  WINDOWS_SFTP_CONNECTOR_ENABLED: AppPreferencesBoolean;
  FIRESTORE_CONNECTOR_ENABLED: AppPreferencesBoolean;
  SHAREPOINT_V2_CONNECTOR_ENABLED: AppPreferencesBoolean;
  MICROSOFT_DATAVERSE_ENABLED: AppPreferencesBoolean;
  MICROSOFT_DYNAMICS_V2_ENABLED: AppPreferencesBoolean;
  NEPTUNE_CONNECTOR_ENABLED: AppPreferencesBoolean;
  SERVICENOW_V2_CONNECTOR_ENABLED: AppPreferencesBoolean;
  JIRA_V2_CONNECTOR_ENABLED: AppPreferencesBoolean;
  DB2_V2_CONNECTOR_ENABLED: AppPreferencesBoolean;
  ORACLE_CLOUD_ERP_CONNECTOR_ENABLED: AppPreferencesBoolean;
  ORACLE_CLOUD_HCM_CONNECTOR_ENABLED: AppPreferencesBoolean;
  SMART_ROOM_CONNECTOR_ENABLED: AppPreferencesBoolean;
  BITBUCKET_DATA_CENTER_CONNECTOR_ENABLED: AppPreferencesBoolean;
  EXCHANGE_V2_CONNECTOR_ENABLED: AppPreferencesBoolean;
  JFROG_CLOUD_CONNECTOR_ENABLED: AppPreferencesBoolean;
  JFROG_ON_PREM_CONNECTOR_ENABLED: AppPreferencesBoolean;
  AI_SECURITY_AND_GOVERNANCE_ENABLED: AppPreferencesBoolean;
  SSE_TEST_CONNECTION: AppPreferencesBoolean;
  SSE_DSAR_ENTITY_SEARCH: AppPreferencesBoolean;
  CLASSIFICATION_IMPORT_EXPORT_ENABLED: AppPreferencesBoolean;
  SCAN_API_ENABLED: AppPreferencesBoolean;
  SHOW_CUSTOM_APPS: AppPreferencesBoolean;
  SHOW_CUSTOM_DASHBOARD: AppPreferencesBoolean;
  DSAR_PDF_REPORT_FILTER_PERSONAL_INFO: AppPreferencesBoolean;
  FETCH_CLEAR_ATTR_VALUE_ENABLED: AppPreferencesBoolean;
  IS_DSAR_REPORTS_GROUPED: boolean | AppPreferencesBoolean;
  DSAR_GROUP_ATTRIBUTE_BY_FRIENDLY_NAME: AppPreferencesBoolean;
  IS_DSAR_LEGACY_SUMMARY_REPORT: AppPreferencesBoolean;
  COLUMN_PROFILING_ENABLED: AppPreferencesBoolean;
  PARTIAL_CORRELATION_SET: AppPreferencesBoolean;
  ENTITY_SOURCE_EXPORT_ENABLED: AppPreferencesBoolean;
  DISPLAY_CHINA_REGULATIONS: AppPreferencesBoolean;
  SHOW_DATA_CATALOG_HIGHLIGHTS: AppPreferencesBoolean;
  ATTR_CONFIDENCE_LEVEL_COLUMN_ENABLED: AppPreferencesBoolean;
  DEPLOY_TPA_AUTOMATICALLY_ENABLED: AppPreferencesBoolean;
  NEW_QUERY_FILTER_ENABLED: AppPreferencesBoolean;
  DS_TYPES_TEMPLATES_SUPPORTED: AppPreferencesBoolean;
  SHOW_DATA_MINIMIZATION_APP: AppPreferencesBoolean;
  DATA_DELETION_DATA_SOURCES_PAGE_V2: AppPreferencesBoolean;
  DATA_MINIMIZATION_DS_GRID_REFRESH_TIMEOUT: number;
  DATA_DELETION_AUDIT_TRAIL_PAGE_ENABLED: AppPreferencesBoolean;
  DATA_MINIMIZATION_AUDIT_TRAIL_GRID_REFRESH_TIMEOUT: number;
  ENABLE_CANCEL_DATA_DELETION_REQUEST: AppPreferencesBoolean;
  SHOW_DATA_DELETION_HIGHLIGHTS: AppPreferencesBoolean;
  USE_SAAS: AppPreferencesBoolean;
  SAAS_APPLICATIONS_CLICK_TO_INSTALL: AppPreferencesBoolean;
  SAAS_ACTIVE_DIRECTORY_ENABLE_FOR_LABELING: AppPreferencesBoolean;
  WILDCARD_SCOPING: AppPreferencesBoolean;
  ACI_GROUPS_ENABLED: AppPreferencesBoolean;
  CLASSIFIERS_ON_PROFILE_FF: AppPreferencesBoolean;
  DATA_CATALOG_FILE_TYPE_ENABLED: AppPreferencesBoolean;
  DATA_CATALOG_EXTENDED_FILE_TYPE_ENABLED: AppPreferencesBoolean;
  DATA_CATALOG_IMPORT_ENABLED: AppPreferencesBoolean;
  SHOW_CUSTOMER_FEEDBACK: AppPreferencesBoolean;
  SHOW_ERROR_REPORTER: AppPreferencesBoolean;
  FEEDBACK_REPORTER_URL: string;
  TAGS_BULK_ASSIGNMENT_ENABLED: AppPreferencesBoolean;
  ALLOW_UNVERIFIED_APPS: AppPreferencesBoolean;
  ALLOW_TPA_AMPLITUDE_EVENTS: AppPreferencesBoolean;
  ENFORCE_STRONG_PASSWORD_SECURITY: AppPreferencesBoolean;
  SCAN_WINDOW_FF: AppPreferencesBoolean;
  ENABLE_NOTIFICATIONS: AppPreferencesBoolean;
  POLICIES_COMPLEX_ATTRIBUTE: AppPreferencesBoolean;
  ENABLE_ACTION_CENTER: AppPreferencesBoolean;
  ENABLE_LINEAGE: AppPreferencesBoolean;
  ENABLE_DATA_CATALOG_ACTIVITY_MONITORING: AppPreferencesBoolean;
  ENABLE_DSPM_ACTIVITY_MONITORING: AppPreferencesBoolean;
  USE_ACM_FOR_DSPM_ACTIVITY_MONITORING: AppPreferencesBoolean;
  ENABLE_ACTIVITY_HIGHLIGHTS_PDF: AppPreferencesBoolean;
  ENABLE_PROXIES: AppPreferencesBoolean;
  CONFIDENCE_LEVEL_EXPLAIN_TOOLTIP_ENABLED: AppPreferencesBoolean;
  DATA_CATALOG_ADD_UNSTRUCTURED_ATTRIBUTE: AppPreferencesBoolean;
  METADATA_SEARCH_ENABLED: AppPreferencesBoolean;
  USE_METADATA_SEARCH_FOR_DSAR: AppPreferencesBoolean;
  USE_CLS_COLUMNS_FOR_ATTRIBUTE_MAPPING: AppPreferencesBoolean;
  SHOW_REMOTE_CYBERARK_CREDENTIALS_TYPE: AppPreferencesBoolean;
  SHOW_REMOTE_HASHICORP_CREDENTIALS_TYPE: AppPreferencesBoolean;
  SHOW_REMOTE_CUSTOM_CREDENTIALS_TYPE: AppPreferencesBoolean;
  SHOW_THYCOTIC_CREDENTIALS_APP: AppPreferencesBoolean;
  SHOW_THYCOTIC_CREDENTIALS_TYPE: AppPreferencesBoolean;
  SHOW_REMOTE_THYCOTIC_CREDENTIALS_TYPE: AppPreferencesBoolean;
  APPLICATIONS_DATA_SOURCE_LIST_ENABLED: AppPreferencesBoolean;
  SHOW_DS_TAGS: AppPreferencesBoolean;
  OBJECT_ISSUES_ENABLED: AppPreferencesBoolean;
  DSAR_SCANS_LIMIT: number;
  OKTA_MAX_USERS: number;
  MAX_UPLOAD_LIMITATION: number;
  DATA_SOURCE_CSV_IMPORT_VISIBLE: AppPreferencesBoolean;
  RULES_PAGE_ENABLED_FF: AppPreferencesBoolean;
  SHOW_NEW_AUDIT_PAGE: AppPreferencesBoolean;
  SHOW_NEW_SCANS_PAGE: AppPreferencesBoolean;
  SHOW_LEGACY_MASTER_DETAILS_VIEWS: AppPreferencesBoolean;
  FOLLOW_OBJECT_ENABLED: AppPreferencesBoolean;
  SENSITIVITY_CLASSIFICATION_ENABLED: AppPreferencesBoolean;
  DS_SENSITIVITY_CLASSIFICATION_ENABLED: AppPreferencesBoolean;
  SC_DC_SIZE_LIMIT_VALIDATION_ENABLED: AppPreferencesBoolean;
  SC_DC_SIZE_LIMIT_FOR_DAILY_TAGGING: number;
  SC_DC_SIZE_LIMIT_FOR_WEEKLY_TAGGING: number;
  SC_DC_SIZE_LIMIT_FOR_MONTHLY_TAGGING: number;
  ACI_LABELING_ENABLED: AppPreferencesBoolean;
  ACI_MIP_LABELING_ENABLED: AppPreferencesBoolean;
  ACI_GDRIVE_LABELING_ENABLED: AppPreferencesBoolean;
  SHOW_AC_CONFIGURATIONS: AppPreferencesBoolean;
  SHOW_NEW_SERVICES_CONFIGURATION_FF: AppPreferencesBoolean;
  SHOULD_CHANGE_SECRET_KEY: boolean;
  ENABLE_REPORTING_ETL: AppPreferencesBoolean;
  ENABLE_TENANT_SERVICE: AppPreferencesBoolean;
  FMSD_ENABLED: AppPreferencesBoolean;
  DISABLE_AUTO_UPGRADE_OF_CLASSIFIERS: AppPreferencesBoolean;
  DISABLE_WIDE_CLASSIFIERS: AppPreferencesBoolean;
  ENABLE_CLASSIFIER_TUNING_REJECT_FORCE_NOTE: AppPreferencesBoolean;
  CATALOG_OBJECT_MANAGE_FF: AppPreferencesBoolean;
  SCOPE_PERMISSIONS_ENABLE: AppPreferencesBoolean;
  IS_SMALLID_FOR_DEV: AppPreferencesBoolean;
  SCANNER_ALIVE_THRESHOLD: number;
  SHOW_EXECUTIVE_REPORT: AppPreferencesBoolean;
  ENABLE_HOTSPOTS_REPORT: AppPreferencesBoolean;
  HOTSPOTS_ENABLED: AppPreferencesBoolean;
  ENABLE_CLOUD_MERGER: AppPreferencesBoolean;
  ENABLE_CUSTOMER_IDP: AppPreferencesBoolean;
  SAML_LOGOUT_ENABLED: AppPreferencesBoolean;
  ENABLE_CREDENTIAL_CUSTOM_TYPES: AppPreferencesBoolean;
  USE_AUDIT_OBJECT_API: AppPreferencesBoolean;
  WRITE_AUDIT_LOGS_TO_MQ: AppPreferencesBoolean;
  CLASSIFIER_EXCLUDE_LIST_FF: AppPreferencesBoolean;
  CLASSIFIER_TESTER_ENABLED: AppPreferencesBoolean;
  SHOW_ONBOARDING_WIZARD: AppPreferencesBoolean;
  SHOULD_CHANGE_AUTH_SECRET_KEY: string;
  SHOW_AUTO_DISCOVERY_WIZARD: AppPreferencesBoolean;
  CLASSIFIER_TUNING_ENABLED: AppPreferencesBoolean;
  CLASSIFIER_MODEL_ENABLED: AppPreferencesBoolean;
  COLUMN_TAB_ADDITIONAL_METADATA: AppPreferencesBoolean;
  DATA_PREVIEW_DISABLED: AppPreferencesBoolean;
  AMPLITUDE_API_KEY: string;
  COMPANY_NAME: string;
  SAAS_ENV: string;
  ALWAYS_SEND_METADATA: AppPreferencesBoolean;
  INCLUDE_RAW_METADATA: AppPreferencesBoolean;
  USE_NORMALIZED_CLASSIFIERS_API: AppPreferencesBoolean;
  MULTI_TENANT_MODE_ENABLED: AppPreferencesBoolean;
  ENABLE_CATALOG_OBJECT_RISK: AppPreferencesBoolean;
  ENABLE_SCAN_TEMPLATES: AppPreferencesBoolean;
  FILE_PREVIEW_TIMEOUT_ENABLED: AppPreferencesBoolean;
  FILE_PREVIEW_TIMEOUT: number;
  DATA_OVERVIEW_ENABLED: AppPreferencesBoolean;
  DATA_OVERVIEW_CATEGORIES_WIDGET_DRILLDOWN_ENABLED: AppPreferencesBoolean;
  DATA_CATALOG_SAVED_QUERIES_AS_A_FILTER_ENABLED: AppPreferencesBoolean;
  ENABLE_SCANNED_FILES_REPORT: AppPreferencesBoolean;
  EVENT_BASED_SENSITIVITY_CLASSIFICATION: AppPreferencesBoolean;
  JSON_LOGS_CREATION: AppPreferencesBoolean;
  STORE_ORCH_REFRESH_UUID_TOKEN: AppPreferencesBoolean;
  ACTIONABLE_INSIGHTS_ENABLED: AppPreferencesBoolean;
  ACTIONABLE_INSIGHTS_BATCH_SELECTION_ENABLED: AppPreferencesBoolean;
  ENCRYPT_USER_PRIVATE_DATA_FF: AppPreferencesBoolean;
  FILTER_CREDENTIALS_IN_DS_WIZARD: AppPreferencesBoolean;
  ENABLE_DATA_SOURCE_BULK_UPDATES: AppPreferencesBoolean;
  ENABLE_LEGACY_STRUCTURED_PREVIEW: AppPreferencesBoolean;
  DEFAULT_LANDING_PAGE: AppPreferencesBoolean;
  RISK_ASSESSMENT_ENABLED: AppPreferencesBoolean;
  ENABLE_NEW_UX_NAVIGATION: AppPreferencesBoolean;
  USE_NATIVE_NOTIFICATION: AppPreferencesBoolean;
  CONTAINER_MISCONFIGURATION_ENABLED: AppPreferencesBoolean;
  DATA_EXPLORER_ENABLED: AppPreferencesBoolean;
  DATA_EXPLORER_SIDEPANEL_ENABLED: AppPreferencesBoolean;
  DATA_CATALOG_SIDE_PANEL_ENABLED: AppPreferencesBoolean;
  DATA_EXPLORER_CATALOG_ENTITY_COLUMN_VIEW_ENABLED: AppPreferencesBoolean;
  DATA_EXPLORER_NEW_OBJECT_PREVIEW_PAGE_ENABLED: AppPreferencesBoolean;
  CLASSIFICATION_NEW_FLOW_ENABLED: AppPreferencesBoolean;
  USE_DISPLAY_NAME_FOR_POLICY_FF: AppPreferencesBoolean;
  CONNECTIVITY_EXPERIENCE_ENABLED: AppPreferencesBoolean;
  CUSTOMER_EA_PROGRAM_ENABLED: AppPreferencesBoolean;
  NEW_CONNECTION_TAB_ENABLED: AppPreferencesBoolean;
  CUSTOMER_OPT_IN_EA_PROGRAM: AppPreferencesBoolean;
  NEGATIVE_SUPPORT_TERM_FF: AppPreferencesBoolean;
  SHOW_GETTING_STARTED_PAGE_FF: AppPreferencesBoolean;
  GETTING_STARTED_SECURITY_ENABLED: AppPreferencesBoolean;
  DYNAMIC_MODIFY_PARSING_THREADS_FF: AppPreferencesBoolean;
  DYNAMIC_MODIFY_SCAN_WINDOW: AppPreferencesBoolean;
  ENABLE_LANDING_PAGE_PER_USER_FF: AppPreferencesBoolean;
  AUTO_RETRY_SCAN_PARTS_FF: AppPreferencesBoolean;
  SHOW_NEW_SCAN_INSIGHT: AppPreferencesBoolean;
  ENABLE_UNIFIED_VAULTS: AppPreferencesBoolean;
  ONEDRIVE_V2_CONNECTOR_ENABLED: AppPreferencesBoolean;
  POSTGRESQL_V2_CONNECTOR_ENABLED: AppPreferencesBoolean;
  ENABLE_NEW_CERTIFICATES_MANAGEMENT_VIEW_FF: AppPreferencesBoolean;
  ENVIRONMENT_FF: string;
  SCAN_JOBS_IN_MEMORY_ENABLED: AppPreferencesBoolean;
  USE_SCAN_PAGE_STATE: AppPreferencesBoolean;
  DISABLE_LEGACY_ACL_FF: AppPreferencesBoolean;
  NEW_CONNECTORS_OAUTH_FF: AppPreferencesBoolean;
  OPEN_ACCESS_ENABLED: AppPreferencesBoolean;
  OAUTH_API_GATEWAY_URL_PROD: string;
  OAUTH_API_GATEWAY_URL_TEST: string;
  MT_CORRELATION_FF_ENABLED: string;
  ENABLE_NEW_ETL_LANDING_PAGE: AppPreferencesBoolean;
  MULTI_TENANT_CONFIG_ENABLED: string;
  SHOW_NEW_AUTO_DISCOVERY_UI_FF: AppPreferencesBoolean;
  ASSESSMENT_SCAN_ENABLED: AppPreferencesBoolean;
  STRUCTURED_CLASSIFICATION_SAMPLING_ENABLED: AppPreferencesBoolean;
  AUTO_DISCOVERY_COLLABORATION_EMAIL: AppPreferencesBoolean;
  BIGCHAT_FF_ENABLED: AppPreferencesBoolean;
  BIG_LLM_ENABLED_FF: AppPreferencesBoolean;
  SIMILAR_TABLES_ENABLED: AppPreferencesBoolean;
  NEW_GLOSSARY_PAGE_ENABLED: AppPreferencesBoolean;
  BUSINESS_TERMS_ENABLED: AppPreferencesBoolean;
  SENSITIVITY_CLASSIFICATION_V2_ENABLED: AppPreferencesBoolean;
  SHOW_GUIDED_TOUR_SCAN_TEMPLATES: AppPreferencesBoolean;
  DSPM_OBJECT_PREVIEW: AppPreferencesBoolean;
  DSPM_SERVICE_NOW: AppPreferencesBoolean;
  DSPM_POLICY_ACTIONS_ENABLED: AppPreferencesBoolean;
  SHOW_GUIDED_TOUR_CLASSIFICATION_STEP: AppPreferencesBoolean;
  SHOW_GUIDED_TOUR_SAVED_SCANS: AppPreferencesBoolean;
  SHOW_DIALOG_GUIDE_CONVERT_SCAN_PROFILE: AppPreferencesBoolean;
  SMB_V2_ACI_ENABLED: AppPreferencesBoolean;
  CATALOG_AUDIT_TRAIL_UI_ENABLED: AppPreferencesBoolean;
  DS_COLLABORATION_ENABLED: AppPreferencesBoolean;
  ONBOARDING_ASSISTANT_ENABLED: AppPreferencesBoolean;
  GDRIVE_LABEL_IMPORTER_V2_ENABLED: string;
  NEW_CREDENTIALS_FLOW_ENABLED: AppPreferencesBoolean;
  DATADOG_APPLICATION_ID: string;
  DATADOG_CLIENT_TOKEN: string;
  DATADOG_RUM_ENABLED: AppPreferencesBoolean;
  DATADOG_SESSION_SAMPLE_RATE: number;
  DATADOG_SESSION_REPLAY_SAMPLE_RATE: number;
  IS_SMTP_CONFIGURED: AppPreferencesBoolean;
  VENDORS_DIRECTORY_ENABLED_FF: AppPreferencesBoolean;
  DISCOVERY_RUN_AND_SCAN_ENABLED: AppPreferencesBoolean;
  TPA_GLOBAL_PRESETS_ENABLED: AppPreferencesBoolean;
  TPA_MULTIPLE_DEPLOYMENTS_ENABLED: AppPreferencesBoolean;
  NEW_CLASSIFIERS_ATTRIBUTES_PAGE_ENABLED: AppPreferencesBoolean;
  NEW_CORRELATION_SET_PAGE_ENABLED: AppPreferencesBoolean;
  CLASSIFIER_TUNING_LIGHT_PREVIEW_ENABLED: AppPreferencesBoolean;
  CLASSIFY_FILE_NAMES_ENABLED: AppPreferencesBoolean;
  DISABLE_SERVICES_LOGS: AppPreferencesBoolean;
  PER_OBJECT_SUMMARY_ENABLED: AppPreferencesBoolean;
  ACCESS_REMEDIATION_SMB_V2_ENABLED: string;
  DATA_MINIMIZATION_PROGRESS_COUNT_ENABLED: AppPreferencesBoolean;
  TABLE_OWNER_FILTER_ENABLED: AppPreferencesBoolean;
  TPA_ACTION_EXECUTION_FORCE_STOP: AppPreferencesBoolean;
  STOP_IN_PROGRESS_BUTTON: AppPreferencesBoolean;
  GRID_EXPERIMENTAL_PERFORMANCE_FEATURES_ENABLED: AppPreferencesBoolean;
  SHOW_NEW_ACCESS_MANAGEMENT: AppPreferencesBoolean;
  SCAN_WINDOW_SCHEDULER_ENABLED: AppPreferencesBoolean;
  DSPM_SUPPORT_CATALOG_FOLDERS: AppPreferencesBoolean;
  PRIVACY_PORTAL_INTEGRATION_ENABLED: AppPreferencesBoolean;
  WORKSPACES_ENABLED: AppPreferencesBoolean;
  WORKSPACES_LAST_VISITED_PAGE_ENABLED: AppPreferencesBoolean;
  NER_CLASSIFIER_ENABLED_FEATURE_FLAG: AppPreferencesBoolean;
  MT_SUPPORTED_TPA_NAMES: string;
  DATA_CATALOG_ELASTIC_ENABLED: AppPreferencesBoolean;
  SIDE_SCANNING_IN_ONBOARDING_UI_ENABLED: AppPreferencesBoolean;
  DEFAULT_DATA_SOURCE_ENC_FIELD_MAX_LENGTH: boolean;
  DEFAULT_DATA_SOURCE_ENC_FIELD_MAX_LENGTH_ENABLED: AppPreferencesBoolean;
  MAX_AMOUNT_OF_DS_PER_SCAN: number;
  PAGE_SIZE_ACTIVE_SCANS_FF: number;
  PAGE_SIZE_SUB_SCANS_FF: number;
  PAGE_SIZE_DATA_SORCE_IN_SCAN_TEMPLATE: number;
  IS_POLLING_ENABLE: boolean;
  ENABLE_LIMIT_AMOUNT_OF_DS_PER_SCAN: AppPreferencesBoolean;
  DSPM_REMEDIATION_STEPS_ENABLED: AppPreferencesBoolean;
  LEGAL_ENTITIES_ENABLED_FF: AppPreferencesBoolean;
  TRIGGER_VOLUME_AFTER_TEST_CONNECTION: AppPreferencesBoolean;
  ENABLE_CREDENTIALS_IN_TPA_FF: AppPreferencesBoolean;
  DATA_SOURCE_CONNECTION_ENRICHMENT_ENABLED: AppPreferencesBoolean;
  SHOW_DIALOG_GUIDE_CLASSIFIER_MANAGEMENT: AppPreferencesBoolean;
  EVENT_BASED_LABELING_ENABLED: AppPreferencesBoolean;
  REFRESH_DYNAMIC_OWNERSHIP_BY_SCHEDULE_FF: AppPreferencesBoolean;
  REFRESH_DYNAMIC_OWNERSHIP_BY_SCHEDULE_CRON: string;
  COMPLIANCE_FRAMEWORKS_ENABLED: AppPreferencesBoolean;
  REGULATIONS_ENABLED: AppPreferencesBoolean;
  NEW_SELECT_DS_VIEW_ENABLED_FF: AppPreferencesBoolean;
  FORCE_REENTER_SENSITIVE_WHEN_URL_UPDATE_FF: AppPreferencesBoolean;
  RISK_REGISTER_ENABLED_FF: AppPreferencesBoolean;
  ACTIONABLE_INSIGHTS_DIGEST_EMAIL_ENABLED: AppPreferencesBoolean;
  SHOW_PRIVACY_EXECUTIVE_DASHBOARD_ENABLED: AppPreferencesBoolean;
  ACTIONABLE_INSIGHTS_DIGEST_EMAIL_SENT: AppPreferencesBoolean;
  DISCOVERY_DASHBOARD_ENABLED: AppPreferencesBoolean;
  ALLOW_CONSOLIDATE_BUCKETS: AppPreferencesBoolean;
  SHOW_METADATA_SEARCH_INDEXING_BUTTON: AppPreferencesBoolean;
  OBJECTS_DELETION_ENABLED: AppPreferencesBoolean;
  GOOGLE_CLOUD_SQL_CONNECTOR_ENABLED: AppPreferencesBoolean;
  SENSITIVITY_CLASSIFICATION_NEW_DESIGN_FF: AppPreferencesBoolean;
  SNOWFLAKE_ADAPTER_ENABLED: AppPreferencesBoolean;
  DP_AZURE_BLOB_ADAPTER_ENABLED: AppPreferencesBoolean;
  DP_BIG_QUERY_ADAPTER_ENABLED: AppPreferencesBoolean;
  DSPM_DASHBOARDS_ENABLED: AppPreferencesBoolean;
  SHOW_EMAIL_APP: AppPreferencesBoolean;
  INFORMIX_V2_CONNECTOR_ENABLED: AppPreferencesBoolean;
  ACI_OBJECTS_QUERY_LIMIT: number;
  CLASSIFIERS_EXPORT_INCLUDE_CLASSIFICATION_SUPPORTED_TERMS: AppPreferencesBoolean;
  CLASSIFIERS_EXPORT_INCLUDE_EXCLUDED_LIST_CLASSIFIERS: AppPreferencesBoolean;
  DSTYPES_DYNAMIC_COST_DIALOG_ENABLED: AppPreferencesBoolean;
}

export async function initApplicationPreferences() {
  const {
    data: { preferences },
  } = await httpService.fetch<{ preferences: AppPreferences }>('preferences');
  const normalizedPreferences = Object.entries(preferences).reduce((res, [preferenceName, preferenceValue]) => {
    res.push({
      name: preferenceName,
      value: getNormalizedPreferenceValue(preferenceName, preferenceValue),
    });

    return res;
  }, [] as SessionStorageItem[]);
  sessionStorageService.setArray(normalizedPreferences);
  await loadIsSmtpConfigured();

  systemEventsEmitter.emit(SystemEvents.APPLICATION_PREFERENCES_UPDATED);
}

export function getApplicationPreference<T = any>(preferenceName: keyof AppPreferences): T {
  return sessionStorageService.get<T>(preferenceName);
}

function getValidTimezone(timeZone: string): string {
  if (!timeZone) {
    return DEFAULT_TIMEZONE;
  }

  try {
    Intl.DateTimeFormat(undefined, { timeZone });
    return timeZone;
  } catch (err) {
    const userAgentTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
    notificationService.warning(
      `The configured timezone, '${timeZone}', is invalid. The timezone will automatically be set to: '${userAgentTZ}'.
        You can manually reconfigure the timezone.`,
    );
    return userAgentTZ;
  }
}

function getNormalizedPreferenceValue(preferenceKeyName: string, preferenceValue: any = ''): any {
  let normalizedValue = preferenceValue;

  if (['true', 'false'].includes(preferenceValue.toLowerCase?.().trim())) {
    normalizedValue = preferenceValue.toLowerCase() === 'true';
  } else if (preferenceKeyName === 'TIME_ZONE') {
    normalizedValue = getValidTimezone(preferenceValue);
  }

  return normalizedValue;
}

export const loadIsSmtpConfigured = async () => {
  try {
    const { data } = await emailSetupService.getMailerData();
    sessionStorageService.set('IS_SMTP_CONFIGURED', !!data?.enabled);
  } catch {
    sessionStorageService.set('IS_SMTP_CONFIGURED', false);
  }
};
