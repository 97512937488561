import { BigidAreaChart } from '@bigid-ui/visualisation';
import { BigidColorsV2 } from '@bigid-ui/components';
import React, { FC } from 'react';
import { DataRiskDataPoint } from '../../types';
import { useLocalTranslation } from '../../translations';
import { DataRiskToggleOption } from '../DataRiskWidget';

export const DataRiskAreaChart: FC<{ areaChart: DataRiskDataPoint[]; viewByOption: DataRiskToggleOption }> = ({
  areaChart = [],
  viewByOption,
}) => {
  const { t } = useLocalTranslation('dataRiskWidget');
  return (
    <BigidAreaChart
      colorDataMap={{ scanned: BigidColorsV2.purple[600], discovered: BigidColorsV2.magenta[300] }}
      isLineSmoothed={true}
      numberFormat="#.A"
      data={areaChart}
      seriesLabelFormatter={series => t(viewByOption + series)}
      showChartCursor={true}
    />
  );
};
