import React, { FC, memo } from 'react';
import {
  compareObjectsExceptFunctions,
  BigidFieldRenderProps,
  BigidTags,
  BigidLoader,
  BigidFormFieldLabelWrapper,
  BigidBody1,
} from '@bigid-ui/components';
import { validateTagNameWithMessage, validateTagValueWithMessage } from '../../../TagsManagement/TagsManagementUtils';
import { useTagsFieldData } from '../hooks/useTagsFieldData';
import styled from '@emotion/styled';

const DEFAULT_ADD_PLACEHOLDER = 'Click the + to add tag';

export const EmptyDisabledTags = styled(BigidBody1)`
  position: absolute;
  left: 28px;
  top: 44px;
  color: ${({ theme }) => `${theme.vars.palette.bigid.gray400}`};
`;

export const FormTagsField: FC<BigidFieldRenderProps> = memo(
  ({
    setValue,
    name,
    label = '',
    value,
    misc,
    isRequired,
    labelWidth,
    tooltipText,
    disabled,
    isFieldsBySelection,
    setDisabled,
  }) => {
    const {
      tags,
      isLoading,
      handleTagCreate,
      handleTagAttach,
      handleTagDetach,
      isReadTagsPermitted,
      systemTagsDictionary,
      isCreateTagsPermitted,
    } = useTagsFieldData({ value, setValue });
    const showEmptyPlaceholder = isFieldsBySelection && disabled && isReadTagsPermitted && !value?.length;

    return isLoading ? (
      <BigidLoader />
    ) : (
      <BigidFormFieldLabelWrapper
        id={`bigid-form-field-${name}`}
        name={name}
        label={String(label)}
        isSeparatorAfter={misc?.isSeparatorAfter}
        labelWidth={labelWidth}
        tooltipText={tooltipText}
        isRequired={isRequired}
        isFieldsBySelection={isFieldsBySelection}
        setDisabled={setDisabled}
        disabled={disabled}
      >
        {showEmptyPlaceholder && <EmptyDisabledTags>Tags not selected</EmptyDisabledTags>}
        <BigidTags
          tags={tags}
          tagWizardMenuPosition="absolute"
          tagsDictionary={systemTagsDictionary}
          onCreate={handleTagCreate}
          onAdd={handleTagAttach}
          onDelete={handleTagDetach}
          isAbleToCreate={isCreateTagsPermitted}
          isAbleToEdit={!disabled && isReadTagsPermitted}
          isAbleToDelete={!disabled}
          placeholder={misc?.placeholder ?? DEFAULT_ADD_PLACEHOLDER}
          useMinHeight
          validateName={validateTagNameWithMessage}
          validateValue={validateTagValueWithMessage}
        />
      </BigidFormFieldLabelWrapper>
    );
  },
  compareObjectsExceptFunctions,
);

FormTagsField.displayName = 'FormTagsField';
