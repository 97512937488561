import { BigidColorsV2 } from '@bigid-ui/colors';
import { BigidWidgetListItemProps } from '@bigid-ui/components';
import { BigidDonutChartSliceData } from '@bigid-ui/visualisation';

import { getFixedT } from './translations';
const tWidgets = getFixedT('widgets');

export const PRIVACY_EXECUTIVE_DASHBOARD = 'PrivacyExecutiveDashboard';

export interface WidgetProps {
  title: string;
}

export enum FilterToolbarFields {
  TIME_RANGE = 'timeRange',
  VENDORS = 'vendors',
  LEGAL_ENTITIES = 'legalEntities',
  ASSETS = 'asset',
}

export enum PrivacyExecutiveDashboardWidgets {
  RISK_DATA_RISK = 'dataRisk',
  RISK_DATA_RISK_TREND = 'dataRiskTrend',
  RISK_DATA_RISK_TYPES = 'dataRiskTypes',
  RISK_DATA_RISK_LANDSCAPE = 'dataRiskLandscape',
  PERFORMANCE_MEASURES = 'performanceMeasures',
  PERFORMANCE_OPERATIONAL_SUCCESS = 'performanceOperationalSuccess',
  DELETION = 'dataDeletion',
  PRIVACY_MAP = 'privacyMap',
}

export enum WidgetSubTypes {
  DATA_RISK = 'DATA_RISK',
  DATA_RISK_CATEGORY = 'DATA_RISK_CATEGORY',
  DATA_RISK_LANDSCAPE = 'DATA_RISK_LANDSCAPE',
  DATA_MAPPING = 'DATA_MAPPING',
  PRIVACY_MAP_RISKS = 'PRIVACY_MAP_RISKS',
}

export const WidgetAggregationNames: Record<string, string> = {
  [WidgetSubTypes.DATA_RISK]: 'dataRisk',
  [WidgetSubTypes.DATA_RISK_CATEGORY]: 'group_by_data_risk_category',
  [WidgetSubTypes.DATA_RISK_LANDSCAPE]: 'data_risk_landscape',
  [WidgetSubTypes.PRIVACY_MAP_RISKS]: 'privacy_map_risks',
};

export interface Series {
  series: {
    name: string;
    interpolationDuration: number;
    interpolationEasing: string;
    animate: ([], interpolationDuration: number, interpolationEasing: string) => void;
    hide: () => void;
    show: () => void;
  }[];
}

export const AllSeverities = 'All severities';

export enum PerformanceCategories {
  ON_TIME = 'On time',
  ALMOST_OVERDUE = 'Almost overdue',
  OVERDUE = 'Overdue',
}

export const DeletionCategories = {
  DELETED: tWidgets('dataDeletion.categoryDeleted'),
  PENDING_DELETION: tWidgets('dataDeletion.categoryPendingDeletion'),
  DELETION_PROGRESS: tWidgets('dataDeletion.categoryDeletionProgress'),
};

export const PerformanceColors = {
  [PerformanceCategories.ON_TIME]: BigidColorsV2.green[300],
  [PerformanceCategories.ALMOST_OVERDUE]: BigidColorsV2.orange[300],
  [PerformanceCategories.OVERDUE]: BigidColorsV2.red[300],
} as const;

export const DeletionColors = {
  [DeletionCategories.PENDING_DELETION]: BigidColorsV2.gray[300],
  [DeletionCategories.DELETED]: BigidColorsV2.purple[300],
  [DeletionCategories.DELETION_PROGRESS]: BigidColorsV2.blue[300],
} as const;

export interface DonutChartData extends BigidDonutChartSliceData {
  percentageValue?: number;
}

export interface InnerObjectData {
  category: string;
  value: number;
  percentageValue: number;
}

export interface PerformanceOperationalSuccessWidgetApiData {
  type: string;
  donut: InnerObjectData[];
  chart: { category: string; data: { type: string; data: InnerObjectData[] }[] }[];
}

export interface PerformanceOperationalSuccessWidgetData
  extends Omit<PerformanceOperationalSuccessWidgetApiData, 'donut' | 'chart'> {
  donut: DonutChartData[];
  chart: Record<string, { category: string }[]>;
}

export interface DeletionWidgetData extends Omit<PerformanceOperationalSuccessWidgetApiData, 'donut' | 'chart'> {
  list: {
    total: string;
    data: BigidWidgetListItemProps[];
  };
  chart: { category: string }[];
}

export interface PrivacyMapWidgetData {
  [key: string]: {
    id: string;
    latitude: number;
    longitude: number;
    name: string;
    value: number;
    category: string;
  }[];
}

export interface PrivacyMapWidgetApiData {
  category: string;
  data: {
    category: string;
    location: string;
    count: number;
  }[];
}
[];

export enum PrivacyMapCategories {
  DATA_MAPPING = 'Data mapping',
  RESIDENCIES = 'Residencies',
  RISKS = 'Risks',
  REQUESTS = 'Requests',
}

export enum Categories {
  ALL = 'All data',
  REQUESTS = 'Requests',
  ASSESSMENTS = 'Assessments',
  PROCESSES = 'Processes',
}

export interface SeverityLevel {
  severity: string;
  values: number[];
  color: string;
}

export type SeverityColorMapper = Record<string, string>;
