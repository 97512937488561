import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  BigidBody1,
  BigidDialog,
  BigidDropdown,
  BigidDropdownOption,
  BigidForm,
  BigidFormField,
  BigidFormFieldLabelPosition,
  BigidFormFieldTypes,
  BigidFormRenderProps,
  BigidFormValues,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { styled, useTheme } from '@mui/material';
import { BigidAddIcon, BigidSendIcon } from '@bigid-ui/icons';
import { getEmailTemplates, getSystemUsers } from './SetEmailActionDialogService';
import { SystemUser } from '../../../../../../AccessManagement/types';
import {
  EmailTemplateData,
  openEmailEditor,
  sendTestEmail,
} from '../../../../../../../components/EmailEditor/emailEditorService';
import { getBrands } from '../../../../../../EmailTemplates/Brands/brandsService';
import { BrandItemResponse } from '../../../../../../EmailTemplates/Brands/brandsTypes';
import { EmailEditor } from '../../../../../../../components/EmailEditor/EmailEditor';
import isEmail from 'validator/lib/isEmail';
import { notificationService } from '../../../../../../../services/notificationService';
import { ACActionType, Action, EmailActionConfiguration } from '../../../../actionWorkflowTypes';
import { useLocalTranslation } from '../../../../../translations';
import { convertUserNameToString } from '../../../../../../../utilities/textualConverter';

const TopSectionWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const SendEmailTitle = styled(BigidBody1)({
  fontWeight: 700,
});

const TestEmailDescription = styled(BigidBody1)(({ theme }) => ({
  color: theme.vars.tokens.bigid.foregroundTertiary,
  marginTop: '12px',
}));

const TestEmailWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '16px',
  borderRadius: '8px',
  marginTop: '16px',
  backgroundColor: theme.vars.palette.bigid.gray125,
}));

const DropdownAndSendButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '8px',
});

const DropdownContainer = styled('div')({
  width: '444px',
});

export interface SetEmailActionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  saveActionConfiguration: (actionToSave: Action) => void;
  initialValue: EmailActionConfiguration;
}

export const SetEmailActionDialog: React.FC<SetEmailActionDialogProps> = ({
  isOpen,
  onClose,
  saveActionConfiguration,
  initialValue,
}) => {
  const [dialogKey, setDialogKey] = useState(uuid());
  const [isReady, setIsReady] = useState(false);
  const [systemUsers, setSystemUsers] = useState<SystemUser[]>([]);
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplateData[]>([]);
  const [brands, setBrands] = useState<BrandItemResponse[]>([]);
  const [testEmailValue, setTestEmailValue] = useState<BigidDropdownOption[]>([]);
  const [createdEmailTemplate, setCreatedEmailTemplate] = useState<{ name: string; id: string }>();
  const [isTestEmailSendButtonDisabled, setIsTestEmailSendButtonDisabled] = useState(true);
  const [missingTemplateOrBrandErrorMessage, setMissingTemplateOrBrandErrorMessage] = useState(false);
  const renderFormRef = useRef<Partial<BigidFormRenderProps>>();

  const { t } = useLocalTranslation('SetEmailActionDialog');
  const theme = useTheme();
  const FULFILLED = 'fulfilled';

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const onEmailEditorOnClose = (args?: { [key: string]: any }) => {
    if (args?.id) {
      setCreatedEmailTemplate({ id: args.id, name: args.name });
      setDialogKey(uuid());
    }
  };

  const onEmailEditorOpen = () => {
    openEmailEditor({ onClose: onEmailEditorOnClose });
  };

  const fetchData = useCallback(async () => {
    setIsReady(false);
    const [usersResponse, emailTemplatesResponse, brandsResponse] = await Promise.allSettled([
      getSystemUsers(),
      getEmailTemplates(),
      getBrands(),
    ]);
    usersResponse.status === FULFILLED && setSystemUsers(usersResponse.value);
    emailTemplatesResponse.status === FULFILLED && setEmailTemplates(emailTemplatesResponse.value);
    brandsResponse.status === FULFILLED && setBrands(brandsResponse.value);
    setIsReady(true);
  }, []);

  useEffect(() => {
    fetchData();
  }, [dialogKey, fetchData]);

  const isDropdownFilled = (value: BigidDropdownOption[], field: string): string | boolean => {
    const mandatoryFieldErrors: { [key: string]: string } = {
      emailTemplate: t('Errors.emailTemplateMandatoryField'),
      brand: t('Errors.brandMandatoryField'),
      recipients: t('Errors.recipientsMandatoryField'),
    };
    if (!value?.length) {
      return mandatoryFieldErrors[field];
    }
    return false;
  };

  const fetchUsersAsync = async (value: string) => {
    const users = await getSystemUsers(value);
    return users.map((user: SystemUser) => {
      return {
        id: user.name,
        displayValue: convertUserNameToString(user.name, user.firstName, user.lastName),
        value: user.name,
      };
    });
  };

  const formFields: BigidFormField[] = [
    {
      label: t('Fields.emailTemplate'),
      name: 'emailTemplate',
      type: BigidFormFieldTypes.DROP_DOWN,
      labelPosition: BigidFormFieldLabelPosition.top,
      isRequired: true,
      validate: value => isDropdownFilled(value, 'emailTemplate'),
      fieldProps: {
        placeholder: t('PlaceHolders.emailTemplate'),
        dataAid: 'email-template-dropdown',
      },
      dropDownOptions:
        emailTemplates?.map(template => {
          return {
            id: template.name,
            displayValue: template.name,
            value: template.name,
          };
        }) ?? [],
    },
    {
      label: t('Fields.brand'),
      name: 'brand',
      type: BigidFormFieldTypes.DROP_DOWN,
      labelPosition: BigidFormFieldLabelPosition.top,
      isRequired: true,
      validate: value => isDropdownFilled(value, 'brand'),
      fieldProps: {
        placeholder: t('PlaceHolders.brand'),
        dataAid: 'brand-dropdown',
      },
      dropDownOptions:
        brands?.map(brand => {
          return {
            id: brand._id,
            displayValue: brand.name,
            value: brand._id,
          };
        }) ?? [],
    },
    {
      label: t('Fields.recipients'),
      name: 'recipients',
      type: BigidFormFieldTypes.DROP_DOWN,
      labelPosition: BigidFormFieldLabelPosition.top,
      isRequired: true,
      validate: value => isDropdownFilled(value, 'recipients'),
      fieldProps: {
        placeholder: t('PlaceHolders.recipients'),
        isSearchable: true,
        isMulti: true,
        isCreatable: true,
        applyOnChange: true,
        displayLimit: 100,
        menuPosition: 'fixed',
        dataAid: 'recipients-dropdown',
        isAsync: true,
        fetchOptions: fetchUsersAsync,
        getIsNewOptionValid: (value: string) => {
          return { isValid: isEmail(value), message: t('Errors.invalidEmailAddress') };
        },
        onCreate: (value: string) => {
          return Promise.resolve({ id: value, displayValue: value, value });
        },
      },
      dropDownOptions:
        systemUsers?.map(user => {
          return {
            id: user.name,
            displayValue: convertUserNameToString(user.name, user.firstName, user.lastName),
            value: user.name,
          };
        }) ?? [],
    },
  ];

  const getInitialValues = useMemo(() => {
    const brandValue = renderFormRef?.current?.getValues()?.brand;
    const recipientsValue = renderFormRef?.current?.getValues()?.recipients;
    return {
      emailTemplate: createdEmailTemplate
        ? [
            {
              id: createdEmailTemplate.name,
              value: createdEmailTemplate.name,
              displayValue: createdEmailTemplate.name,
            },
          ]
        : initialValue?.templateName
        ? [emailTemplates?.find(template => template.name === initialValue?.templateName)].map(template => ({
            id: template?.name,
            value: template?.name,
            displayValue: template?.name,
          }))
        : undefined,
      brand: brandValue?.length
        ? brandValue
        : initialValue?.brandId
        ? [brands?.find(brand => brand?._id === initialValue?.brandId)].map(brand => ({
            id: brand?._id,
            value: brand?._id,
            displayValue: brand?.name,
          }))
        : undefined,
      recipients: recipientsValue?.length
        ? recipientsValue
        : initialValue?.recipientEmail?.map(recipient => {
            console.log(`all recipients ${initialValue?.recipientEmail}`);
            console.log(`current recipient ${recipient}`);
            const user = systemUsers?.find(user => user.name === recipient);
            return {
              id: user?.name || recipient,
              value: user?.name || recipient,
              displayValue: user ? convertUserNameToString(user.name, user.firstName, user.lastName) : recipient,
            };
          }),
    };
  }, [
    brands,
    createdEmailTemplate,
    emailTemplates,
    initialValue?.brandId,
    initialValue?.templateName,
    initialValue?.recipientEmail,
    systemUsers,
  ]);

  const onFormChange = async ({ emailTemplate, brand }: BigidFormValues) => {
    if (emailTemplate && brand && missingTemplateOrBrandErrorMessage) {
      setMissingTemplateOrBrandErrorMessage(false);
    }
  };

  const onSendTestEmailButtonClick = async () => {
    try {
      const { emailTemplate, brand } = renderFormRef.current.getValues();
      if (!emailTemplate || !brand) {
        setMissingTemplateOrBrandErrorMessage(true);
        return;
      } else if (missingTemplateOrBrandErrorMessage) {
        setMissingTemplateOrBrandErrorMessage(false);
      }
      const emailTemplateToSend = emailTemplates.find(template => template.name === emailTemplate?.[0].value);
      await sendTestEmail({
        emailContent: {
          subject: emailTemplateToSend?.content?.subject,
          header: emailTemplateToSend?.content?.header,
          body: emailTemplateToSend?.content?.body,
        },
        brandName: brand?.[0].displayValue,
        recipientEmail: testEmailValue[0].value,
      });
      notificationService.success(t('Notifications.testEmailSuccess', { testEmail: testEmailValue[0].value }));
      /* eslint-disable @typescript-eslint/no-explicit-any */
    } catch (err: any) {
      console.error(err);
      notificationService.error(t('Errors.sendTestEmailError'));
    }
  };

  const onSaveButtonClick = async () => {
    const validateAndSubmit = renderFormRef?.current?.validateAndSubmit;
    if (validateAndSubmit) {
      validateAndSubmit(async (values: BigidFormValues) => {
        saveActionConfiguration({
          type: ACActionType.SEND_EMAIL,
          configuration: {
            ...initialValue,
            templateName: values.emailTemplate[0].value,
            brandId: values.brand[0].value,
            recipientEmail: values.recipients.map((recipient: BigidDropdownOption) => recipient.value),
          },
        });
        onClose();
      });
    }
  };

  return (
    <>
      {isReady && (
        <BigidDialog
          key={dialogKey}
          title={t('Common.dialogTitle')}
          isOpen={isOpen}
          onClose={onClose}
          buttons={[
            {
              text: t('Common.cancel'),
              component: TertiaryButton,
              onClick: onClose,
              isClose: true,
              dataAid: 'set-email-action-dialog-cancel-button',
            },
            {
              text: t('Common.save'),
              component: PrimaryButton,
              onClick: onSaveButtonClick,
              dataAid: 'set-email-action-dialog-save-button',
            },
          ]}
        >
          <>
            <BigidForm
              fields={formFields}
              stateAndHandlersRef={renderFormRef}
              controlButtons={false}
              onChange={onFormChange}
              initialValues={getInitialValues}
              renderForm={({ renderField }) => {
                return (
                  <>
                    <TopSectionWrapper>
                      <SendEmailTitle variantMapping={{ body1: 'strong' }} size="large">
                        {t('Common.sendEmail')}
                      </SendEmailTitle>
                      <TertiaryButton
                        startIcon={<BigidAddIcon />}
                        size={'medium'}
                        dataAid="create-new-template-button"
                        onClick={onEmailEditorOpen}
                      >
                        {t('Common.createNewTemplate')}
                      </TertiaryButton>
                    </TopSectionWrapper>
                    {renderField('emailTemplate')}
                    {renderField('brand')}
                    {renderField('recipients')}
                    <TestEmailDescription>{t('Common.testEmailDescription')}</TestEmailDescription>
                    <TestEmailWrapper>
                      <SendEmailTitle variantMapping={{ body1: 'strong' }} size="large">
                        {t('Common.sendTestEmail')}
                      </SendEmailTitle>
                      <DropdownAndSendButtonContainer>
                        <DropdownContainer>
                          <BigidDropdown
                            size="medium"
                            fetchOptions={fetchUsersAsync}
                            options={
                              systemUsers?.map(user => {
                                return {
                                  id: user.name,
                                  displayValue: convertUserNameToString(user.name, user.firstName, user.lastName),
                                  value: user.name,
                                };
                              }) ?? []
                            }
                            isAsync
                            displayLimit={100}
                            isCreatable
                            isSearchable
                            isErasable
                            placeholder={t('PlaceHolders.recipients')}
                            dataAid="test-email-dropdown"
                            value={testEmailValue}
                            errorMessage={missingTemplateOrBrandErrorMessage ? t('Errors.templateOrBrandMissing') : ''}
                            getIsNewOptionValid={(value: string) => {
                              return Promise.resolve({
                                isValid: isEmail(value),
                                message: t('Errors.invalidEmailAddress'),
                              });
                            }}
                            onCreate={(value: string) => {
                              return Promise.resolve({ id: value, displayValue: value, value });
                            }}
                            onSelect={options => {
                              setTestEmailValue(options);
                              setIsTestEmailSendButtonDisabled(!options.length);
                            }}
                          />
                        </DropdownContainer>
                        <SecondaryButton
                          text={t('Common.send')}
                          size="medium"
                          startIcon={<BigidSendIcon color={theme.vars.tokens.bigid.borderCta} />}
                          disabled={isTestEmailSendButtonDisabled}
                          onClick={onSendTestEmailButtonClick}
                          dataAid="send-test-email-button"
                        />
                      </DropdownAndSendButtonContainer>
                    </TestEmailWrapper>
                  </>
                );
              }}
            />
          </>
        </BigidDialog>
      )}
      <EmailEditor />
    </>
  );
};
