import React, { FC, useCallback, useContext, useState } from 'react';
import styled from '@emotion/styled';
import { BigidClearFilledIcon } from '@bigid-ui/icons';
import { noop } from 'lodash';
import { BigidBody1, BigidColorsV2, BigidSubtitle, SecondaryButton } from '@bigid-ui/components';
import { AutoDiscoveryWizardContext } from '../../autoDiscoveryWizardContext';
import {
  CloudProvider,
  COMMON_FOR_ALL_TYPES_ERRORS,
  DiscoveryAuthTypes,
  DiscoveryErrors,
  ERRORS_HELP_MAPPING,
} from '../../../constants';
import { getDiscoverAuthTypeString } from '../../autoDiscoveryWizardFormUtils';
import { AutoDiscoverWizardAccordionItem } from '../AutoDiscoverWizardAccordionItem';
import { getApplicationPreference } from '../../../../../services/appPreferencesService';
import { $state } from '../../../../../services/angularServices';
import { CONFIG } from '../../../../../../config/common';
import { useLocalTranslation } from '../../../translations';
import { AccordionInfoPanel } from '../AccordionInfoPanel/AccordionInfoPanel';
import { AccordionInfoPanelVariants } from '../AccordionInfoPanel/styles';
import { AutoDiscoveryCollaboration } from '../../../AutoDiscoveryCollaboration/AutoDiscoveryCollaboration';

export const AutoDiscoverWizardAsideErrorsContact = styled('div')`
  display: inline-flex;
  align-items: center;
  gap: 16px;
  padding-top: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

export const AutoDiscoverWizardAsideErrorsContactLabel = styled(BigidBody1)`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

const getErrorData = (type: CloudProvider, authType: DiscoveryAuthTypes, errorCode?: DiscoveryErrors) => {
  if (!errorCode) {
    return null;
  }
  return ERRORS_HELP_MAPPING[type]?.[authType]?.[errorCode] || COMMON_FOR_ALL_TYPES_ERRORS[errorCode];
};

export interface ContactSupportButtonProps {
  onClick: () => void;
}
export const ContactSupportButton: FC<ContactSupportButtonProps> = ({ onClick }) => {
  const { t } = useLocalTranslation('AutoDiscovery');
  return <SecondaryButton size={'medium'} onClick={onClick} text={t('configModal.buttons.contactSupport')} />;
};

export const AutoDiscoverWizardAsideErrors: FC = () => {
  const { discoveryConfigData, discoveryConfigDataRef } = useContext(AutoDiscoveryWizardContext);
  const isError = !!discoveryConfigData?.errorMessageKey;
  const authTypeValue = getDiscoverAuthTypeString(discoveryConfigData?.formData?.authType);
  const data = getErrorData(discoveryConfigData?.type, authTypeValue, discoveryConfigData?.errorMessageKey);
  const { t } = useLocalTranslation('AutoDiscovery');
  const [expandedSubItems, setExpandedSubItems] = useState<Record<string, boolean>>(
    data?.options?.reduce((acc, { title, content }) => ({ ...acc, [title]: !!content }), {}) ?? {},
  );
  const isSaas = getApplicationPreference('USE_SAAS');
  const onChangeOptionExpanded = useCallback(
    (title: string) =>
      setExpandedSubItems(current => ({
        ...current,
        [title]: current?.[title] === undefined ? false : !current?.[title],
      })),
    [],
  );
  return (
    isError &&
    discoveryConfigData?.formData?.authType && (
      <AccordionInfoPanel
        dataAid={'AutoDiscoverWizardAsideErrors'}
        variant={AccordionInfoPanelVariants.error}
        title={data?.title}
        onChange={noop}
        expanded={isError}
        titleIcon={BigidClearFilledIcon}
        titleIconColor={BigidColorsV2.red[600]}
        endLinkTitle={!isSaas && t('configModal.buttons.viewLogs')}
        endLinkOnClick={() =>
          $state.go(
            CONFIG.states.CUSTOM_APP_ACTIVITY_LOG,
            { id: discoveryConfigData.configData.appId },
            { reload: true },
          )
        }
      >
        <>
          <BigidSubtitle>{t('configModal.aside.errorsSubtitle')}</BigidSubtitle>
          {data?.options.map(({ title, content }, index) => (
            <AutoDiscoverWizardAccordionItem
              key={title}
              title={`Option ${index + 1}`}
              subTitle={title}
              isExpanded={content && expandedSubItems[title]}
              onChange={() => onChangeOptionExpanded(title)}
              showExpandIcon={!!content}
            >
              {content}
            </AutoDiscoverWizardAccordionItem>
          ))}
          <AutoDiscoverWizardAsideErrorsContact>
            <AutoDiscoverWizardAsideErrorsContactLabel>
              {t('configModal.aside.errorsAssistance')}
            </AutoDiscoverWizardAsideErrorsContactLabel>
            <AutoDiscoveryCollaboration
              message={t('collaborateModal.errorHelp', {
                type: discoveryConfigData?.type,
                authType: authTypeValue,
                error: discoveryConfigData.errorMessage,
              })}
              discoveryConfigDataRef={discoveryConfigDataRef}
              button={ContactSupportButton}
            />
          </AutoDiscoverWizardAsideErrorsContact>
        </>
      </AccordionInfoPanel>
    )
  );
};
