import React, { useCallback, useState } from 'react';
import { useCorrelationSetWizardContext } from '../../../contexts/correlationSetContext';
import {
  BigidForm,
  BigidFormField,
  BigidFormRenderProps,
  BigidFormValidateOnTypes,
  BigidFormValues,
} from '@bigid-ui/components';
import { useTrainingStepFormConfig } from './useTrainingStepFormConfig';
import { getIsValueChanged, isIdCompositeEnabled } from '../../../utils/utils';
import { DataSourceTypes, DefaultStepProps, WizardFormFields } from '../../../types/CorrelationSetsTypes';
import { AdvancedOptions } from './AdvancedOptions';
import styled from '@emotion/styled';
import { AttributesGrid } from '../../../components/AttributesGrid/AttributesGrid';
import { Loader } from '../../../components/Loader';
//STEP 3

const Container = styled('div')`
  max-width: 617px;

  [data-aid='BigidFormFieldLabelWrapper-bigid-form-field-scanConnectionTimeoutInSeconds'] > div:first-of-type {
    max-width: 617px;
  }
`;

const GridHolder = styled.div`
  margin-bottom: 16px;
`;

interface RenderFormProps extends BigidFormRenderProps {
  fields: BigidFormField[];
}

const RenderForm = ({ renderField, getValues, errors }: RenderFormProps) => {
  const { isAddCompositeIdentifiers, dsConnection } = getValues();
  const selectedDsType: DataSourceTypes = dsConnection?.[0]?.type;

  return (
    <>
      {isIdCompositeEnabled(selectedDsType) && (
        <>
          {renderField(WizardFormFields.isAddCompositeIdentifiers)}
          {isAddCompositeIdentifiers && renderField(WizardFormFields.composite_attributes)}
        </>
      )}
      {renderField(WizardFormFields.unique_id)}
      <AdvancedOptions renderField={renderField} />
    </>
  );
};

export const TrainingStep = ({ detailsStepFormControls, activeStepId }: DefaultStepProps) => {
  const { correlationSetFormData, isLoading } = useCorrelationSetWizardContext();
  const [formState, setFormState] = useState<BigidFormValues>(correlationSetFormData);

  const { fields } = useTrainingStepFormConfig({ formState });

  const handleFormChange = useCallback(
    async (values: BigidFormValues) => {
      const isFormChanged = getIsValueChanged(values, formState);

      if (isFormChanged) {
        setFormState(values as BigidFormValues);
      }
    },
    [formState],
  );

  const memoizedRenderForm = useCallback(
    (props: BigidFormRenderProps) => <RenderForm {...props} fields={fields} />,
    [],
  );

  return (
    <>
      {isLoading && <Loader />}
      <GridHolder>
        <AttributesGrid
          activeStepId={activeStepId}
          detailsStepFormControls={detailsStepFormControls}
          isEditableGridColumns={false}
        />
      </GridHolder>

      <Container>
        <BigidForm
          key={correlationSetFormData.name}
          stateAndHandlersRef={detailsStepFormControls}
          onChange={handleFormChange}
          controlButtons={false}
          fields={fields}
          initialValues={correlationSetFormData}
          validateOn={BigidFormValidateOnTypes.SUBMIT}
          renderForm={memoizedRenderForm}
        />
      </Container>
    </>
  );
};
