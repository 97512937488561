import React, { useMemo } from 'react';

import { CONFIG } from '../../../../../config/common';
import { $state } from '../../../../services/angularServices';

import { usePrivacyExecutiveDashboardContext } from '../../PrivacyExecutiveDashboardContext';
import { Menu } from '../helpers/HelperComponents';
import { generateMenuItems, getRiskFilterParam } from '../helpers/generateMenuItems';

export function useWidgetMenu() {
  const { activeFilters } = usePrivacyExecutiveDashboardContext();

  const menuItems = useMemo(() => {
    const onClickHandlers = {
      go: () => {
        const filterString = getRiskFilterParam(activeFilters);
        $state.go(CONFIG.states.RISK_REGISTER, { filter: filterString });
      },
    };

    const hideMenuItemContext = {
      canGoToLink: true,
    };

    const menuItems = generateMenuItems(onClickHandlers, hideMenuItemContext);
    return menuItems;
  }, [activeFilters]);

  const WidgetMenu = useMemo(() => {
    return () => <Menu menuItems={menuItems} isEnabled />;
  }, [menuItems]);

  return { WidgetMenu };
}
