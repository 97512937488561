import React from 'react';
import { DataExplorerAttributes } from '../../SearchGrid/GridComponents/sidePanels/CatalogSidePanel/Attributes/DataExplorerAttributes';
import { DataCatalogObjectDetails } from '../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { styled } from '@mui/material';
import { PagesWithSidePanel } from '../../SearchGrid/GridComponents/sidePanels/CatalogSidePanel/CatalogSidePanel';

export const GridWrapper = styled('div')`
  padding: 24px;
  display: flex;
  flex-grow: 1;
  overflow-x: scroll;
`;

type AttributesTabProps = {
  objectDetails: DataCatalogObjectDetails;
  rootPage?: PagesWithSidePanel;
};

export const AttributesTab = ({ objectDetails, rootPage }: AttributesTabProps) => {
  const { scannerType, dataSourceName, fullyQualifiedName } = objectDetails;
  return (
    <GridWrapper>
      <DataExplorerAttributes
        id={fullyQualifiedName}
        isExtendedView
        datasource={{ scannerType, value: dataSourceName }}
        rootPage={rootPage}
      />
    </GridWrapper>
  );
};
