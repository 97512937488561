import React from 'react';
import { DataCatalogObjectDetails } from '../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { styled } from '@mui/material';
import { DataExplorerColumns } from '../../SearchGrid/GridComponents/sidePanels/CatalogSidePanel/DataExplorerColumns/DataExplorerColumns';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { PagesWithSidePanel } from '../../SearchGrid/GridComponents/sidePanels/CatalogSidePanel/CatalogSidePanel';

export const GridWrapper = styled('div')`
  padding: 24px;
  display: flex;
  flex-grow: 1;
  overflow-x: scroll;
`;

type ColumnsTabProps = {
  objectDetails: DataCatalogObjectDetails;
  rootPage?: PagesWithSidePanel;
};

export const ColumnsTab = ({ objectDetails, rootPage }: ColumnsTabProps) => {
  const { scannerType, dataSourceName, fullyQualifiedName, scanner_type_group } = objectDetails;
  const isDataPreviewDisabled = getApplicationPreference('DATA_PREVIEW_DISABLED');
  return (
    <GridWrapper>
      <DataExplorerColumns
        id={fullyQualifiedName}
        isExtendedView
        datasource={{ scannerType, value: dataSourceName }}
        scannerTypeGroup={scanner_type_group}
        isDataPreviewDisabled={isDataPreviewDisabled}
        rootPage={rootPage}
      />
    </GridWrapper>
  );
};
