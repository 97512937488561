import styled from '@mui/material/styles/styled';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  max-height: 52rem;
`;

export const InnerContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
`;

export const SubTitleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

export const DefaultSection = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

export const ExcludeSection = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

export const AddCustomValueSection = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  align-self: stretch;
  padding: 8px 0;
`;

export const ButtonsSection = styled('div')`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  padding: 0 0 16px 16px;
  gap: 8px;
  justify-content: flex-end;
`;

export const Divider = styled('hr')`
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 16px auto;
  width: 108%;
  transform: translateX(-4%);
`;
