import React, { FC, useState, useEffect } from 'react';
import { BigidBody1, PrimaryButton, SecondaryButton, TertiaryButton } from '@bigid-ui/components';
import { BigidAddIcon } from '@bigid-ui/icons';
import { noop } from 'lodash';
import { v4 as uuid } from 'uuid';
import { closeSystemDialog } from '../../services/systemDialogService';
import { DsTypeDynamicCost } from './DsTypeDynamicCost';
import { useLocalTranslation } from './translations';
import { DsTypeGroup } from './DsTypeGroup';
import {
  AddCustomValueSection,
  ButtonsSection,
  Container,
  DefaultSection,
  Divider,
  ExcludeSection,
  InnerContainer,
  SubTitleContainer,
} from './SetDsTypesDynamicCostModalStyles';

const DEFAULT_SERVICE_NAME = 'Default';
const DEFAULT_FRIENDLY_NAME = 'Default';
const TB_VOLUME_UNIT = 'TB';
const DEFAULT_COST = 10;

export interface SetDollarValueModalProps {}

export interface DsType {
  friendlyName: string;
  serviceName: string;
  cost?: number;
  volumeUnit?: 'TB' | 'GB';
}

export interface DsGroup {
  id: string;
  configured: string[];
  isCollapsed: boolean;
  isDefault?: boolean;
  isNew?: boolean;
}

export const SetDsTypesDynamicCostModal: FC<SetDollarValueModalProps> = () => {
  const [allDsTypes, setAllDsTypes] = useState<DsType[]>([
    { friendlyName: 'Amazon AWS S3', serviceName: 'S3', cost: 20, volumeUnit: 'TB' },
    { friendlyName: 'MongoDB Atlas', serviceName: 'Mongo', cost: 20, volumeUnit: 'TB' },
    { friendlyName: 'Test1', serviceName: 'Test1', cost: 30, volumeUnit: 'TB' },
    { friendlyName: 'Test2', serviceName: 'Test2', cost: 30, volumeUnit: 'GB' },
    { friendlyName: 'Test3', serviceName: 'Test3', cost: 30, volumeUnit: 'GB' },
    { friendlyName: 'Test4', serviceName: 'Test4', cost: 30, volumeUnit: 'GB' },
    { friendlyName: 'MySQL Database', serviceName: 'MySQL' },
    { friendlyName: 'Google Drive', serviceName: 'GoogleDrive' },
  ]);
  const [dsGroups, setDsGroups] = useState<DsGroup[]>([]);
  const { t } = useLocalTranslation();
  const hasDefaultService = allDsTypes.some(ds => ds.serviceName === DEFAULT_SERVICE_NAME);

  useEffect(() => {
    setAllDsTypes(prevDsTypes => {
      const hasDefault = prevDsTypes.some(dsType => dsType.serviceName === DEFAULT_SERVICE_NAME);
      if (!hasDefault) {
        return [
          ...prevDsTypes,
          {
            friendlyName: DEFAULT_FRIENDLY_NAME,
            serviceName: DEFAULT_SERVICE_NAME,
            cost: DEFAULT_COST,
            volumeUnit: TB_VOLUME_UNIT,
          },
        ];
      }
      return prevDsTypes;
    });

    if (dsGroups.length > 0) return;

    const initialGroups: DsGroup[] = [];
    const existingCostVolumeMap = new Map<string, string[]>();

    allDsTypes.forEach(dsType => {
      const isDefaultDsType = dsType.serviceName === DEFAULT_SERVICE_NAME;
      const isConfigured = dsType.cost !== undefined && dsType.volumeUnit !== undefined;

      if (!isDefaultDsType && isConfigured) {
        const key = `${dsType.cost}-${dsType.volumeUnit}`;
        if (!existingCostVolumeMap.has(key)) {
          existingCostVolumeMap.set(key, []);
        }
        existingCostVolumeMap.get(key)!.push(dsType.serviceName);
      }
    });

    for (const [costVolumeKey, serviceNames] of existingCostVolumeMap.entries()) {
      initialGroups.push({
        id: uuid(),
        configured: serviceNames,
        isCollapsed: false,
      });
    }

    setDsGroups(initialGroups);
  }, [dsGroups.length, allDsTypes]);

  const existingConfigs = dsGroups.reduce((acc, group) => {
    if (group.isNew) return acc;
    const ownedDs = allDsTypes.find(
      dsType =>
        group.configured.includes(dsType.serviceName) && dsType.cost !== undefined && dsType.volumeUnit !== undefined,
    );
    if (ownedDs) {
      acc.push({ cost: ownedDs.cost!, volumeUnit: ownedDs.volumeUnit! });
    }
    return acc;
  }, [] as { cost: number; volumeUnit: 'TB' | 'GB' }[]);

  const handleAddCustomValue = () => {
    const newGroup: DsGroup = {
      id: uuid(),
      configured: [],
      isCollapsed: false,
      isNew: true,
    };
    setDsGroups(prevGroups => [...prevGroups, newGroup]);
  };

  const handleToggleCollapse = (groupId: string) => {
    setDsGroups(prevGroups =>
      prevGroups.map(group => (group.id === groupId ? { ...group, isCollapsed: !group.isCollapsed } : group)),
    );
  };

  const handleDsTypeGroupChange = (groupId: string, updatedDsTypes: DsType[]) => {
    setAllDsTypes(prevDsTypes =>
      prevDsTypes.map(existingDs => {
        const changed = updatedDsTypes.find(dsType => dsType.serviceName === existingDs.serviceName);
        return changed ? { ...existingDs, ...changed } : existingDs;
      }),
    );

    const newlyConfiguredNames = updatedDsTypes
      .filter(dsType => dsType.cost !== undefined && dsType.volumeUnit !== undefined)
      .map(dsType => dsType.serviceName);

    setDsGroups(prevGroups =>
      prevGroups.map(group => {
        if (group.id === groupId) {
          return { ...group, configured: newlyConfiguredNames };
        }
        return group;
      }),
    );
  };

  const handleRemoveIfEmpty = (groupId: string) => {
    setDsGroups(prevGroups => prevGroups.filter(group => group.id !== groupId));

    setAllDsTypes(prevDsTypes =>
      prevDsTypes.map(dsType => {
        const isInGroup = dsGroups.some(group => group.id === groupId && group.configured.includes(dsType.serviceName));
        if (isInGroup) {
          return { ...dsType, cost: undefined, volumeUnit: undefined };
        }
        return dsType;
      }),
    );
  };

  const handleSaveClicked = () => {
    const configuredDsTypes = allDsTypes.filter(dsType => dsType.cost !== undefined && dsType.volumeUnit !== undefined);
    console.log('Configured dsTypes to save =>', configuredDsTypes);
    closeSystemDialog();
  };

  return (
    <Container>
      <InnerContainer>
        <SubTitleContainer>
          <BigidBody1 fontWeight={400} size="large">
            {t(`modal.headingText`)}
          </BigidBody1>

          {hasDefaultService && (
            <DefaultSection>
              <BigidBody1 fontWeight="bold">{t(`modal.defaultSectionLabel`)}</BigidBody1>
              <DsTypeDynamicCost
                groupId="default-block"
                dsTypes={[allDsTypes.find(ds => ds.serviceName === DEFAULT_SERVICE_NAME)]}
                onBlockChange={handleDsTypeGroupChange}
                isDefault={true}
                isCollapsed={false}
                onToggleCollapse={noop}
                existingGroupConfigs={existingConfigs}
                onRemoveIfEmpty={noop}
              />
            </DefaultSection>
          )}

          {dsGroups.length > 0 && (
            <ExcludeSection>
              <BigidBody1 fontWeight="bold">{t(`modal.excludeSectionLabel`)}</BigidBody1>
              <DsTypeGroup
                dsGroups={dsGroups}
                allDsTypes={allDsTypes}
                handleDsTypeGroupChange={handleDsTypeGroupChange}
                handleToggleCollapse={handleToggleCollapse}
                existingGroupConfigs={existingConfigs}
                handleRemoveIfEmpty={handleRemoveIfEmpty}
              />
            </ExcludeSection>
          )}

          <AddCustomValueSection>
            <SecondaryButton
              startIcon={<BigidAddIcon />}
              color="grey"
              onClick={handleAddCustomValue}
              size="small"
              text={t(`modal.customValue`)}
            />
          </AddCustomValueSection>
        </SubTitleContainer>
      </InnerContainer>

      <Divider />

      <ButtonsSection>
        <TertiaryButton onClick={() => closeSystemDialog()} size="medium" text={t(`modal.cancelText`)} />
        <PrimaryButton onClick={() => handleSaveClicked()} size="medium" text={t(`modal.saveText`)} />
      </ButtonsSection>
    </Container>
  );
};
