import { getQueryClient } from '../../config/query';
import React from 'react';
import { QueryClientProvider } from 'react-query';
import { DataCatalogContextProvider } from './NewDataCatalog/newDataCatalogContext';
import { DataCatalog } from './DataCatalog';

const queryClient = getQueryClient();

export class DataCatalogComponentClass extends React.Component {
  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <DataCatalogContextProvider>
          <DataCatalog />
        </DataCatalogContextProvider>
      </QueryClientProvider>
    );
  }
}
