import React from 'react';
import { BigidLoader } from '@bigid-ui/components';
import styled from '@emotion/styled';

const LoaderStyled = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderTitle = styled('div')`
  margin-top: 12px;
  font-size: 14px;
  line-height: 18px;
  color: black;
`;

interface PreviewLoaderProps {
  loaderText?: string;
}

export const Loader = ({ loaderText }: PreviewLoaderProps) => (
  <LoaderStyled>
    <div>
      <BigidLoader data-aid={'preview-loader'} size={32} thickness={4} position="static" />
      {loaderText && <LoaderTitle>{loaderText}</LoaderTitle>}
    </div>
  </LoaderStyled>
);
