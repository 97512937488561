import { useState, useEffect } from 'react';
import {
  businessGlossaryService,
  GlossaryItem,
} from '../../../administration/generalSettings/businessGlossary/businessGlossary.service';

export const useGlossaryItems = () => {
  const [glossaryItems, setGlossaryItems] = useState<GlossaryItem[]>([]);

  useEffect(() => {
    const loadGlossaryItems = async () => {
      const glossaryItems = await businessGlossaryService.getGlossaryItems();
      setGlossaryItems(glossaryItems);
    };

    loadGlossaryItems();
  }, []);

  return glossaryItems;
};
