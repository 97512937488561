import React, { FC } from 'react';
import { useTheme } from '@mui/styles';
import { BigidBody1 } from '@bigid-ui/components';
import { BigidCircularProgressBar } from '@bigid-ui/visualisation';
import { formatBytes, formatNumberCompact } from '../../../../utilities/numericDataConverter';
import { styled } from '@mui/material';
import { ProgressChartData } from '../../types';
import { useLocalTranslation } from '../../translations';
import { DATA_COVERAGE_FORMATTERS, DataCoverageToggleOption } from '../DataCoverageWidget';

const LegendContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 216px;
`;

export const DataCoverageProgressChart: FC<{
  progressChart: ProgressChartData;
  viewByOption: DataCoverageToggleOption;
}> = ({ progressChart, viewByOption }) => {
  const { t } = useLocalTranslation('dataCoverageWidget');
  const theme = useTheme();

  const formatLegends = (dataItem: any) => {
    const percentage = `(${Math.round(dataItem?.percent)}%)`;

    return (
      <LegendContainer>
        <BigidBody1>{dataItem?.category}</BigidBody1>
        <BigidBody1>
          {viewByOption === DataCoverageToggleOption.VOLUME
            ? formatBytes(dataItem?.value, 0)
            : formatNumberCompact(dataItem?.value, 0)}{' '}
          {percentage}
        </BigidBody1>
      </LegendContainer>
    );
  };

  const generateTooltip = () => {
    return `
    <div style="width: 289px; z-index: 1000; padding: 2px 6px;">
      <div style="display: flex; flex-direction: column; overflow-wrap: break-word;">
        <div style="display: flex; align-items: center; width: 100%">
          <div style="width: 12px; height: 12px; background-color: {color}; margin-right: 6px;"></div>
          <div style="margin-right: 12px; font-size: 14px; line-height: 22px; color: ${theme.palette.bigid.gray600};">{category}</div>
          <div style="color: ${theme.palette.bigid.gray500}; font-size: 14px; line-height: 22px; display: flex; justify-content: flex-end; margin-left: auto;">
            {value} ({percent.formatNumber('#.')}%)
          </div>
        </div>
        <div style="margin-top: 6px; font-size: 12px; line-height: 15px; color: ${theme.palette.bigid.gray500};">{additionalInfo}</div>
      </div>
    </div>
`;
  };
  return (
    <BigidCircularProgressBar
      legendProps={{
        legendPosition: 'bottom',
        shouldHideSeries: false,
        hideLegend: false,
        legendLabelFormatter: dataItem => formatLegends(dataItem),
        legendConfig: { orientation: 'column' },
      }}
      size={230}
      data={progressChart.data}
      numberFormat={DATA_COVERAGE_FORMATTERS[viewByOption] || DATA_COVERAGE_FORMATTERS[DataCoverageToggleOption.AMOUNT]}
      label={progressChart.data?.length > 1 ? t('progressChartTotalLabel') : t('listed')}
      value={progressChart.total}
      customTooltipTemplate={generateTooltip}
    />
  );
};
