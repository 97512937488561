import React, { useEffect, useMemo, useState } from 'react';
import { useUpdatedStateParams } from '../../../hooks/useUpdatedStateParams';
import { SidePanelMetadata } from '../SearchGrid/GridComponents/sidePanels/CatalogSidePanel/SidePanelMetadata';
import { useGetObjectDetails } from '../SearchGrid/GridComponents/sidePanels/CatalogSidePanel/hooks/useGetObjectDetails';
import { styled } from '@mui/material';
import { BigidPaper, BigidTabs, BigidTabsItem } from '@bigid-ui/components';
import { useLocalTranslation } from './translations';
import { OverviewTab } from './tabs/OverviewTab';
import { AttributesTab } from './tabs/AttributesTab';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { ColumnsTab } from './tabs/ColumnsTab';
import { PreviewTab } from './tabs/PreviewTab';
import { isPermitted } from '../../../services/userPermissionsService';
import { CATALOG_PERMISSIONS, LINEAGE_PERMISSIONS } from '@bigid/permissions';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { SimilarTablesTab } from './tabs/SimilarTablesTab';
import { DataRetentionTab } from './tabs/DataRetentionTab';
import {
  DATA_RETENTION_FRIENDLY_NAME,
  useAppSettings,
} from '../SearchGrid/GridComponents/sidePanels/CatalogSidePanel/hooks/useAppSettings';
import { DataCatalogObjectType, DataCatalogRecordScannerTypeGroup } from '../../DataCatalog/DataCatalogService';
import { RelationsTab } from './tabs/RelationsTab';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  height: calc(100% - 24px);
`;

const MetadataWrapper = styled('div')`
  position: fixed;
  width: calc(100% - 100px);
  top: 130px;
`;

const TabsWrapper = styled('div')`
  padding: 16px 24px 0 24px;
`;

export const ObjectDetailsPage = () => {
  const { id, name, rootPage } = useUpdatedStateParams(true);
  const { t } = useLocalTranslation();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { data: dataRetention } = useAppSettings(DATA_RETENTION_FRIENDLY_NAME);

  const { data: objectDetailsData, refetch, isLoading } = useGetObjectDetails(id, true);
  const objectDetails = objectDetailsData?.data;
  const { scanner_type_group, fullyQualifiedName } = objectDetails ?? {};
  const { extendedObjectType } = objectDetails ?? {};

  const {
    isPreviewAvailable,
    isLineageEnabled,
    isSimilarTablesEnabled,
    isDataPreviewDisabled,
    isDataRetentionAvailable,
    isColumnsTabAvailable,
    isRelationsTabAvailable,
  } = useMemo(
    () => ({
      isPreviewAvailable: isPermitted(CATALOG_PERMISSIONS.PREVIEW_FILE_INVESTIGATION.name),
      isLineageEnabled:
        getApplicationPreference('ENABLE_LINEAGE') &&
        isPermitted(LINEAGE_PERMISSIONS.READ.name) &&
        isPermitted(LINEAGE_PERMISSIONS.ACCESS.name),
      isSimilarTablesEnabled: getApplicationPreference('SIMILAR_TABLES_ENABLED'),
      isDataPreviewDisabled: getApplicationPreference('DATA_PREVIEW_DISABLED'),
      isDataRetentionAvailable: Boolean(dataRetention),
      isColumnsTabAvailable: scanner_type_group === DataCatalogRecordScannerTypeGroup.STRUCTURED,
      isRelationsTabAvailable:
        DataCatalogObjectType.MODEL === extendedObjectType &&
        getApplicationPreference('AI_SECURITY_AND_GOVERNANCE_ENABLED'),
    }),
    [dataRetention, scanner_type_group],
  );

  const shouldShowPreview = isPreviewAvailable && !isDataPreviewDisabled;

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: name ?? id,
      showBackButton: true,
    });
  }, [id, name]);

  const tabs: BigidTabsItem[] = [
    {
      label: t('tabs.overview'),
    },
    {
      label: t('tabs.attributes'),
    },
    ...(isColumnsTabAvailable ? [{ label: t('tabs.columns') }] : []),
    ...(isSimilarTablesEnabled
      ? [
          {
            label: t('tabs.similarTables'),
          },
        ]
      : []),
    ...(shouldShowPreview ? [{ label: t('tabs.preview') }] : []),
    ...(isDataRetentionAvailable ? [{ label: t('tabs.dataRetention') }] : []),
    ...(isRelationsTabAvailable ? [{ label: t('tabs.relations') }] : []),
  ];

  const tabsContent = [
    <OverviewTab
      isLoading={isLoading}
      objectDetails={objectDetails}
      refetchDetails={refetch}
      rootPage={rootPage}
      key="OverviewTab"
    />,
    <AttributesTab objectDetails={objectDetails} rootPage={rootPage} key="AttributesTab" />,
    ...(isColumnsTabAvailable
      ? [<ColumnsTab objectDetails={objectDetails} rootPage={rootPage} key="ColumnsTab" />]
      : []),
    ...(isSimilarTablesEnabled
      ? [<SimilarTablesTab fullyQualifiedName={fullyQualifiedName} key="SimilarTablesTab" />]
      : []),
    ...(shouldShowPreview ? [<PreviewTab objectDetails={objectDetails} rootPage={rootPage} key="PreviewTab" />] : []),
    ...(isDataRetentionAvailable
      ? [<DataRetentionTab data={dataRetention} fullyQualifiedName={fullyQualifiedName} key="RetentionTab" />]
      : []),
    ...(isRelationsTabAvailable ? [<RelationsTab objectDetails={objectDetails} key="RelationsTab" />] : []),
  ];

  return (
    <Root>
      <BigidPaper>
        <MetadataWrapper>
          <SidePanelMetadata fullyQualifiedName={id} />
        </MetadataWrapper>
        <TabsWrapper>
          <BigidTabs tabs={tabs} showBorderBottom onChange={setSelectedTabIndex} selectedIndex={selectedTabIndex} />
        </TabsWrapper>
        {tabsContent[selectedTabIndex]}
      </BigidPaper>
    </Root>
  );
};
