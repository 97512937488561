import { Dispatch, MutableRefObject, SetStateAction, useState } from 'react';
import { testConnectionRequest } from '../../services/correlationSetsService';
import { transformCorrelationSetFormStateToPayload } from '../../utils/utils';
import {
  GuideState,
  WizardFormFields,
  CorrelationSetData,
  CorrelationSetFormData,
  CorrelationSetsWizardSteps,
} from '../../types/CorrelationSetsTypes';

interface useTestConnectionProps {
  detailsStepFormControls: MutableRefObject<any>;
  guideState: GuideState;
  setCorrelationGuideState: Dispatch<SetStateAction<GuideState>>;
  setCorrelationSetFormData: Dispatch<SetStateAction<CorrelationSetFormData>>;
}

export const useTestConnection = ({
  guideState,
  detailsStepFormControls,
  setCorrelationGuideState,
  setCorrelationSetFormData,
}: useTestConnectionProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleTestConnectionError = (err: any) => {
    let errorMsg = 'Test Connection Failed!';
    if (err?.code) {
      errorMsg = err.code;
    } else if (err?.message) {
      errorMsg = err.message;
    } else if (typeof err === 'string') {
      errorMsg = err;
    }

    setCorrelationGuideState({ ...guideState, errorMessage: errorMsg });
  };

  const testESConnectionSync = async (formData: CorrelationSetData, connectionStep: CorrelationSetsWizardSteps) => {
    try {
      setIsLoading(true);
      const response = await testConnectionRequest(formData);

      if (!response.isSuccessful) {
        if (response.err?.code) handleTestConnectionError(response.err);
        return;
      }

      if (connectionStep === CorrelationSetsWizardSteps.PREVIEW) {
        setCorrelationSetFormData(prevState => ({ ...prevState, attributes: response.columns }));
        detailsStepFormControls.current.setValue(WizardFormFields.attributes)(response.columns);
        setCorrelationGuideState({ ...guideState, errorMessage: '' });
      }

      if (connectionStep === CorrelationSetsWizardSteps.TRAINING) {
        setCorrelationSetFormData(prevState => ({ ...prevState, attributesTrainingStep: response.columns }));
        detailsStepFormControls.current.setValue(WizardFormFields.attributesTrainingStep)(response.columns);
        setCorrelationGuideState({ ...guideState, errorMessage: '' });
      }

      return response;
    } catch (e) {
      handleTestConnectionError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTestConnection = async (
    formState: CorrelationSetFormData,
    connectionStep: CorrelationSetsWizardSteps,
    isCustomQueryStringImportantForTestConnection?: boolean,
  ) => {
    const correlationSetPayload = transformCorrelationSetFormStateToPayload({
      formState,
      isCustomQueryStringImportantForTestConnection,
    });

    return testESConnectionSync(correlationSetPayload, connectionStep);
  };

  return {
    isLoading,
    handleTestConnection,
  };
};
