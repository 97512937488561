import React, { useState, useMemo, useEffect, Suspense, FC } from 'react';
import { Divider } from '@mui/material';
import styled from '@emotion/styled';
import {
  BigidLink,
  BigidSearch,
  BigidColors,
  BigidLoader,
  SecondaryButton,
  BigidHoverActionCard,
  useStringFilter,
  normalizeSearchString,
  StyledButtonType,
  BigidColorsV2,
  BigidHoverActionCardProps,
  BigidHeading2,
} from '@bigid-ui/components';
import { flatten } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { getSupportedDataSources, DataSource, DataSourceTemplateStatus } from '../../../utilities/dataSourcesUtils';
import { $state } from '../../../services/angularServices';
import { licenseService } from '../../../services/licenseService';
import BigIdLockFilledIcon from '../../../assets/icons/BigIdLockFilledIcon.svg';
import { BigidAppLogoDataRightsFulfillmentIcon, getDataSourceIconByDsType } from '@bigid-ui/icons';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { docsUrls } from '../../../config/publicUrls';
import { DataSourcesUITrackingEvent } from '../DataSourcesEventTrackerUtils';
import { generateDsDocsUrl } from '../DataSourceConnectionModal/utils/utils';
import { ChunkErrorBoundary } from '../SelectDataSourceOnboardingType/components/ChunkErrorBoundary';

export type SelectDataSourceTypeProps = {
  onSelect?: (props: Partial<DataSource>, handlers: { onSelectHandler: (props: Partial<DataSource>) => void }) => void;
};

const DataSourcesDivider = styled(Divider)`
  margin-top: 16px;
  width: 100%;
`;

const useStyles = makeStyles({
  selectDataSourceTypePage: {
    display: 'flex',
    justifyContent: 'center',
    height: 'calc(100% - 8px)',
    overflow: 'hidden',
  },
  selectDataSourceTypeContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  selectTitle: {
    textAlign: 'center',
  },
  infoBlock: {
    fontSize: 14,
    marginTop: 20,
    marginBottom: 26,
    textAlign: 'center',
  },
  noResultBlock: {
    fontSize: 17,
    marginTop: 150,
    color: BigidColors.gray[500],
    textAlign: 'center',
  },
  selectDataSourceTypeList: {
    display: 'grid',
    gridColumnGap: 16,
    gridRowGap: 16,
    marginTop: 16,
    gridTemplateColumns: 'repeat(auto-fit, 100px)',
    gridTemplateRows: 'repeat(auto-fit, 175px)',
    flex: '0 1 auto',
    scrollbarWidth: 'none',
    width: 800,
    '&:after': {
      content: '""',
      height: 92,
      position: 'relative',
      gridColumn: 1,
    },
  },
  selectDataSourceTypeHeader: {
    width: 800,
  },
  selectDataSourceTypeListWrapper: {
    maxWidth: 'unset',
    overflow: 'scroll',
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

type StatusChipPropsByStatus = Pick<BigidHoverActionCardProps['chip'], 'label' | 'bgColor' | 'tooltipProps'>;

const statusChipProps: Record<DataSourceTemplateStatus, StatusChipPropsByStatus> = {
  [DataSourceTemplateStatus.NEW]: {
    bgColor: BigidColorsV2.purple[600],
    label: DataSourceTemplateStatus.NEW,
    tooltipProps: { title: 'This is an early adopter feature' },
  },
  [DataSourceTemplateStatus.PREMIUM]: {
    bgColor: BigidColorsV2.blue[300],
    label: DataSourceTemplateStatus.PREMIUM,
    tooltipProps: { title: 'Additional license is required' },
  },
  [DataSourceTemplateStatus.DEPRECATED]: {
    bgColor: BigidColorsV2.yellow[700],
    label: DataSourceTemplateStatus.DEPRECATED,
    tooltipProps: { title: 'This data source connector has a new version (V2)' },
  },
};

const INFO_TEXT = `On this page you can connect your data sources one by one. Click below on the type of data source you wish to
connect now. For more information on data sources, `;
const DATA_SOURCE_DOCS_LINK = docsUrls.DATA_SOURCES;
const CARD_SIZE = 100;
export const IS_TRAY_MODEL = 'isTray';
export const TRAY_CARD_PROPS = {
  cornerIcon: BigidAppLogoDataRightsFulfillmentIcon,
  tooltipText: 'Data Rights Fulfillment only',
};

const BUTTONS: any[] = [
  {
    text: 'Select',
    type: 'secondary',
    value: 'select',
  },
  {
    text: 'Info',
    type: 'secondary',
    value: 'info',
  },
];

function useFilterObjectArrayByString<T>(fieldForFilteringName: string, data: Array<T>) {
  const { filterParts, updateNameFilter, normalizedSearchString } = useStringFilter();
  const availableData = useMemo(() => {
    let parts = [...filterParts];

    return filterParts?.length === 0
      ? data
      : data
          .map((valueObject: any) => ({
            ...valueObject,
            isMatchedByKeywords: flatten(valueObject.keywords).some((keyword: any) => {
              if (
                normalizedSearchString.includes(` ${keyword?.toLowerCase()}`) ||
                normalizedSearchString.startsWith(keyword?.toLowerCase())
              ) {
                const keyWordParts = keyword?.toLowerCase().split(' ') || [];
                parts = parts.filter(part => keyWordParts.every((keyWordPart: string) => keyWordPart !== part));
                return true;
              }
              return false;
            }),
          }))
          .filter(
            (valueObject: any) =>
              valueObject.isMatchedByKeywords ||
              parts.some((token: string) =>
                valueObject[fieldForFilteringName].some((tag: string) => tag.startsWith(token)),
              ),
          );
  }, [filterParts, data, fieldForFilteringName, normalizedSearchString]);

  return { availableData, updateNameFilter };
}

const buttonsMap = BUTTONS.map(({ text, value, type }) => ({
  onClick: ({ name, nameInDocs, templateName }: Partial<DataSource>) => {
    if (value === 'select') {
      if (getApplicationPreference('DS_TYPES_TEMPLATES_SUPPORTED') && templateName) {
        $state.go(`configDataSourceNew`, {
          selectedType: name.toLowerCase(),
        });
      } else {
        $state.go('newDataSourceConnection', { selectedType: name, id: null });
      }
    } else {
      window.open(generateDsDocsUrl(nameInDocs));
    }
  },
  text,
  value,
  component: (props: StyledButtonType) => (
    <SecondaryButton
      {...props}
      color={value === 'select' ? 'grey' : 'purple'}
      size="medium"
      bi={
        value === 'select'
          ? {
              eventType: DataSourcesUITrackingEvent.DATA_SOURCES_INIT_SELECT_DS_CLICK,
              eventData: { selectedType: name },
            }
          : {}
      }
    />
  ),
}));

const buttonsMapForBlocked = buttonsMap.map(buttonItem =>
  buttonItem.text !== 'Select'
    ? buttonItem
    : {
        ...buttonItem,
        text: 'Setup',
        onClick: licenseService.openLicenseUpgradeFlow,
      },
);

const getButtonsForDsTypeCard = (
  { nameInDocs, isPermitted }: Partial<DataSource>,
  { onSelect }: Partial<SelectDataSourceTypeProps>,
) => {
  const buttons = (isPermitted ? buttonsMap : buttonsMapForBlocked).map(({ onClick, value, ...rest }) => ({
    ...rest,
    onClick: (props: Partial<DataSource>) => {
      const isSelectOverride = value === 'select' && onSelect;
      isSelectOverride ? onSelect(props, { onSelectHandler: onClick }) : onClick(props);
    },
  }));

  return nameInDocs ? buttons : buttons.filter(({ text }) => text !== 'Info');
};

const prepareDsForList = (dataSources: DataSource[]) =>
  dataSources.map(dataSource => ({
    ...dataSource,
    searchParts: normalizeSearchString(dataSource.displayName || dataSource.name)
      .split(' ')
      .filter(value => value !== ''),
  }));

export const SelectDataSourceType = ({ onSelect }: SelectDataSourceTypeProps): JSX.Element => {
  const classes = useStyles({});
  const [supportedDataSources, setSupportedDataSources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getSupportedDataSources(true)
      .then(data => setSupportedDataSources(prepareDsForList(data)))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  const { availableData, updateNameFilter } = useFilterObjectArrayByString<DataSource>(
    'searchParts',
    supportedDataSources,
  );

  return (
    <div className={classes.selectDataSourceTypePage} data-aid="SelectDataSourceType">
      <div className={classes.selectDataSourceTypeContainer}>
        <div className={classes.selectDataSourceTypeHeader}>
          <BigidHeading2 className={classes.selectTitle} data-aid="SelectDataSourceTypeTitle">
            Connect Your Data
          </BigidHeading2>
          <div className={classes.infoBlock} data-aid="SelectDataSourceTypeText">
            {INFO_TEXT}
            <BigidLink href={DATA_SOURCE_DOCS_LINK} text="click here" shouldOpenNewTab />.
          </div>

          <BigidSearch onChange={updateNameFilter} onSubmit={updateNameFilter} placeholder="Search" size="large" />
        </div>

        {availableData.length ? (
          <>
            <DataSourcesDivider />
            <div className={classes.selectDataSourceTypeListWrapper}>
              <div className={classes.selectDataSourceTypeList} data-aid="SelectDataSourceTypeList">
                {availableData.map(
                  ({
                    name,
                    type,
                    nameInDocs,
                    displayName,
                    isPermitted,
                    templateName,
                    modelProp,
                    status,
                  }: Partial<DataSource>) => (
                    <Suspense fallback={<BigidLoader />} key={name}>
                      <BigidHoverActionCard
                        title={displayName || name}
                        width={CARD_SIZE}
                        height={CARD_SIZE}
                        backgroundIcon={() => {
                          const getIcon = () => {
                            const Icon = getDataSourceIconByDsType(type);
                            return <Icon />;
                          };
                          return <ChunkErrorBoundary> {getIcon()} </ChunkErrorBoundary>;
                        }}
                        cardDataObject={{ name, nameInDocs, templateName, displayName }}
                        buttons={getButtonsForDsTypeCard({ nameInDocs, isPermitted }, { onSelect })}
                        dataAid={`dataSourceCard-${name}`}
                        lockIcon={!isPermitted && BigIdLockFilledIcon}
                        {...(modelProp === IS_TRAY_MODEL && TRAY_CARD_PROPS)}
                        chip={status && { ...statusChipProps[status], color: BigidColorsV2.white }}
                      />
                    </Suspense>
                  ),
                )}
              </div>
            </div>
          </>
        ) : (
          <div className={classes.noResultBlock} data-aid="SelectDataSourceTypeNoResult">
            {isLoading ? <BigidLoader /> : 'No results found'}
          </div>
        )}
      </div>
    </div>
  );
};
