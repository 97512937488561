import React, { FC } from 'react';
import { BigidBody1 } from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';

type NotificationWithSkippedItemsProps = {
  message: string;
  skippedRows: Array<{ name: string; type: string }>;
};

export const NotificationWithSkippedItems: FC<NotificationWithSkippedItemsProps> = ({ message, skippedRows }) => {
  const { t } = useLocalTranslation('BusinessGlossary.importDialog');

  return (
    <BigidBody1>
      <div>{message}</div>
      <br />
      <div>
        {t('skippedRowsText1')}
        <b>{t('skippedRowsText2')}</b>
        {t('skippedRowsText3')}
      </div>
      <ul>
        {skippedRows.map(row => (
          <li key={row.name}>{`${t('skippedRowsName')}: "${row.name}", ${t('skippedRowsType')}: "${row.type}"`}</li>
        ))}
      </ul>
    </BigidBody1>
  );
};
