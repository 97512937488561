import React, { FC, useEffect, useState, useRef, useCallback, Fragment, PropsWithChildren } from 'react';
import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColors, BigidLoader, BigidIcon, BigidIconSize } from '@bigid-ui/components';
import { ColumnProfilingPercentageChart } from './ColumnProfilingPercentageChart';
import { getColumnProfileCancelable, ColumnProfile } from '../../DataCatalogColumnsService';
import { notificationService } from '../../../../../services/notificationService';
import classNames from 'classnames';
import Close from '@mui/icons-material/Close';
import { Canceler } from 'axios';
import { ColumnProfilingEntity, ColumnProfilingEntityProps } from './ColumnProfilingEntity';
import { formatNumber } from '../../../../../utilities/numericDataConverter';
import { analyticsService } from '../../../../../services/analyticsService';
import { CATALOG_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../../../services/userPermissionsService';
import { SidePanelEvents } from '../../../../DataExplorerSearchResults/SearchGrid/GridComponents/sidePanels/CatalogSidePanel/events';
import { PagesWithSidePanel } from '../../../../DataExplorerSearchResults/SearchGrid/GridComponents/sidePanels/CatalogSidePanel/CatalogSidePanel';
import { CatalogEventsEnum } from '../../../events';

export interface ColumnProfilingProps extends PropsWithChildren {
  dataAid?: string;
  fullyQualifiedName: string;
  columnName: string;
  isProfiled?: boolean;
  onClose?: () => void;
  dsType: string;
  dsName: string;
  scannerType: string;
  rootPage?: PagesWithSidePanel;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  title: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    //NOTE: until there is no toolbar in the grid, make it 50px afterwards
    height: '42px',
    borderBottom: `1px solid ${BigidColors.borderLight}`,
    backgroundColor: BigidColors.gray[50],
  },
  titleText: {
    fontWeight: 400,
    //NOTE: until there is no toolbar in the grid, make it gray-700 afterwards
    color: 'rgba(0, 0, 0, 0.87)',
    //NOTE: until there is no toolbar in the grid, make it 1rem afterwards
    fontSize: '0.875rem',
    paddingLeft: 12,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  titleClose: {
    display: 'flex',
  },
  close: {
    //NOTE: until there is no toolbar in the grid, make it 1rem afterwards
    fontSize: '0.8125rem',
  },
  content: {
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: 'calc(100% - 42px)',
    overflowY: 'auto',
  },
  row: {
    marginBottom: 12,
  },
  text: {
    fontSize: '0.8125rem',
  },
  textRegular: {
    color: BigidColors.gray[700],
    fontWeight: 400,
  },
  textLight: {
    color: BigidColors.gray[600],
    fontWeight: 300,
  },
  busy: {
    pointerEvents: 'none',
    opacity: '0.5',
  },
  unavailable: {
    backgroundColor: BigidColors.gray[50],
    border: `1px solid ${BigidColors.gray[50]}`,
    borderRadius: 4,
    padding: '8px 12px',
  },
  buttonsWrapper: {
    backgroundColor: BigidColors.gray[50],
    border: `1px solid ${BigidColors.gray[50]}`,
    borderRadius: '4px',
    padding: '8px 12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '63px',
    '& button': {
      '&:not(:last-child)': {
        marginBottom: '5px',
      },
    },
  },
});

const NUMERIC_TYPE = 'Numeric';
const TEXTUAL_TYPE = 'Textual';

export const ColumnProfiling: FC<ColumnProfilingProps> = ({
  dataAid = 'ColumnProfiling',
  onClose,
  fullyQualifiedName,
  columnName,
  isProfiled = false,
  children,
  dsType,
  scannerType,
  rootPage,
}) => {
  const classes = useStyles({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [columnProfileEntities, setColumnProfileEntities] = useState<ColumnProfilingEntityProps[]>([]);
  const [fieldCount, setFieldCount] = useState<number>(0);
  const fetchColumnProfileCancellerRef = useRef<Canceler>();

  useEffect(() => {
    const trackingData = {
      fullyQualifiedName,
      columnName,
      dsType,
      scannerType,
    };
    const eventName = rootPage
      ? rootPage + SidePanelEvents.CATALOG_COLUMN_PROFILE_EVENT
      : CatalogEventsEnum.CATALOG_COLUMN_PROFILE_EVENT;

    analyticsService.trackManualEvent(eventName, trackingData);
  }, [fullyQualifiedName, columnName, dsType, scannerType]);

  const getColumnProfileEntities = (data: ColumnProfile): ColumnProfilingEntityProps[] => {
    const columnProfileEntities: ColumnProfilingEntityProps[] = [];

    const {
      fieldCount,
      inferredDataType,
      avgNum,
      avgStrLen,
      minNum,
      maxNum,
      strDev,
      numDev,
      maxLexStr,
      minLexStr,
      emptyPct,
      distinctPct,
    } = data;

    const avgLenReduced = inferredDataType ? avgStrLen : undefined;
    const minNumReduced = inferredDataType ? (inferredDataType === NUMERIC_TYPE ? minNum : minLexStr) : undefined;
    const maxNumReduced = inferredDataType ? (inferredDataType === NUMERIC_TYPE ? maxNum : maxLexStr) : undefined;

    const emptyPctReduced = !isNaN(emptyPct) ? Number(emptyPct % 1 === 0 ? emptyPct : emptyPct.toFixed(2)) : undefined;
    const distinctPctReduced = !isNaN(distinctPct)
      ? Number(distinctPct % 1 === 0 ? distinctPct : distinctPct.toFixed(2))
      : undefined;
    const avgNumReduced = avgNum;
    const devReduced = inferredDataType ? (inferredDataType === NUMERIC_TYPE ? numDev : strDev) : undefined;

    !isNaN(fieldCount) && setFieldCount(fieldCount);

    columnProfileEntities.push({ name: 'Inferred data type', value: inferredDataType });
    columnProfileEntities.push({
      name: 'Average length',
      value: avgLenReduced ? (avgLenReduced % 1 === 0 ? avgLenReduced : avgLenReduced.toFixed(2)) : undefined,
    });
    columnProfileEntities.push({
      name: '% Null/Empty values',
      value: emptyPctReduced,
      valueColor: !isNaN(emptyPctReduced) ? BigidColors.blue[600] : BigidColors.gray[700],
      widget: !isNaN(emptyPctReduced) ? (
        <ColumnProfilingPercentageChart value={emptyPctReduced} color={BigidColors.blue[600]} />
      ) : undefined,
    });
    columnProfileEntities.push({
      name: '% Distinct values',
      value: distinctPctReduced,
      valueColor: !isNaN(distinctPctReduced) ? BigidColors.blue[600] : BigidColors.gray[700],
      widget: !isNaN(distinctPctReduced) ? (
        <ColumnProfilingPercentageChart value={distinctPctReduced} color={BigidColors.blue[600]} />
      ) : undefined,
    });

    if (isPermitted(CATALOG_PERMISSIONS.PREVIEW_FILE_INVESTIGATION.name)) {
      columnProfileEntities.push({
        name: 'Min value',
        value: minNumReduced,
      });
    }

    if (isPermitted(CATALOG_PERMISSIONS.PREVIEW_FILE_INVESTIGATION.name)) {
      columnProfileEntities.push({
        name: 'Max value',
        value: maxNumReduced,
      });
    }

    inferredDataType === NUMERIC_TYPE && columnProfileEntities.push({ name: 'Average value', value: avgNumReduced });
    inferredDataType === NUMERIC_TYPE &&
      columnProfileEntities.push({
        name: 'Standard deviation',
        value: devReduced,
      });

    return columnProfileEntities;
  };

  const fetchColumnProfile = useCallback(async () => {
    const { cancel, promise } = getColumnProfileCancelable(fullyQualifiedName, columnName);

    fetchColumnProfileCancellerRef.current = cancel;

    const { data } = await promise;

    return data;
  }, [fullyQualifiedName, columnName]);

  useEffect(() => {
    if (isProfiled) {
      setIsLoading(true);
      fetchColumnProfile()
        .then(({ data }) => {
          setColumnProfileEntities(getColumnProfileEntities(data));
          fetchColumnProfileCancellerRef.current = null;
        })
        .catch(error => {
          if (error.message !== 'cancelled') {
            console.error(error);
            notificationService.error('An error has occurred');
            setColumnProfileEntities([]);
          }

          setIsLoading(false);
          fetchColumnProfileCancellerRef.current = null;
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    return () => {
      fetchColumnProfileCancellerRef.current?.('cancelled');
      fetchColumnProfileCancellerRef.current = null;
    };
  }, [fetchColumnProfile, isProfiled]);

  return (
    <div className={classes.root} data-aid={dataAid}>
      {onClose && (
        <div className={classes.title}>
          <div className={classes.titleText}>Column profiling</div>
          <div className={classes.titleClose}>
            <IconButton
              classes={{ root: classes.close }}
              aria-label="close"
              onClick={onClose}
              size="large"
              sx={{ height: 'auto' }}
            >
              <BigidIcon icon={Close} size={BigidIconSize.REGULAR} color={BigidColors.iconDark} />
            </IconButton>
          </div>
        </div>
      )}
      <div className={classNames(classes.content, isLoading && classes.busy)}>
        {isLoading && <BigidLoader />}
        {isProfiled ? (
          <Fragment>
            <div className={classes.row}>
              {fieldCount > 0 && (
                <span className={classNames(classes.text, classes.textRegular)}>
                  Profile based on {formatNumber(fieldCount)} records
                </span>
              )}
            </div>
            {children && (
              <div className={classes.row} data-aid={`${dataAid}-actions`}>
                <div className={classes.buttonsWrapper}>{children}</div>
              </div>
            )}
            {columnProfileEntities.map(
              ({ name, value, valuePlaceholder, valueColor, widget }: ColumnProfilingEntityProps, index) => (
                <div key={index} className={classes.row}>
                  <ColumnProfilingEntity
                    name={name}
                    value={value}
                    valueColor={valueColor}
                    valuePlaceholder={valuePlaceholder}
                    widget={widget}
                  />
                </div>
              ),
            )}
          </Fragment>
        ) : (
          <div className={classes.row}>
            <div className={classes.unavailable}>
              <span className={classNames(classes.text, classes.textRegular)}>Column profiling is unavailable</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
