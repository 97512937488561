import React from 'react';
import { GuideState, ListType } from '../../../types/CorrelationSetsTypes';
import { useLocalTranslation } from '../../../translations';
import styled from '@emotion/styled';
import { GuideContent } from '../../GuideContent/GuideContent';

const ErrorContainer = styled('div')`
  margin-top: 12px;

  > div {
    overflow-x: scroll;
  }
`;

interface TrainingGuideContentProps {
  contentTitle: string;
  guideState: GuideState;
}

export const TrainingGuideContent = ({ contentTitle, guideState }: TrainingGuideContentProps) => {
  const { t } = useLocalTranslation('wizard.guideContent.trainingStep');

  const guideDataTraining = [
    {
      title: t('title1'),
      listStyle: ListType.none,
      data: t('text1'),
    },
    {
      title: t('title2'),
      listStyle: ListType.none,
      data: t('text2'),
    },
  ];
  return <GuideContent content={guideDataTraining} />;
};
