import React, { FC, Component, useMemo, useState, useEffect } from 'react';
import { BigidTabsAndContent, BigidTabsAndContentProps, BigidPaper } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { DataCatalogDetails } from '../DataCatalogDetails';
import { getDetailsByObjectName, DataCatalogObjectDetails } from '../DataCatalogDetails/DataCatalogDetailsService';
import { DataCatalogColumns } from '../DataCatalogColumns';
import { DataCatalogAttributes } from '../DataCatalogAttributes';
import { DataCatalogFileDuplicates } from '../DataCatalogFileDuplicates/DataCatalogFileDuplicates';
import { DataCatalogTablePreview } from '../DataCatalogTablePreview';
import { PreviewFileContent } from '../PreviewFileObjects/PreviewFileContent';
import { DataCatalogRecordScannerTypeGroup, DataCatalogObjectType } from '../DataCatalogService';
import { CATALOG_PERMISSIONS, LINEAGE_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';
import { notificationService } from '../../../services/notificationService';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { DataCatalogEntityPreviewNoResult } from './DataCatalogEntityPreviewNoResult';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { DataCatalogLineage } from '../DataCatalogLineage';
import { useUpdatedStateParams } from '../../../hooks/useUpdatedStateParams';
import { DataCatalogSimilarTables } from '../DataCatalogSimilarTables/DataCatalogSimilarTables';
import { ObjectDetailsPage } from '../../DataExplorerSearchResults/ObjectDetailsPage/ObjectDetailsPage';
import { QueryClientProvider } from 'react-query';
import { getQueryClient } from '../../../config/query';
import { isDataExplorerEnabled } from '../../../utilities/featureFlagUtils';
import { DataCatalogRelations } from '../DataCatalogRelations';

export const previewTableDataSourcesBlacklist: string[] = ['cassandra', 'ldap', 'external-catalog'];
export const previewFileDataSourcesBlacklist: string[] = ['external-catalog'];

const useStyles = makeStyles({
  root: {
    height: 'calc(100% - 10px)',
    width: '100%',
    display: 'flex',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '24px',
  },
  content: {
    display: 'flex',
    overflow: 'hidden',
    flexFlow: 'row nowrap',
    flex: '1 1 auto',
  },
});

export const DataCatalogEntityPreview: FC = () => {
  const classes = useStyles();
  const [entity, setEntity] = useState<DataCatalogObjectDetails>(null);
  const [isObjectUnavailable, setIsObjectUnavailable] = useState<boolean>(false);
  const { id, name } = useUpdatedStateParams(true);

  const { isPreviewAvailable, isLineageEnabled, isSimilarTablesEnabled } = useMemo(
    () => ({
      isPreviewAvailable: isPermitted(CATALOG_PERMISSIONS.PREVIEW_FILE_INVESTIGATION.name),
      isLineageEnabled:
        getApplicationPreference('ENABLE_LINEAGE') &&
        isPermitted(LINEAGE_PERMISSIONS.READ.name) &&
        isPermitted(LINEAGE_PERMISSIONS.ACCESS.name),
      isSimilarTablesEnabled: getApplicationPreference('SIMILAR_TABLES_ENABLED'),
    }),
    [],
  );

  const isColumnsAvailable = useMemo(() => {
    return (
      entity?.scanner_type_group !== DataCatalogRecordScannerTypeGroup.UNSTRUCTURED &&
      entity?.scanner_type_group !== DataCatalogRecordScannerTypeGroup.EMAIL
    );
  }, [entity?.scanner_type_group]);

  const isDuplicatesAvailable = useMemo(() => {
    return entity?.type === DataCatalogObjectType.FILE;
  }, [entity?.type]);

  const tabsAndContentConfig: BigidTabsAndContentProps = useMemo(
    () => ({
      classes: {
        contentContainer: classes.content,
      },
      tabProps: {
        tabs: [
          {
            label: 'Details',
            id: 'details',
            data: { component: DataCatalogDetails, customProps: entity },
          },
          {
            label: 'Attributes',
            id: 'attributes',
            data: { component: DataCatalogAttributes, customProps: entity },
          },
          {
            label: 'Columns',
            id: 'columns',
            data: { component: DataCatalogColumns, customProps: entity },
            getIsAvailable: () => isColumnsAvailable,
          },
          {
            label: 'Similar tables',
            id: 'similar tables',
            data: { component: DataCatalogSimilarTables, customProps: entity },
            getIsAvailable: () => {
              const { scanner_type_group } = entity || {};
              return scanner_type_group === DataCatalogRecordScannerTypeGroup.STRUCTURED && isSimilarTablesEnabled;
            },
          },
          {
            label: 'Graph View',
            id: 'lineage',
            data: { component: DataCatalogLineage, customProps: entity },
            getIsAvailable: () => isLineageEnabled,
          },
          {
            label: 'Preview',
            id: 'preview',
            data: { component: PreviewFileContent, customProps: entity },
            getIsAvailable: () => {
              const { scanner_type_group, scannerType } = entity || {};
              return (
                scanner_type_group === DataCatalogRecordScannerTypeGroup.UNSTRUCTURED &&
                isPreviewAvailable &&
                !previewFileDataSourcesBlacklist.includes(scannerType)
              );
            },
          },
          {
            label: 'Preview',
            id: 'preview',
            data: { component: DataCatalogTablePreview, customProps: entity },
            getIsAvailable: () => {
              const { scanner_type_group, scannerType } = entity || {};
              return (
                scanner_type_group === DataCatalogRecordScannerTypeGroup.STRUCTURED &&
                isPreviewAvailable &&
                !previewTableDataSourcesBlacklist.includes(scannerType)
              );
            },
          },
          {
            label: 'Duplicates',
            id: 'duplicates',
            data: { component: DataCatalogFileDuplicates, customProps: entity },
            getIsAvailable: () => isDuplicatesAvailable,
          },
          {
            label: 'Relations',
            id: 'relations',
            data: { component: DataCatalogRelations, customProps: entity },
            getIsAvailable: () => {
              const { extendedObjectType } = entity || {};
              return (
                DataCatalogObjectType.MODEL === extendedObjectType &&
                getApplicationPreference('AI_SECURITY_AND_GOVERNANCE_ENABLED')
              );
            },
          },
        ],
      },
    }),
    [
      classes.content,
      entity,
      isColumnsAvailable,
      isDuplicatesAvailable,
      isLineageEnabled,
      isPreviewAvailable,
      isSimilarTablesEnabled,
    ],
  );

  useEffect(() => {
    if (id) {
      getDetailsByObjectName(id)
        .then(({ data }) => {
          if (data.fullyQualifiedName) {
            setEntity(data);
            setIsObjectUnavailable(false);
          } else {
            setIsObjectUnavailable(true);
          }
        })
        .catch(({ message }) => {
          console.error(`An error has occurred: ${message}`);
          notificationService.error(`An error has occurred`);
        });
    } else {
      setIsObjectUnavailable(true);
    }

    pageHeaderService.setTitle({
      showBackButton: true,
      breadcrumbs: [
        {
          label: name || id,
        },
      ],
    });
  }, [id, name]);

  return (
    <div className={classes.root}>
      <BigidPaper>
        <div className={classes.container}>
          {isObjectUnavailable ? (
            <DataCatalogEntityPreviewNoResult />
          ) : (
            entity && <BigidTabsAndContent {...tabsAndContentConfig} />
          )}
        </div>
      </BigidPaper>
    </div>
  );
};

export class DataCatalogEntityPreviewComponent extends Component {
  render() {
    const client = getQueryClient();

    const newObjectDetailsEnabled =
      getApplicationPreference('DATA_EXPLORER_NEW_OBJECT_PREVIEW_PAGE_ENABLED') || isDataExplorerEnabled();

    if (newObjectDetailsEnabled) {
      return (
        <QueryClientProvider client={client}>
          <ObjectDetailsPage />
        </QueryClientProvider>
      );
    }
    return <DataCatalogEntityPreview />;
  }
}
