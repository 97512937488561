import React, { FC, memo, useState, useEffect, useMemo, useCallback, useRef, useContext } from 'react';
import {
  BigidFieldRenderProps,
  compareObjectsExceptFunctions,
  BigidLoader,
  BigidSelectOption,
  BigidSelect,
  BigidFormFieldSideLabelWrapper,
  SecondaryButton,
  BigidFormFieldErrorHelper,
} from '@bigid-ui/components';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import makeStyles from '@mui/styles/makeStyles';
import { credentialsService, $uibModal } from '../../../../services/angularServices';
import { notificationService } from '../../../../services/notificationService';
import { CREDENTIALS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted, hasRootScope } from '../../../../services/userPermissionsService';
import { DataSourceConfigurationContext } from '../DataSourceConfigurationContext';
import { DataSourceTemplateCondition } from '../types';
import { CredentialType } from '../../../Credentials/credentialsFormUtils';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { getDataSourceScopes, useScopeOptions } from '../hooks/useScopeOptions';

export enum CredentialTypesEnum {
  CyberArk = 'CyberArk',
  BigID = 'simple',
  HashiCorp = 'HashiCorp',
  Custom = 'Custom',
  Thycotic = 'Thycotic',
}

export interface CredentialItemInterface {
  credential_id: string;
  password: string;
  scopes: string[];
  type: CredentialTypesEnum;
  subType: CredentialType;
  username: string;
  actions: string[];
  apps: string[];
  _id: string;
}

interface UseCredentialOptionsState {
  options: BigidSelectOption[];
  isLoading: boolean;
}

const naiveCache: UseCredentialOptionsState = {
  options: [],
  isLoading: true,
};

const filterOptions = (
  options: CredentialItemInterface[],
  supportedTypes?: DataSourceTemplateCondition[],
  scopeIds?: string[],
) => {
  const data = scopeIds?.length
    ? options.filter(({ scopes }) => scopes.some(scopeId => scopeIds.includes(scopeId)))
    : options;
  if (!getApplicationPreference('FILTER_CREDENTIALS_IN_DS_WIZARD') || !supportedTypes) {
    return data;
  }

  return data.filter((credential: CredentialItemInterface) =>
    supportedTypes?.some(({ value }) => {
      const { type, subType } = value as Record<string, string>;
      return credential.type === type && (!subType || subType === credential.subType);
    }),
  );
};

const mapOptions = (options: CredentialItemInterface[]) =>
  options.map(({ credential_id, type }: CredentialItemInterface) => ({
    id: credential_id,
    value: credential_id,
    label: type === CredentialTypesEnum.BigID ? credential_id : `${credential_id} (${type})`,
  }));

const getCredentialsData = async (supportedTypes?: DataSourceTemplateCondition[], scopeIds?: string[]) => {
  try {
    const { data } = (isPermitted(CREDENTIALS_PERMISSIONS.READ.name) &&
      (await credentialsService.getCredentials())) || { data: [] };
    const filteredOptions = filterOptions(data, supportedTypes, scopeIds);
    const options = mapOptions(filteredOptions);
    naiveCache.options = options;
    naiveCache.isLoading = false;

    return options;
  } catch (error) {
    console.error(error);
    notificationService.error('Error getting the list of credentials');
  }
};

const getSelectedScopeIds = async (dataSourceName?: string, scopeSelectedInForm?: BigidSelectOption[]) => {
  if (!dataSourceName) return [];
  const { scopes, value } = await getDataSourceScopes(dataSourceName, false);
  const selectedScopes = scopeSelectedInForm?.[0]?.label ? scopeSelectedInForm : value;
  return selectedScopes.map(({ value }) => scopes.find(({ name }) => name === value).id).filter(id => !!id);
};

const useCredentialOptions = (
  setValue: BigidFieldRenderProps['setValue'],
  filter?: DataSourceTemplateCondition[],
  id?: string,
  scope?: BigidSelectOption[],
) => {
  const setValueFunctionRef = useRef(setValue);
  setValueFunctionRef.current = setValue;
  const [{ options, isLoading }, setOptions] = useState(naiveCache);
  const updateCredentialList = useCallback(
    async (newId?: string | number) => {
      try {
        const selectedScopesIds: string[] = await getSelectedScopeIds(id, scope);
        const options: BigidSelectOption[] = await getCredentialsData(filter, selectedScopesIds);
        setOptions(current => ({
          ...current,
          options,
        }));
        if (newId) {
          const newValue = options.filter(({ id }) => id === newId);
          setValueFunctionRef.current(newValue?.length ? newValue : '');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setOptions(current => ({
          ...current,
          isLoading: false,
        }));
      }
    },
    [filter, scope?.reduce((acc, { label }) => acc + label, '')],
  );

  useEffect(() => {
    updateCredentialList();
  }, [updateCredentialList]);

  return {
    options,
    isLoading,
    updateCredentialList,
  };
};

const useCredentialUpdate = (credentialId: string | number, updateCredentialList: (newId?: string | number) => void) =>
  useCallback(() => {
    credentialsService.getCredential(credentialId).then(
      (result: any) => {
        const credential = result.data;

        //TODO use react modal
        $uibModal
          .open({
            animation: true,
            template: `<credentials form-only="true" form-data="$ctrl.credential"
                  on-form-closed="$ctrl.onFormClosed()"
                  on-form-submitted="$ctrl.onFormSubmitted(isUpdated, credentialName)"></credentials>`,
            controllerAs: '$ctrl',
            controller: [
              'credential',
              '$uibModalInstance',
              function (this: any, _classification: any, $uibModalInstance: any) {
                this.credential = credential;

                this.onFormSubmitted = (isUpdated: boolean, credentialName: string) => {
                  $uibModalInstance.close({ isUpdated, credentialName });
                };

                this.onFormClosed = () => {
                  $uibModalInstance.close();
                };
              },
            ],
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            resolve: {
              credential: () => {
                return credential;
              },
            },
          })
          .result.then(result => {
            if (typeof result !== 'undefined') {
              updateCredentialList(credentialId);
            }
          });
      },
      (err: any) => {
        notificationService.error('An error has occurred! ' + err?.data?.message);
      },
    );
  }, [credentialId, updateCredentialList]);

const useCredentialCreate = (updateCredentialList: (newId: string) => void) =>
  useCallback(() => {
    $uibModal
      .open({
        animation: true,
        template: `<credentials form-only="true"
                          on-form-closed="$ctrl.onFormClosed()"
                          on-form-submitted="$ctrl.onFormSubmitted(isUpdated, credentialName)"></credentials>`,
        controllerAs: '$ctrl',
        controller: [
          '$uibModalInstance',
          function (this: any, $uibModalInstance: any) {
            this.onFormSubmitted = (isUpdated: boolean, credentialName: string) => {
              $uibModalInstance.close({ isUpdated, credentialName });
            };

            this.onFormClosed = () => {
              $uibModalInstance.close();
            };
          },
        ],
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
      })
      .result.then(result => {
        if (typeof result !== 'undefined') {
          updateCredentialList(result.credentialName);
        }
      });
  }, [updateCredentialList]);

const useStyles = makeStyles({
  editWrapper: {
    width: '36px',
    height: '36px',
    display: 'flex',
    marginLeft: '12px',
    '& > button': {
      padding: 0,
      width: '36px',
      minWidth: '36px',
    },
  },
  errorWrapper: {
    position: 'absolute',
    top: '32px',
    left: '250px',
  },
  selectWrapper: {
    width: '100%',
  },
});

export const FormCredentialField: FC<BigidFieldRenderProps> = memo(
  ({ setValue, name, label = '', value, misc, isRequired, labelWidth, tooltipText, disabled, errorIsShown, error }) => {
    const { isCredentialDeleted, getValuesContainer, id } = useContext(DataSourceConfigurationContext);
    const { editWrapper, errorWrapper, selectWrapper } = useStyles({});
    const { options, isLoading, updateCredentialList } = useCredentialOptions(
      setValue,
      misc?.asyncSource?.filter,
      id,
      getValuesContainer.current?.()?.scope,
    );
    const { valueArray, canEdit } = useMemo(() => {
      const valueId = value?.[0]?.id ?? value;
      if (!valueId) return { valueArray: [], canEdit: false };
      const filteredOptions = options.filter(({ id }) => id === valueId);
      const isFound = !!filteredOptions?.length;
      return {
        valueArray: isFound
          ? filteredOptions
          : [
              {
                id: valueId,
                value: valueId,
                disabled: true,
                label: isCredentialDeleted
                  ? `${valueId} (Credential deleted)`
                  : (!hasRootScope() && `${valueId} (Credential not in scope)`) || '',
              },
            ],
        canEdit: isFound && isPermitted(CREDENTIALS_PERMISSIONS.EDIT.name),
      };
    }, [value, options, isCredentialDeleted]);
    const editCredential = useCredentialUpdate(valueArray?.[0]?.id, updateCredentialList);
    const createCredential = useCredentialCreate(updateCredentialList);

    return isLoading ? (
      <BigidLoader />
    ) : (
      <BigidFormFieldSideLabelWrapper
        id={`bigid-form-field-${name}`}
        name={name}
        label={String(label)}
        isRequired={isRequired}
        isSeparatorAfter={misc?.isSeparatorAfter}
        labelWidth={labelWidth}
        tooltipText={tooltipText}
      >
        <div data-aid="FormCredentialFieldSelect" className={selectWrapper}>
          <BigidSelect
            isDisabled={disabled}
            isSearchable={true}
            value={valueArray}
            placeholder={misc?.placeholder || label}
            onChange={(value: BigidSelectOption[]) => {
              setValue(value);
            }}
            name={name}
            options={options}
            isAddNewInMenu={isPermitted(CREDENTIALS_PERMISSIONS.CREATE.name)}
            onAddNew={createCredential}
          />
        </div>
        {canEdit && (
          <div data-aid="FormCredentialFieldEditWrapper" className={editWrapper}>
            <SecondaryButton
              disabled={disabled}
              size="small"
              onClick={editCredential}
              dataAid={'FormCredentialFieldEdit'}
              startIcon={<EditOutlinedIcon />}
            />
          </div>
        )}
        <div className={errorWrapper} data-aid="FormCredentialFieldErrorWrapper">
          <BigidFormFieldErrorHelper errorMessage={errorIsShown ? (error as string) : undefined} />
        </div>
      </BigidFormFieldSideLabelWrapper>
    );
  },
  compareObjectsExceptFunctions,
);

FormCredentialField.displayName = 'FormCredentialField';
