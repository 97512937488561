import React, { useCallback, useState } from 'react';
import { noop } from 'lodash';
import { DefaultStepProps } from '../../../types/CorrelationSetsTypes';
import { AttributesGrid } from '../../../components/AttributesGrid/AttributesGrid';
import { Loader } from '../../../components/Loader';
import { useCorrelationSetWizardContext } from '../../../contexts/correlationSetContext';
import { useLocalTranslation } from '../../../translations';
import { BigidForm, BigidFormValidateOnTypes, BigidFormValues } from '@bigid-ui/components';
import { getIsValueChanged } from '../../../utils/utils';
import { usePreviewStepFormConfig } from './useDetailsStepFormConfig';

//STEP 2
export const PreviewStep = ({ detailsStepFormControls, activeStepId }: DefaultStepProps) => {
  const { t } = useLocalTranslation('wizard.previewStep');
  const { isTestConnectionLoading, correlationSetFormData } = useCorrelationSetWizardContext();
  const [formState, setFormState] = useState<BigidFormValues>(correlationSetFormData);

  const { fields } = usePreviewStepFormConfig({ formState });

  const handleFormChange = useCallback(
    async (values: BigidFormValues) => {
      const isFormChanged = getIsValueChanged(values, formState);

      if (isFormChanged) {
        setFormState(values as BigidFormValues);
      }
    },
    [formState],
  );

  return (
    <>
      {isTestConnectionLoading && <Loader loaderText={t('loadingText')} />}
      <AttributesGrid
        activeStepId={activeStepId}
        detailsStepFormControls={detailsStepFormControls}
        isEditableGridColumns={true}
      />
      <BigidForm
        key={correlationSetFormData.name}
        stateAndHandlersRef={detailsStepFormControls}
        controlButtons={false}
        fields={fields}
        onChange={handleFormChange}
        initialValues={correlationSetFormData}
        validateOn={BigidFormValidateOnTypes.SUBMIT}
        renderForm={noop}
      />
    </>
  );
};
