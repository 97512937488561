import { useCallback, useMemo } from 'react';
import {
  AdvancedToolbarOverrideValue,
  BigidFilter,
  advancedToolbarFilterMinifier,
  useHashQueryParams,
} from '@bigid-ui/components';

import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';

export enum RiskFilterIds {
  ASSETS = 'asset.id',
  LEGAL_ENTITIES = 'legalEntities.id',
  VENDORS = 'vendors.id',
  SOURCE = 'source.id',
  SOURCE_TYPE = 'sourceType',
  ASSIGNEE = 'assignee',
}

const allowedQueryFilterIds = [RiskFilterIds.ASSETS, RiskFilterIds.LEGAL_ENTITIES, RiskFilterIds.VENDORS];

export function useRiskQueryFilter() {
  const { filter: queryFilterString = '' } = useHashQueryParams();

  const queryFilters: AdvancedToolbarOverrideValue[] = useMemo(() => {
    try {
      if (!queryFilterString) return [];
      const minifiedOverrideValues = JSON.parse(decodeURI(queryFilterString)) || [];
      const unminifiedOverrideValues = minifiedOverrideValues.map(
        advancedToolbarFilterMinifier.getOverrideValueUnminified,
      );

      return unminifiedOverrideValues;
    } catch (err) {
      console.error('Error parsing queryFilterString', err);
      return [];
    }
  }, [queryFilterString]);

  const riskQueryFilter = useMemo(() => {
    if (!queryFilters?.length) {
      return;
    }
    const filters = allowedQueryFilterIds.reduce((acc, field) => {
      const queryFilter = queryFilters.find(f => f.id === field);
      if (!queryFilter) {
        return acc;
      }
      const queryFilterOptionValues = Array.isArray(queryFilter?.options) ? queryFilter.options.map(o => o.value) : [];

      const filter = {
        field,
        value: queryFilterOptionValues,
        operator: 'in',
      };
      return [...acc, filter];
    }, []);

    return filters as BigidFilter;
  }, [queryFilters]);

  const resetQueryFilter = useCallback(async () => {
    $state.go(CONFIG.states.RISK_REGISTER, { ...$state.params, filter: null }, { location: 'replace' });
  }, []);

  return {
    riskQueryFilter,
    resetQueryFilter,
  };
}
