import React from 'react';
import { DataCatalogObjectDetails } from '../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { styled } from '@mui/material';
import { Relations } from '../../SearchGrid/GridComponents/sidePanels/CatalogSidePanel/relations/Relations';

export const Root = styled('div')`
  display: flex;
  flex-grow: 1;
  padding: 19px;
`;

type RelationsTabProps = {
  objectDetails: DataCatalogObjectDetails;
};

export const RelationsTab = ({ objectDetails }: RelationsTabProps) => {
  const { scannerType, dataSourceName, fullyQualifiedName } = objectDetails;
  return (
    <Root>
      <Relations id={fullyQualifiedName} isExtendedView datasource={{ scannerType, value: dataSourceName }} />
    </Root>
  );
};
