import { useMemo } from 'react';
import {
  BigidFormField,
  BigidFormFieldLabelPosition,
  BigidFormFieldTypes,
  BigidFormValidateOnTypes,
  BigidFormValues,
} from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../../../translations';
import { WizardFormFields } from '../../../types/CorrelationSetsTypes';
import { hasDuplicates } from '../../../utils/utils';
import { UniqueId } from './UniqueId';
import { CompositeIdentifier } from './CompositeIdentifier';
import { useColumnsList } from '../../../hooks/useColumnsList';
import { notificationService } from '../../../../../services/notificationService';
import { requiredFieldValidator } from '../DetailsStep/validationUtils';
import { isEmpty } from 'lodash';

interface UseDetailsStepFormConfigProps {
  formState: BigidFormValues;
}

export const useTrainingStepFormConfig = ({ formState }: UseDetailsStepFormConfigProps) => {
  const { t } = useLocalTranslation('wizard.trainingStep');
  const { optionsList } = useColumnsList();

  const { fields } = useMemo(() => {
    const fields: BigidFormField[] = [
      {
        name: WizardFormFields.isAddCompositeIdentifiers,
        label: t('addCompositeIdentifiers'),
        type: BigidFormFieldTypes.CHECKBOX,
        labelPosition: BigidFormFieldLabelPosition.left,
        tooltipText: t('compositeTooltip'),
        fieldProps: {
          dataAid: `${generateDataAid('checkbox', ['is-use-sample'])}`,
          tooltipText: t('addCompositeIdentifiers'),
        },
      },
      {
        name: WizardFormFields.composite_attributes,
        label: t('compositeAttributes'),
        render: CompositeIdentifier,
        validate: values => {
          const isAddCompositeIdentifiers = formState[WizardFormFields.isAddCompositeIdentifiers];
          if (!isAddCompositeIdentifiers) {
            return false;
          }

          const isCountValid = values?.every((compositeValueField: Record<string, any>) => {
            if (isEmpty(compositeValueField.value)) {
              return true;
            }

            return compositeValueField.value.length > 1 && compositeValueField.value.length < 5;
          });

          if (!isCountValid) {
            return t('compositeValidationMessage');
          }
          const compositeValues = values?.map((compositeValueField: Record<string, any>) => compositeValueField.value);
          const hasDuplicateArrays = hasDuplicates(compositeValues);

          if (hasDuplicateArrays) {
            notificationService.error(t('compositeAlreadyExist'));
            return t('compositeAlreadyExist');
          }

          return false;
        },
        validateOn: BigidFormValidateOnTypes.CHANGE,
      },
      {
        name: WizardFormFields.enabled,
        type: BigidFormFieldTypes.SWITCH,
        labelPosition: BigidFormFieldLabelPosition.left,
        fieldProps: {
          label: t('enabled'),
        },
      },
      {
        name: WizardFormFields.scanConnectionTimeoutInSeconds,
        label: t('scanConnectionTimeoutInSeconds'),
        type: BigidFormFieldTypes.NUMBER,
        labelPosition: BigidFormFieldLabelPosition.top,
        tooltipText: t('scanConnectionTimeoutInSecondsTooltip'),
        fieldProps: {
          min: 10,
          max: 7200,
          placeholder: t('scanConnectionTimeoutInSecondsPlaceholder'),
        },
        validate: scanConnectionTime => {
          const scanConnectionTimeInt = parseInt(scanConnectionTime);
          if (isNaN(scanConnectionTimeInt) || (scanConnectionTimeInt >= 10 && scanConnectionTimeInt <= 7200)) {
            return false;
          }

          return t('scanConnectionTimeoutInSecondsError');
        },
      },
      {
        name: WizardFormFields.unique_id,
        label: t('uniqId'),
        render: UniqueId,
        isRequired: true,
        validate: values => {
          const isRequiredValid = requiredFieldValidator(values);
          if (isRequiredValid) {
            return isRequiredValid;
          }
          return false;
        },
      },
      {
        name: WizardFormFields.display_name,
        type: BigidFormFieldTypes.SELECT,
        label: t('displayName'),
        dropDownOptions: optionsList,
        options: optionsList,
        tooltipText: t('displayNameTooltip'),
        fieldProps: {
          menuPlacement: 'top',
        },
        misc: {
          placeholder: t('displayNamePlaceholder'),
        },
      },
      {
        name: WizardFormFields.residency,
        type: BigidFormFieldTypes.SELECT,
        label: t('residency'),
        dropDownOptions: optionsList,
        options: optionsList,
        tooltipText: t('residencyTooltip'),
        fieldProps: {
          placeholder: t('residency'),
        },
        misc: {
          placeholder: t('residencyPlaceholder'),
        },
      },
      {
        name: WizardFormFields.attributes,
      },
      {
        name: WizardFormFields.attributesTrainingStep,
      },
      {
        name: WizardFormFields.custom_query_string,
      },
    ];

    return {
      fields,
    };
  }, [t, formState]);

  return {
    fields,
  };
};
