import { BigidDialog, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import React, { FC } from 'react';
import { GlossaryItemForm } from '../GlossaryItemForm/GlossaryItemForm';
import { useGlossaryItemForm } from '../GlossaryItemForm/useGlossaryItemForm';
import { useLocalTranslation } from '../translations';

export interface AddGlossaryItemDialogProps {
  isOpen: boolean;
  onSave: () => void;
  onClose: () => void;
}

export const AddGlossaryItemDialog: FC<AddGlossaryItemDialogProps> = ({ isOpen, onSave, onClose }) => {
  const { isSubmitting, handleSubmit, handleClose, formControlsRef } = useGlossaryItemForm({
    onSubmit: onSave,
    onClose,
  });
  const { t } = useLocalTranslation('BusinessGlossary.addGlossaryItemDialog');

  return (
    <BigidDialog
      title={t('title')}
      fixedHeight={600}
      isOpen={isOpen}
      onClose={handleClose}
      borderBottom
      buttons={[
        { component: SecondaryButton, onClick: onClose, text: t('cancelButton') },
        { component: PrimaryButton, onClick: handleSubmit, text: t('addButton') },
      ]}
      isLoading={isSubmitting}
      muiOverrides={{ disableEnforceFocus: true }}
    >
      <GlossaryItemForm formControlsRef={formControlsRef} />
    </BigidDialog>
  );
};
