import React, { ElementType, PropsWithChildren, forwardRef, useLayoutEffect, useRef, useState } from 'react';
import { useBoundingClientRect } from './hooks/useBoundingClientRect';
import styled from '@emotion/styled';

type DataSourceConnectionResizableFieldProps = PropsWithChildren & {
  as: ElementType;
  type: string;
  disableSizeCheck?: boolean;
  threshold?: number;
};

// @info the minimum height of a VerticalObject field without content
const ELEMENT_HEIGHT_MAX_THRESHOLD = 70;
const ELEMENT_HEIGHT_MIN_THRESHOLD = 0;

const Field = styled.div``;

export const DataSourceConnectionResizableField = forwardRef<HTMLDivElement, DataSourceConnectionResizableFieldProps>(
  (
    { as: Component, type, children, disableSizeCheck = true, threshold = ELEMENT_HEIGHT_MAX_THRESHOLD, ...rest },
    _ref,
  ): JSX.Element => {
    const ref = useRef<HTMLDivElement>();
    const [isHidden, setHidden] = useState(false);
    const [{ height }, el] = useBoundingClientRect(ref);

    useLayoutEffect(() => {
      !disableSizeCheck && height !== ELEMENT_HEIGHT_MIN_THRESHOLD && setHidden(height <= threshold);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [height]);

    return disableSizeCheck ? (
      <Field {...rest} as={Component}>
        {children}
      </Field>
    ) : (
      <div
        className={`DataSourceConnectionResizableField-${type}`}
        ref={el}
        style={isHidden ? { zIndex: -10, position: 'absolute' } : {}}
      >
        <Field {...rest} as={Component}>
          {children}
        </Field>
      </div>
    );
  },
);
