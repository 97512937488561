import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { httpService } from '../../../services/httpService';
import {
  Action,
  Category,
  ActionTypes,
  CLASSIFIER_TYPES,
  ClassifierGridRow,
  NerClassifier,
  RegulationsData,
  DuplicateClassifierRequestBody,
} from '../types/ClassifierTypes';
import { createAction } from '../actions/classifierManagementAction';
import { AssignCategoryRequest, CreateCategoryResponse } from '../types/AssignCategories';
import { getFixedT } from '../translations';
import { notificationService } from '../../../services/notificationService';
import { CreateCategory } from '../../../components/AssignCategoryDialog/types';
import { queryService } from '../../../services/queryService';
import { ClassifierVersionState } from '../../../services/classifiersService';
import { Attributes } from '../types/Attributes';
import { AxiosResponse } from 'axios';
import { omit } from 'lodash';
import { isClassifierEnabled } from '../utils/utils';

export const fetchClassifiers = async (
  queryComponents: BigidGridQueryComponents,
  dispatch: (action: Action) => void,
) => {
  dispatch(createAction(ActionTypes.setFilterQuery, queryComponents));
  const gridConfigQuery = queryService.getGridConfigQuery({
    ...queryComponents,
    filter: queryComponents.filter,
    sort: queryComponents.sort,
  });

  const { data } = (await httpService.fetch(`all-classifications?${gridConfigQuery}`)).data;
  const classifiers = (data.classifications as ClassifierGridRow[])?.map(classifier => ({
    ...classifier,
    enabled: isClassifierEnabled(classifier),
  }));

  return {
    data: classifiers,
    totalCount: data.totalCount,
  };
};

export const getClassifierByName = (classifierName: string) =>
  httpService.fetch(`classifications/${encodeURIComponent(classifierName)}`);

export const getClassifierExcludeListByName = (classifierName: string, type: string) => {
  return httpService.fetch(`classifications/exclude-list/${encodeURIComponent(classifierName)}?type=${type}`);
};

export const upsertExcludeList = (payload: any) => {
  return httpService.post('classifications/exclude-list/', payload);
};

export const deleteManyExcludeValues = (payload: any) => {
  return httpService.post('classifications/exclude-list/delete-many', payload);
};

export const deleteCustomNerClassifier = (id: string) => {
  return httpService.delete(`classifier-algorithms/ner/${encodeURIComponent(id)}`);
};

export const deleteClassifier = (id: string) => {
  return httpService.delete(`classifications/${encodeURIComponent(id)}`);
};

export const updateClassifier = (classifierName: string, payload: any) => {
  // we need to remove "classification_name" from the payload to support old classifiers (that were created before adding validation to BE)
  return httpService.put(`classifications/${encodeURIComponent(classifierName)}`, omit(payload, 'classification_name'));
};

export const createClassifier = async (data: ClassifierGridRow) => await httpService.post(`classifications`, data);

export const duplicateClassifierRequest = async (data: DuplicateClassifierRequestBody) =>
  await httpService.post(`all-classifications/duplicate`, data);

export const getChecksumValidations = async () => await httpService.fetch(`/classifications/supported_checksums`);

export const getClassifierChecksumValidationByName = async (checksumName: string) =>
  await httpService.fetch(`/checksum-validation/${checksumName}`);

export const getAdvancedClassifiers = async () => await httpService.fetch('/classifier-algorithms?base_ner');

export const createNerClassifier = async (data: Partial<NerClassifier>[]) =>
  await httpService.post(`classifier-algorithms/ner`, data);

export const updateNerOrDocClassifier = (classifierType: CLASSIFIER_TYPES.NER | CLASSIFIER_TYPES.DOC, payload: any) => {
  return httpService.put(`classifier-algorithms/${classifierType}`, payload);
};

export const getCategoriesDetails = () => httpService.fetch<Category[]>(`data_categories`);

export const importClassifiers = (file: File, isImportExcludeList: boolean) =>
  httpService.multipart('post', `classifications/import`, {
    files: { file },
    data: { isImportExcludeList },
  });

export const exportBulkClassifiers = (filter: any) =>
  httpService.downloadFilePost(`classifications/file-download/export`, { query: filter });

export const assignCategoryToAttributeRequest = async (payload: AssignCategoryRequest[]) => {
  const t = getFixedT('AssignCategoryDialog');

  try {
    const { data } = await httpService.post(`attributes`, payload);
    return data;
  } catch (error) {
    console.error(`An error has occurred: ${error.message}`);
    notificationService.error(t('errors.categoryAssignmentFailed'));
  }
};

export const createCategoryRequest = async ({
  color,
  name,
  description,
}: CreateCategory): Promise<CreateCategoryResponse> | null => {
  const t = getFixedT('AssignCategoryDialog');

  try {
    const { data } = await httpService.post<CreateCategoryResponse, Partial<Category>>(`data_categories`, {
      color,
      description,
      display_name: name.trim(),
      unique_name: name.trim(),
    });

    return data;
  } catch ({ response }) {
    if (response.status === 409) {
      notificationService.error(response.data);
    } else {
      notificationService.error(t('errors.categoryCreationFailed'));
    }

    return null;
  }
};

export const updateEnableBulkClassification = (body: Record<string, any>) => {
  return httpService.post('classifications-bulk-enabled', body);
};

export const getAppDetails = (appName: string) => httpService.fetch(`tpa/${appName}`);

export const fetchAttributes = async (): Promise<AxiosResponse<Attributes[], any>> => {
  const response = await httpService.fetch(`attributes`);
  const sortedResponse = response?.data.sort((a: Attributes, b: Attributes) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  });

  return {
    ...response,
    data: sortedResponse,
  };
};

export const fetchClassifiersToUpdate = async () => {
  try {
    const query = queryService.getGridConfigQuery({
      limit: 5000,
      skip: 0,
      requireTotalCount: true,
      filter: [{ field: 'version_state', operator: 'in', value: [ClassifierVersionState.NEEDS_UPDATE] }],
    });
    const response = (await httpService.fetch(`all-classifications?${query}`))?.data;

    return response?.data;
  } catch (e) {
    console.log('FetchClassifiersToUpdate error: ', e);
  }
};

export const fetchRegulations = async (
  gridConfigQuery: string,
): Promise<{ regulations: RegulationsData[]; totalCount: number }> => {
  try {
    const {
      data: { data },
    } = await httpService.fetch<{ data: { regulations: RegulationsData[]; totalCount: number } }>(
      `regulations-aggregation?${gridConfigQuery}`,
    );

    return data;
  } catch (e) {
    notificationService.error('Failed to fetch regulations, see logs for more information.');
    return { regulations: [], totalCount: 0 };
  }
};

export const getClassifierById = async (id: string) => {
  try {
    const response = (await httpService.fetch(`all-classifications/${id}`))?.data;
    return {
      ...(response?.data || {}),
      enabled: isClassifierEnabled(response?.data),
    };
  } catch (e) {
    console.log('getClassifierById error: ', e);
  }
};

export const updateIdConnection = async (connectionName: string, payload: Record<string, any>) => {
  const response = (await httpService.put(`id_connections/${connectionName}`, payload))?.data;
  return response;
};
