import React, { useEffect, useMemo, useRef, useState } from 'react';
import { dataSourceConnectionsService } from '../../../services/angularServices';
import {
  BigidFormStateAndHandlers,
  BigidWizardContainer,
  BigidWizardHorizontalNavStepData,
  EntityEvents,
  entityEventsEmitter,
  TertiaryButton,
  useHorizontalWizard,
} from '@bigid-ui/components';
import { BigidSaveIcon } from '@bigid-ui/icons';
import { getFixedT, useLocalTranslation } from '../translations';
import { DetailsStep } from './Steps/DetailsStep/DetailsStep';
import { PreviewStep } from './Steps/PreviewStep/PreviewStep';
import { TrainingStep } from './Steps/TrainingStep/TrainingStep';
import { ResultsStep } from './Steps/ResultsStep/ResultsStep';
import {
  CorrelationSetWizardContext,
  DEFAULT_CORRELATION_SET_FORM_DATA,
  DEFAULT_CORRELATION_SET_GUIDE_STATE,
} from '../contexts/correlationSetContext';
import {
  CorrelationSet,
  CorrelationSetFormData,
  CorrelationSetsWizardSteps,
  DataSourceType,
  GuideState,
  IdConnectionState,
} from '../types/CorrelationSetsTypes';
import { DetailsGuideContent } from './Steps/DetailsStep/DetailsGuideContent';
import { withGuideRightComponent } from '../components/CorrelationSetStepsWrapper';
import {
  CORRELATION_SET_CONNECTIONS_GRID_ID,
  createScanProfileName,
  transformCorrelationSetFormStateToPayload,
  transformCorrelationSetResponseToFormState,
  getActiveStepId,
  checkIfNextStepDisabled,
  getStepsWithStatus,
  getStepStatus,
} from '../utils/utils';
import {
  createNewCorrelationSet,
  createScanProfile,
  fetchCorrelationSetByName,
  getScanProfilesList,
  triggerScan,
  updateNewCorrelationSet,
} from '../services/correlationSetsService';
import { openTestConnectionConfirmationDialog } from '../components/Dialogs/TestConnectionConfirmationDialog';
import { getDsConnectionsQuery } from '../../../utilities/dataSourcesUtils';
import styled from '@emotion/styled';
import { TrainingGuideContent } from './Steps/TrainingStep/TrainingGuideContent';
import { PreviewGuideContent } from './Steps/PreviewStep/PreviewGuideContent';
import { isEqual } from 'lodash';
import { notificationService } from '../../../services/notificationService';
import { useTestConnection } from './Steps/useTestConnection';
import { ResultGuideContent } from './Steps/ResultsStep/ResultsGuideContent';

const Container = styled('div')`
  height: 100%;

  & [data-aid='BigidWizardHorizontalNav'] {
    padding-top: 24px;
  }

  & [data-aid='BigidWizardContainerHeader'] {
    display: none;
  }

  & [data-aid='correlationSetsWizard'] [data-aid='BigidPaper'] {
    padding: 0;
    border: none;
    background: transparent;
    box-shadow: none;
    position: relative;
    padding-bottom: 72px; // reserved space for the buttons container
  }

  & [data-aid='BigidWizardContainerButtons'] {
    position: absolute;
    bottom: 0;
    padding: 16px;
  }
`;

const fixedT = getFixedT('wizard');

export const correlationSetWizardSteps: BigidWizardHorizontalNavStepData[] = [
  {
    id: CorrelationSetsWizardSteps.DETAILS,
    title: fixedT('titleStep1'),
    component: withGuideRightComponent(DetailsStep),
  },
  {
    id: CorrelationSetsWizardSteps.PREVIEW,
    title: fixedT('titleStep2'),
    component: withGuideRightComponent(PreviewStep),
  },
  {
    id: CorrelationSetsWizardSteps.TRAINING,
    title: fixedT('titleStep3'),
    component: withGuideRightComponent(TrainingStep),
  },
  {
    id: CorrelationSetsWizardSteps.RESULTS,
    title: fixedT('titleStep4'),
    component: withGuideRightComponent(ResultsStep),
  },
];

export const guideStepsContent = {
  [CorrelationSetsWizardSteps.DETAILS]: {
    title: 'Correlation set details',
    contentComponent: DetailsGuideContent,
  },
  [CorrelationSetsWizardSteps.PREVIEW]: {
    title: 'Review and adjust the Analyzing set',
    contentComponent: PreviewGuideContent,
  },
  [CorrelationSetsWizardSteps.TRAINING]: {
    title: 'Set a Unique ID',
    contentComponent: TrainingGuideContent,
  },
  [CorrelationSetsWizardSteps.RESULTS]: {
    title: 'Correlation set details',
    contentComponent: ResultGuideContent,
  },
};

type ResolveType = (value: PromiseLike<boolean> | boolean) => void;

interface CorrelationSetWizardProps {
  correlationSetName?: string;
}

export const CorrelationSetWizard = ({ correlationSetName }: CorrelationSetWizardProps) => {
  const { t } = useLocalTranslation('wizard');
  const { t: notificationsT } = useLocalTranslation('notifications');

  const detailsStepFormControls = useRef<BigidFormStateAndHandlers>();

  const [isLoading, setIsLoading] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [correlationName, setCorrelationName] = useState<string>(correlationSetName);
  const [guideState, setCorrelationGuideState] = useState<GuideState>(DEFAULT_CORRELATION_SET_GUIDE_STATE);
  const [initialActiveStepId, setInitialActiveStepId] = useState(getActiveStepId({} as CorrelationSet));
  const [correlationSetFormData, setCorrelationSetFormData] = useState<CorrelationSetFormData>(
    DEFAULT_CORRELATION_SET_FORM_DATA,
  );
  const initialSteps = useMemo(
    () => getStepsWithStatus(initialActiveStepId, correlationSetWizardSteps),
    [initialActiveStepId],
  );
  const { steps, updateStepAttributes, ActiveView, activeStepId, goToNext, goToPrev, noPrev, noNext, getPrevStep } =
    useHorizontalWizard(initialSteps, initialActiveStepId);
  const { handleTestConnection, isLoading: isTestConnectionLoading } = useTestConnection({
    guideState,
    detailsStepFormControls,
    setCorrelationGuideState,
    setCorrelationSetFormData,
  });
  const isEdit = !!correlationName;

  const fetchCorrelationSetData = async (name: string) => {
    const response = await fetchCorrelationSetByName(name);

    if (response && response.id_connection) {
      const dsConnection = response.id_connection.dsConnection;
      let dsConnectionType = response.id_connection.dsConnectionType;

      if (!dsConnectionType && dsConnection) {
        const selectedDsQuery = getDsConnectionsQuery({
          operator: 'equal',
          searchString: dsConnection,
        });

        const {
          data: { ds_connections },
        } = await dataSourceConnectionsService.getDSConnectionDataByQuery(selectedDsQuery);

        dsConnectionType = (ds_connections as DataSourceType[]).find(ds => ds.name === dsConnection)?.type;
      }

      if (response.id_connection.type === 'ds-connection') {
        setInitialActiveStepId(getActiveStepId(response?.id_connection));
        setCorrelationSetFormData(
          transformCorrelationSetResponseToFormState(response?.id_connection, dsConnectionType),
        );
      }
    }
  };

  const handleTriggerScan = async (correlationSetName: string) => {
    try {
      const scanProfilesList = await getScanProfilesList();
      const scanProfileName = createScanProfileName(correlationSetName);

      const isProfileExist = scanProfilesList?.some(profile => profile.name === scanProfileName);
      if (!isProfileExist) {
        await createScanProfile(correlationSetName);
      }

      return triggerScan(correlationSetName);
    } catch (e) {
      notificationService.error(notificationsT('analyzingFailed'));
    }
  };

  const handleGoNext = async (cb: (formData: CorrelationSetFormData, resolve: ResolveType) => void) => {
    return new Promise<boolean>(resolve => {
      detailsStepFormControls.current?.validateAndSubmit(async formData => {
        return cb(formData as CorrelationSetFormData, resolve);
      });
      () => resolve(false);
    });
  };

  const handleGoNextDetailsStep = async (formData: CorrelationSetFormData, resolve: ResolveType) => {
    setCorrelationSetFormData(formData as CorrelationSetFormData);

    let response = null;
    const hasAttributes = formData?.attributes?.length > 0;

    // is already connected to DS
    if (hasAttributes) {
      //show confirmation dialog
      const { isGoNext, isReConnect } = await openTestConnectionConfirmationDialog();

      if (isGoNext) return resolve(true);
      if (isReConnect) {
        response = await handleTestConnection(formData as CorrelationSetFormData, CorrelationSetsWizardSteps.PREVIEW);
      }
    } else {
      response = await handleTestConnection(formData as CorrelationSetFormData, CorrelationSetsWizardSteps.PREVIEW);
    }

    if (response?.columns?.length > 0) {
      resolve(true);
    }

    return false;
  };

  const handleGoNextPreviewStep = async (formData: CorrelationSetFormData, resolve: ResolveType) => {
    const oldAttributes = correlationSetFormData.attributes;
    const newAttributes = formData.attributes;
    const isAttributesEqual = isEqual(oldAttributes, newAttributes);

    setCorrelationSetFormData(formData as CorrelationSetFormData);

    if (isAttributesEqual && formData.attributesTrainingStep?.length > 0) return resolve(true);

    // means that user has hanged attributes array
    if (!isAttributesEqual || !formData.attributesTrainingStep?.length) {
      const response = await handleTestConnection(
        formData as CorrelationSetFormData,
        CorrelationSetsWizardSteps.TRAINING,
        true,
      );

      if (response?.columns?.length > 0) {
        return resolve(true);
      }
    }

    return false;
  };

  const handleGoNextTrainingStep = async (formData: CorrelationSetFormData, resolve: ResolveType) => {
    setIsLoading(true);
    setCorrelationSetFormData({ ...formData, state: IdConnectionState.ANALYZING } as CorrelationSetFormData);

    await handleSaveDraft();
    const data = await handleTriggerScan(formData.name);

    // can be removed when SSE mechanism will be implemented
    setCorrelationSetFormData({ ...correlationSetFormData, state: IdConnectionState.ANALYZING });

    if (data.result.state === 'Started') {
      resolve(true);
    }

    setIsLoading(false);
  };

  const validateStep = async (activeStepId: CorrelationSetsWizardSteps): Promise<boolean> => {
    switch (activeStepId) {
      case CorrelationSetsWizardSteps.DETAILS:
        return handleGoNext(handleGoNextDetailsStep);

      case CorrelationSetsWizardSteps.PREVIEW:
        return handleGoNext(handleGoNextPreviewStep);

      case CorrelationSetsWizardSteps.TRAINING:
        return handleGoNext(handleGoNextTrainingStep);
      default:
        return true;
    }
  };

  const handleGoToNext = async () => {
    const isCurrentStepDataValid = await validateStep(activeStepId as CorrelationSetsWizardSteps);

    if (isCurrentStepDataValid) {
      if (noNext) {
        // TODO: implement behavior for last step save button;
        console.log('SAVE clicked');
      } else {
        updateStepAttributes([
          { stepId: activeStepId, attr: { status: getStepStatus(activeStepId as CorrelationSetsWizardSteps, true) } },
        ]);
        goToNext();
      }
    }
  };

  const handleGoToPrev = async () => {
    const isAnalyzingInProgress =
      correlationSetFormData.state === IdConnectionState.ANALYZING &&
      activeStepId === CorrelationSetsWizardSteps.RESULTS;

    if (isAnalyzingInProgress) {
      return false;
    }

    await detailsStepFormControls.current.validateAndSubmit(async formData => {
      setCorrelationSetFormData(formData as CorrelationSetFormData);
      goToPrev();

      updateStepAttributes([
        {
          stepId: activeStepId,
          attr: { status: getStepStatus(activeStepId as CorrelationSetsWizardSteps, false, true) },
        },
        { stepId: getPrevStep(), attr: { status: getStepStatus(activeStepId as CorrelationSetsWizardSteps) } },
      ]);
    });
  };

  const handleSaveDraft = async () => {
    await detailsStepFormControls.current.validateAndSubmit(async formData => {
      try {
        const correlationSetPayload = transformCorrelationSetFormStateToPayload({
          formState: formData as CorrelationSetFormData,
          idConnectionState: IdConnectionState.DRAFT,
        });

        let response;
        if (isEdit) {
          response = await updateNewCorrelationSet(correlationName, correlationSetPayload);
        } else {
          response = await createNewCorrelationSet(correlationSetPayload);
        }

        if ((isEdit && response?.result === 'OK') || (response?.name && response?.id)) {
          setCorrelationName(formData?.name);
          entityEventsEmitter.emit(EntityEvents.RELOAD, CORRELATION_SET_CONNECTIONS_GRID_ID);
          notificationService.success(notificationsT('correlationSavedSuccessfully'));
        }
      } catch (e) {
        let errorMessage = e?.data?.message ?? e.response.data.message;
        if (e?.response?.data?.message?.includes('11000')) {
          errorMessage = notificationsT('nameExistError');
        }

        console.error(`An error has occurred: ${e.message}`);
        notificationService.error(errorMessage);
      }
    });
  };

  useEffect(() => {
    correlationSetName && fetchCorrelationSetData(correlationSetName);
  }, [correlationSetName]);

  useEffect(() => {
    setIsNextDisabled(checkIfNextStepDisabled(activeStepId, correlationSetFormData));
  }, [activeStepId]);

  const isSaveAsDraftButtonDisabled = correlationSetFormData.state === IdConnectionState.ANALYZING;

  return (
    <Container>
      <CorrelationSetWizardContext.Provider
        value={{
          correlationSetFormData,
          setCorrelationSetFormData,
          guideState,
          setCorrelationGuideState,
          isTestConnectionLoading,
          isLoading,
        }}
      >
        <BigidWizardContainer
          steps={steps}
          activeStepId={activeStepId}
          ActiveView={ActiveView}
          isLoading={false}
          onNext={handleGoToNext}
          onPrev={handleGoToPrev}
          noNext={noNext}
          noPrev={noPrev}
          stepsProps={{ activeStepId, detailsStepFormControls, id: correlationSetName }}
          isNextDisabled={isNextDisabled}
          lastStepText={'Save'}
          title=""
          disableStepClick
          dataAid="correlationSetsWizard"
          customButton={
            <TertiaryButton
              size="large"
              startIcon={<BigidSaveIcon />}
              onClick={handleSaveDraft}
              dataAid="save-draft-button"
              disabled={isSaveAsDraftButtonDisabled}
              text={t('saveDratBtn')}
            />
          }
        />
      </CorrelationSetWizardContext.Provider>
    </Container>
  );
};
