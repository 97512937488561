import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { generateDataAid } from '@bigid-ui/utils';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { $state, $stateParams } from '../../../services/angularServices';
import { SubScansGrid } from './SubscanGrid';
import { fetchScanInsightsData } from './ScanInsightService';
import { DatasourceScanInsightsInfo, ScanProfileInfo, SubScansInfo } from './ScanInsightTypes';
import { ScanProfileInfoComponent } from './ScanProfileInfo/ScanProfileInfoComponent';
import { ScanInsightsInfoComponent } from './ScanInsightsInfo/ScanInsightsInfoComponent';
import {
  BigidLoader,
  BigidPaper,
  BigidStatusBadge,
  BigidStatusBadgeSize,
  BigidStatusBadgeType,
} from '@bigid-ui/components';
import { REVIEW_FINDINGS_PERMISSIONS } from '@bigid/permissions';
import { SubScanInfo } from './SubScanGridInfo';
import { notificationService } from '../../../services/notificationService';
import { ActiveScanState, CompletedParentScanState, ParentScanType } from '../ScanTypes';
import { CONFIG } from '../../../../config/common';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { isPermitted } from '../../../services/userPermissionsService';
import { ScanOverview } from './ScanInsightsInfo/ScanOverview/ScanOverview';
import { isScanInsightsAvailable } from '../ScanUtils';
import { startCase } from 'lodash';
import { sessionStorageService } from '../../.../../../../common/services/sessionStorageService';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'scroll',
    gap: '12px',
    paddingBottom: '24px', // added to make sure SubScanGrid's last row will display the tooltip correctly
    ...(isScanInsightsAvailable() ? { height: '100%' } : {}),
  },
  bigidPaperContainer: {
    flex: '1 1 auto',
    border: '1px solid rgb(230 230 230)',
    height: 'calc(100vh - 220px)',
    minHeight: '750px',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    flexFlow: 'column',
    boxShadow: '0 1px 12px 0 rgb(0 0 0 / 5%)',
  },
  statusChipBox: {
    marginLeft: '16px',
  },
});

export const ScanInsights: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [scanProfileData, setScanProfileData] = useState<ScanProfileInfo>();
  const [scanInsightsData, setScanInsightsData] =
    useState<Omit<DatasourceScanInsightsInfo, 'scanId' | 'scanProfileName'>>();
  const [subScansData, setSubScansData] = useState<SubScansInfo>();
  const { content, bigidPaperContainer, statusChipBox } = useStyles({});

  const status = scanProfileData?.progress?.state;

  const statusChip = status === ActiveScanState.IN_PROGRESS && (
    <div className={statusChipBox}>
      <BigidStatusBadge
        label={startCase(status)}
        type={BigidStatusBadgeType.INFO}
        size={BigidStatusBadgeSize.MEDIUM}
        dataAid={generateDataAid('ScanInsights', ['in-progress-badge'])}
      />
    </div>
  );

  const getScanInsightData = async (id: string) => {
    try {
      setIsLoading(true);

      const { scanProfile, dataSources = null, subScans } = await fetchScanInsightsData(id);
      const shouldShowAttributes =
        scanProfile.type !== ParentScanType.METADATA_SCAN && scanProfile.type !== ParentScanType.LABELING;
      const { updatedAt } = scanProfile;

      const state = scanProfile?.progress?.state;
      const isScanComplete = Object.values(CompletedParentScanState).includes(state as CompletedParentScanState);

      setScanProfileData(scanProfile);
      setScanInsightsData({
        ...dataSources,
        shouldShowAttributes,
        isScanComplete,
        updatedAt,
        isReviewFindingsEnabled:
          getApplicationPreference('CLASSIFIER_TUNING_ENABLED') &&
          isPermitted(REVIEW_FINDINGS_PERMISSIONS.ACCESS.name) &&
          scanProfile.isReviewFindingsEnabled,
      });
      setSubScansData(subScans);
    } catch (error) {
      notificationService.error('Failed to load scan insight data. See logs for more info');
      console.error('error getting scan insight data', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getScanInsightData($stateParams.scanId);
  }, []);

  pageHeaderService.setTitle({
    showBackButton: false,
    breadcrumbs: [
      {
        label: 'Scans',
        onClick: () => {
          const history: { to: Record<string, any>; from: Record<string, any> }[] =
            sessionStorageService.get('history') || [];
          const lastRoute = history[history.length - 1]?.from?.state;

          if (
            lastRoute === CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS ||
            lastRoute === CONFIG.states.SCANS_NEW_SCANS_COMPLETED
          ) {
            return window.history.back();
          } else {
            $state.go(CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS);
          }
        },
      },
      {
        label: $stateParams.scanProfileName,
        dataAid: 'scanPageLink',
      },
    ],
    titleHelperComponent: statusChip,
  });

  return (
    <div className={content}>
      {isLoading && <BigidLoader />}
      {!isLoading && (
        <>
          {isScanInsightsAvailable() ? (
            <>
              <ScanOverview
                {...scanInsightsData}
                scanId={$stateParams.scanId}
                scanProfileName={$stateParams.scanProfileName}
                scanProfileData={scanProfileData}
                type={scanProfileData?.type}
              />
              <BigidPaper classes={{ root: bigidPaperContainer }}>
                <SubScanInfo {...subScansData} type={scanProfileData?.type} />
                <SubScansGrid
                  scanId={$stateParams.scanId}
                  type={scanProfileData?.type}
                  isScanComplete={scanInsightsData?.isScanComplete}
                />
              </BigidPaper>
            </>
          ) : (
            <>
              <ScanInsightsInfoComponent
                {...scanInsightsData}
                scanId={$stateParams.scanId}
                scanProfileName={$stateParams.scanProfileName}
              />
              <ScanProfileInfoComponent {...scanProfileData} />

              <BigidPaper>
                <SubScanInfo {...subScansData} type={scanProfileData?.type} />
                <SubScansGrid
                  scanId={$stateParams.scanId}
                  type={scanProfileData?.type}
                  isScanComplete={scanInsightsData?.isScanComplete}
                />
              </BigidPaper>
            </>
          )}
        </>
      )}
    </div>
  );
};
