import { snakeCase, upperFirst } from 'lodash';
import {
  BigidAdvancedToolbarDropdownFilter,
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
  BigidColorsV2,
  BigidWorldMapImageSeries,
  BigidWorldMapSeriesCategory,
} from '@bigid-ui/components';

import { CountryDefinition, getCopyOfCountries } from '../../../../../config/countries';
import { getApplicationPreference } from '../../../../services/appPreferencesService';

import { PrivacyMapCategories, PrivacyMapWidgetData, WidgetSubTypes } from '../../PrivacyExecutiveDashboardTypes';
import { buildFilterQuery, DynamicFilterKey, fetchWidgetData, getQuery } from '../../services/dashboardService';

type Coordinates = CountryDefinition['points'][0];
type CountryPointsMapper = Record<string, Coordinates>;

interface Location {
  location: string;
  count: number;
}
interface PrivacyMapWidgetRiskCasesApiData {
  severityLevel: string;
  color: string;
  locations: Location[];
}

enum DataMappingCategories {
  assets = 'assets',
  data_sources = 'data_sources',
  legal_entities = 'legal_entities',
  vendors = 'vendors',
}

const DataMappingCategoryNames = {
  [DataMappingCategories.assets]: 'Assets',
  [DataMappingCategories.data_sources]: 'Data sources',
  [DataMappingCategories.legal_entities]: 'Legal entities',
  [DataMappingCategories.vendors]: 'Vendors',
};

interface PrivacyMapWidgetDataMappingApiData extends Record<DataMappingCategories, number> {
  location: string;
}

const mapper: Record<string, DynamicFilterKey> = {
  legalEntities: 'legalEntityIds',
  vendors: 'vendorIds',
  asset: 'assetIds',
};

const getClusterTooltipContent = (images: BigidWorldMapImageSeries[]) => {
  const totalComputed = images.reduce((total, image) => total + (image?.value || 0), 0);

  return `<strong>Total: ${totalComputed}</strong>`;
};

export const categoryColorMapperCache: Record<PrivacyMapCategories, BigidWorldMapSeriesCategory[]> = {
  [PrivacyMapCategories.DATA_MAPPING]: [
    {
      displayName: DataMappingCategoryNames['vendors'],
      fillColor: BigidColorsV2.blue[400],
      name: DataMappingCategories.vendors,
      strokeColor: BigidColorsV2.blue[600],
      getClusterTooltipContent,
    },
    {
      displayName: DataMappingCategoryNames['data_sources'],
      fillColor: BigidColorsV2.magenta[400],
      name: DataMappingCategories.data_sources,
      strokeColor: BigidColorsV2.magenta[600],
      getClusterTooltipContent,
    },
    {
      displayName: DataMappingCategoryNames['assets'],
      fillColor: BigidColorsV2.purple[400],
      name: DataMappingCategories.assets,
      strokeColor: BigidColorsV2.purple[600],
      getClusterTooltipContent,
    },
    {
      displayName: DataMappingCategoryNames['legal_entities'],
      fillColor: BigidColorsV2.cyan[400],
      name: DataMappingCategories.legal_entities,
      strokeColor: BigidColorsV2.cyan[600],
      getClusterTooltipContent,
    },
  ],
  [PrivacyMapCategories.RESIDENCIES]: [
    {
      displayName: PrivacyMapCategories.RESIDENCIES,
      fillColor: BigidColorsV2.blue[300],
      name: snakeCase(PrivacyMapCategories.RESIDENCIES),
      strokeColor: BigidColorsV2.blue[500],
      getClusterTooltipContent,
    },
  ],
  [PrivacyMapCategories.RISKS]: [],
  [PrivacyMapCategories.REQUESTS]: [
    {
      displayName: 'View',
      fillColor: BigidColorsV2.blue[400],
      name: 'view',
      strokeColor: BigidColorsV2.blue[600],
      getClusterTooltipContent,
    },
    {
      displayName: 'Edit',
      fillColor: BigidColorsV2.magenta[400],
      name: 'edit',
      strokeColor: BigidColorsV2.magenta[600],
      getClusterTooltipContent,
    },
    {
      displayName: 'Delete',
      fillColor: BigidColorsV2.purple[400],
      name: 'delete',
      strokeColor: BigidColorsV2.purple[600],
      getClusterTooltipContent,
    },
    {
      displayName: 'Appeal',
      fillColor: BigidColorsV2.cyan[400],
      name: 'appeal',
      strokeColor: BigidColorsV2.cyan[600],
      getClusterTooltipContent,
    },
  ],
};

/**
 * The world map widget doesn't support multiple categories
 * from a single location. For example if we have Assets and Vendors
 * from the same location - e.g. Israel - the map shows only one of
 * the categories.
 *
 * To solve this issue we shift each additional category either in
 * latitude or longitude or both with 1 degree, so that all categories are shown on the map.
 */

const coordinatesShiftMapper: number[][] = [
  [0, 0], // the first category keeps its coordinates intact
  [0, -1], // the second category is shifted 1 degree in longitude
  [-1, 0], // the third category is shifted 1 degree in latitude
  [-1, -1], // the fourth category is shifted 1 degree both in latitude and longitude
];

const shiftPoints = (points: Coordinates, index: number) => {
  const [latitudeShift = 0, longitudeShift = 0] = coordinatesShiftMapper[index];
  return {
    latitude: points?.latitude + latitudeShift,
    longitude: points?.longitude + longitudeShift,
  };
};

const getLocationCoordinates = (location: string, index: number) => {
  const countryPointsMapper = getCopyOfCountries(getApplicationPreference('DISPLAY_CHINA_REGULATIONS')).reduce(
    (acc, country) => {
      acc[country.displayName.toLowerCase()] = country.points[0];
      return acc;
    },
    {} as CountryPointsMapper,
  );

  return shiftPoints(countryPointsMapper[location.toLowerCase()], index);
};

const transformRiskData = (data: PrivacyMapWidgetRiskCasesApiData[]) => {
  categoryColorMapperCache[PrivacyMapCategories.RISKS] = [];

  const transformedData = data.reduce((acc, item, index) => {
    const severityLevel = item.severityLevel;
    const color = item.color.split(' ').join(',');

    categoryColorMapperCache[PrivacyMapCategories.RISKS].push({
      displayName: severityLevel,
      fillColor: color,
      name: severityLevel,
      strokeColor: color,
      getClusterTooltipContent,
    });

    const locations = item.locations.map((item, i) => ({
      id: `${severityLevel}_${item.location}_${i}`,
      category: severityLevel,
      value: item.count,
      name: upperFirst(item.location),
      tooltipContent: '<strong>{category}: {value}</strong>',
      ...getLocationCoordinates(item.location, index),
    }));

    return [...acc, ...locations];
  }, []);

  return { [PrivacyMapCategories.RISKS]: transformedData };
};

const fetchPrivacyMapRiskCases = async (
  activeFilters: BigidAdvancedToolbarFilterUnion[],
): Promise<PrivacyMapWidgetData> => {
  const { severityLevelLocations } = await fetchWidgetData({
    subType: WidgetSubTypes.PRIVACY_MAP_RISKS,
    filter: buildFilterQuery(activeFilters),
  });
  const transformedData = transformRiskData(severityLevelLocations);
  return transformedData;
};

const dataMappingCategories = Object.values(DataMappingCategories);

const transformDataMappingData = (data: PrivacyMapWidgetDataMappingApiData[]) => {
  const transformedData = data.reduce((acc, item) => {
    const categories = dataMappingCategories
      .filter(category => !!item[category])
      .map((category, index) => ({
        id: `${category}_${item.location}`,
        category: category,
        value: item[category],
        name: DataMappingCategoryNames[category],
        tooltipContent: '<strong>{name}: {value}</strong>',
        ...getLocationCoordinates(item.location, index),
      }));

    return [...acc, ...categories];
  }, []);

  return { [PrivacyMapCategories.DATA_MAPPING]: transformedData };
};

const fetchPrivacyMapDataMapping = async (
  activeFilters: BigidAdvancedToolbarFilterUnion[],
): Promise<PrivacyMapWidgetData> => {
  const isDateRange = (filter: BigidAdvancedToolbarFilterUnion) =>
    filter.type === BigidAdvancedToolbarFilterTypes.DATE_RANGE;
  const dateRangeFilter = activeFilters.find(filter => isDateRange(filter));
  const dropdownFilters = activeFilters.filter(filter => !isDateRange(filter)) as BigidAdvancedToolbarDropdownFilter[];

  const widgetData: PrivacyMapWidgetDataMappingApiData[] = await fetchWidgetData({
    subType: WidgetSubTypes.DATA_MAPPING,
    filter: getQuery(dateRangeFilter, '_es_updateDate'),
    indexName: 'privacy_risk_case',
    dynamicFilter: dropdownFilters
      .map(filter => ({
        key: mapper[filter.field],
        value: filter.options.map(option => option.id),
      }))
      .filter(filter => !!filter.value.length),
  });
  const transformedData = transformDataMappingData(widgetData);
  return transformedData;
};

export const fetcherFunctionMapper = [
  fetchPrivacyMapDataMapping,
  () => Promise.resolve({} as PrivacyMapWidgetData), // TODO replace with the proper fetcher when backend is ready
  fetchPrivacyMapRiskCases,
  () => Promise.resolve({} as PrivacyMapWidgetData), // TODO replace with the proper fetcher when backend is ready
];
