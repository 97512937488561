import { useCallback, useState } from 'react';

export const useDialogState = (): [boolean, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDialogClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleDialogOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  return [isOpen, handleDialogClose, handleDialogOpen];
};
