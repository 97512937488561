import { styled } from '@mui/material';
import React, { useMemo } from 'react';
import { OverviewTags } from '../../SearchGrid/GridComponents/sidePanels/CatalogSidePanel/Overview/OverviewTags';
import { OverviewCategories } from '../../SearchGrid/GridComponents/sidePanels/CatalogSidePanel/Overview/OverviewCategories';
import { BasicDetails } from '../../SearchGrid/GridComponents/sidePanels/CatalogSidePanel/Overview/BasicDetails';
import { DataCatalogObjectDetails } from '../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { useGetOverviewEntities } from '../../SearchGrid/GridComponents/sidePanels/CatalogSidePanel/hooks/useGetOverviewEntities';
import { isPermitted } from '../../../../services/userPermissionsService';
import { CATALOG_PERMISSIONS, TAGS_PERMISSIONS } from '@bigid/permissions';
import { BigidLoader } from '@bigid-ui/components';
import { FullPath } from '../../components/FullPath/FullPath';
import { OverviewFullPath } from '../../SearchGrid/GridComponents/sidePanels/CatalogSidePanel/Overview/OverviewFullPath';
import { PagesWithSidePanel } from '../../SearchGrid/GridComponents/sidePanels/CatalogSidePanel/CatalogSidePanel';

const Root = styled('div')`
  display: flex;
`;

const Section = styled('div')<{ width?: string; hasRightBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 24px;
  width: ${({ width }) => width};
  border-right: ${({ hasRightBorder, theme }) => hasRightBorder && theme.vars.tokens.bigid.borderDefault};
`;

type OverviewTabProps = {
  objectDetails: DataCatalogObjectDetails;
  refetchDetails: () => void;
  isLoading: boolean;
  rootPage?: PagesWithSidePanel;
};

export const OverviewTab = ({ objectDetails, refetchDetails, isLoading, rootPage }: OverviewTabProps) => {
  const { isCreateTagsPermitted, isReadTagsPermitted, isObjectTagsAssignmentPermitted } = useMemo(
    () => ({
      isCreateTagsPermitted: isPermitted(TAGS_PERMISSIONS.CREATE.name),
      isReadTagsPermitted: isPermitted(TAGS_PERMISSIONS.READ.name),
      isObjectTagsAssignmentPermitted: isPermitted(CATALOG_PERMISSIONS.ASSIGN_TAG.name),
    }),
    [],
  );

  const { dataSourceDetailsResponse, objectCategoriesResponse, usersListResponse } = useGetOverviewEntities(
    objectDetails.fullyQualifiedName,
    objectDetails.dataSourceName,
  );

  const { data: usersList, refetch: refetchUsers } = usersListResponse;
  const { data: objectCategories } = objectCategoriesResponse;
  const { data: dataSourceDetails } = dataSourceDetailsResponse;

  const refetchData = () => {
    refetchDetails();
    refetchUsers();
  };

  return (
    <Root>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <>
          <Section width="70%" hasRightBorder>
            <OverviewTags
              isReadTagsPermitted={isReadTagsPermitted}
              isCreateTagsPermitted={isCreateTagsPermitted}
              objectDetails={objectDetails}
              isObjectTagsAssignmentPermitted={isObjectTagsAssignmentPermitted}
              refetchDetails={refetchDetails}
            />
            <OverviewCategories categories={objectCategories} />
            <OverviewFullPath scannerType={objectDetails.scannerType} fullPath={objectDetails.fullPath} />
          </Section>
          <Section width="30%">
            <BasicDetails
              details={objectDetails}
              dsOwners={dataSourceDetails?.owners_v2}
              refetchData={refetchData}
              usersList={usersList}
              isListView
              rootPage={rootPage}
            />
          </Section>
        </>
      )}
    </Root>
  );
};
