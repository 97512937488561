import React, { useMemo, useState } from 'react';
import { getApplicationPreference } from '../../../../../../../services/appPreferencesService';
import { isPermitted } from '../../../../../../../services/userPermissionsService';
import { CATALOG_PERMISSIONS } from '@bigid/permissions';
import { BigidCaption, BigidTabs, BigidTabsItem, SecondaryButton } from '@bigid-ui/components';
import { ColumnProfiling } from '../../../../../../DataCatalog/DataCatalogColumns/widgets/ColumnProfiling/ColumnProfiling';
import { ColumnsMasterDetailsPreview } from './ColumnsMasterDetailsPreview';
import { ColumnsGridRow } from './sidePanelColumnsService';
import { Box, styled } from '@mui/material';
import { PagesWithSidePanel } from '../CatalogSidePanel';

const Root = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Content = styled('div')`
  padding: 12px;
`;

const SimilarTableWrapper = styled('div')`
  padding: 16px;
`;

type ColumnsSidePanelContentProps = {
  selectedColumn: ColumnsGridRow;
  fullyQualifiedName: string;
  dsType: string;
  dsName: string;
  scannerType: string;
  handleViewSimilarColumnsClick: () => void;
  rootPage?: PagesWithSidePanel;
};
export const ColumnsSidePanelContent = ({
  selectedColumn,
  fullyQualifiedName,
  dsName,
  dsType,
  scannerType,
  handleViewSimilarColumnsClick,
  rootPage,
}: ColumnsSidePanelContentProps) => {
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(0);
  const { isColumnProfilingAvailable, isColumnPreviewAvailable, isDataPreviewDisabled, isColumnClusteringEnabled } =
    useMemo(
      () => ({
        isColumnProfilingAvailable: getApplicationPreference('COLUMN_PROFILING_ENABLED'),
        isColumnPreviewAvailable: isPermitted(CATALOG_PERMISSIONS.PREVIEW_FILE_INVESTIGATION.name),
        isDataPreviewDisabled: getApplicationPreference('DATA_PREVIEW_DISABLED'),
        isColumnClusteringEnabled: getApplicationPreference('SHOW_STRUCTURED_CLUSTERING'),
      }),
      [],
    );

  const dataAid = 'ColumnsSidePanelContent';

  const tabs = useMemo(() => {
    const tabs: BigidTabsItem[] = [];

    if (isColumnProfilingAvailable) {
      tabs.push({
        label: 'Column Profile',
      });
    }

    if (isColumnPreviewAvailable && !isDataPreviewDisabled) {
      tabs.push({
        label: 'Column Preview',
      });
    }

    return tabs;
  }, [isColumnProfilingAvailable, isColumnPreviewAvailable, isDataPreviewDisabled]);

  const handleColumnWidgetTabChange = (value: number) => {
    setSelectedColumnIndex(value);
  };

  const { column_name, isProfiled } = selectedColumn;
  const isSimilarColumnsAvailable = Boolean(selectedColumn?.clusterId);

  return (
    <Root>
      <Box paddingLeft={2} paddingRight={2} paddingTop={3}>
        <BigidTabs
          onChange={handleColumnWidgetTabChange}
          orientation="horizontal"
          selectedIndex={selectedColumnIndex}
          showBorderBottom
          size="small"
          tabs={tabs}
        />
      </Box>

      <Content>
        {selectedColumnIndex === 0 && isColumnProfilingAvailable ? (
          <>
            <ColumnProfiling
              dataAid={`${dataAid}-column-profile`}
              fullyQualifiedName={fullyQualifiedName}
              columnName={column_name}
              isProfiled={isProfiled}
              dsType={dsType}
              dsName={dsName}
              scannerType={scannerType}
              rootPage={rootPage}
            />
            {isColumnClusteringEnabled && (
              <SimilarTableWrapper>
                {isSimilarColumnsAvailable ? (
                  <SecondaryButton size="medium" onClick={handleViewSimilarColumnsClick} text="View Similar Columns" />
                ) : (
                  <BigidCaption>Similar Columns are unavailable</BigidCaption>
                )}
              </SimilarTableWrapper>
            )}
          </>
        ) : (
          <ColumnsMasterDetailsPreview columnName={column_name} fullyQualifiedName={fullyQualifiedName} />
        )}
      </Content>
    </Root>
  );
};
