import React, { useRef, useState, Suspense } from 'react';
import { BigidBody1, BigidEntity, BigidStateProgressBar, BigidLoader } from '@bigid-ui/components';
import { useTheme } from '@mui/styles';
import { WidgetContainer, WidgetTitleContainer, WidgetFooterContainer, ProgressBarWrapper } from '../WidgetsStyles';
import { CarouselDataProps } from '../../../../../components/Carousel/Carousel';
import { iconsSchema } from '../../../../../views/Frameworks/utils';
import { CarouselItemMenu } from './CarouselItemMenu';
import { useFiltersActions } from '../../hooks/useFiltersActions';
import { ComplianceDashboardWidget } from '../../ComplianceDashboard';
import { MenuActions } from '../WidgetsContainer';

export const CarouselItem = ({
  frameworkName,
  failedControlsCount,
  totalControls,
  handleLoader,
}: CarouselDataProps) => {
  const [isOnHover, setIsOnHover] = useState<boolean>(false);
  const Theme = useTheme();
  const ref = useRef(null);
  const { handleFilterAction, handleRedirectAction } = useFiltersActions(handleLoader);

  const handleHoverEffect = (value: boolean) => {
    if (value) {
      ref.current.style.backgroundColor = 'rgba(34, 34, 34, 0.06)';
      setIsOnHover(true);
    } else {
      ref.current.style.backgroundColor = 'transparent';
      setIsOnHover(false);
    }
  };

  const menuItems = [
    {
      id: 1,
      label: MenuActions.OPEN_SECURITY_POSTURE,
      onClick: () => handleRedirectAction(ComplianceDashboardWidget.FAILED_CONTROLS, [frameworkName]),
    },
    {
      id: 2,
      label: MenuActions.ADD_FILTERS,
      onClick: () => handleFilterAction(ComplianceDashboardWidget.FAILED_CONTROLS, frameworkName),
    },
  ];

  return (
    <WidgetContainer
      ref={ref}
      onMouseEnter={() => handleHoverEffect(true)}
      onMouseLeave={() => handleHoverEffect(false)}
    >
      <WidgetTitleContainer>
        <Suspense fallback={<BigidLoader />}>
          <BigidEntity icon={iconsSchema[frameworkName]} name={frameworkName} isNameBold={false} />
        </Suspense>
        {isOnHover && <CarouselItemMenu handleHoverEffect={handleHoverEffect} menuItems={menuItems} />}
      </WidgetTitleContainer>
      <WidgetFooterContainer>
        <ProgressBarWrapper>
          <BigidStateProgressBar
            percentage={(failedControlsCount / totalControls) * 100}
            size="small"
            width="100%"
            barColor={Theme.palette.bigid.red300}
            barBackgroundColor={Theme.palette.bigid.gray200}
          />
        </ProgressBarWrapper>
        <BigidBody1
          color={Theme.palette.bigid.gray500}
        >{`${failedControlsCount} of ${totalControls} requirements`}</BigidBody1>
      </WidgetFooterContainer>
    </WidgetContainer>
  );
};
