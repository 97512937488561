import { useMemo } from 'react';
import { BigidFormField, BigidFormValues } from '@bigid-ui/components';
import { WizardFormFields } from '../../../types/CorrelationSetsTypes';

interface UsePreviewStepFormConfigProps {
  formState: BigidFormValues;
}

export const usePreviewStepFormConfig = ({ formState }: UsePreviewStepFormConfigProps) => {
  const { fields } = useMemo(() => {
    const fields: BigidFormField[] = [
      {
        name: WizardFormFields.custom_query_string,
      },
      {
        name: WizardFormFields.attributes,
      },
      {
        name: WizardFormFields.attributesTrainingStep,
      },
    ];

    return {
      fields,
    };
  }, [formState]);

  return {
    fields,
  };
};
