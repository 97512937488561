import {
  BigidBody1,
  BigidStatusBadge,
  BigidStatusBadgeSize,
  BigidStatusBadgeType,
  ToolbarActionType,
  BigidLoader,
} from '@bigid-ui/components';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import React, { useEffect, Suspense } from 'react';
import { BigidApplyIcon, BigidDisableIcon, Us } from '@bigid-ui/icons';
import { allFrameworks, FrameworkProps, updateFrameworksStatus } from '../FrameworksServices';
import styled from '@emotion/styled';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
} from '@bigid-ui/grid';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { notificationService } from '../../../services/notificationService';
import { frameworkIconGenerator } from '../utils';
import { useLocalTranslation } from '../translations';
import { isPermitted } from '../../../services/userPermissionsService';
import { COMPLIANCE_FRAMEWORKS_PERMISSIONS } from '@bigid/permissions';

const GridWrapper = styled.div(({ theme }) => ({
  margin: '24px 0',
  border: `1px solid ${theme.palette.bigid.gray200}`,
  borderRadius: '4px',
}));

export const Frameworks = () => {
  const { t } = useLocalTranslation();

  const config: BigidGridWithToolbarProps<{
    id: string;
    _id: string;
    framework_name: string;
    enabled: boolean;
    number_of_controls: number;
  }> = {
    showFilteringControls: true,
    rowClickShouldKeepSelection: true,
    showSelectAllColumnChooser: true,
    entityName: t('Labels.frameworks')?.toLowerCase(),
    filterToolbarConfig: {
      filters: [
        {
          title: t('Labels.columns.state'),
          field: 'enabled',
          operator: 'in',
          value: [],
          options: [
            {
              label: t('Labels.columns.enabled'),
              value: 'enabled',
              isSelected: true,
            },
            {
              label: t('Labels.columns.disabled'),
              value: 'disabled',
              isSelected: false,
            },
          ],
        },
      ],
      searchConfig: {
        searchFilterKeys: ['framework_name'],
        operator: 'contains',
        placeholder: 'framework',
      },
    },
    fetchData: async (queryComponents: BigidGridQueryComponents) => {
      try {
        const data = await allFrameworks(queryComponents);
        return {
          totalCount: data.length,
          data,
        };
      } catch {
        notificationService.error(t('Error.requests.fetchAllFrameworks'));
        return {
          totalCount: 0,
          data: [],
        };
      }
    },
    columns: [
      {
        name: 'framework_name',
        title: t('Labels.columns.framework'),
        getCellValue: ({ framework_name }) => {
          const Icon = frameworkIconGenerator(framework_name);
          return {
            text: {
              displayValue: framework_name,
              tooltip: framework_name,
            },
            icon: {
              icon: () => (
                <Suspense fallback={<BigidLoader />}>
                  <Icon width={24} height={24} />
                </Suspense>
              ),
              tooltip: t('Labels.columns.framework'),
            },
          };
        },
        type: BigidGridColumnTypes.TEXTIFIED_ICON,
      },
      {
        filteringEnabled: true,
        name: 'enabled',
        title: t('Labels.columns.state'),
        getCellValue: row => {
          return (
            <div>
              {row.enabled ? (
                <BigidStatusBadge
                  label={t('Labels.columns.enabled')}
                  size={BigidStatusBadgeSize.SMALL}
                  type={BigidStatusBadgeType.SUCCESS}
                />
              ) : (
                <BigidStatusBadge
                  label={t('Labels.columns.disabled')}
                  size={BigidStatusBadgeSize.SMALL}
                  type={BigidStatusBadgeType.DISABLED}
                />
              )}
            </div>
          );
        },
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'number_of_controls',
        title: t('Labels.columns.controls'),
        getCellValue: row => row.number_of_controls,
        getCustomCellTooltipValue: () => false,
        type: BigidGridColumnTypes.NUMBER,
        filteringEnabled: false,
      },
    ] as BigidGridColumn<{
      id: string;
      _id: string;
      framework_name: string;
      enabled: boolean;
      number_of_controls: number;
    }>[],
    defaultSorting: [{ field: 'framework_name', order: 'asc' }],
    toolbarActions: isPermitted(COMPLIANCE_FRAMEWORKS_PERMISSIONS.EDIT.name)
      ? [
          {
            type: ToolbarActionType.TERTIARY,
            label: t('Labels.actions.enabled'),
            icon: BigidApplyIcon,
            tooltip: t('Labels.actions.enabled'),
            show: ({ selectedRows }) => {
              return selectedRows.length > 0;
            },
            execute: async ({ selectedRows }) => {
              try {
                const selectedFrameworksToEnable = selectedRows.map((el: any) => el.framework_name);
                await updateFrameworksStatus({
                  frameworksToEnable: selectedFrameworksToEnable,
                  frameworksToDisable: [],
                });
                notificationService.success(t('Success.requests.updatedFrameworkStatus'), { duration: 3000 });
                return { shouldGridReload: true, shouldClearSelection: true };
              } catch (e) {
                console.error(e);
                notificationService.error(t('Error.requests.updatedFrameworkStatus'), { duration: 3000 });
              }
            },
          },
          {
            type: ToolbarActionType.TERTIARY,
            label: t('Labels.actions.disabled'),
            icon: BigidDisableIcon,
            tooltip: t('Labels.actions.disabled'),
            show: ({ selectedRows }) => {
              return selectedRows.length > 0;
            },
            execute: async ({ selectedRows }) => {
              try {
                const selectedFrameworksToEnable = selectedRows.map(
                  (framework: FrameworkProps) => framework.framework_name,
                );
                await updateFrameworksStatus({
                  frameworksToEnable: [],
                  frameworksToDisable: selectedFrameworksToEnable,
                });
                notificationService.success(t('Success.requests.updatedFrameworkStatus'), { duration: 3000 });
                return { shouldGridReload: true, shouldClearSelection: true };
              } catch (e) {
                console.error(e);
                notificationService.error(t('Error.requests.updatedFrameworkStatus'), { duration: 3000 });
              }
            },
          },
        ]
      : undefined,
    onRowClick: row => {
      $state.go(CONFIG.states.FRAMEWORKS_CONTROLS, {
        id: row.framework_name,
        state: row.enabled,
      });
    },
  };

  return (
    <GridWrapper>
      <BigidGridWithToolbar {...config} />
    </GridWrapper>
  );
};
