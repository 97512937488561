import { BigidStatusBadgeProps, BigidStatusBadgeSize, BigidStatusBadgeType } from '@bigid-ui/components';
import { DataMinimizationExecutionState } from './DataMinimizationActivityLogs/types';
import { DataMinimizationPartsInfo, State, States, User } from './types';

export function executionStateToStatusProps(state: DataMinimizationExecutionState): BigidStatusBadgeProps | null {
  switch (state) {
    case DataMinimizationExecutionState.FAILED:
      return {
        size: BigidStatusBadgeSize.SMALL,
        type: BigidStatusBadgeType.ERROR,
        label: 'Failed',
      };
    case DataMinimizationExecutionState.IN_PROGRESS:
      return {
        size: BigidStatusBadgeSize.SMALL,
        type: BigidStatusBadgeType.INFO,
        label: 'In progress',
      };
    case DataMinimizationExecutionState.COMPLETED:
      return {
        size: BigidStatusBadgeSize.SMALL,
        type: BigidStatusBadgeType.SUCCESS,
        label: 'Completed',
      };
    case DataMinimizationExecutionState.QUEUED:
      return {
        size: BigidStatusBadgeSize.SMALL,
        type: BigidStatusBadgeType.WARNING,
        label: 'Queued',
      };
    case DataMinimizationExecutionState.STOPPED:
      return {
        size: BigidStatusBadgeSize.SMALL,
        type: BigidStatusBadgeType.PENDING,
        label: 'Stopped',
      };
    case DataMinimizationExecutionState.STOP_REQUESTED:
      return {
        size: BigidStatusBadgeSize.SMALL,
        type: BigidStatusBadgeType.WARNING,
        label: 'Stop requested',
      };
    default:
      return null;
  }
}

export function partsInfoToStates(pi: DataMinimizationPartsInfo = {}): States {
  const total =
    (pi?.Queued || 0) +
    (pi?.Completed || 0) +
    (pi?.Failed || 0) +
    (pi?.Canceled || 0) +
    (pi?.RedisQueued || 0) +
    (pi?.Started || 0) +
    (pi?.Stopped || 0) +
    (pi?.InProgress || 0);
  return {
    [State.total]: total,
    [State.queued]: pi?.Queued || 0,
    [State.completed]: pi?.Completed || 0,
    [State.failed]: pi?.Failed || 0,
    [State.canceled]: pi?.Canceled || 0,
    [State.stopped]: pi?.Stopped || 0,
    [State.in_progress]: (pi?.RedisQueued || 0) + (pi?.Started || 0) + (pi?.InProgress || 0),
  };
}

export function getUserLabel({ first_name = '', last_name = '', id }: User) {
  return first_name && last_name ? `${first_name} ${last_name}` : first_name || id;
}
