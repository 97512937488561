import React, { FC, useEffect, useState, useCallback, Fragment } from 'react';
import { BigidSelect, BigidSelectOption, SelectSize } from '@bigid-ui/components';
import { FormControl, FormControlProps } from '@mui/material';
import { notificationService } from '../../../../services/notificationService';
import { sortAlphabetically } from '../../../../../common/services/localizationHelper';
import {
  GlossaryItem,
  GlossaryItemType,
  businessGlossaryService,
} from '../../../../../administration/generalSettings/businessGlossary/businessGlossary.service';

interface GlossaryItemOption extends BigidSelectOption {
  glossaryItem: GlossaryItem;
}

export interface GlossaryItemSelectProps {
  // value is a glossary_id
  type: GlossaryItemType;
  value: string[];
  selectedOptions?: GlossaryItemOption[];
  title: React.ReactNode;
  fieldName: string;
  margin?: FormControlProps['margin'];
  size?: SelectSize;
  onSelectionChange: (ids: string[]) => void;
}

export const GlossaryItemSelect: FC<GlossaryItemSelectProps> = ({
  type,
  value,
  title,
  fieldName,
  margin = 'normal',
  size,
  onSelectionChange,
}) => {
  const PLACEHOLDERS = {
    [GlossaryItemType.PersonalDataItem]: 'Add or search friendly name',
    [GlossaryItemType.PurposeOfProcessing]: 'Add or search purpose of use',
    [GlossaryItemType.PersonalDataCategory]: 'Add or search category item',
    [GlossaryItemType.BusinessTerm]: 'Add or search business term',
  };

  const [glossaryItemsOptions, setGlossaryItemsOptions] = useState<GlossaryItemOption[]>([]);
  const [glossaryItemMap, setGlossaryItemMap] = useState<Map<string, GlossaryItemOption>>(new Map());
  const [selectedOptions, setSelectedOptions] = useState<GlossaryItemOption[]>([]);
  const [createGlossaryItemInProgress, setCreateGlossaryItemInProgress] = useState(false);

  const loadGlossaryItems = useCallback(async () => {
    let options: GlossaryItemOption[] = [];
    try {
      // TODO: use container to store this any time the main view is refreshed
      const glossaryItems = await businessGlossaryService.getGlossaryItems({ type });
      options = [...glossaryItems]
        // should be sorted by name
        .sort(({ name: a }, { name: b }) => sortAlphabetically(a, b))
        .map<GlossaryItemOption>(glossaryItem => ({
          id: glossaryItem.glossary_id,
          label: glossaryItem.name,
          value: glossaryItem.glossary_id,
          glossaryItem,
        }));
    } catch (err) {
      console.error(`Error while fetching glossary items list, err:`, err);
    }
    setGlossaryItemsOptions(options);
    setGlossaryItemMap(new Map(options.map(item => [item.value, item])));
  }, [type]);

  useEffect(() => {
    loadGlossaryItems();
  }, [loadGlossaryItems, type]);

  useEffect(() => {
    const selectedOptions = [...new Set(value.map(glossaryItemId => glossaryItemMap.get(glossaryItemId)))];

    if (selectedOptions.length > 1) {
      setSelectedOptions([{ label: 'Multiple Values', value: undefined }] as any);
    }
    if (selectedOptions.length === 1) {
      setSelectedOptions(selectedOptions);
    }
  }, [glossaryItemsOptions, glossaryItemMap, value]);

  const handleCreate = useCallback(
    async (name: string, type: GlossaryItemType) => {
      if (!name || !Object.values(GlossaryItemType).includes(type)) {
        return;
      }

      try {
        setCreateGlossaryItemInProgress(true);
        const createdItem = await businessGlossaryService.createGlossaryItem({ name, type });
        await loadGlossaryItems();
        onSelectionChange([createdItem.glossary_id]);
        notificationService.success(`Created new glossary Item "${name}"`);
      } catch (err) {
        notificationService.error(`Failed to create new Glossary Item`);
        console.error(`Failed to create new Glossary Item.`, err);
        throw err;
      } finally {
        setCreateGlossaryItemInProgress(false);
      }
    },
    [loadGlossaryItems, onSelectionChange],
  );

  const formatCreateLabel = useCallback((inputValue: string) => `Add new "${inputValue}"`, []);

  function handleOnChange(options: BigidSelectOption[]) {
    onSelectionChange(options?.map(selectedOption => selectedOption?.value ?? selectedOption));
  }

  return (
    <Fragment>
      <FormControl fullWidth margin={margin}>
        <div>{title}</div>
        <BigidSelect
          menuPosition="fixed"
          name={fieldName}
          isDisabled={createGlossaryItemInProgress}
          options={glossaryItemsOptions}
          message={selectedOptions?.[0]?.label === 'Multiple Values' ? 'Select new Value to Apply to all' : ''}
          placeholder={PLACEHOLDERS[type]}
          value={selectedOptions}
          onChange={handleOnChange}
          isSearchable={true}
          isCreatable={true}
          isClearable={true}
          formatCreateLabel={formatCreateLabel}
          onCreateOption={(name: string) => handleCreate(name, type)}
          size={size}
        />
      </FormControl>
    </Fragment>
  );
};
