import React from 'react';
import { BigidInlineNotification } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { GuideState, ListType } from '../../../types/CorrelationSetsTypes';
import { useLocalTranslation } from '../../../translations';
import styled from '@emotion/styled';
import { GuideContent } from '../../GuideContent/GuideContent';

const ErrorContainer = styled('div')`
  margin-top: 12px;
  margin-bottom: 12px;
  > div {
    overflow-x: scroll;
  }
`;

interface DetailsGuideContentProps {
  contentTitle: string;
  guideState: GuideState;
}

export const DetailsGuideContent = ({ contentTitle, guideState }: DetailsGuideContentProps) => {
  const { t } = useLocalTranslation('wizard.guideContent');

  const guideDataDetails = [
    {
      title: t('detailsStep.title1'),
      listStyle: ListType.none,
      data: t('detailsStep.text1'),
    },
    {
      title: t('detailsStep.title2'),
      listStyle: ListType.number,
      data: [
        {
          title: t('detailsStep.titleData1'),
          text: t('detailsStep.textData1'),
        },
        {
          title: t('detailsStep.titleData2'),
          text: t('detailsStep.textData2'),
        },
      ],
    },
    {
      title: t('detailsStep.title3'),
      listStyle: ListType.none,
      data: t('detailsStep.text3'),
    },
    {
      title: t('detailsStep.title4'),
      listStyle: ListType.none,
      data: t('detailsStep.text4'),
    },
  ];

  return (
    <>
      {guideState.errorMessage && (
        <ErrorContainer>
          <BigidInlineNotification
            open={true}
            showMoreButton={true}
            dataAid={generateDataAid('DetailsGuideContent', ['connection-error'])}
            text={[
              {
                subText: guideState.errorMessage,
              },
            ]}
            title={t('errors.failedPreviewTitle')}
            type="error"
          />
        </ErrorContainer>
      )}
      <GuideContent content={guideDataDetails} />
    </>
  );
};
