import styled from '@mui/material/styles/styled';

export const Container = styled('div')<{ isCollapsed: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ isCollapsed }) => (isCollapsed ? '0' : '16px')};
  padding: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  background: ${({ theme }) => theme.vars.tokens.bigid.pageBackground || '#fff'};
`;

export const HeaderContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
`;

export const CollapsibleContainer = styled('div')<{ height: string }>`
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  height: ${({ height }) => height};
`;

export const LineContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 8px;
`;

export const EqualContainer = styled(LineContainer)`
  gap: 4px;
  align-self: stretch;
`;
