import React, { FC, MutableRefObject, memo } from 'react';
import { BigidForm, BigidFormRenderProps, BigidDropdownOption, TertiaryButton } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import {
  GlossaryItem,
  GlossaryItemType,
} from '../../../../administration/generalSettings/businessGlossary/businessGlossary.service';
import { styled } from '@mui/material';
import { getFormFields, getFormInitialValues } from './glossaryItemFormUtils';
import { useGlossaryItems } from '../useGlossaryItems';
import { getAllGlossaryDomains, getAllGlossaryOwners, getAllGlossarySubDomains } from '../businessGlossaryUtils';

const FormWrapper = styled('div')<{ withPadding: boolean }>`
  padding: ${props => (props.withPadding ? 16 : 0)}px;

  fieldset {
    margin: 0 0 16px;
  }
`;

type GlossaryItemFormProps = {
  glossaryItem?: GlossaryItem;
  formControlsRef: MutableRefObject<any>;
  withPadding?: boolean;
};

const FormContent: FC<BigidFormRenderProps> = ({ renderField, getValues, getFieldProps }) => {
  const values = getValues();
  const isCreateMode = !values._id;

  const isCategoryItem = Boolean(
    (values.type as BigidDropdownOption[])?.find(({ value }) => value === GlossaryItemType.PersonalDataCategory),
  );
  const showReportOrderClearButton =
    values.report_order !== '' && Boolean(values.report_order?.length || !isNaN(values.report_order));

  return (
    <>
      {renderField('name')}
      {isCreateMode && renderField('type')}
      {renderField('description')}
      {renderField('owner')}
      {renderField('domain')}
      {renderField('sub_domain')}
      {renderField('glossary_id', { disabled: !isCreateMode })}
      {isCategoryItem && (
        <div data-aid={generateDataAid('GlossaryItemForm', ['category-item-block'])}>
          {renderField('report_order')}
          {showReportOrderClearButton ? (
            <span>
              <TertiaryButton
                onClick={() => {
                  const { setValue } = getFieldProps('report_order');
                  setValue('');
                }}
                size="medium"
                dataAid="report_order_clear_btn"
                text="Clear"
              />
            </span>
          ) : null}
        </div>
      )}
    </>
  );
};

export const GlossaryItemForm: FC<GlossaryItemFormProps> = memo(({ glossaryItem, formControlsRef, withPadding }) => {
  const glossaryItems = useGlossaryItems();
  const owners = getAllGlossaryOwners(glossaryItems);
  const domains = getAllGlossaryDomains(glossaryItems);
  const subDomains = getAllGlossarySubDomains(glossaryItems);
  const isCreateMode = !glossaryItem;
  const initialValues = getFormInitialValues(glossaryItem);
  const fields = getFormFields({ isCreateMode, owners, domains, subDomains });

  return (
    <FormWrapper withPadding={withPadding}>
      <BigidForm
        initialValues={initialValues}
        fields={fields}
        key={glossaryItem?.glossary_id}
        renderForm={FormContent}
        controlButtons={false}
        stateAndHandlersRef={formControlsRef}
      />
    </FormWrapper>
  );
});
