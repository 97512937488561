import React, { FC, ReactText, useCallback, useMemo, useState } from 'react';
import {
  BigidBody1,
  BigidContentItem,
  BigidDialog,
  BigidHeading5,
  BigidPaper,
  objectToQueryString,
  PrimaryButton,
  QueryParams,
  SecondaryButton,
} from '@bigid-ui/components';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridRow,
  BigidGrid,
  BigidGridProps,
  useFetch,
} from '@bigid-ui/grid';
import {
  AttributeRanked,
  ColumnBusinessAttributeSuggestion,
  getColumnBusinessAttributeSuggestions,
  ManualField,
  ManualFieldType,
  updateManualFields,
} from '../DataCatalogColumnsService';
import { notificationService } from '../../../../services/notificationService';
import { isEqual } from 'lodash';
import { ColumnWidgetsWrapper } from '../widgets/ColumnWidgetsWrapper';
import { BigidAiInsightIllustration, BigidAiIcon } from '@bigid-ui/icons';

type BusinessAttributeGridColumn = BigidGridRow & ColumnBusinessAttributeSuggestion;

export type BusinessAttributeSuggestionsDialog = {
  columnName: string;
  fullyQualifiedName: string;
  isOpen: boolean;
  onClose: () => void;
  onAttributesUpdate: () => void;
  attributeList?: AttributeRanked[];
};

export const BusinessAttributeSuggestionsDialog: FC<BusinessAttributeSuggestionsDialog> = ({
  columnName,
  fullyQualifiedName,
  isOpen,
  onClose,
  onAttributesUpdate,
  attributeList,
}) => {
  const [selectedRowIds, setSelectedRowIds] = useState<ReactText[]>(
    attributeList?.map(attr => attr.attribute_name) || [],
  );
  const [selectedItem, setSelectedItem] = useState<BigidContentItem>(null);

  const tableState = useFetch({
    fetchDataFunction: async queryComponents => {
      try {
        const query = objectToQueryString({
          ...(queryComponents as QueryParams),
          fullyQualifiedName,
          columnName,
          requireTotalCount: false,
        });
        const {
          data: { results },
        } = await getColumnBusinessAttributeSuggestions(query);

        return {
          totalCount: results.length,
          data: results
            .map(suggestion => ({ id: `businessTerm.${suggestion.friendlyName}`, ...suggestion }))
            .sort((a, b) => {
              if (a.glossaryId && !b.glossaryId) return -1;
              if (!a.glossaryId && b.glossaryId) return 1;
              return (Number(b.score) || 0) - (Number(a.score) || 0);
            }),
        };
      } catch ({ message }) {
        notificationService.error('An error has occurred');
        console.error(`An error has occurred: ${message}`);
        return {
          totalCount: 0,
          data: [],
        };
      }
    },
  });

  const handleGridRowClick = useCallback(
    (row: BigidGridRow): void => {
      const newSelectedRow = !isEqual(selectedItem, row) ? row : undefined;
      setSelectedItem(newSelectedRow as BigidContentItem);
    },
    [selectedItem],
  );

  const getSelectedRowId = useCallback((): string[] => {
    return selectedItem ? [selectedItem.id as string] : [];
  }, [selectedItem]);

  const columns: BigidGridColumn<BusinessAttributeGridColumn>[] = useMemo(
    () => [
      {
        name: 'friendlyName',
        title: 'Friendly Name',
        getCellValue: ({ friendlyName }) => friendlyName,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'source',
        title: 'Data Source',
        width: 150,
        getCellValue: ({ glossaryId }) =>
          glossaryId ? (
            <div style={{ display: 'flex' }}>
              <BigidAiIcon />
              <div style={{ marginLeft: 8 }}>AI model</div>
            </div>
          ) : (
            'Similar column'
          ),
        type: BigidGridColumnTypes.CUSTOM,
      },
    ],
    [],
  );

  const gridConfig: BigidGridProps<BusinessAttributeGridColumn> = {
    ...tableState,
    showSortingControls: false,
    showSelectionColumn: true,
    rowClickShouldKeepSelection: true,
    showSelectAll: true,
    selectedRowIds,
    onRowClick: handleGridRowClick,
    onSelectedRowIdsChanged: setSelectedRowIds,
    columns,
  };

  const handleColumnWidgetClose = (): void => {
    setSelectedItem(null);
  };

  const handleAttributeAssign = async () => {
    const attributesToUpdate: ManualField[] = [];
    tableState.rows.forEach(row => {
      const oldAttr = (attributeList || []).find(attr => attr.attribute_name === row.id);
      const isNewAttrSelected = selectedRowIds.includes(row.id);
      if (isNewAttrSelected && !oldAttr) {
        attributesToUpdate.push({
          attribute_type: 'Business Term',
          fieldName: columnName,
          fullyQualifiedName,
          type: ManualFieldType.ATTRIBUTE,
          value: row.id,
        });
      }
      if (!isNewAttrSelected && oldAttr) {
        attributesToUpdate.push({
          attribute_type: 'Business Term',
          fieldName: columnName,
          fullyQualifiedName,
          type: ManualFieldType.ATTRIBUTE,
          value: row.id,
          confLevel: 'LOW',
        });
      }
    });
    try {
      await updateManualFields(attributesToUpdate);
      onAttributesUpdate();
    } catch (error) {
      console.error(error);
      notificationService.error('Failed to assign business term(s)');
    }
  };

  return (
    <BigidDialog
      title={`Business terms suggestions for '${columnName}'`}
      isOpen={isOpen}
      onClose={onClose}
      borderTop
      borderBottom
      buttons={[
        {
          component: SecondaryButton,
          onClick: onClose,
          text: 'Cancel',
        },
        {
          component: PrimaryButton,
          onClick: handleAttributeAssign,
          text: 'Apply to Column',
        },
      ]}
      maxWidth="md"
      isLoading={false}
    >
      <div style={{ display: 'flex', height: 300 }}>
        <div style={{ overflow: 'hidden' }}>
          <BigidBody1 mb={3}>
            Select business terms to apply to this column. Editing attributes and business terms can be done in the
            attributes cell
          </BigidBody1>
          <BigidGrid {...gridConfig} />
        </div>
        {selectedItem && !selectedItem.glossaryId && (
          <div style={{ marginLeft: 16 }}>
            <BigidHeading5 mb={2}>{selectedItem.friendlyName}</BigidHeading5>
            <div style={{ display: 'flex', height: 275 }}>
              <ColumnWidgetsWrapper
                dataAid="BusinessAttributeDialog-column-widget"
                preferenceId="DataCatalogColumnPreviewPreferences"
                fullyQualifiedName={selectedItem.fullyQualifiedName}
                columnName={selectedItem.columnName}
                isProfiled={selectedItem.isProfiled}
                onClose={handleColumnWidgetClose}
                dsName={selectedItem.source}
                dsType={selectedItem.scannerType}
                scannerType={selectedItem.scanner_type_group}
              />
            </div>
          </div>
        )}
        {selectedItem?.glossaryId && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: 335,
              marginLeft: 16,
            }}
          >
            <BigidAiInsightIllustration />
            <BigidBody1
              style={{ textAlign: 'center' }}
            >{`Suggested for you based on our AI model's recommendations`}</BigidBody1>
          </div>
        )}
      </div>
    </BigidDialog>
  );
};
