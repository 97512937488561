import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useLocalTranslation } from '../../translations';
import { BigidDataScanIllustration } from '@bigid-ui/icons';
import { BigidHeading5, BigidHeading1 } from '@bigid-ui/components';
import { formatNumberCompact } from '../../../../utilities/numericDataConverter';

const FindingsChartContainer = styled.div(({ theme }) => ({
  display: 'flex',
  padding: '24px',
  flexDirection: 'column',
  gap: '8px',
  border: theme.vars.tokens.bigid.borderDefault,
  borderRadius: '8px',
  width: '288px',
}));

const BottomSection = styled('div')`
  justify-content: space-between;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const DataRiskFindingsIndicator: FC<{ findingsCount: number }> = ({ findingsCount }) => {
  const { t } = useLocalTranslation('dataRiskWidget');
  return (
    <FindingsChartContainer>
      <BigidHeading5>{t('discoveredFindings')}</BigidHeading5>
      <BottomSection>
        <BigidHeading1>{formatNumberCompact(findingsCount, 0)}</BigidHeading1>
        <BigidDataScanIllustration />
      </BottomSection>
    </FindingsChartContainer>
  );
};
