import { notificationService } from '../../services/notificationService';
import { SSE_EVENTS, SSEDataMessage, subscribeToRepeatedSSEEventById } from '../../services/sseService';
import { isBusinessTermsEnabled } from '../../utilities/featureFlagUtils';

enum BusinessTermsOperationStatus {
  EXCEEDED_USAGE_LIMIT = 'Quota Exceeded',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
}

const handleBusinessTermSSE = (data: SSEDataMessage) => {
  const {
    results: [{ status, message }],
  } = data;
  const notificationConfig = {
    shouldAutoHide: false,
  };
  if (status === BusinessTermsOperationStatus.EXCEEDED_USAGE_LIMIT || status.FAILED) {
    notificationService.error(message, notificationConfig);
  } else {
    notificationService.info(message, notificationConfig);
  }
};

const initListener = () => {
  let unregister: () => void;

  if (isBusinessTermsEnabled()) {
    unregister = subscribeToRepeatedSSEEventById(
      SSE_EVENTS.ASSIGN_BUSINESS_TERNS_SSE_EVENT_NAME,
      handleBusinessTermSSE,
    );
  }
  return () => {
    unregister?.();
  };
};

export const businessTermsEventService = {
  initListener,
};
