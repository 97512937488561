import { useCallback, useRef, useState } from 'react';
import {
  businessGlossaryService,
  GlossaryItem,
  GlossaryItemType,
} from '../../../../administration/generalSettings/businessGlossary/businessGlossary.service';
import { notificationService } from '../../../services/notificationService';
import { getMessageFromUpdateItemError } from './glossaryItemFormUtils';
import { openKeepEditingDialog } from '../../ClassifiersManagement/components/Dialogs/KeepEditing';

type UseGlossaryItemFormProps = {
  onSubmit: () => void;
  onClose: () => void;
};

export const useGlossaryItemForm = ({ onSubmit, onClose }: UseGlossaryItemFormProps) => {
  const formControlsRef = useRef<any>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(async () => {
    formControlsRef.current.validateAndSubmit(async (values: any) => {
      const {
        _id,
        name,
        type: typeOption,
        glossary_id: glossaryId,
        report_order: reportOrderStringValue,
        description: richTextDescription,
        owner: ownerDropdownValue,
        domain: domainDropdownValue,
        sub_domain: subDomainDropdownValue,
      } = values;
      const isCreateMode = !_id;

      const description = richTextDescription.markdown ?? '';
      const owner = ownerDropdownValue.length ? ownerDropdownValue[0].value : undefined;
      const domain = domainDropdownValue.length ? domainDropdownValue[0].value : undefined;
      const sub_domain = subDomainDropdownValue.length ? subDomainDropdownValue[0].value : undefined;

      const reportOrder = parseInt(reportOrderStringValue, 10) || null;

      const type = typeOption[0].value;
      const isCategoryItem = type === GlossaryItemType.PersonalDataCategory;

      const data: Partial<GlossaryItem> = {
        name,
        ...(isCreateMode && { type, glossary_id: glossaryId }),
        ...(isCategoryItem && { report_order: reportOrder }),
        description,
        owner,
        domain,
        sub_domain,
      };

      setIsSubmitting(true);

      try {
        let wasItemCreated = isCreateMode;
        if (isCreateMode) {
          const { is_new } = await businessGlossaryService.createGlossaryItem(data);
          wasItemCreated = is_new;
        } else {
          await businessGlossaryService.updateGlossaryItem(_id, data);
        }
        notificationService.success(`Glossary Item successfully ${wasItemCreated ? 'created' : 'updated'}.`);
        onSubmit();
      } catch (err) {
        const errMsg = getMessageFromUpdateItemError(err, isCreateMode);
        notificationService.error(errMsg);
        console.error(err, errMsg);
        throw err;
      } finally {
        setIsSubmitting(false);
      }
    });
  }, [onSubmit]);

  const handleClose = useCallback(async () => {
    if (formControlsRef.current.formTouched) {
      const isKeepEditing = await openKeepEditingDialog();
      if (isKeepEditing) {
        return;
      }
    }
    onClose();
  }, [onClose]);

  return {
    isSubmitting,
    handleSubmit,
    handleClose,
    formControlsRef,
  };
};
