import {
  CLASSIFIER_ALGORITHMS,
  DEFAULT_MODEL_NAME,
  getClassifierNameById,
} from '../../../administration/classifiers/classifiers.service';
import { httpService, QueryParamsAndDataObject } from '../../services/httpService';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { notificationService } from '../../services/notificationService';
import { BigidGridQueryComponents } from '@bigid-ui/grid';

export interface AttributeRisksResponse {
  attribute_risks: AttributeRisk[];
}

export interface AttributeRisk {
  id: string;
  glossary_id: null | string;
  original_names: string[];
  short_name: string;
  name: string;
  count: number;
  avg: number;
  id_score: number;
  min_score: number;
  max_score: number;
}

export interface AdvancedClassifier {
  _id: string;
  type: 'DOC' | 'NER';
  enabled: boolean;
  nerEntityName: string;
  modelId: string;
  classifierName: string;
  modelName: string;
  name: string;
}

export interface AdvancedClassifierResponse {
  classifierAlgorithms: Record<
    'nerClassifiers' | 'docClassifiers',
    {
      featureFlag: boolean;
      classifiers: AdvancedClassifier[];
    }
  >;
}

export interface ClassifiersResponse {
  _id: string;
  classification_name: string;
  classifierType?: 'data' | 'columnName';
  enabled?: boolean;
  name: string;
  classification_regex: string;
  min_length?: number | string;
  max_length?: number | string;
  validation?: null | string;
  description?: string;
}

interface ModelInfo {
  _id: string;
  model_name: string;
}

export interface MlModelMapResponse {
  data: {
    trainingModels: ModelInfo[];
  };
}

export type NormalizedClassifierType = 'doc' | 'ner' | 'meta' | 'data' | 'data-and-metadata';

export interface NormalizedClassifier {
  id: string;
  name: string;
  attributeRiskName: string;
  type: NormalizedClassifierType;
  totalFindings: number;
  originalData: (ClassifiersResponse | AdvancedClassifier)[];
  originalNames?: string[];
  selectedItemTitle?: string;
  nerTitle?: string;
}

interface NormalizedAttribute {
  originalName: string;
  friendlyName?: string;
  count: number;
}

export interface NormalizedClassifiersResult {
  totalCount?: number;
  classifiers: NormalizedClassifier[];
}

export interface NormalizedClassifierResponse {
  data: NormalizedClassifiersResult;
}

/**
 * @deprecated - use API instead
 */
export const getNormalizedClassifiersOld = async (): Promise<NormalizedClassifier[]> => {
  const [attributeRisks, advancedClassifiers, classifiers, mlModelsMap] = await Promise.all([
    httpService
      .fetch<AttributeRisksResponse>(`attributeRisks`)
      .then(({ data: { attribute_risks } }) => attribute_risks),
    httpService
      .fetch<AdvancedClassifierResponse>(`classifier-algorithms`)
      .then(({ data: { classifierAlgorithms } }) => classifierAlgorithms),
    httpService.fetch<ClassifiersResponse[]>(`classifications`).then(({ data }) => data),
    httpService.fetch<MlModelMapResponse>(`ml-models-map`).then(({ data: { data } }) => data),
  ]);

  const normalizedAttributes: NormalizedAttribute[] = attributeRisks
    .filter(
      ({ id, original_names = [] }) =>
        id.startsWith('classifier.') || original_names.some(originalName => originalName.startsWith('classifier.')),
    )
    .reduce((normalizedAttributesAggr, { id, count, original_names = [] }) => {
      if (original_names.length === 1 && original_names[0] === id) {
        return [...normalizedAttributesAggr, { originalName: id, count }];
      } else {
        return [
          ...normalizedAttributesAggr,
          ...original_names.map(originalName => ({ originalName, friendlyName: id, count })),
        ];
      }
    }, []);

  const getModelNameByModelId = (modelId: string) => {
    const model = mlModelsMap.trainingModels.find(({ _id }) => _id === modelId);
    return model ? model.model_name : '';
  };

  const getAttributeRiskName = (
    type: NormalizedClassifierType,
    name: string,
    modelId?: string,
    nerModelName?: string,
  ) => {
    const normalizedType = type.toLowerCase();
    switch (normalizedType) {
      case 'data':
        return `classifier.${name}`;
      case 'meta':
        return `classifier.MD::${name}`;
      case 'doc': {
        let modelName = DEFAULT_MODEL_NAME;
        if (modelId && modelId !== DEFAULT_MODEL_NAME) {
          modelName = getModelNameByModelId(modelId);
        }
        return `classifier.DOC::${modelId ? modelName + '::' : ''}${name}`;
      }
      case 'ner':
        if (nerModelName && nerModelName !== DEFAULT_MODEL_NAME) {
          return `classifier.NER::${nerModelName + '::'}${name}`;
        }
        return `classifier.NER::${name}`;
      default:
        console.warn(`Invalid classifier type: "${type}"`);
    }
  };

  const normalizedClassifiers: NormalizedClassifier[] = classifiers.reduce(
    (normalizedClassifiers, normalizedClassifier) => {
      const { _id: id, classification_name: name, classifierType } = normalizedClassifier;
      const type: NormalizedClassifierType = classifierType === 'columnName' ? 'meta' : 'data';
      const attributeRiskName = getAttributeRiskName(type, name);
      const normalizedAttribute = normalizedAttributes.find(({ originalName }) => originalName === attributeRiskName);

      if (normalizedAttribute) {
        const { originalName, friendlyName, count } = normalizedAttribute;

        if (friendlyName) {
          const usedWithFriendlyNameClassifiers = normalizedClassifiers.filter(({ name }) => name === friendlyName);

          if (usedWithFriendlyNameClassifiers.length > 0) {
            usedWithFriendlyNameClassifiers.forEach(classifier => {
              classifier.originalData = [...(classifier.originalData || []), normalizedClassifier];
              classifier.originalNames = [...(classifier.originalNames || []), originalName];
            });
            return normalizedClassifiers;
          } else {
            return [
              ...normalizedClassifiers,
              {
                id,
                name: friendlyName,
                type,
                attributeRiskName,
                totalFindings: count,
                originalData: [normalizedClassifier],
                originalNames: [originalName],
              },
            ];
          }
        } else {
          return [
            ...normalizedClassifiers,
            {
              id,
              name,
              type,
              attributeRiskName,
              totalFindings: count,
              originalData: [normalizedClassifier],
            },
          ];
        }
      } else {
        return [
          ...normalizedClassifiers,
          {
            id,
            name,
            type,
            attributeRiskName,
            totalFindings: 0,
            originalData: [normalizedClassifier],
          },
        ];
      }
    },
    [],
  );

  const normalizedAdvancedClassifiers: NormalizedClassifier[] = Object.values(advancedClassifiers)
    .filter(value => value && Array.isArray(value.classifiers))
    .flatMap(({ classifiers }) => classifiers)
    .reduce((advancedNormalizedClassifiers, advancedNormalizedClassifier) => {
      const {
        _id: advancedNormalizedClassifierId,
        type: advancedNormalizedClassifierType,
        nerEntityName: advancedNormalizedClassifierNerEntityName,
        classifierName: advancedNerNormalizedClassifierName,
        modelName: advancedNerNormalizedClassifierModel,
        modelId,
      } = advancedNormalizedClassifier;
      const type: NormalizedClassifierType = advancedNormalizedClassifierType.toLowerCase() as NormalizedClassifierType;
      let originalClassifierName = '';
      let attributeRiskName = '';

      if (advancedNormalizedClassifierType === CLASSIFIER_ALGORITHMS.NER) {
        attributeRiskName = getAttributeRiskName(
          type,
          advancedNerNormalizedClassifierName,
          null,
          advancedNerNormalizedClassifierModel,
        );
        originalClassifierName =
          advancedNerNormalizedClassifierModel === DEFAULT_MODEL_NAME
            ? advancedNerNormalizedClassifierName
            : `${advancedNerNormalizedClassifierName}::${advancedNerNormalizedClassifierModel}`;
      } else {
        originalClassifierName = getClassifierNameById(advancedNormalizedClassifierId);
        // attributeRisk use different name then the originalName for NER classifiers,
        // so we need to make manual mapping here in UI until we have normalized API to work with
        const nerEntityName = `${advancedNormalizedClassifierNerEntityName}`;
        attributeRiskName = getAttributeRiskName(type, nerEntityName, modelId);
      }
      const normalizedAttribute = normalizedAttributes.find(({ originalName }) => originalName === attributeRiskName);

      if (normalizedAttribute) {
        const { originalName, friendlyName, count } = normalizedAttribute;

        if (friendlyName) {
          const usedWithFriendlyNameClassifiers = advancedNormalizedClassifiers.filter(
            ({ name }) => name === friendlyName,
          );

          if (usedWithFriendlyNameClassifiers.length > 0) {
            usedWithFriendlyNameClassifiers.forEach(classifier => {
              classifier.originalData = [...(classifier.originalData || []), advancedNormalizedClassifier];
              classifier.originalNames = [...(classifier.originalNames || []), originalName];
            });
            return advancedNormalizedClassifiers;
          } else {
            return [
              ...advancedNormalizedClassifiers,
              {
                id: advancedNormalizedClassifierId,
                name: friendlyName,
                type,
                attributeRiskName,
                totalFindings: count,
                originalData: [advancedNormalizedClassifier],
                originalNames: [originalName],
              },
            ];
          }
        } else {
          return [
            ...advancedNormalizedClassifiers,
            {
              id: advancedNormalizedClassifierId,
              name: `${type === 'ner' ? 'NER::' : ''}${originalClassifierName}`,
              type,
              attributeRiskName,
              totalFindings: count,
              originalData: [advancedNormalizedClassifier],
            },
          ];
        }
      } else {
        return [
          ...advancedNormalizedClassifiers,
          {
            id: advancedNormalizedClassifierId,
            name: `${type === 'ner' ? 'NER::' : ''}${originalClassifierName}`,
            type,
            attributeRiskName,
            totalFindings: 0,
            originalData: [advancedNormalizedClassifier],
          },
        ];
      }
    }, []);

  return [...normalizedClassifiers, ...normalizedAdvancedClassifiers];
};

export const getNormalizedClassifiers = async (bigIdGridQuery?: string): Promise<NormalizedClassifiersResult> => {
  try {
    if (getApplicationPreference('USE_NORMALIZED_CLASSIFIERS_API')) {
      const query = bigIdGridQuery ? `?${bigIdGridQuery}` : '';
      const {
        data: { data },
      } = await httpService.fetch<NormalizedClassifierResponse>(`classifiers${query}`);

      return data;
    }
    return { classifiers: await getNormalizedClassifiersOld() };
  } catch ({ message }) {
    notificationService.error('An error has occurred while fetching the normalized classifiers.');
    console.error(`An error has occurred: ${message}`);
  }
};

export const getNormalizedClassifiersViaPost = async (
  bigidGridPayload?: BigidGridQueryComponents,
  classifierNamesAsIds?: string[],
  filterClassificationRegex = false,
): Promise<NormalizedClassifiersResult> => {
  try {
    const payload = bigidGridPayload ? bigidGridPayload : {};
    const {
      data: { data },
    } = await httpService.post<NormalizedClassifierResponse>(
      `classifiers${filterClassificationRegex ? '?filterClassificationRegex=true' : ''}`,
      {
        query: payload,
        ...(classifierNamesAsIds != null && { selectedClassifiers: classifierNamesAsIds }),
      } as QueryParamsAndDataObject,
    );
    return data;
  } catch ({ message }) {
    notificationService.error('An error has occurred while fetching the normalized classifiers via post request.');
    console.error(`An error has occurred: ${message}`);
  }
};
