export enum State {
  'pending' = 'pending',
  'queued' = 'queued',
  'in_progress' = 'in_progress',
  'completed' = 'completed',
  'failed' = 'failed',
  'total' = 'total',
  'canceled' = 'canceled',
  'stopped' = 'stopped',
}
export type States = Partial<Record<State, number>>;

export type PartInfoName =
  | 'Queued'
  | 'RedisQueued'
  | 'Started'
  | 'InProgress'
  | 'Total'
  | 'Failed'
  | 'Canceled'
  | 'Completed'
  | 'Stopped'
  | 'Done';
export type DataMinimizationPartsInfo = Partial<Record<PartInfoName, number>>;

export type User = {
  id: string;
  first_name: string;
  last_name?: string;
  origin?: string;
};
