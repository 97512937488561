import {
  businessGlossaryService,
  GlossaryItem,
  GlossaryItemType,
} from '../../../../administration/generalSettings/businessGlossary/businessGlossary.service';
import { BigidGridQueryComponents, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { escapeRegExp, orderBy } from 'lodash';
import { getAllGlossaryDomains, getAllGlossaryOwners, getAllGlossarySubDomains } from '../businessGlossaryUtils';

export const gridClientSideFiltering = (data: GlossaryItem[], { filter, sort }: BigidGridQueryComponents) => {
  const freeTextFields: Array<keyof GlossaryItem> = [
    'name',
    'description',
    'domain',
    'sub_domain',
    'owner',
    'glossary_id',
    'id',
    '_id',
  ];

  let res = data;

  filter.forEach(({ field, value, operator }) => {
    if (operator === 'equal' && value) {
      res = res.filter(glossaryItem => glossaryItem[field as keyof GlossaryItem] === value);
    }
  });

  const textValue = filter.find(({ field, value }) => field === 'text' && value)?.value;
  const textValueRegex = textValue ? new RegExp(escapeRegExp(String(textValue)), 'i') : undefined;
  res = textValueRegex
    ? res.filter(row => freeTextFields.some(fieldName => textValueRegex.test(String(row[fieldName]))))
    : res;

  if (sort?.length) {
    const fields = sort.map(
      ({ field }) =>
        (row: any) =>
          typeof row[field] === 'string' ? row[field].toLowerCase() : row[field],
    );
    const orders = sort.map(({ order }) => order || 'asc');
    res = orderBy(res, fields, orders);
  }

  return res;
};

export const getFilterOptions = (values: string[]) =>
  values.map(value => ({
    label: value,
    value,
    isSelected: false,
  }));

export async function getInitialFilterToolbarConfig(): Promise<BigidGridWithToolbarProps<any>['filterToolbarConfig']> {
  const glossaryItems = await businessGlossaryService.getGlossaryItems();
  const owners = getAllGlossaryOwners(glossaryItems);
  const domains = getAllGlossaryDomains(glossaryItems);
  const subDomains = getAllGlossarySubDomains(glossaryItems);

  return {
    filters: [
      {
        title: 'Type',
        field: 'type',
        operator: 'equal',
        disabled: false,
        options: getFilterOptions(Object.values(GlossaryItemType)),
        single: true,
        value: [],
      },
      {
        title: 'Domain',
        field: 'domain',
        operator: 'equal',
        disabled: false,
        options: getFilterOptions(domains),
        single: true,
        value: [],
      },
      {
        title: 'Sub-domain',
        field: 'sub_domain',
        operator: 'equal',
        disabled: false,
        options: getFilterOptions(subDomains),
        single: true,
        value: [],
      },
      {
        title: 'Owner',
        field: 'owner',
        operator: 'equal',
        disabled: false,
        options: getFilterOptions(owners),
        single: true,
        value: [],
      },
    ],
    searchConfig: {
      searchFilterKeys: ['text'],
      placeholder: 'name, description or id',
      initialValue: '',
      operator: 'contains',
    },
  };
}
