import React from 'react';
import { DataSourceIconByDsType } from '@bigid-ui/icons';
import { Header } from './mappers/styles';
import { BigidHeading5 } from '@bigid-ui/components';
import { ChunkErrorBoundary } from '../SelectDataSourceOnboardingType/components/ChunkErrorBoundary';

type DataSourceConnectionModalHeaderProps = {
  title: string;
  dsType?: string;
};

const styles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '0.5rem',
};

export const DataSourceConnectionModalHeader = ({
  title,
  dsType,
}: DataSourceConnectionModalHeaderProps): JSX.Element => {
  return (
    <Header.Content>
      <BigidHeading5 sx={styles}>
        <Header.Image>
          <ChunkErrorBoundary>
            <DataSourceIconByDsType dataSourceType={dsType} width={22} height={22} />
          </ChunkErrorBoundary>
        </Header.Image>
        {title}
      </BigidHeading5>
    </Header.Content>
  );
};
