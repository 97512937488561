import { BigidSystemDialogOptions, openSystemDialog } from './systemDialogService';
import { BigidIconType, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import {
  ConfirmationDialogContent,
  ConfirmationDialogContentProps,
} from '../components/ConfirmationDialogContent/ConfirmationDialogContent';
import { ReactElement } from 'react';
import { commonTranslate } from '../translations';

export interface ConfirmationDialogOptions {
  entityNameSingular: string;
  entityNamePlural?: string;
  entitiesCount?: number;
  actionName?: string;
  allSelected?: boolean;
  postfix?: string;
  customTitle?: string;
  customDescription?: string;
  actionButtonName?: string;
  cancelButtonName?: string;
  icon?: BigidIconType;
  ContentComponent?: ReactElement;
  isContentScrollable?: boolean;
  showCloseIcon?: boolean;
  maxWidth?: BigidSystemDialogOptions<ConfirmationDialogContentProps>['maxWidth'];
  borderBottom?: boolean;
}

export const showConfirmationDialog = ({
  entityNameSingular,
  entityNamePlural,
  entitiesCount,
  allSelected = false,
  actionName = 'Delete',
  actionButtonName = 'Confirm',
  customTitle,
  customDescription,
  cancelButtonName = 'Cancel',
  isContentScrollable = false,
  icon,
  ContentComponent = null,
  showCloseIcon = true,
  maxWidth = 'xs',
  borderBottom = false,
}: ConfirmationDialogOptions): Promise<boolean> => {
  const entityName = entitiesCount > 1 ? commonTranslate(entityNamePlural) : commonTranslate(entityNameSingular);

  return new Promise(resolve => {
    const options: BigidSystemDialogOptions<ConfirmationDialogContentProps> = {
      title: customTitle || `${commonTranslate(actionName)} ${commonTranslate(entityName)}`,
      maxWidth,
      icon,
      isContentScrollable,
      buttons: [
        {
          text: commonTranslate(cancelButtonName),
          component: SecondaryButton,
          onClick: () => {
            resolve(false);
          },
          isClose: true,
        },
        {
          text: commonTranslate(actionButtonName),
          component: PrimaryButton,
          onClick: () => {
            resolve(true);
          },
          isClose: true,
        },
      ],
      onClose: () => {
        resolve(false);
      },
      content: ConfirmationDialogContent,
      contentProps: {
        entityName: commonTranslate(entityName),
        entitiesCount,
        actionName: commonTranslate(actionName),
        allSelected,
        customDescription: commonTranslate(customDescription),
        ContentComponent,
      },
      borderTop: true,
      borderBottom,
      showCloseIcon,
    };
    openSystemDialog<ConfirmationDialogContentProps>(options);
  });
};
