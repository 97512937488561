import React, { useEffect, useState, Suspense } from 'react';
import angular from 'angular';
import styled from '@emotion/styled';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import {
  BigidBody1,
  BigidButtonIcon,
  BigidChip,
  BigidTooltip,
  SecondaryButton,
  BigidDialog,
  BigidLoader,
} from '@bigid-ui/components';
import { BigidInfoSmallIcon, BigidAddIcon, BigidEditIcon, BigidResetIcon } from '@bigid-ui/icons';
import { MappedControlsTooltip } from './MappedControlsTooltip';
import { useTheme } from '@mui/styles';
import { frameworkIconGenerator } from '../../../../react/views/Frameworks/utils';
import { useLocalTranslation } from '../translations';
import { MappedControlsDialog } from './MappedControlsDialog/MappedControlsDialog';
import { resetMappedControls } from '../../../../react/views/Frameworks/FrameworksServices';
import { notificationService } from '../../../../react/services/notificationService';
import { MappedControlsDialogReset } from './MappedControlsDialog/MappedControlsDialogReset';
import { generateDataAid } from '@bigid-ui/utils';
import { NormalizedMappedControlsProps } from '../utils';
import { POLICIES_PERMISSIONS } from '@bigid/permissions';
import { licenseService } from '../../../../react/services/licenseService';
import { isPermitted } from '../../../../react/services/userPermissionsService';

const Main = styled.div({
  width: '100%',
  padding: '24px 0',
});

const WidgetWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  width: 'calc(100% - 16px)',
  minHeight: '100px',
  backgroundColor: theme.palette.bigid.gray100,
  border: `1px solid ${theme.palette.bigid.gray200}`,
  borderRadius: '4px',
}));

const WidgetView = styled.div({
  width: '100%',
  minHeight: '60px',
  padding: '24px',
  display: 'flex',
  columnGap: '8px',
  rowGap: '8px',
  justifyItems: 'center',
  flexDirection: 'column',
});

const WidgetAdd = styled.div({
  minHeight: '60px',
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
  columnGap: '8px',
  justifyContent: 'center',
});

const WidgetAddTitle = styled.div({
  height: '32px',
  display: 'flex',
  alignItems: 'center',
});

const WidgetAddButton = styled.div({
  width: '160px',
});

const WidgetViewTitle = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const WidgetViewTitleWrapper = styled.div({
  display: 'flex',
  columnGap: '8px',
  alignItems: 'center',
});

const WidgetViewFrameworks = styled.div({
  display: 'flex',
  columnGap: '2px',
  rowGap: '8px',
  flexWrap: 'wrap',
  width: '100%',
});

const ChipContent = styled.div({
  display: 'flex',
  columnGap: '8px',
  alignItems: 'center',
});

const ChipContentTitle = styled(BigidBody1)({
  width: '54px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const ChipControls = styled.div(({ theme }) => ({
  width: '18px',
  height: '18px',
  backgroundColor: theme.palette.bigid.gray150,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const IconWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

interface ControlsWidgetProps {
  mappedControls: NormalizedMappedControlsProps[];
  policyFqdn: string;
  fetchMappedControls: () => Promise<null>;
  isMappedCotrolsButtonEnabled: boolean;
  dataAid?: string;
}

export const MappedControlsWidget = ({
  mappedControls = [],
  policyFqdn,
  fetchMappedControls,
  isMappedCotrolsButtonEnabled,
  dataAid = 'ControlsWidget',
}: ControlsWidgetProps) => {
  const [isContentLoading, setIsContentLoading] = useState<boolean>(false);
  const [currentFrameworkName, setCurrentFrameworkName] = useState<string>('');
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
  const [isResetDialogVisible, setIsResetDialogVisible] = useState<boolean>(false);
  const [dynamicMappedControls, setDynamicMappedControls] = useState<NormalizedMappedControlsProps[]>(mappedControls);
  const Theme = useTheme();
  const { t } = useLocalTranslation();
  const isShown = (permission: string) =>
    !licenseService.shouldBlockUserWithLicenseExpired() && isPermitted(permission);
  const isEditPolicyPermission = isShown(POLICIES_PERMISSIONS.EDIT.name);

  const handleDynamicControls = (dynamicControls: NormalizedMappedControlsProps[]) => {
    setDynamicMappedControls(dynamicControls);
  };

  const handleContentLoading = (value: boolean) => {
    setIsContentLoading(value);
  };

  const handleDialogVisibility = (value: boolean) => {
    setIsDialogVisible(value);
  };

  const handleResetDialog = (value: boolean, frameworkName?: string) => {
    setIsResetDialogVisible(value);
    setCurrentFrameworkName(frameworkName);
  };

  const handleResetControls = async (frameworkName: string) => {
    handleContentLoading(true);
    try {
      const [data] = await Promise.all([
        await resetMappedControls(policyFqdn, currentFrameworkName),
        await fetchMappedControls(),
      ]);
      if (Boolean(frameworkName)) {
        const updatedDynamicControls = dynamicMappedControls?.map((dynamicMapped: NormalizedMappedControlsProps) =>
          dynamicMapped.widgetFrameworkName === frameworkName
            ? {
                ...dynamicMapped,
                controls: data,
              }
            : dynamicMapped,
        );
        handleDynamicControls(updatedDynamicControls);
      }
      notificationService.success(t('messages.resetMappedSuccess', { frameworkName: currentFrameworkName }), {
        duration: 3000,
      });
    } catch {
      notificationService.error(t('messages.resetMappedError'), { duration: 3000 });
    } finally {
      handleContentLoading(false);
      setIsResetDialogVisible(false);
    }
  };

  useEffect(() => {
    if (!isDialogVisible) {
      setCurrentFrameworkName('');
    } else {
      handleDynamicControls(mappedControls);
    }
  }, [isDialogVisible]);

  return (
    <Main data-aid={dataAid}>
      <WidgetWrapper>
        {mappedControls.length > 0 ? (
          <WidgetView>
            <WidgetViewTitle>
              <WidgetViewTitleWrapper>
                <BigidBody1 data-aid={generateDataAid(dataAid, ['title'])}>{t('title')}</BigidBody1>
                <BigidTooltip title={t('description')} placement="top">
                  <Suspense fallback={<BigidLoader />}>
                    <IconWrapper>
                      <BigidInfoSmallIcon />
                    </IconWrapper>
                  </Suspense>
                </BigidTooltip>
              </WidgetViewTitleWrapper>
              <WidgetViewTitleWrapper>
                <Suspense fallback={<BigidLoader />}>
                  <BigidButtonIcon
                    disabled={!isEditPolicyPermission}
                    icon={BigidEditIcon}
                    onClick={() => handleDialogVisibility(true)}
                  />
                </Suspense>
                <Suspense fallback={<BigidLoader />}>
                  <BigidButtonIcon
                    disabled={!isEditPolicyPermission}
                    icon={BigidResetIcon}
                    onClick={() => handleResetDialog(true)}
                  />
                </Suspense>
              </WidgetViewTitleWrapper>
            </WidgetViewTitle>
            <WidgetViewFrameworks>
              {mappedControls.map((mappedControl: NormalizedMappedControlsProps) => {
                const Icon = frameworkIconGenerator(mappedControl.widgetFrameworkName);
                return (
                  <BigidChip
                    key={mappedControl.widgetFrameworkName}
                    label={
                      <ChipContent>
                        <ChipContentTitle>{mappedControl.widgetFrameworkName}</ChipContentTitle>
                        <ChipControls
                          data-aid={generateDataAid(dataAid, [mappedControl.widgetFrameworkName, 'amount'])}
                        >
                          {mappedControl.controls?.length || 0}
                        </ChipControls>
                      </ChipContent>
                    }
                    dataAid={generateDataAid(dataAid, [mappedControl.widgetFrameworkName])}
                    icon={
                      <Suspense fallback={<BigidLoader />}>
                        <Icon size="small" width={24} height={24} />
                      </Suspense>
                    }
                    iconPlacement="left"
                    bgColor="white"
                    outlineColor={Theme.palette.bigid.gray200}
                    outline="solid"
                    shadow={true}
                    tooltipProps={{
                      title: (
                        <MappedControlsTooltip
                          widgetFrameworkName={mappedControl.widgetFrameworkName}
                          controls={mappedControl.controls || []}
                        />
                      ),
                      placement: 'top',
                    }}
                  />
                );
              })}
            </WidgetViewFrameworks>
          </WidgetView>
        ) : (
          <WidgetAdd>
            <WidgetAddTitle>
              <BigidBody1 data-aid={generateDataAid(dataAid, ['title'])}>{t('title')}</BigidBody1>
            </WidgetAddTitle>
            <BigidBody1 data-aid={generateDataAid(dataAid, ['description'])}>{t('description')}</BigidBody1>
            <WidgetAddButton>
              <SecondaryButton
                size="medium"
                onClick={() => handleDialogVisibility(true)}
                startIcon={
                  <Suspense fallback={<BigidLoader />}>
                    <BigidAddIcon />
                  </Suspense>
                }
                text="Connect Requirements"
                width="auto"
                disabled={!isEditPolicyPermission || !isMappedCotrolsButtonEnabled}
              />
            </WidgetAddButton>
          </WidgetAdd>
        )}
      </WidgetWrapper>
      <BigidDialog
        maxWidth="lg"
        title={t('dialog.description')}
        isOpen={isDialogVisible}
        onClose={() => {
          handleDialogVisibility(false);
        }}
      >
        <MappedControlsDialog
          dynamicMappedControls={dynamicMappedControls}
          staticMappedControls={mappedControls}
          handleDialogVisibility={handleDialogVisibility}
          policyFqdn={policyFqdn}
          fetchMappedControls={fetchMappedControls}
          handleResetDialog={handleResetDialog}
          handleDynamicControls={handleDynamicControls}
          isContentLoading={isContentLoading}
          handleContentLoading={handleContentLoading}
        />
      </BigidDialog>
      <BigidDialog
        maxWidth="xs"
        title={t('dialog.resetTitle')}
        isOpen={isResetDialogVisible}
        onClose={() => handleResetDialog(false)}
      >
        <MappedControlsDialogReset
          handleResetDialog={handleResetDialog}
          handleResetControls={handleResetControls}
          currentFrameworkName={currentFrameworkName}
          dataAid={generateDataAid(dataAid, ['dialog', 'reset'])}
        />
      </BigidDialog>
    </Main>
  );
};

angular
  .module('app')
  .component(
    'mappedControlsWidget',
    convertToAngular(MappedControlsWidget, [
      'mappedControls',
      'policyFqdn',
      'fetchMappedControls',
      'isMappedCotrolsButtonEnabled',
    ]),
  );
