import { GetErrorResolutionResponse } from '../views/DataSources/DataSourceConnectionModal/types';
import {
  DataSourceModel,
  DataSourcesResponse,
  DsCountByTypeResponse,
  TestConnectionStatusResponse,
} from '../views/DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { httpService } from './httpService';
import { notificationService } from './notificationService';

const DS_ENRICHMENT_ENDPOINT = 'ds-connections-enrichment';
const DS_CONNECTIONS_ENDPOINT = 'ds-connections';

export const getDataSources = async (
  gridConfigQuery: string,
  useDsEnrichmentEndPoint = false,
): Promise<{ dsConnections: DataSourceModel[]; count: number }> => {
  const fetchEndPoint = useDsEnrichmentEndPoint ? DS_ENRICHMENT_ENDPOINT : DS_CONNECTIONS_ENDPOINT;
  try {
    const {
      data: {
        data: { ds_connections, totalCount: count },
      },
    } = await httpService.fetch<{ data: DataSourcesResponse }>(`${fetchEndPoint}?${gridConfigQuery}`);

    return {
      dsConnections: ds_connections,
      count,
    };
  } catch (e) {
    console.error(e);
    notificationService.error('Could not fetch data. See logs for more information');
  }
};

export const getDataSourcesByNames = async (dataSourceNames?: string[]) => {
  const filterByNames = JSON.stringify([{ field: 'name', value: dataSourceNames, operator: 'in' }]);
  const {
    data: {
      data: { ds_connections: dataSources },
    },
  } = await httpService.fetch<{ data: DataSourcesResponse }>(`ds-connections?filter=${filterByNames}`);

  return dataSources;
};

export const getDataSourcesFilteredByNames = async (dataSourceNames?: string[]) => {
  const filterByNames = [{ field: 'name', value: dataSourceNames, operator: 'in' }];
  const {
    data: {
      data: { ds_connections: dataSources },
    },
  } = await httpService.post<{ data: DataSourcesResponse }>(`ds-connections`, {
    query: { filter: filterByNames, limit: 800 },
  });
  return dataSources;
};

export const getDataSourcesUpdate = async (payload: Record<string, any>) => {
  const {
    data: {
      data: { ds_connections: dataSources, totalCount: count },
    },
  } = await httpService.post<{ data: DataSourcesResponse }>(`ds-connections`, payload);
  return { dataSources, count };
};

export const getDataSourceCountGroupedByType = async (): Promise<DsCountByTypeResponse> => {
  const {
    data: {
      data: { dsCountByType, totalCount },
    },
  } = await httpService.fetch<{ data: DsCountByTypeResponse }>(`ds-connections/count-by-type`);
  return { dsCountByType, totalCount };
};

export const getSingleDsByName = async (dataSourceNames: string) => {
  const filterByName = JSON.stringify([{ field: 'name', value: dataSourceNames, operator: 'equal' }]);
  const {
    data: {
      data: { ds_connections: dataSources },
    },
  } = await httpService.fetch<{ data: DataSourcesResponse }>(`ds-connections?filter=${filterByName}`);

  return dataSources;
};

export const isDataSourceExist = async (dsName: string): Promise<boolean> => {
  try {
    const result = await httpService.head<{ data: { isExist: boolean } }>(`ds-connections/${dsName}`);
    return result?.headers?.['data-source-exist'] === 'true';
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getTestConnectionSSEStatus = async (dsName: string): Promise<TestConnectionStatusResponse> => {
  const {
    data: {
      data: { isInProgress, seeEventID },
    },
  } = await httpService.fetch<{ data: TestConnectionStatusResponse }>(`ds-connections/${dsName}/test-status`);
  return { isInProgress, seeEventID };
};

export const getLatestErrorResolutionForDs = async (dsName: string): Promise<GetErrorResolutionResponse> => {
  const {
    data: {
      data: { errorResolution, error },
    },
  } = await httpService.fetch<{ data: GetErrorResolutionResponse }>(`ds-connections/${dsName}/error-resolution`);
  return { errorResolution, error };
};
