import React, { Dispatch, FC, Fragment, SetStateAction, useEffect, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { cloneDeep, isEmpty, isUndefined } from 'lodash';
import { BigidColors, BigidColorsV2 } from '@bigid-ui/components';
import { BigidCollapsable } from '../../../../components/BigidCollapsable/BigidCollapsable';
import { CustomAppParam, InputTypes } from '../../views/EditCustomApp/EditCustomApp';
import { isObjectTypeParam } from '../../utils/CustomAppUtils';
import { SingleParamRow } from './SingleParamRow';
import { CredentialsParamsGroup } from './CredentialsParamsGroup';
import { ParamTypes } from '../../utils/CustomAppTypes';

interface CustomAppParamsTableProps {
  params: CustomAppParam[];
  onParamChange: (key: string, value: string) => void;
  onParamToggle?: (paramKey: string, paramValue: string, enabled: boolean) => void;
  setIsValuesValid: Dispatch<SetStateAction<boolean>>;
  values?: Record<string, string>;
  readOnly?: boolean;
  shouldEnableOverriding?: boolean;
  isGlobalParams?: boolean;
}

type CategorizedParams = Record<string, CustomAppParam[]>;

const MANDATORY_ERROR_MESSAGE = 'Missing mandatory field';

const DEFAULT_PARAM_CATEGORY = 'General Parameters';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '16px',
  },
  mandatoryParam: {
    paddingLeft: '2px',
    color: BigidColorsV2.red[600],
  },
  missingValue: {
    color: BigidColors.failureRed,
  },
  paramInfo: {
    display: 'flex',
    height: '24px',
  },
  paramValue: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  description: {
    paddingLeft: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  paramName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
  },
  paramValueSwitcher: {
    position: 'absolute',
    left: '100%',
  },
  categoryParams: {
    margin: '10px',
  },
});

const getIsValueValid = (param: CustomAppParam, value: string) => {
  const { isMandatory, inputItems, inputType } = param;
  if (isMandatory) {
    if ([InputTypes.MULTIPLE_SELECTION, InputTypes.SINGLE_ELECTION].includes(inputType)) {
      return value?.split(',').every(v => inputItems.includes(v));
    }
    return !isEmpty(value);
  }
  return true;
};
const getCategorizedParams = (params: CustomAppParam[]) => {
  return params.reduce((accumulatedCategorizedParams: CategorizedParams, currentParam: CustomAppParam) => {
    const paramCategory = currentParam.paramCategory || DEFAULT_PARAM_CATEGORY;
    if (!accumulatedCategorizedParams[paramCategory]) {
      accumulatedCategorizedParams[paramCategory] = [];
    }
    accumulatedCategorizedParams[paramCategory].push(currentParam);
    return accumulatedCategorizedParams;
  }, {} as CategorizedParams);
};

const normalizedCategorizedParams = (paramsInCategory: CustomAppParam[], values: Record<string, string>) => {
  return paramsInCategory
    .filter(param => !isObjectTypeParam(param))
    .map(param => {
      const { name, description, isMandatory, friendlyName } = param;
      const paramValue = values?.[name];
      const isValueValid = getIsValueValid(param, paramValue);
      const errorMessage = isValueValid ? '' : MANDATORY_ERROR_MESSAGE;
      return { ...param, name, description, isMandatory, friendlyName, errorMessage, paramValue };
    });
};

const getCredentialsGroupedParams = (
  paramsInCategory: CustomAppParam[],
  values: Record<string, string>,
): Map<CustomAppParam, CustomAppParam[]> => {
  const credentialTypeParams = paramsInCategory.filter(param => param.type === ParamTypes.CREDENTIAL);
  return credentialTypeParams.reduce(
    (acc: Map<CustomAppParam, CustomAppParam[]>, currCredentialParam: CustomAppParam) => {
      const relevantCredentialParamNames = new Set(Object.values(currCredentialParam.appFieldIdentifiersMap ?? {}));
      const paramsRelatedToCredentialParam = paramsInCategory.filter(param =>
        relevantCredentialParamNames.has(param.name),
      );
      acc.set(
        normalizedCategorizedParams([currCredentialParam], values)[0],
        normalizedCategorizedParams(paramsRelatedToCredentialParam, values),
      );
      return acc;
    },
    new Map<CustomAppParam, CustomAppParam[]>(),
  );
};

const getFilteredParamsWithoutCredentials = (paramsInCategory: CustomAppParam[]): CustomAppParam[] => {
  const credentialsFieldsNames = getCredentialsFieldsNames(paramsInCategory);
  return paramsInCategory.filter(
    param => param.type !== ParamTypes.CREDENTIAL && !credentialsFieldsNames.has(param.name),
  );
};

const getCredentialsFieldsNames = (paramsInCategory: CustomAppParam[]) => {
  return new Set(
    paramsInCategory
      .filter(param => param.type === ParamTypes.CREDENTIAL)
      .map(param => Object.values(param.appFieldIdentifiersMap ?? {}))
      .flat(),
  );
};

export const CustomAppParamsTable: FC<CustomAppParamsTableProps> = ({
  params = [],
  onParamChange,
  onParamToggle,
  setIsValuesValid,
  values: valuesBeforeAddingNewParams = {},
  readOnly = false,
  shouldEnableOverriding = false,
  isGlobalParams = false,
}) => {
  const categorizedParams = getCategorizedParams(params);

  const updateCredentialFieldValue = (credentialParamName: string, isUsingCredentialField: boolean) => {
    setCredentialFieldStateMap(prevState => {
      const newState = new Map(prevState);
      newState.set(credentialParamName, isUsingCredentialField);
      return newState;
    });
  };
  const [credentialFieldStateMap, setCredentialFieldStateMap] = useState<Map<string, boolean>>(new Map());

  const initialCollapseStates = () => {
    return Object.keys(categorizedParams).reduce((acc, category) => {
      acc[category] = true;
      return acc;
    }, {} as Record<string, boolean>);
  };

  const [collapseStates, setCollapseStates] = useState<Record<string, boolean>>(initialCollapseStates);
  const classes = useStyles({});
  const initialValues = cloneDeep(valuesBeforeAddingNewParams);
  const values = useMemo(() => {
    return params.reduce(
      (acc, { defaultValue, value = '', name }) => {
        if (isUndefined(acc[name])) {
          acc[name] = defaultValue;
          if (isGlobalParams && value) acc[name] = value;
        }
        return acc;
      },
      isGlobalParams ? initialValues : valuesBeforeAddingNewParams,
    );
  }, [params, isGlobalParams, initialValues, valuesBeforeAddingNewParams]);

  useEffect(
    () => {
      const credentialParams = params.filter(({ type }) => type === ParamTypes.CREDENTIAL);
      const credentialParamRelatedFields = credentialParams.reduce((acc, credParam) => {
        if (credParam.appFieldIdentifiersMap) {
          acc.push(...Object.values(credParam.appFieldIdentifiersMap));
        }
        return acc;
      }, []);

      for (const param of params) {
        const isUsingCredentialField = credentialFieldStateMap.get(param.name) || false;
        if (!isUsingCredentialField && param.type === ParamTypes.CREDENTIAL) {
          continue;
        } else if (isUsingCredentialField && credentialParamRelatedFields.includes(param.name)) {
          continue;
        }
        if (!getIsValueValid(param, values?.[param.name]) && param.type !== ParamTypes.CREDENTIAL) {
          setIsValuesValid(false);
          return;
        }
      }
      setIsValuesValid(true);
    },
    //eslint-disable-next-line
      [params, setIsValuesValid, values]);

  return (
    <div>
      {Object.entries(categorizedParams).map(([category, paramsInCategory]) => {
        return (
          <Fragment key={category}>
            <BigidCollapsable
              leftHeader={category}
              isOpen={collapseStates[category]}
              onCollapseClick={() => {
                setCollapseStates(prevStates => ({
                  ...prevStates,
                  [category]: !prevStates[category],
                }));
              }}
            >
              <div className={classes.categoryParams}>
                {normalizedCategorizedParams(getFilteredParamsWithoutCredentials(paramsInCategory), values).map(
                  (param, key) => {
                    return (
                      <SingleParamRow
                        param={param}
                        key={key}
                        shouldEnableOverriding={shouldEnableOverriding}
                        values={valuesBeforeAddingNewParams}
                        readOnly={readOnly}
                        onParamToggle={onParamToggle}
                        onParamChange={onParamChange}
                      />
                    );
                  },
                )}
                <div>
                  {Array.from(getCredentialsGroupedParams(paramsInCategory, values)).map(
                    ([credentialParam, relatedParams], key) => {
                      return (
                        <CredentialsParamsGroup
                          key={key}
                          credentialParam={credentialParam}
                          relatedParams={relatedParams}
                          onParamChange={onParamChange}
                          onParamToggle={onParamToggle}
                          values={valuesBeforeAddingNewParams}
                          readOnly={readOnly}
                          shouldEnableOverriding={shouldEnableOverriding}
                          isUsingCredentialField={credentialFieldStateMap.get(credentialParam.name) || false}
                          updateCredentialFieldValue={isUsingCredentialField =>
                            updateCredentialFieldValue(credentialParam.name, isUsingCredentialField)
                          }
                        />
                      );
                    },
                  )}
                </div>
              </div>
            </BigidCollapsable>
          </Fragment>
        );
      })}
    </div>
  );
};
