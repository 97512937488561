import React, { FC, useState, useRef } from 'react';
import { BigidMenu, TertiaryButton } from '@bigid-ui/components';
import { BigidChevronDownIcon, BigidImportIcon, BigidExportIcon, BigidAiIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../translations';
import { isPermitted } from '../../../services/userPermissionsService';
import { GENERAL_SETTINGS_PERMISSIONS } from '@bigid/permissions';
import { businessGlossaryService } from '../../../../administration/generalSettings/businessGlossary/businessGlossary.service';
import { downloadFile } from '../../../services/downloadFile';
import { notificationService } from '../../../services/notificationService';
import { isBusinessTermsEnabled } from '../../../utilities/featureFlagUtils';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';

type ActionButtonProps = {
  onImportDialogOpen: () => void;
  isExportInProgress: boolean;
  setIsExportInProgress: (status: boolean) => void;
};

export const ActionsButton: FC<ActionButtonProps> = ({
  onImportDialogOpen,
  isExportInProgress,
  setIsExportInProgress,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useLocalTranslation('BusinessGlossary.headerButtons');

  const onClickHandler = () => setIsOpen(state => !state);

  const onExportHandler = async () => {
    try {
      setIsExportInProgress(true);
      const response = await businessGlossaryService.getGlossaryDataExport();
      downloadFile(response, 'attachment/csv', 'glossaryData.csv');
    } catch (err) {
      const errMsg = t('exportActionError');
      notificationService.error(errMsg);
      console.error(`${errMsg} ${err}`);
    } finally {
      setIsExportInProgress(false);
    }
  };

  const onMapHandler = async () => {
    if (
      await showConfirmationDialog({
        entityNameSingular: 'businessTerms',
        actionButtonName: 'Start mapping',
        customTitle: 'Map business terms',
        customDescription:
          'Our AI will map the business terms in this list directly to relevant objects in your data catalog',
        borderBottom: true,
      })
    ) {
      try {
        const response = await businessGlossaryService.mapBusinessTerms();
        notificationService.success(response.data.message);
      } catch (err) {
        notificationService.error(err.message);
      }
    }
  };

  return (
    <div ref={ref}>
      <TertiaryButton
        text={t('actionsButtonTitle')}
        size="large"
        onClick={onClickHandler}
        endIcon={<BigidChevronDownIcon />}
        dataAid={generateDataAid('HeaderButtons', ['actions-button'])}
      />
      <BigidMenu
        open={isOpen}
        onMenuClose={() => setIsOpen(false)}
        anchorEl={ref.current as Element}
        items={[
          ...(isPermitted(GENERAL_SETTINGS_PERMISSIONS.IMPORT_BUSINESS_GLOSSARY.name)
            ? [
                {
                  id: 'import',
                  label: t('importAction'),
                  startIcon: BigidImportIcon,
                  onClick: onImportDialogOpen,
                  disabled: isExportInProgress,
                },
              ]
            : []),
          ...(isPermitted(GENERAL_SETTINGS_PERMISSIONS.EXPORT_BUSINESS_GLOSSARY.name)
            ? [
                {
                  id: 'export',
                  label: t('exportAction'),
                  startIcon: BigidExportIcon,
                  onClick: onExportHandler,
                  disabled: isExportInProgress,
                },
              ]
            : []),
          ...(isBusinessTermsEnabled()
            ? [
                {
                  id: 'map_bt',
                  label: t('mapBusinessTermsAction'),
                  startIcon: BigidAiIcon,
                  onClick: onMapHandler,
                  disabled: isExportInProgress,
                },
              ]
            : []),
        ]}
        paperStyle={{ marginTop: '4px' }}
      />
    </div>
  );
};
