import { ReactText } from 'react';
import { BigidGridRow } from '@bigid-ui/grid';
import { BigidStateProgressBarStatus } from '@bigid-ui/components';
import { SubScanGridRow, SubscanStage } from './ScanInsights/ScanInsightTypes';
import { ScansUITrackingEvent } from './ScansEventTrackerUtils';

export enum ScanActions {
  PAUSE = 'pause',
  RESUME = 'resume',
  STOP = 'stop',
  ARCHIVE = 'archive',
  RESCAN = 'rescan',
  RETRY = 'retry',
  RETRY_PREDICTION = 'Retry Prediction',
  DOWNLOAD_FAILED_OBJECTS_REPORT = 'download failed objects report',
}

export interface ActionResponse {
  success: boolean;
}

export interface ActionType {
  ids: ReactText[];
  type: ScanActions;
  allSelected: boolean;
}

export enum MtSupportedParentScanType {
  METADATA_SCAN = 'metadataScan',
  FULL_SCAN = 'full_scan',
}

export enum ParentScanType {
  METADATA_SCAN = 'metadataScan',
  LINEAGE_SCAN = 'lineageScan',
  FULL_SCAN = 'full_scan',
  LABELING = 'Labeling',
  DATA_IN_MOTION = 'dataInMotion',
  HYPERSCAN = 'hyper_scan',
  ASSESSMENT_SCAN = 'assessment_scan',
  IMPORT_LABELS = 'importLabels',
  DS_TAG = 'dsTag',
}
export enum ScansTypes {
  ID_SUB_SCAN = 'identity_sub_scan',
}

export const scansWithInsights: string[] = [
  ParentScanType.METADATA_SCAN,
  ParentScanType.LINEAGE_SCAN,
  ParentScanType.FULL_SCAN,
  ParentScanType.ASSESSMENT_SCAN,
  ParentScanType.HYPERSCAN,
];

export const dsScanTypes: string[] = [...scansWithInsights, ParentScanType.LABELING, ParentScanType.DATA_IN_MOTION];

export interface ScansGridRow extends BigidGridRow {
  _id: string;
  name: string;
  state: ScanState;
  type: ParentScanType;
  progress: ProgressType;
  runningTime?: string;
  completedAt?: string;
  startTime?: string;
  subScansFailed?: number;
  owners?: string[];
  origin: string;
  isPauseAvailable: boolean;
  isStopAvailable: boolean;
  isResumeAvailable: boolean;
  isRetryAvailable: boolean;
  isReviewFindingsEnabled: boolean;
  piisummary_completed_dt: Date;
  scan_management_complete_dt: Date;
  scanTemplateName?: string;
  isFailedObjects: boolean;
  isRbacPermitted: boolean;
  enumerationStatus?: string;
  updated_at?: string;
  executedBy?: string;
  hasInsight?: boolean;
}

export interface ProgressType {
  state: ScanState;
  complete: number;
  failed?: number;
  total: number;
  isFailedObjects?: boolean;
}

export type ScanState = CompletedParentScanState | ActiveScanState;

export enum CompletedParentScanState {
  STOPPED = 'Stopped',
  FAILED = 'Failed',
  COMPLETED_WITH_FAILURES = 'CompletedWithFailures',
  COMPLETED = 'Completed',
  ABORTED = 'Aborted',
}

export enum ActiveScanState {
  IN_PROGRESS = 'InProgress',
  PAUSED = 'Paused',
  NOT_RUNNING = 'NotRunning',
  PAUSE_REQUESTED = 'PauseRequested',
  STOP_REQUESTED = 'StopRequested',
  RESUME_REQUESTED = 'InitiatingResume',
  STARTED = 'Started',
  QUEUED = 'Queued',
  PENDING = 'Pending',
  COLLECTING_METADATA = 'CollectingMetadata',
}

export enum SubScanStates {
  IN_PROGRESS = 'InProgress',
  PAUSED = 'Paused',
  NOT_RUNNING = 'NotRunning',
  PAUSE_REQUESTED = 'PauseRequested',
  STOP_REQUESTED = 'StopRequested',
  RESUME_REQUESTED = 'InitiatingResume',
  QUEUED = 'Queued',
  PENDING = 'Pending',
  COLLECTING_METADATA = 'CollectingMetadata',
  STOPPED = 'Stopped',
  FAILED = 'Failed',
  COMPLETED_WITH_FAILURES = 'CompletedWithFailures',
  COMPLETED = 'Completed',
  ABORTED = 'Aborted',
}

export interface OwnerChip {
  id: string;
  label: string;
}

export interface OwnerChipResponse {
  value: OwnerChip[];
  isDisabled: boolean;
}

export interface Owner {
  id: string;
  name?: string;
  email?: string;
}

export const MapScanStatusToProgressStatus = {
  [ActiveScanState.IN_PROGRESS]: BigidStateProgressBarStatus.REGULAR,
  [ActiveScanState.PAUSED]: BigidStateProgressBarStatus.PAUSED,
  [ActiveScanState.PENDING]: BigidStateProgressBarStatus.PAUSED,
  [ActiveScanState.PAUSE_REQUESTED]: BigidStateProgressBarStatus.REGULAR,
  [ActiveScanState.STOP_REQUESTED]: BigidStateProgressBarStatus.REGULAR,
  [ActiveScanState.STARTED]: BigidStateProgressBarStatus.REGULAR,
  [ActiveScanState.COLLECTING_METADATA]: BigidStateProgressBarStatus.REGULAR,
  [ActiveScanState.QUEUED]: BigidStateProgressBarStatus.REGULAR,
  [ActiveScanState.RESUME_REQUESTED]: BigidStateProgressBarStatus.REGULAR,
  [ActiveScanState.NOT_RUNNING]: BigidStateProgressBarStatus.STUCK,
  [CompletedParentScanState.COMPLETED]: BigidStateProgressBarStatus.REGULAR,
  [CompletedParentScanState.STOPPED]: BigidStateProgressBarStatus.STUCK,
  [CompletedParentScanState.COMPLETED_WITH_FAILURES]: BigidStateProgressBarStatus.STATUS,
  [CompletedParentScanState.FAILED]: BigidStateProgressBarStatus.STATUS,
  [CompletedParentScanState.ABORTED]: BigidStateProgressBarStatus.STUCK,
};

export interface ParentScanRightFilterParams {
  complete: number;
  failed: number;
  total: number;
  state: ScanState;
  type?: ParentScanType;
  isFailedObjects?: boolean;
  isSubScan?: boolean;
  enumerationStatus?: string;
  parentScanType?: ParentScanType;
  scan?: ScansGridRow;
  isShouldDisplayRetryButton?: boolean;
}

export interface SubScanRightFilterParams {
  complete: number;
  failed: number;
  total: number;
  state: ScanState;
  type: SubscanStage;
  isFailedObjects?: boolean;
  isSubScan?: boolean;
  parentScanType?: ParentScanType;
  enumerationStatus?: string;
  scan?: SubScanGridRow;
  isShouldDisplayRetryButton?: boolean;
  setIsCompleted?: any;
}

export interface ShowScanActionParams {
  state: ScanState;
  stateCondition: ScanState[];
  notEqual?: boolean;
}

export interface ShowSubScanActionParams {
  state: ScanState;
  stateCondition: ScanState[];
  type: SubscanStage;
  notEqual?: boolean;
}

export enum FilterColor {
  RED = 'error',
  DEFAULT = 'textPrimary',
}

export enum SSEType {
  PARENT = 'parent_scan_update',
  CHILD = 'child_scan_update',
}

export enum UpdateType {
  PARENT = 'Parent',
  CHILD = 'Child',
}

export enum SSEActionType {
  ADD = 'Add',
  UPDATE = 'Update',
  COMPLETE = 'Complete',
  ABORTED = 'Aborted',
  DELETE = 'Delete',
}

export type ScanUpdateSSE = {
  id: string;
  type: UpdateType;
  action: SSEActionType;
  payload: ScansGridRow | SubScanGridRow;
};

export interface CreateScanButtonInterface {
  state: string;
  action: ScansUITrackingEvent;
  label: string;
  dataAid: string;
  isShowIcon: boolean;
  dataTourId?: string;
}

export interface GetProgressPercentageParam {
  complete: number;
  total: number;
  isScanRunning: boolean;
  isSubScan: boolean;
}
