import React, { FC } from 'react';
import { styled } from '@mui/material';
import { BigidLink, LinkColor } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { DataSourceIconByDsType } from '@bigid-ui/icons';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { getSupportedDataSources } from '../../../utilities/dataSourcesUtils';
import { $state } from '../../../services/angularServices';
import { notificationService } from '../../../services/notificationService';
import { DataSourceModel } from './DataSourceConnectionTypes';
import { isNewConnectivityExperienceEnabled } from '../DataSourceConfiguration/utils/isNewConnectivityExperienceEnabled';
import { ChunkErrorBoundary } from '../SelectDataSourceOnboardingType/components/ChunkErrorBoundary';

interface DataSourceLayoutNameCellProps {
  dataAid?: string;
  row: DataSourceModel;
  iconWidth?: number;
  isPlainText?: boolean;
}

const Root = styled('div')`
  width: 100%;
  height: 100%;
`;

const CellContent = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const IconContainer = styled('div')`
  margin-right: 8px;
  display: flex;
`;

const DataSourceName = styled(BigidLink)`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DataSourceLayoutNameCell: FC<DataSourceLayoutNameCellProps> = ({
  dataAid = 'DataSourceLayoutNameCell',
  row,
  iconWidth = 32,
  isPlainText,
}) => {
  const { id, type, archived } = row;

  const onDataSourceNameClick = async (event: React.MouseEvent<Element>) => {
    event?.stopPropagation();

    if (archived) {
      notificationService.warning('Data source cannot be edited because it is archived');
      return {};
    }

    const supportedDataSources = await getSupportedDataSources();
    const isNewDsConfigPage =
      getApplicationPreference('DS_TYPES_TEMPLATES_SUPPORTED') &&
      supportedDataSources.some(
        ({ displayName, templateName, type }) => (row.displayType === displayName || type === row.type) && templateName,
      );

    const { displayName } = supportedDataSources.find(({ type }) => type === row.type) ?? {};

    const params =
      isNewConnectivityExperienceEnabled(type) && isNewDsConfigPage
        ? { id, shouldOpenNewModal: !row?.connectionStatusTest?.is_success, dsLabel: displayName }
        : { id, dsLabel: displayName };

    $state.go(isNewDsConfigPage ? 'configDataSource' : `dataSourceConnection`, params);
    return {};
  };

  return (
    <Root data-aid={generateDataAid(dataAid, ['dsNameCell'])}>
      <CellContent>
        <IconContainer>
          <ChunkErrorBoundary>
            <DataSourceIconByDsType
              data-aid={generateDataAid(dataAid, ['dsIcon', type])}
              dataSourceType={type}
              width={iconWidth}
            />
          </ChunkErrorBoundary>
        </IconContainer>
        {isPlainText ? (
          id
        ) : (
          <DataSourceName
            text={id as string}
            onClick={onDataSourceNameClick}
            dataAid={generateDataAid(`LinkFormatter`, [])}
            disabled={archived}
            color={archived ? LinkColor.SECONDARY : LinkColor.PRIMARY}
            underline={archived ? 'none' : 'always'}
          />
        )}
      </CellContent>
    </Root>
  );
};
