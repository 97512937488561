import React from 'react';
import styled from '@emotion/styled';
import { BigidBookIcon, BigidExternalLinkIcon } from '@bigid-ui/icons';
import { BigidHeading6, TertiaryButton } from '@bigid-ui/components';
import { docsUrls } from '../../../../react/config/publicUrls';

const CorrelationSetGuideContainer = styled.div`
  height: 100%;
  background: ${({ theme }) => `${theme.vars.palette.bigid.gray125}`};
  font-size: 14px;
  padding: 0 16px 20px;
  border-radius: 8px;
  overflow-y: scroll;
`;

const CorrelationSetGuideTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
`;

const CorrelationSetGuideTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const CorrelationSetGuideContent = styled.div`
  background: white;
  font-size: 14px;
  border-radius: 8px;
  padding: 16px 16px 24px;
  border: 1px solid ${({ theme }) => `${theme.vars.palette.bigid.gray200}`};
`;

const HeaderTitle = styled('div')`
  display: flex;
`;

interface CorrelationSetGuideProps {
  headerTitle: string;
  headerRightLink?: React.ReactNode;
  contentComponent: React.ReactNode;
}

export const CorrelationSetGuide = ({ headerTitle, contentComponent, headerRightLink }: CorrelationSetGuideProps) => {
  const viewDocsHandler = () => {
    window.open(docsUrls.CORRELATION_SET_BEST_PRACTICE, '_blank');
  };

  return (
    <CorrelationSetGuideContainer>
      <CorrelationSetGuideTitleBox>
        <CorrelationSetGuideTitle>
          <HeaderTitle>
            <BigidBookIcon size="medium" style={{ marginRight: 8 }} />
            <BigidHeading6>{headerTitle}</BigidHeading6>
          </HeaderTitle>
          <div>
            <TertiaryButton
              onClick={viewDocsHandler}
              size="medium"
              endIcon={<BigidExternalLinkIcon />}
              text="View Docs"
            />
          </div>
        </CorrelationSetGuideTitle>
        {headerRightLink && headerRightLink}
      </CorrelationSetGuideTitleBox>
      {contentComponent}
    </CorrelationSetGuideContainer>
  );
};
