import React from 'react';
import { BigidBody1, BigidHeading3, BigidSidePanelProps } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { CatalogSidePanel, PagesWithSidePanel } from './CatalogSidePanel/CatalogSidePanel';
import { CatalogSearchGridRow, ResultsEntityType } from '../../../types';
import { SidePanelTitle } from './SidePanelTitle';
import { DataCatalogAttribute } from '../../../../DataCatalog/DataCatalogAttributes';
import { DataOwnerEntity } from '../../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { Highlight } from '../../../utils';
import { BigidGridRow, ChipsFormatterProps, IconFormatterProps } from '@bigid-ui/grid';

type CustomSidePanelProps = Pick<BigidSidePanelProps, 'dataAid' | 'title' | 'content' | 'customTitleComponent'>;

export type GetSidePanelComponentProps = {
  entityType: ResultsEntityType;
  selectedItem: CatalogSearchGridRow;
  highlightValue?: string;
  rootPage?: PagesWithSidePanel;
};

export function getSidePanelComponentProps({
  entityType,
  selectedItem,
  highlightValue,
  rootPage,
}: GetSidePanelComponentProps): CustomSidePanelProps {
  let sidePanelProps: CustomSidePanelProps = {
    content: null,
    title: null,
  };

  if (selectedItem) {
    switch (entityType) {
      case 'catalog':
        const item = selectedItem;
        const { objectName } = item.data;
        sidePanelProps = {
          dataAid: generateDataAid('BigidSidePanel', ['catalog']),
          title: objectName,
          customTitleComponent: <SidePanelTitle selectedItem={item} highlightValue={highlightValue} />,
          content: (
            <CatalogSidePanel
              dataAid={generateDataAid('BigidSidePanel', ['catalog', 'content'])}
              selectedItem={item}
              highlightValue={highlightValue}
              rootPage={rootPage}
            />
          ),
        };
    }
  }

  return sidePanelProps;
}

export interface AttributesGridColumn extends Partial<DataCatalogAttribute>, BigidGridRow {
  columns?: string;
  purposes: string;
  confidenceLevel: ChipsFormatterProps;
  cachedValues?: IconFormatterProps;
  numberOfFindings: number;
}

export const getHighlightedElement = (title: string, highlightValue: string, type: 'heading' | 'body' = 'heading') => {
  const TypographyComponent = type === 'heading' ? BigidHeading3 : BigidBody1;
  if (!highlightValue) {
    return (
      <TypographyComponent overflow="inherit" textOverflow="inherit" color="inherit">
        {title}
      </TypographyComponent>
    );
  }

  try {
    const parts = title.split(new RegExp(`(${highlightValue})`, 'gi'));

    return (
      <TypographyComponent overflow="inherit" textOverflow="inherit">
        {parts.map((part, index) =>
          part.toLowerCase() === highlightValue.toLowerCase() ? <Highlight key={index}>{part}</Highlight> : part,
        )}
      </TypographyComponent>
    );
  } catch (e) {
    console.error('Error in getHighlightedElement', e);
  }
};

export const formatOwnerToDetails = (owner: DataOwnerEntity) => {
  if (!owner) {
    return;
  }
  const { fullName, email } = owner;
  return `${fullName ?? ''} ${email}`.trim();
};
