import React, { useEffect, useState } from 'react';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import angular from 'angular';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { CONFIG } from '../../../../config/common';
import { $state } from '../../../services/angularServices';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { IconButton } from '@mui/material';
import { useNgTranslate } from '../../../components/hooks/useNgTranslate';
import { useLocationChange } from './useLocationChange';
import { getProfileById } from '../dsarService';
import { DsarSettingsTabPaths } from '../DsarTypes';

const settingsPaths = Object.values(DsarSettingsTabPaths);

export const DsarPageTitle = (): null => {
  const translations = useNgTranslate(['ACCESS_REQUEST_MANAGEMENT']);
  const [profileName, setProfileName] = useState(null);
  const {
    stateParams: { profileId, profileName: profileNameState },
  } = useLocationChange();

  useEffect(() => {
    if (!profileName && profileNameState) {
      setProfileName(profileNameState);
    }

    if (!profileId) {
      setProfileName(null);
    }
  }, [profileNameState, profileName, profileId]);

  useEffect(() => {
    if (!profileId || profileName || profileNameState) {
      return;
    }

    (async () => {
      try {
        const profile = await getProfileById(
          profileId,
          {},
          {
            excludeEsConnections: true,
            excludeDsConnections: true,
          },
        );
        setProfileName(profile.name);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [profileId, profileName, profileNameState]);

  useEffect(() => {
    if (!translations) {
      return;
    }

    const { path } = $state.params;
    const isSettingsView = settingsPaths.includes(path);
    const changeTab = () => {
      const transitionToState = `subjectAccessRequest.${DsarSettingsTabPaths.SAR_PROFILE_SETTINGS_PATH}`;
      $state.transitionTo(
        transitionToState,
        { path: DsarSettingsTabPaths.SAR_PROFILE_SETTINGS_PATH },
        { reload: false, notify: false },
      );
    };

    const goBackToDsarMainPage = () => {
      $state.transitionTo(CONFIG.states.ACCESS_REQUEST_MANAGEMENT, { path: 'new-request' });
    };

    if (isSettingsView) {
      const titleData = {
        breadcrumbs: [
          {
            label: translations.ACCESS_REQUEST_MANAGEMENT,
            onClick: goBackToDsarMainPage,
          },
          ...(profileName
            ? [
                {
                  label: 'Settings',
                  onClick: changeTab,
                },
                {
                  label: 'Profile Settings',
                  onClick: changeTab,
                },
                {
                  label: profileName,
                },
              ]
            : [
                {
                  label: 'Settings',
                },
              ]),
        ],
      };
      pageHeaderService.setTitle(titleData);
    } else {
      pageHeaderService.setTitle({
        pageTitle: translations.ACCESS_REQUEST_MANAGEMENT,
        rightSideComponentsContainer: (
          <IconButton
            title={'Settings'}
            onClick={() => changeTab()}
            className={'buttonsWrapper'}
            size="large"
            sx={{ height: 'auto' }}
          >
            <SettingsOutlinedIcon />
          </IconButton>
        ),
      });
    }
  }, [translations, profileName]);

  return null;
};

angular.module('app').component('dsarPageTitle', convertToAngular(DsarPageTitle));
