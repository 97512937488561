import { addNewResources, i18next, supportedLngs } from '@bigid-ui/i18n';
export const NAMESPACE = 'bigid/common';
export const commonTranslate = key => i18next.t(key, {
  ns: NAMESPACE
});
supportedLngs.forEach(language => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    addNewResources(require(`./locales/${language}.json`), NAMESPACE, language);
  } catch (error) {
    // currently only EN supported, warnning is relevant only if en is not existed
    if (language === 'en') {
      console.warn(`Translation resource "${language}.json" not found.`, error.stack);
    }
  }
});