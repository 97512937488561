import { deepDiscoveryProvider } from './deepDiscoveryProvider';
import { DataSourceCategoryFetch, DataSourceOnboardingTypesProvider } from '../types';
import { AI_KEYWORD } from '../constats';
import { getSupportedDataSources } from '../../../../utilities/dataSourcesUtils';
import { mapSupportedDataSourcesToOptions } from '../utils';
import { isAISecurityAndGovernanceEnabled } from '../../../../utilities/featureFlagUtils';

const fetchTypes: DataSourceCategoryFetch = async (_search, _invalidate, onStart) => {
  const items = await getSupportedDataSources(true, onStart);

  return {
    items: items.filter(({ keywords }) => keywords.includes(AI_KEYWORD)).map(mapSupportedDataSourcesToOptions),
    isFromCache: false,
  };
};

export const aiSystemsProvider: DataSourceOnboardingTypesProvider = {
  ...deepDiscoveryProvider,
  isPermitted: () => deepDiscoveryProvider.isPermitted() && isAISecurityAndGovernanceEnabled(),
  fetch: fetchTypes,
};
