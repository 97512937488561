import { useCallback, useState, useEffect, useContext } from 'react';
import { rolesService } from '../../../../services/angularServices';
import { Scope } from '../../../DSAR/SarProfileSettings/ProfileManagement/UserScopesSelector';
import { BigidSelectOption } from '@bigid-ui/components';
import { isPermitted, hasRootScope } from '../../../../services/userPermissionsService';
import { ACCESS_MANAGEMENT_PERMISSIONS } from '@bigid/permissions';
import { DataSourceConfigurationContext } from '../DataSourceConfigurationContext';

export interface UseScopeOptions {
  options: BigidSelectOption[];
  isLoading: boolean;
  value: BigidSelectOption[];
  showOnCreateTooltip?: boolean;
}

const DEFAULT_SCOPE_OPTIONS_STATE: UseScopeOptions = {
  options: [],
  value: [],
  isLoading: true,
  showOnCreateTooltip: false,
};

interface RoleResult extends Record<string, string | number | string[]> {
  scopes: string[];
}

interface GetRBACRolesResult {
  data: {
    roles: RoleResult[];
  };
}

interface GetRBACScopesResult {
  data: {
    scopes: Scope[];
  };
}

export const getAvailableScopes = async () => {
  const [
    {
      data: { roles },
    },
    {
      data: { scopes },
    },
  ]: [GetRBACRolesResult, GetRBACScopesResult] = isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.READ.name)
    ? await Promise.all([rolesService.getRBACRoles(), rolesService.getRBACScopes()])
    : [{ roles: [] }, { scopes: [] }];

  const availableScopes = scopes.filter(scope => scope?.name !== 'root' && scope?.id !== 'root');

  const scopesIdsInRoles = roles?.reduce(
    (scopesIds: string[], role: RoleResult) => [...scopesIds, ...(role?.scopes || [])],
    [],
  );

  return {
    scopes: availableScopes,
    scopesIdsInRoles,
  };
};

export async function getDataSourceScopes(dataSourceName: string, isInitValuesOnCreate: boolean) {
  const { scopes, scopesIdsInRoles } = await getAvailableScopes();

  const [value, options] = scopes.reduce(
    ([initValues, options], { name, dataSourceNames, id }) => {
      const option = {
        label: name,
        value: name,
      };
      const isCanByAddedToInit = isInitValuesOnCreate && scopesIdsInRoles.includes(id);
      const initValue = (dataSourceNames.includes(dataSourceName) || isCanByAddedToInit) && option;

      return [
        [...initValues, ...(initValue ? [initValue] : [])],
        [...options, option],
      ];
    },
    [[], []],
  );

  return { value, options, scopes };
}

export const useScopeOptions = (
  setValue: (value: BigidSelectOption) => void,
): UseScopeOptions & { setValueAndUpdateFilled: (value: BigidSelectOption) => void } => {
  const [{ options, isLoading, value, showOnCreateTooltip }, setScopeState] =
    useState<UseScopeOptions>(DEFAULT_SCOPE_OPTIONS_STATE);
  const { updateState, id: dataSourceName } = useContext(DataSourceConfigurationContext);

  const updateOptions = useCallback(async () => {
    try {
      const isInitValuesOnCreate = Boolean(!dataSourceName && !hasRootScope());
      const { value, options } = await getDataSourceScopes(dataSourceName, isInitValuesOnCreate);
      setScopeState({
        options,
        value,
        isLoading: false,
        showOnCreateTooltip: isInitValuesOnCreate,
      });

      updateState &&
        updateState({
          isScopeSelected: !!value.length,
        });
    } catch (e) {
      setScopeState({
        ...DEFAULT_SCOPE_OPTIONS_STATE,
        isLoading: false,
      });
      console.warn(e);
      throw new Error('Error getting Scopes');
    }
  }, [dataSourceName, updateState]);

  useEffect(() => {
    updateOptions();
  }, [updateOptions]);

  // fixed implicit any during react 18 migration
  const setValueAndUpdateFilled = useCallback(
    (value: any) => {
      setValue(value);
      updateState &&
        updateState({
          isScopeSelected: !!value.length,
        });
    },
    [setValue, updateState],
  );

  return { options, isLoading, value, showOnCreateTooltip, setValueAndUpdateFilled };
};
