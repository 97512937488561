import React, { FC, memo, useCallback } from 'react';
import {
  BigidDropdown,
  BigidExtraField,
  BigidFieldRenderProps,
  BigidFormFieldLabelWrapper,
  useExtraFieldControl,
} from '@bigid-ui/components';
import { v4 as uuid } from 'uuid';
import { useColumnsList } from '../../../hooks/useColumnsList';
import { useLocalTranslation } from '../../../translations';

const createNewExtraField = (): { id: string; value: Record<string, any>[] } => {
  return {
    id: uuid(),
    value: [],
  };
};

export const CompositeIdentifier: FC<BigidFieldRenderProps> = memo(({ value, setValue, error }) => {
  const { t } = useLocalTranslation('wizard.trainingStep');
  const createNew = useCallback(() => createNewExtraField(), []);

  const { optionsList } = useColumnsList();

  const { onAddNew, onDelete, data, onChangeItem } = useExtraFieldControl({
    createNewItem: createNew,
    initialData: value?.length ? value : [createNewExtraField()],
    onChange: selecetedValue => {
      setValue(selecetedValue);
    },
  });

  return (
    <BigidFormFieldLabelWrapper id="CompositeIdentofier" error={error} errorIsShown={Boolean(error)}>
      <BigidExtraField
        alignCenter
        data={data}
        fieldComponent={fieldProps => (
          <BigidDropdown
            isMulti
            withChipsBar
            onSelect={array => {
              onChangeItem(fieldProps.id, array);
            }}
            value={fieldProps.value}
            options={optionsList}
            isSearchable
            searchBarPlaceholder={t('searchBarPlaceholderComposite')}
          />
        )}
        fieldsBottomOffset={5}
        onAddNew={onAddNew}
        onChangeItem={onChangeItem}
        onDelete={onDelete}
      />
    </BigidFormFieldLabelWrapper>
  );
});
