import React, { useEffect, useState } from 'react';
import { useLocalTranslation } from '../translations';
import { Typography } from '@mui/material';
import { BigidConnectionInProgressAnimation } from '@bigid-ui/components';
import styled from '@emotion/styled';

export type CalculatingStatisticsLoaderProps = {
  headerText?: string;
  onSave?: () => void;
  dataAid?: string;
};

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 480px;
`;

export const Image = styled.div`
  margin-bottom: 1rem;
`;

const DEFAULT_TEST_CONNECTION_TIMEOUT = 10000;

export const CalculatingStatisticsLoader = ({
  headerText,
  dataAid = 'CalculatingStatisticsLoader',
}: CalculatingStatisticsLoaderProps): JSX.Element => {
  const { t } = useLocalTranslation('wizard.trainingStep.calculatingStatisticsLoader');
  const [isTimeout, setTimeoutValue] = useState(false);
  const header = headerText ?? t('header');

  useEffect(() => {
    const timeoutId = setTimeout(() => setTimeoutValue(true), DEFAULT_TEST_CONNECTION_TIMEOUT);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Overlay data-aid={dataAid}>
      <Image>
        <BigidConnectionInProgressAnimation width={159} height={155} />
      </Image>
      <Content>
        <Typography variant="h6">{header}</Typography>
        {isTimeout && (
          <>
            <Typography variant="h6">{t('content')}</Typography>
          </>
        )}
      </Content>
    </Overlay>
  );
};
