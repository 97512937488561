import { useState, useCallback, useMemo, useEffect } from 'react';
import {
  BigidTagWizardDictionary,
  BigidTagBaseProps,
  getTagsDictionary,
  BigidFieldRenderProps,
} from '@bigid-ui/components';
import {
  TagEntity,
  createTag,
  UseTagResponseData,
  TagCompositionPartType,
  getTagsAllPairs,
  TagResponseEntity,
  TagMutualExclusion,
} from '../../../TagsManagement/TagsManagementService';
import { isPermitted } from '../../../../services/userPermissionsService';
import { TAGS_PERMISSIONS } from '@bigid/permissions';
import {
  getTagEntityByName,
  getTagEntityById,
  getUserDefinedTagsOnly,
  getVisibleTagsOnly,
  getTagIcon,
  getTagFormattedName,
  isUserDefinedTag,
} from '../../../TagsManagement/TagsManagementUtils';
import { notificationService } from '../../../../services/notificationService';

interface DsUseTagResponseData {
  tag: TagEntity;
  response?: UseTagResponseData;
}

interface DataSourceDetailsBigidTagProps extends BigidTagBaseProps {
  tagId?: string;
  valueId?: string;
}

const EXIST_RESPONSE = 'this tag-name already exist';
const EXIST_CREATE_NEW_MESSAGE = 'A tag with this name already exists';
const ERROR_MESSAGE = 'An error has occurred';

const createTagForDs = async (systemTags: TagEntity[], tag: BigidTagBaseProps): Promise<DsUseTagResponseData> => {
  const tagNameDescription = `${tag.name} tag name`;
  const tagValueDescription = `${tag.value} tag value`;
  let tagNameCreatedObject: TagResponseEntity;

  if (tag.isNew) {
    const { data: tagCreatedResponse } = await createTag({
      name: tag.name,
      type: TagCompositionPartType.tag,
      description: tagNameDescription,
    });
    tagNameCreatedObject = tagCreatedResponse[0];
  }

  const tagNameIdToAttach = tag.isNew ? tagNameCreatedObject?._id : getTagEntityByName(systemTags, tag.name)?.tagId;
  const { data: valueCreatedResponse } = await createTag({
    name: tag.value,
    type: TagCompositionPartType.value,
    description: tagValueDescription,
    parentId: tagNameIdToAttach,
  });
  const valueCreated = valueCreatedResponse[0];
  const tagValueIdToAttach = valueCreated._id;

  return {
    tag: {
      ...tag,
      valueId: tagValueIdToAttach,
      tagId: tagNameIdToAttach,
    },
  };
};

const addOneTag = (systemTags: TagEntity[], tags: TagEntity[], { valueId, tagId }: TagEntity) => {
  const tag = getTagEntityById(systemTags, tagId, valueId);
  return tag?.tagName && tags
    ? [
        ...tags,
        {
          valueId,
          tagId,
          name: getTagFormattedName(tag?.tagName),
          value: tag?.tagValue,
          icon: getTagIcon(tag?.properties, tag?.tagType),
          isDeleteUnavailable: !isUserDefinedTag(tag),
        },
      ]
    : tags;
};

const showMutuallyExclusiveNotification = (exclusion: TagMutualExclusion[], systemTags: TagEntity[]): void => {
  let message = '';

  if (exclusion.length > 1) {
    const messageBase = 'The following tags will be excluded on save due to their mutual exclusion:';

    message = exclusion.reduce((messageAggr, { from, to }) => {
      const fromTagEntity = getTagEntityById(systemTags, from.tagId, from.valueId);
      const toTagEntity = getTagEntityById(systemTags, to.tagId, to.valueId);

      if (fromTagEntity && toTagEntity) {
        const fromTag = `${fromTagEntity.tagName}:${fromTagEntity.tagValue}`;
        const toTag = `${toTagEntity.tagName}:${toTagEntity.tagValue}`;
        return messageAggr ? `${messageAggr}, '${fromTag}' to '${toTag}'` : `${messageBase} '${fromTag}' to '${toTag}'`;
      } else {
        return messageAggr;
      }
    }, '');
  } else if (exclusion.length === 1) {
    const { from, to } = exclusion[0];
    if (from && to) {
      const fromTagEntity = getTagEntityById(systemTags, from.tagId, from.valueId);
      const toTagEntity = getTagEntityById(systemTags, to.tagId, to.valueId);

      if (fromTagEntity && toTagEntity) {
        message = `'${fromTagEntity.tagName}:${fromTagEntity.tagValue}'
          will be replaced on save by '${toTagEntity.tagName}:${toTagEntity.tagValue}'
          because '${fromTagEntity.tagName}' is a mutually exclusive tag`;
      }
    }
  }

  if (message.length > 0) {
    notificationService.success(message);
  }
};

export const useTagsFieldData = ({ value = [], setValue }: Pick<BigidFieldRenderProps, 'value' | 'setValue'>) => {
  const [isLoading, setIsLoading] = useState(true);
  const [systemTags, setSystemTags] = useState<TagEntity[]>([]);
  const [systemTagsDictionary, setSystemTagsDictionary] = useState<BigidTagWizardDictionary>();
  const tags = useMemo(
    () => value.reduce((tagsAcc: TagEntity[], tag: TagEntity) => addOneTag(systemTags, tagsAcc, tag), []),
    [value, systemTags],
  );

  const { isCreateTagsPermitted, isReadTagsPermitted } = useMemo(
    () => ({
      isCreateTagsPermitted: isPermitted(TAGS_PERMISSIONS.CREATE.name),
      isReadTagsPermitted: isPermitted(TAGS_PERMISSIONS.READ.name),
    }),
    [],
  );

  const handleTagAttach = useCallback(
    async ({ name: tagName, value: tagValue }: BigidTagBaseProps, systemTagsProp: TagEntity[] = systemTags) => {
      const { valueId, tagId, isMutuallyExclusive } = getTagEntityByName(systemTagsProp, tagName, tagValue);
      const mutuallyExcluded: TagMutualExclusion[] = [];
      const valueForAttach = { tagName, tagValue, valueId, tagId };
      const tagsFilteredByMutualExclusion = value.filter((tag: TagEntity) => {
        const tagInSystem = getTagEntityById(systemTagsProp, tag.tagId, tag.valueId);
        const isExcluded =
          tagInSystem && (isMutuallyExclusive || tagInSystem.isMutuallyExclusive) && tag?.tagId === tagId;
        isExcluded &&
          mutuallyExcluded.push({
            from: tag,
            to: valueForAttach,
          });
        return !isExcluded;
      });
      if (mutuallyExcluded.length) {
        showMutuallyExclusiveNotification(mutuallyExcluded, systemTagsProp);
      }
      setValue([...tagsFilteredByMutualExclusion, valueForAttach]);
    },
    [systemTags, setValue, value],
  );

  const handleTagCreate = useCallback(
    async (tag: BigidTagBaseProps) => {
      try {
        const { tag: createdTag } = await createTagForDs(systemTags, tag);
        const resultTag = {
          ...tag,
          ...createdTag,
          tagName: tag.name,
          tagValue: tag.value,
        };
        const systemTagsUpdated = [...systemTags, resultTag];
        setSystemTags(systemTagsUpdated);

        const dictionary = getTagsDictionary(
          systemTagsUpdated.map(({ tagName, tagValue }) => ({ name: tagName, value: tagValue })),
        );
        setSystemTagsDictionary(dictionary);
        await handleTagAttach(resultTag, systemTagsUpdated);
      } catch ({ message, response }) {
        const notificationMessage =
          response?.data?.message === EXIST_RESPONSE ? EXIST_CREATE_NEW_MESSAGE : ERROR_MESSAGE;
        notificationService.error(notificationMessage);
        console.error(`An error has occurred: ${message}`);
      }
    },
    [handleTagAttach, systemTags],
  );

  const handleTagDetach = useCallback(
    async ({ tagId, valueId }: DataSourceDetailsBigidTagProps) => {
      const tagEntity = getTagEntityById(systemTags, tagId, valueId);

      if (!isUserDefinedTag(tagEntity)) {
        notificationService.error('System tag cannot be removed manually!');
        return;
      }

      setValue(value.filter((tagItem: TagEntity) => tagItem?.valueId !== valueId || tagItem?.tagId !== tagId));
    },
    [value, setValue, systemTags],
  );

  const initTagsData = async () => {
    try {
      const tags = await getTagsAllPairs();
      const visibleTags = getVisibleTagsOnly(tags).filter(({ tagName, tagValue }) => tagName && tagValue);
      const userDefinedTags = getUserDefinedTagsOnly(visibleTags);
      const dictionary = getTagsDictionary(
        userDefinedTags.map(({ tagName, tagValue }) => ({ name: tagName, value: tagValue })),
      );
      setSystemTags(visibleTags);
      setSystemTagsDictionary(dictionary);
    } catch (err) {
      notificationService.error(ERROR_MESSAGE);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initTagsData();
  }, []);

  return {
    tags,
    isLoading,
    handleTagCreate,
    handleTagAttach,
    handleTagDetach,
    isReadTagsPermitted,
    systemTagsDictionary,
    isCreateTagsPermitted,
  };
};
