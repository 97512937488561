import { EntityEvents, entityEventsEmitter } from '@bigid-ui/components';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { GlossaryItem } from '../../../administration/generalSettings/businessGlossary/businessGlossary.service';
import { navigateToOldPageIfFFOff } from './businessGlossaryUtils';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { HeaderButtons } from './HeaderButtons/HeaderButtons';
import { useLocalTranslation } from './translations';
import { ImportGlossaryDialog } from './ImportGlossaryDialog/ImportGlossaryDialog';
import { GlossaryGrid } from './GlossaryGrid/GlossaryGrid';
import { GlossaryItemSidePanel } from './GlossaryItemSidePanel/GlossaryItemSidePanel';
import { useDialogState } from '../../hooks/useDialogState';
import { AddGlossaryItemDialog } from './AddGlossaryItemDialog/AddGlossaryItemDialog';

export const BusinessGlossary: FC = () => {
  const { t } = useLocalTranslation('BusinessGlossary');

  const [isExportInProgress, setIsExportInProgress] = useState(false);
  const [selectedGlossaryItem, setSelectedGlossaryItem] = useState<GlossaryItem>();
  const [isAddItemDialogOpen, closeAddItemDialog, openAddItemDialog] = useDialogState();
  const [isImportDialogOpen, closeImportDialog, openImportDialog] = useDialogState();

  const handleSidePanelClose = useCallback(() => {
    setSelectedGlossaryItem(undefined);
  }, []);

  const handleSidePanelSave = useCallback(() => {
    setTimeout(() => {
      setSelectedGlossaryItem(undefined);
      entityEventsEmitter.emit(EntityEvents.RELOAD);
    }, 50);
  }, []);

  const handleAddItemDialogSave = useCallback(() => {
    setTimeout(() => {
      closeAddItemDialog();
      entityEventsEmitter.emit(EntityEvents.RELOAD);
    }, 50);
  }, []);

  useEffect(navigateToOldPageIfFFOff, []);

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: t('pageTitle'),
      rightSideComponentsContainer: (
        <HeaderButtons
          onImportDialogOpen={openImportDialog}
          isExportInProgress={isExportInProgress}
          setIsExportInProgress={setIsExportInProgress}
          onAddItemDialogOpen={openAddItemDialog}
        />
      ),
    });
  }, [t, isExportInProgress]);

  return (
    <>
      <GlossaryGrid onGlossaryItemSelect={setSelectedGlossaryItem} setIsExportInProgress={setIsExportInProgress} />
      <GlossaryItemSidePanel
        glossaryItem={selectedGlossaryItem}
        onSave={handleSidePanelSave}
        onClose={handleSidePanelClose}
      />
      <AddGlossaryItemDialog
        isOpen={isAddItemDialogOpen}
        onClose={closeAddItemDialog}
        onSave={handleAddItemDialogSave}
      />
      <ImportGlossaryDialog
        onImportDialogClose={closeImportDialog}
        isImportDialogOpen={isImportDialogOpen}
        setIsExportInProgress={setIsExportInProgress}
      />
    </>
  );
};
