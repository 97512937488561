import { BigidColorsV2 } from '@bigid-ui/components';
import { State } from './types';

export const colors: Record<State, string> = {
  [State.completed]: BigidColorsV2.green[300],
  [State.pending]: BigidColorsV2.gray[400],
  [State.queued]: BigidColorsV2.purple[300],
  [State.in_progress]: BigidColorsV2.blue[300],
  [State.failed]: BigidColorsV2.red[300],
  [State.total]: BigidColorsV2.gray[200],
  [State.canceled]: BigidColorsV2.blue[150],
  [State.stopped]: BigidColorsV2.gray[500],
};
