import React, { useCallback, useEffect, useMemo, useState, Suspense } from 'react';
import { BigidWidgetContainer, BigidLoader } from '@bigid-ui/components';
import { DataSourceIconByDsType } from '@bigid-ui/icons';
import { useLocalTranslation } from '../../../translations';
import { BigidTreemapChart } from '@bigid-ui/visualisation';
import { fetchConnectionsType, fetchWidgetData, WidgetName, WidgetSubtype } from '../../../services/dashboardService';
import { WidgetControlsProps } from '../TopRiskWidget/TopRiskWidget';
import { activeFiltersParser } from '../../utils';
import { useFiltersActions } from '../../hooks/useFiltersActions';
import { MenuActions } from '../WidgetsContainer';
import { ComplianceDashboardWidget } from '../../ComplianceDashboard';
import { useWidgetEmptyState } from '../../hooks/useWidgetEmptyState';
import { DataSource } from '../../../../../utilities/dataSourcesUtils';

interface DataSourceWidgetProps {
  dataAid: string;
}

export interface DataSourceWidgetResponse {
  controls: WidgetControlsProps[];
  dataSourceType: string;
  failedControlsCount: number;
  iconDsType?: string;
}

export const DataSourceWidget = ({ dataAid }: DataSourceWidgetProps) => {
  const [dsourceTypes, setDsourceTypes] = useState<DataSourceWidgetResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const { t } = useLocalTranslation('compliance.widgets');

  const { handleFilterAction, handleRedirectAction, activeFilters } = useFiltersActions();
  const { filter } = activeFiltersParser(activeFilters);

  const emptyState = useWidgetEmptyState(
    ComplianceDashboardWidget.DATA_SOURCE,
    hasError,
    dsourceTypes?.length === 0,
    dataAid,
  );

  const widgetList = useMemo(() => {
    return dsourceTypes.map((element: DataSourceWidgetResponse) => {
      return {
        id: element.dataSourceType,
        name: element.dataSourceType,
        value: element.failedControlsCount,
        icon: (
          <Suspense fallback={<BigidLoader />}>
            <DataSourceIconByDsType dataSourceType={element.iconDsType} width={32} />
          </Suspense>
        ),
      };
    });
  }, [dsourceTypes]);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const {
        dashboardData: { dataSourceTypes },
      } = await fetchWidgetData({
        filter,
        subType: WidgetSubtype.GROUP_BY_DS_TYPE,
        widgetName: WidgetName.GROUP_BY_DS_TYPE,
      });
      const dsConnectionsData = await fetchConnectionsType();
      const dsConnectionsMap = new Map();
      dsConnectionsData.map((dataSource: DataSource) => dsConnectionsMap.set(dataSource.displayName, dataSource.type));

      const widgetDsList = dataSourceTypes?.map((widgetDs: DataSourceWidgetResponse) => {
        const currentDsType = dsConnectionsMap.get(widgetDs?.dataSourceType);
        return { ...widgetDs, iconDsType: currentDsType };
      });
      setDsourceTypes(widgetDsList);
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [filter]);

  const menuItems = [
    {
      id: 1,
      label: MenuActions.OPEN_SECURITY_POSTURE,
    },
    {
      id: 2,
      label: MenuActions.ADD_FILTERS,
    },
  ];

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <BigidWidgetContainer
      dataAid={dataAid}
      titleConfig={{
        title: t('dataSource.title'),
      }}
      content={
        <BigidTreemapChart
          options={widgetList}
          homeText={t('dataSource.homeText')}
          isTooltipDisabled={true}
          customLabelFormatString={"[font-weight: 600]{name}[/]\n{value.formatNumber('#,##a')} requirements"}
          menuProps={{
            items: menuItems,
            onItemSelect: (option, item) => {
              const { name } = option;
              const { label } = item;
              if (label === MenuActions.OPEN_SECURITY_POSTURE) {
                handleRedirectAction(ComplianceDashboardWidget.DATA_SOURCE, null, null, null, name);
              } else {
                handleFilterAction(ComplianceDashboardWidget.DATA_SOURCE, null, null, null, name);
              }
            },
          }}
        />
      }
      contentHeight="416px"
      isLoading={isLoading}
      emptyStateConfig={emptyState}
    />
  );
};
