export enum CatalogEventsEnum {
  CATALOG_SIDE_FILTER = 'Catalog_Side_filter',
  CATALOG_EXPORT = 'Catalog_Export',
  CATALOG_ASSIGN_TAG = 'Catalog_Assign_Tag',
  CATALOG_ASSIGN_BULK_TAG = 'Catalog_Assign_Bulk_Tag',
  CATALOG_SEARCH = 'Catalog_Search',
  CATALOG_FOLLOW_OBJECT = 'Catalog_Follow_Object',
  CATALOG_REPORT_AN_ISSUE = 'Catalog_Report_an_Issue',
  CATALOG_ATTRIBUTE_TAB = 'Catalog_Attribute_Tab',
  CATALOG_ATTRIBUTE_TAB_FETCH_VALUES = 'CATALOG_ATTRIBUTE_TAB_FETCH_VALUES',
  CATALOG_DUPLICATE_TAB = 'CATALOG_DUPLICATE_TAB',
  CATALOG_COLUMNS_TAB = 'Catalog_Columns_Tab',
  CATALOG_ADD_BUSINESS_ATTRIBUTE = 'Catalog_Add_BusinessAttribute',
  CATALOG_REMOVE_BUSINESS_ATTRIBUTE = 'Catalog_Remove_BusinessAttribute',
  CATALOG_ADD_ATTRIBUTE = 'Catalog_Add_Attribute',
  CATALOG_REMOVE_ATTRIBUTE_EVENT = 'Catalog_Remove_Attribute_event',
  CATALOG_VIEW_SIMILAR_COLUMNS_EVENT = 'Catalog_View_Similar_Columns_event',
  CATALOG_VIEW_SIMILAR_COLUMNS_LINKED_COLUMNS = 'Catalog_View_Similar_Columns_Linked_columns',
  CATALOG_COLUMN_PROFILE_EVENT = 'Catalog_ColumnProfile_event',
  CATALOG_PREVIEW_FILE_EVENT = 'Catalog_Preview_File_event',
  CATALOG_PREVIEW_FILE_EVENT_DISPLAYONLY = 'Catalog_Preview_File_event_Displayonly',
  CATALOG_VIEW_PREVIEW_TABLE = 'Catalog_View_Preview_Table',
  CATALOG_ADD_TAG_EVENT = 'Catalog_Add_Tag_event',
  CATALOG_REMOVE_TAG_EVENT = 'Catalog_Remove_Tag_event',
  CATALOG_CUSTOMIZE_COLUMNS_EVENT = 'Catalog_Customize_Columns_event',
  CATALOG_CLICK_WIDGET_EVENT = 'Catalog_click_widget_event',
  CATALOG_ADD_DATASOURCE_OWNER_EVENT = 'Catalog_Add_DatasourceOwner_event',
  CATALOG_VIEW_CLUSTER_EVENT = 'Catalog_View_cluster_event',
  CATALOG_REMOVE_DATASOURCE_OWNER_EVENT = 'Catalog_remove_DatasourceOwner_event',
  CATALOG_OBJECT_IGNORE_CONFIDENCE = 'Catalog_object_ignore_confidence',
  CATALOG_OBJECT_REVERT_CONFIDENCE = 'Catalog_object_revert_confidence',
  CATALOG_UNSTRUCTURED_PREVIEW_ATTRIBUTE_FILTER_TYPE = 'Catalog_unstructured_preview_attribute_filter_type',
  CATALOG_UNSTRUCTURED_PREVIEW_ATTRIBUTE_FILTER_NAME = 'Catalog_unstructured_preview_attribute_filter_name',
  CATALOG_GOTODISCOVERYINSIGHTS_CLICK = 'CATALOG_GOTODISCOVERYINSIGHTS_CLICK',
  ATTRIBUTE_PREVIEW_EXPIRED_MSG = 'attribute_preview_expired_msg',
}
