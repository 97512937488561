import React, { Suspense } from 'react';
import { BigidGridColumn, BigidGridColumnTypes } from '@bigid-ui/grid';
import { CatalogSearchGridRow, DataSourceGridRow, PolicyGridRow } from '../types';
import { getCatalogEntityIconByType, removeHtmlTags } from '../utils';
import { FooterSection } from './Card/components/Footer/FooterSection';
import { FullPath } from '../components/FullPath/FullPath';
import { FooterDsType } from './Card/components/Footer/FooterDsType';
import { BigidDataSourceOnlyIcon, BigidPolicyIcon, getDataSourceIconByDsType } from '@bigid-ui/icons';
import { FooterOwners } from './Card/components/Footer/FooterOwners';
import { FooterModifiedDate } from './Card/components/Footer/FooterModifiedDate';
import { getFixedT } from '../translations';
import { CatalogGridViewMode } from '../contexts/dataCatalogSearchResultsContext';
import { userPreferencesService } from '../../../services/userPreferencesService';
import { CONFIG } from '../../../../config/common';
import { BigidLoader } from '@bigid-ui/components';

export function getCatalogGridCardViewCellValue(highlight: string): BigidGridColumn<CatalogSearchGridRow>[] {
  return [
    {
      getCellValue: row => {
        const {
          title,
          data: {
            fullPath,
            type,
            datasource: { scannerType },
          },
        } = row;

        const icon = getCatalogEntityIconByType(type);

        const { columns, tags, attributes, category } = row.highlights.reduce(
          (acc, { fieldName, ...rest }) => {
            if (fieldName === 'columns') acc.columns.push({ fieldName, ...rest });
            else if (fieldName === 'tags') acc.tags.push({ fieldName, ...rest });
            else if (fieldName === 'attributes') acc.attributes.push({ fieldName, ...rest });
            else if (fieldName === 'category') acc.category.push({ fieldName, ...rest });
            return acc;
          },
          { columns: [], tags: [], attributes: [], category: [] },
        );

        return {
          card: {
            isCardOutlined: true,
            cardDisplayData: {
              subtitle: {
                value: <FullPath dsType={scannerType} fullPath={fullPath} highlight={highlight} />,
              },
              title: {
                icon,
                value: removeHtmlTags(title),
                highlight,
              },
              footer: {
                value: [
                  <FooterSection
                    sectionName="columns"
                    key="columns"
                    isFirstItem
                    highlightValue={columns}
                    searchValue={highlight}
                  />,
                  <FooterSection
                    key="attributes"
                    sectionName="attributes"
                    highlightValue={attributes}
                    searchValue={highlight}
                  />,
                  <FooterSection key="tags" sectionName="tags" highlightValue={tags} searchValue={highlight} />,
                  <FooterSection
                    key="categories"
                    sectionName="category"
                    highlightValue={category}
                    searchValue={highlight}
                  />,
                ],
              },
            },
          },
        };
      },
      type: BigidGridColumnTypes.PARTITIONED_CARD,
      name: 'objectName',
      title: 'Name',
      width: 'auto',
    },
  ];
}

export function getCatalogGridTableViewCellValue(): BigidGridColumn<CatalogSearchGridRow>[] {
  const t = getFixedT('catalogGridColumnLabels');

  return [
    {
      title: t('objectName'),
      name: 'objectName',
      type: BigidGridColumnTypes.TEXTIFIED_ICON,
      sortingEnabled: false,
      getCellValue: row => {
        const {
          data: { type },
        } = row;

        const icon = getCatalogEntityIconByType(type);

        return {
          text: { displayValue: removeHtmlTags(row?.title) },
          icon: {
            icon,
            placement: 'left',
          },
        };
      },
    },
    {
      name: 'dataSource',
      sortingEnabled: false,
      type: BigidGridColumnTypes.TEXTIFIED_ICON,
      title: t('dataSource'),
      getCellValue: row => {
        const {
          data: {
            datasource: { scannerType, value },
          },
        } = row;

        const Icon = getDataSourceIconByDsType(scannerType);

        return {
          text: { displayValue: value },
          icon: {
            icon: () => (
              <Suspense fallback={<BigidLoader />}>
                <Icon width={24} />
              </Suspense>
            ),
            placement: 'left',
            tooltip: scannerType,
          },
          tooltip: scannerType,
        };
      },
    },
    {
      title: t('attributes'),
      name: 'attributes',
      sortingEnabled: false,
      type: BigidGridColumnTypes.CUSTOM,
      getCellValue: row => {
        const attributes = row.highlights.filter(highlight => highlight.fieldName === 'attributes');
        return <FooterSection hideSectionName sectionName="attributes" searchValue="" highlightValue={attributes} />;
      },
    },
    {
      title: t('tags'),
      name: 'tags',
      sortingEnabled: false,
      type: BigidGridColumnTypes.CUSTOM,
      getCellValue: row => {
        const tags = row.highlights.filter(highlight => highlight.fieldName === 'tags');
        return <FooterSection hideSectionName sectionName="tags" searchValue="" highlightValue={tags} />;
      },
    },
    {
      title: t('categories'),
      name: 'categories',
      sortingEnabled: false,
      type: BigidGridColumnTypes.CUSTOM,
      getCellValue: row => {
        const categories = row.highlights.filter(highlight => highlight.fieldName === 'category');
        return <FooterSection hideSectionName sectionName="category" searchValue="" highlightValue={categories} />;
      },
    },
    {
      title: t('objectPath'),
      sortingEnabled: false,
      name: 'objectPath',
      type: BigidGridColumnTypes.TEXT,
      getCellValue: row => row.id,
    },
  ];
}

export function getPolicyGridCellValue(highlight: string): BigidGridColumn<PolicyGridRow>[] {
  return [
    {
      name: 'name',
      title: 'Name',
      type: BigidGridColumnTypes.PARTITIONED_CARD,
      width: 'auto',
      getCellValue: row => {
        const {
          title,
          id,
          data: { _es_date, owner },
        } = row;

        return {
          card: {
            isCardOutlined: true,
            cardDisplayData: {
              title: {
                icon: BigidPolicyIcon,
                value: removeHtmlTags(title),
                highlight,
              },
              id,
              footer: {
                value: [
                  <FooterOwners key="owner" owners={owner} />,
                  <FooterModifiedDate key="modifiedDate" date={_es_date} />,
                ],
              },
            },
          },
        };
      },
    },
  ];
}

export function getDataSourceGridCellValue(highlight: string): BigidGridColumn<DataSourceGridRow>[] {
  return [
    {
      name: 'name',
      title: 'Name',
      type: BigidGridColumnTypes.PARTITIONED_CARD,
      width: 'auto',
      getCellValue: row => {
        const {
          title,
          id,
          data: { owners_v2 = [], type: scannerType, scanDate },
        } = row;

        const stringifiedOwnersSet = new Set(owners_v2.map(owner => owner.id));

        return {
          card: {
            isCardOutlined: true,
            cardDisplayData: {
              title: {
                icon: BigidDataSourceOnlyIcon,
                value: removeHtmlTags(title),
                highlight,
              },
              id,
              footer: {
                value: [
                  <FooterDsType dsType={scannerType} key="dsType" />,
                  <FooterOwners key="owner" owners={[...stringifiedOwnersSet]} />,
                  <FooterModifiedDate key="modifiedDate" date={scanDate} />,
                ],
              },
            },
          },
        };
      },
    },
  ];
}

export const setUserPreferencesCatalogView = async (catalogView: CatalogGridViewMode) => {
  try {
    const preferences = await userPreferencesService.get(CONFIG.states.METADATA_SEARCH_RESULTS);

    if (preferences) {
      await userPreferencesService.update({
        preference: CONFIG.states.METADATA_SEARCH_RESULTS,
        data: {
          ...preferences.data,
          catalogLastViewMode: catalogView,
        },
      });
    } else {
      await userPreferencesService.add({
        preference: CONFIG.states.METADATA_SEARCH_RESULTS,
        data: { catalogLastViewMode: catalogView },
      });
    }
  } catch ({ message }) {
    console.error(`An error has occurred: ${message}`);
  }
};

export const getCatalogLastViewFromUserPreference = async () => {
  const preferences = await userPreferencesService.get(CONFIG.states.METADATA_SEARCH_RESULTS);

  return preferences?.data?.catalogLastViewMode || 'list';
};
