import React, { FC, useMemo } from 'react';
import {
  BigidAdvancedToolbarFilterUnion,
  BigidWidgetContainer,
  BigidWidgetListItemProps,
  BigidColorsV2,
  BigidCategoryColorIndicator,
} from '@bigid-ui/components';
import { BigidLineChartV2DataItem } from '@bigid-ui/visualisation';

import { useLocalTranslation } from '../translations';
import { WidgetListWithTitle } from './helpers/HelperComponents';
import { DonutLegendMarkerGrayWrapper, RiskWidgetWrapper } from './styles/WidgetStyles';
import { useFetchWidgetData } from './hooks/useFetchWidgetData';
import { WidgetProps, WidgetSubTypes } from '../PrivacyExecutiveDashboardTypes';
import { buildFilterQuery, fetchWidgetData } from '../services/dashboardService';
import { useEmptyState } from './hooks/useEmptyState';
import { useWidgetMenu } from './hooks/useWidgetMenu';

const CATEGORY_COLOR_INDICATOR_COLORS = [
  BigidColorsV2.purple[500],
  BigidColorsV2.magenta[300],
  BigidColorsV2.cyan[300],
  BigidColorsV2.blue[300],
  BigidColorsV2.yellow[300],
];

interface DataRiskTypesApiData {
  totalCount: number;
  riskCategoriesCount: {
    category: string;
    openCases: number;
    percentage: number;
  }[];
}

export const DataRiskTypesWidget: FC<WidgetProps> = ({ title }) => {
  const { t } = useLocalTranslation('widgets');
  const { WidgetMenu } = useWidgetMenu();
  const { data, isLoading, error, fetchData } = useFetchWidgetData(fetchDataRiskTypesWidgetData);
  const emptyStateConfig = useEmptyState(data, error, fetchData);
  const widgetListItems: BigidWidgetListItemProps[] = useMemo(() => {
    return (data || []).slice(0, 5).map((item, i) => ({
      description: item.category,
      descriptionIcon: <BigidCategoryColorIndicator color={CATEGORY_COLOR_INDICATOR_COLORS[i]} />,
      value: (
        <span>
          {item.openCases}
          <DonutLegendMarkerGrayWrapper>{` (${Math.round(item.percentage as number)}%)`}</DonutLegendMarkerGrayWrapper>
        </span>
      ),
    }));
  }, [data]);

  return (
    <RiskWidgetWrapper>
      <BigidWidgetContainer
        content={
          <WidgetListWithTitle
            items={widgetListItems}
            headerTitleOne={t('dataRiskTypes.headerType')}
            headerTitleTwo={t('dataRiskTypes.headerOpenCases')}
          />
        }
        titleConfig={{ title }}
        isLoading={isLoading}
        emptyStateConfig={emptyStateConfig}
        actionsSlot={<WidgetMenu />}
      />
    </RiskWidgetWrapper>
  );
};

const fetchDataRiskTypesWidgetData = async (
  activeFilters: BigidAdvancedToolbarFilterUnion[],
): Promise<BigidLineChartV2DataItem[]> => {
  const widgetData: DataRiskTypesApiData = await fetchWidgetData({
    subType: WidgetSubTypes.DATA_RISK_CATEGORY,
    filter: buildFilterQuery(activeFilters),
  });
  return widgetData.riskCategoriesCount;
};
