export const YES = 'yes';
export const NO = 'no';
export const CONTAINS_PI = 'Contains PI';

export const OPEN_ACCESS_CHIP_TYPE = 'open_access';
export const OPEN_ACCESS = 'Open Access';
export const NO_OPEN_ACCESS = 'No Open Access';

export const HAS_DUPLICATES_CHIP_TYPE = 'has_duplicates';
export const HAS_DUPLICATES = 'Has Duplicates';
export const NO_DUPLICATES = 'No Duplicates';

export const FAILED_TOTAL_COUNT_INDICATION = -1;
export const EXCLUDED_TAGS = ['Risk', 'Top Entity Sources'];
