import { useEffect, useState } from 'react';
import { EventEmitter } from '@bigid-ui/utils';
import {
  DEFAULT_WORKSPACE,
  resolveNextWorkspace,
  WorkspaceId,
  workspaces,
  workspacesWithAdmin,
  getWorkspacesByStateAndPreferences,
  canPageBeLastVisited,
  doesAppPageAllowedForWorkspace,
  doesPageAllowedForWorkspace,
  ALL_WORKSPACES,
} from '../utilities/workspacesUtils';
import { userPreferencesService } from './userPreferencesService';
import { sessionStorageService } from '../../common/services/sessionStorageService';
import { isWorkspacesEnabled, isWorkspacesLastVisitedPageEnabled } from '../utilities/featureFlagUtils';
import { CONFIG } from '../../config/common';

export enum WorkspacesEvents {
  REFRESH_SELECTED_WORKSPACE = 'refreshSelectedWorkspace',
}

export interface WorkspacesSettingsPreferences {
  workspaceId: string;
  lastVisitedPageSettings?: WorkspaceLastVisitedPageSettings;
}

export interface WorkspaceLastVisitedPageSettings {
  name: string;
  params: Record<string, string | number | boolean>;
}

export const workspacesEventsEmitter = new EventEmitter<WorkspacesEvents>();

const WORKSPACES_SETTINGS = 'workspacesSettings';
export const WORKSPACE_USER_PREFERENCE = 'workspaceUserPreference';

export const getSelectedWorkspaceSettings = async (
  workspaceId?: WorkspaceId,
): Promise<WorkspacesSettingsPreferences> => {
  if (!isWorkspacesEnabled()) {
    return;
  }

  const selectedWorkspaceId = workspaceId || getSelectedWorkspaceId();

  const allWorkspacesSettings = await userPreferencesService.get<WorkspacesSettingsPreferences[]>(WORKSPACES_SETTINGS);

  const workspaceSettings = allWorkspacesSettings?.data?.find(w => w.workspaceId === selectedWorkspaceId);

  return {
    ...(workspaceSettings || {}),
    workspaceId: selectedWorkspaceId,
    lastVisitedPageSettings: await getActualLastVisitedPageSettings(
      workspaceId,
      workspaceSettings?.lastVisitedPageSettings,
    ),
  };
};

const getActualLastVisitedPageSettings = async (
  workspaceId: WorkspaceId,
  lastVisitedPageSettings?: WorkspaceLastVisitedPageSettings,
) => {
  if (
    isWorkspacesLastVisitedPageEnabled() &&
    lastVisitedPageSettings &&
    (await canUserSeeLastVisitedPage(lastVisitedPageSettings, workspaceId))
  ) {
    return lastVisitedPageSettings;
  } else {
    return getDefaultLastVisitedPageSettings(workspaceId);
  }
};

const canUserSeeLastVisitedPage = async (
  pageSettings: WorkspaceLastVisitedPageSettings,
  selectedWorkspaceId: WorkspaceId,
): Promise<boolean> => {
  const { name: pageName, params: pageParams } = pageSettings;
  if (canPageBeLastVisited(pageName)) {
    if (isCustomAppPage(pageName)) {
      return doesAppPageAllowedForWorkspace(
        selectedWorkspaceId as WorkspaceId,
        pageParams.id as string,
        pageParams.appRoute as string,
      );
    } else {
      return doesPageAllowedForWorkspace(pageName, selectedWorkspaceId);
    }
  }
  return false;
};

const isCustomAppPage = (pageName: string) => {
  return pageName === CONFIG.states.CUSTOM_APP;
};

export const getDefaultLastVisitedPageSettings = (workspaceId: WorkspaceId) => {
  return {
    name: workspacesWithAdmin.find(workspace => workspace.id === workspaceId)?.getHomePage(),
    params: {},
  };
};

export const updateWorkspaceSettingsByWorkspaceId = async (newWorkspaceSettings: WorkspacesSettingsPreferences) => {
  if (canPageBeLastVisited(newWorkspaceSettings.lastVisitedPageSettings.name) && isWorkspacesLastVisitedPageEnabled()) {
    try {
      const prevWorkspacesSettings =
        (await userPreferencesService.get<WorkspacesSettingsPreferences[]>(WORKSPACES_SETTINGS))?.data || [];

      const newWorkspaceSettingsSerialized = {
        ...newWorkspaceSettings,
        lastVisitedPageSettings: getSerializedLastVisitedPageSettings(newWorkspaceSettings.lastVisitedPageSettings),
      };

      const preferenceForUpdate = ALL_WORKSPACES.map(workspaceId => {
        const prevWorkspaceSettings = prevWorkspacesSettings.find(workspace => workspace.workspaceId === workspaceId);
        return {
          workspaceId,
          lastVisitedPageSettings:
            workspaceId === newWorkspaceSettingsSerialized.workspaceId
              ? newWorkspaceSettingsSerialized.lastVisitedPageSettings
              : prevWorkspaceSettings?.lastVisitedPageSettings,
        };
      });

      await userPreferencesService.update<WorkspacesSettingsPreferences[]>({
        preference: WORKSPACES_SETTINGS,
        data: preferenceForUpdate,
      });
    } catch (err) {
      console.error(err);
      return false;
    }
  }
};

const getSerializedLastVisitedPageSettings = (lastVisitedPageSettings: WorkspaceLastVisitedPageSettings) => {
  return {
    name: lastVisitedPageSettings.name,
    params: Object.keys(lastVisitedPageSettings.params)
      .filter(key => ['string', 'number', 'boolean'].includes(typeof lastVisitedPageSettings.params[key]))
      .reduce((acc, key) => ({ ...acc, [key]: lastVisitedPageSettings.params[key] }), {}),
  };
};

export const selectWorkspace = (workspaceId: string, saveToPreferences = true) => {
  const prevWorkspaceId = getSelectedWorkspaceId();

  if (prevWorkspaceId !== workspaceId) {
    sessionStorageService.set('selectedWorkspaceId', workspaceId);
    workspacesEventsEmitter.emit(WorkspacesEvents.REFRESH_SELECTED_WORKSPACE);

    if (saveToPreferences) {
      userPreferencesService.update({
        preference: WORKSPACE_USER_PREFERENCE,
        data: workspaceId,
      });
    }
  }
};

export const getSelectedWorkspaceId = (): WorkspaceId =>
  sessionStorageService.get('selectedWorkspaceId') || DEFAULT_WORKSPACE;

export const getSelectedWorkspace = () => {
  const selectedWorkspaceId = getSelectedWorkspaceId();
  return workspacesWithAdmin.find(w => w.id === selectedWorkspaceId);
};

export const syncWorkspacesWithUserPreferences = async () => {
  const preferences = await userPreferencesService.get<string>(WORKSPACE_USER_PREFERENCE);
  if (preferences?.data) {
    selectWorkspace(preferences.data, false);
  }
};

export const updateWorkspaceOnTransition = async (nextState: string) => {
  const workspacesToChoose = await getWorkspacesByStateAndPreferences(nextState);
  const nextWorkspace = resolveNextWorkspace(workspacesToChoose, getSelectedWorkspaceId());
  selectWorkspace(nextWorkspace);
};

export const useWorkspaces = () => {
  const [, setRefreshNumber] = useState(0);

  useEffect(() => {
    const triggerComponentRefresh = () => {
      setRefreshNumber(prevState => prevState + 1);
    };

    return workspacesEventsEmitter.addEventListener(
      WorkspacesEvents.REFRESH_SELECTED_WORKSPACE,
      triggerComponentRefresh,
    );
  }, []);

  return {
    selectedWorkspaceId: getSelectedWorkspaceId(),
    selectedWorkspace: getSelectedWorkspace(),
    selectWorkspace,
    workspaces,
  };
};
