import React, { FC, useContext, useMemo } from 'react';
import { BigidBody1, BigidForm, BigidFormProps } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { DataSourceBulkUpdateContext } from './DataSourceBulkUpdateContext';
import { Divider } from '@mui/material';
import { checkIsFieldVisible } from '../utils/conditionUtils';
import { getInitialFormValues, isBooleanField } from './dsBulkUpdateUtils';
import { DataSourceBulkUpdateOmittedFieldsList } from './DataSourceBulkUpdateOmittedFieldsList';
import { omittedFieldNames } from './dataSourceBulkUpdateConstants';

const BulkUpdateFormWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});
const FormFieldDivider = styled(Divider)`
  padding-top: 12px;
  margin-bottom: 12px;
`;

export const DataSourceBulkUpdateForm: FC = () => {
  const { dataSourceBulkUpdateState, bulkDataSourceUpdateHandlersRef, isSingleDataSourceType } =
    useContext(DataSourceBulkUpdateContext);

  const formProps: BigidFormProps = useMemo(() => {
    return {
      fields: [...dataSourceBulkUpdateState.fields, ...dataSourceBulkUpdateState.defaultFields],
      initialValues: getInitialFormValues([
        ...dataSourceBulkUpdateState.fields,
        ...dataSourceBulkUpdateState.defaultFields,
      ]),
      controlButtons: false,
      stateAndHandlersRef: bulkDataSourceUpdateHandlersRef,
      isFieldsBySelection: false,
      renderForm: ({ renderField, getFieldProps }) => {
        let additionalFields = null;

        const defaultFieldsToDisplay = (
          <BulkUpdateFormWrapper>
            {dataSourceBulkUpdateState?.defaultFields.map(({ name, fieldProps, isAddDivider }) => (
              <>
                {renderField(name, { selectProps: fieldProps })}
                {isAddDivider && <FormFieldDivider variant={'fullWidth'} orientation={'horizontal'} light={false} />}
              </>
            ))}
          </BulkUpdateFormWrapper>
        );
        if (isSingleDataSourceType) {
          try {
            additionalFields = (
              <BulkUpdateFormWrapper>
                {dataSourceBulkUpdateState?.fields.map(({ name, misc }) => {
                  let isVisible = false;
                  try {
                    isVisible = checkIsFieldVisible({ misc, getFieldProps });
                  } catch (e) {
                    console.warn(e);
                  }
                  return (
                    isVisible &&
                    renderField(
                      name,
                      isBooleanField(misc.type)
                        ? {
                            fieldProps: {
                              label: 'Enable',
                              isFieldsBySelection: true,
                            },
                          }
                        : {},
                    )
                  );
                })}
              </BulkUpdateFormWrapper>
            );
          } catch (e) {
            console.error(e);
            additionalFields = (
              <BigidBody1>{`This data source does not support bulk modification of additional controls yet. We're working on it.`}</BigidBody1>
            );
          }
        }

        return (
          <>
            {defaultFieldsToDisplay}
            {additionalFields}
            {!isSingleDataSourceType && <DataSourceBulkUpdateOmittedFieldsList omittedFields={omittedFieldNames} />}
          </>
        );
      },
    };
  }, [
    bulkDataSourceUpdateHandlersRef,
    dataSourceBulkUpdateState.defaultFields,
    dataSourceBulkUpdateState.fields,
    isSingleDataSourceType,
  ]);
  return useMemo(() => <BigidForm {...formProps} />, [formProps]);
};
