import { CredsStatus } from '../views/Fmsd/FmsdComponents';
import { customAppService } from './customAppService';
import { scansService } from './angularServices';
import { ConnectionInfo } from '../views/Fmsd/FmsdPreDiscover/hooks';
import { AutoDiscoveryAppIdsType } from '../views/Fmsd/FmsdPreDiscover/FmsdPreDiscoverViews';
import { CustomAppStatus } from '../views/CustomApp/views/ActivityLog/ActivityLog';
import { notificationService } from './notificationService';
import { CustomAppActionData, LastAppRunInfo } from '../views/Fmsd/FmsdDiscover/fmsdDiscoverServices';
import { ScanProfile } from '../views/AutoDiscoveryWizard/autoDiscoveryWizardServices';
import { ParamResponseType } from '../views/CustomApp/utils/CustomAppTypes';
import { CustomApps } from '../views/ApplicationsManagement/applicationManagementService';
import { appsUrls } from '../config/publicUrls';
import { CustomAppAction, ExecutionPayload } from '../views/CustomApp/views/CustomAppActions/CustomAppActions';
import { ActionItemPresetsEntity } from '../views/CustomApp/types';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { BigidFieldFilter } from '@bigid-ui/components';
import { orderBy, get } from 'lodash';
import { CloudProvider } from '../views/AutoDiscovery/constants';

export const AUTO_DISCOVERY_CHECK_PERMISSION_PARAM = 'check_permission';
const ACCESS_DENIED = 'Access Denied';
const WRONG_CREDS = [
  'wrong_credentials',
  'Invalid AWS credential',
  'Permissions validation failed',
  'An action failed',
  'Invalid credentials',
];

const NUM_OF_THREADS = '25';

const DEFAULT_ERROR_MESSAGE = 'Oops! Something went wrong with the Auto Discovery app.';

export interface AppInfo {
  appName: string;
  runAction: string;
  dsPrefix: string;
  appUrl: string;
  creds: Record<string, string>;
  config?: Record<string, string>;
  stopAction?: string;
  runMultiAction?: string;
}

export interface AppRunStatus {
  isPreviousRunExisted: boolean;
  lastRunProgress?: number;
  lastRunStatus?: CustomAppStatus;
}

interface MapAutoDiscoveryPreset {
  type: CloudProvider;
  appId: string;
  latestRuns: ExecutionPayload[];
  appRunAction: CustomAppAction;
  appRunMultiAction: CustomAppAction;
  appStopAction: CustomAppAction;
}

export type TestConnectionData = Pick<ConnectionInfo, 'credsStatus' | 'permissions'>;

export const appsInfo: Record<CloudProvider, AppInfo> = {
  [CloudProvider.AWS]: {
    appName: 'AWS Auto-Discovery App',
    runAction: 'aws_automation',
    runMultiAction: 'aws_autodiscovery_from_config',
    stopAction: 'stop',
    dsPrefix: 'aws_',
    appUrl: appsUrls.AWS_LOCAL_URL,
    creds: { accessKeyId: 'access_key_id', secretAccessKey: 'secret_access_key' },
    config: {
      dsTypes: 'ds_type',
    },
  },
  [CloudProvider.AZURE]: {
    appName: 'Azure Auto-Discovery App',
    runAction: 'azure_automation',
    runMultiAction: 'azure_mgmt_group_discovery',
    stopAction: 'stop',
    dsPrefix: 'Azure_',
    appUrl: appsUrls.AZURE_LOCAL_URL,
    creds: {
      clientId: 'client_id',
      clientSecret: 'client_secret',
      tenantId: 'tenant_id',
      subscriptionId: 'subscription_id',
    },
  },
  [CloudProvider.GCP]: {
    appName: 'GCP Auto-Discovery App',
    runAction: 'google_cloud_automation',
    stopAction: 'stop',
    dsPrefix: 'google_cloud_',
    appUrl: appsUrls.GCP_LOCAL_URL,
    creds: {
      credentials: 'credentials',
    },
  },
  [CloudProvider.ONTAP]: {
    appName: 'NetApp Ontap Auto-Discovery App',
    runAction: 'ontap_automation',
    stopAction: 'stop',
    dsPrefix: 'NETAPP_ONTAP',
    appUrl: appsUrls.ONTAP_LOCAL_URL,
    creds: { username: 'username', password: 'password' },
  },
};

export const DISCOVERY_NEW_UI_SUPPORTED_APP_TYPES = [
  CloudProvider.AZURE,
  CloudProvider.AWS,
  CloudProvider.GCP,
  CloudProvider.ONTAP,
];

export const getParamValue = (params: [ParamResponseType], name: string) => {
  const matchedParam = params.find(param => param.param_name === name);
  return matchedParam ? matchedParam.value : null;
};

export const isCredsExist = async (type: CloudProvider) => {
  try {
    const { params } = await getAutoDiscoveryAppInfo(type);
    return (
      appsInfo[type]?.creds &&
      Object.keys(appsInfo[type]?.creds).every(paramKey =>
        Boolean(getParamValue(params, appsInfo[type].creds[paramKey])),
      )
    );
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getAutoDiscoveryAppIds = async (type: CloudProvider, onFinally?: () => void) => {
  try {
    const appData = await findAutoDiscoveryApp(type);
    if (!appData) {
      onFinally?.();
      throw new Error(`Can't find ${appsInfo[type].appName}`);
    }
    const { data: appActions } = await customAppService.getCustomAppActions(appData._id);
    const appRunAction =
      appActions &&
      appActions.find((action: { action_name: string }) => action?.action_name === appsInfo[type]?.runAction);
    if (!appActions || !appRunAction) {
      throw new Error("Can't find app actions.");
    }
    const { _id, tpa_id } = appRunAction;
    return (await isCredsExist(type)) && { id: _id, tpaId: tpa_id };
  } catch (e) {
    onFinally?.();
    throw new Error(e.message);
  }
};

export const findActionByType = (actionType: string, appType: CloudProvider, appActions: Record<string, any>) =>
  appActions &&
  appActions.find(
    (action: { action_name: string }) => action?.action_name === appsInfo[appType][actionType as keyof AppInfo],
  );

const getAutoAppRunActionAndLastRun = async ({ _id, type }: { _id: string; type: CloudProvider }) => {
  const [{ data: appActions }, { data: latestRuns }] = await Promise.all([
    customAppService.getCustomAppActions(_id),
    customAppService.getCustomAppLastExecutions(_id),
  ]);
  const appRunAction = findActionByType('runAction', type, appActions);
  const appRunMultiAction = findActionByType('runMultiAction', type, appActions);
  const appStopAction = findActionByType('stopAction', type, appActions);

  if (!appActions || !appRunAction) {
    throw new Error("Can't find app actions.");
  }
  return {
    type,
    appId: _id,
    latestRuns,
    appRunAction,
    appRunMultiAction,
    appStopAction,
  };
};

const getLabeledCount = (message: string, label: string): number => {
  const position = message.indexOf(label);

  if (position > 0) {
    const count = parseInt(message.slice(0, position).trim().split(' ').pop());

    if (!isNaN(count)) {
      return count;
    }
  }
  return 0;
};

const getDsCountFromMessage = (message = '') => {
  return ['Data Sources created', 'Data Sources updated'].reduce(
    (count, label) => count + getLabeledCount(message, label),
    0,
  );
};

const getLastRunInfoForPreset = (latestRuns: ExecutionPayload[], item: ActionItemPresetsEntity) => {
  const latestRunForPreset = latestRuns?.find?.(({ preset_id }: ExecutionPayload) => preset_id === item._id);
  const dsCount = getDsCountFromMessage(latestRunForPreset?.message);

  return {
    latestRunForPreset,
    dsCount,
  };
};

const sortConfigGridItems = (items: any[], queryComponents: BigidGridQueryComponents) => {
  if (queryComponents?.sort?.[0]?.field) {
    const { field, order } = queryComponents.sort[0];
    return orderBy(items, [item => get(item, field)?.toLowerCase?.()], [order]);
  }
  return items;
};

const getGridFilterByName = (name: string, queryComponents: BigidGridQueryComponents): BigidFieldFilter =>
  queryComponents.filter.find(({ field }) => field === name);

const filterConfigGridItems = (items: any[], queryComponents: BigidGridQueryComponents) => {
  const searchFilter = getGridFilterByName('name', queryComponents);
  const typeFilter = getGridFilterByName('type', queryComponents);
  const statusFilter = getGridFilterByName('latestRun.status_enum', queryComponents);
  const isDefaultFilter = getGridFilterByName('is_default', queryComponents);
  const idFilter = getGridFilterByName('id', queryComponents);

  return items.filter(item => {
    if (idFilter && (idFilter.value as string) === item._id) {
      return true;
    }
    if (searchFilter) {
      return searchFilter.operator === 'textSearch'
        ? item.name.toLowerCase().includes(String(searchFilter.value).toLowerCase())
        : item.name === searchFilter.value;
    }
    if (typeFilter && !(typeFilter.value as string[]).includes(item.type)) {
      return false;
    }
    if (statusFilter && !(statusFilter.value as string[]).includes(String(item.latestRun?.status_enum))) {
      return false;
    }
    return !(isDefaultFilter && !(isDefaultFilter.value as string[]) === item.is_default);
  });
};

const mapAutoDiscoveryPreset = (
  item: ActionItemPresetsEntity,
  { type, appId, latestRuns, appRunMultiAction, appRunAction, appStopAction }: MapAutoDiscoveryPreset,
  isMultiConfig = false,
) => {
  const { latestRunForPreset, dsCount } = getLastRunInfoForPreset(latestRuns, item);
  const appRunActionData = isMultiConfig ? appRunMultiAction : appRunAction;

  return {
    ...item,
    name: item.is_default && isMultiConfig ? `${item.name}-Multiple-Account` : item.name,
    type,
    dsCount,
    latestRun: latestRunForPreset,
    appId,
    appRunAction: appRunActionData,
    appStopAction,
    isMultiConfig,
  };
};

export const getAutoDiscoveryAppsConfigsList = async (queryComponents: BigidGridQueryComponents) => {
  const appsData = await findAllAutoDiscoveryApps();
  const actionsDataWithLastRuns = await Promise.all(appsData.map(getAutoAppRunActionAndLastRun));

  const items = actionsDataWithLastRuns.reduce((acc, action) => {
    return [
      ...acc,
      ...action.appRunAction.presets.map((item: ActionItemPresetsEntity) => {
        return mapAutoDiscoveryPreset(item, action);
      }),
      ...(action.appRunMultiAction
        ? action.appRunMultiAction?.presets.map((item: ActionItemPresetsEntity) => {
            return mapAutoDiscoveryPreset(item, action, true);
          })
        : []),
    ];
  }, []);

  const itemsFiltered = filterConfigGridItems(items, queryComponents);
  const itemsSorted = sortConfigGridItems(itemsFiltered, queryComponents);
  return queryComponents.limit && itemsSorted.length > queryComponents.limit
    ? {
        data: itemsSorted.slice(queryComponents.skip, queryComponents.skip + queryComponents.limit),
        totalCount: itemsSorted.length,
      }
    : {
        data: itemsSorted,
        totalCount: itemsSorted.length,
      };
};

export const parsePermissionsStringToArray = (permissions: string) => {
  const firstSymbol = permissions.indexOf('[') + 1;
  const lastSymbol = permissions.indexOf(']');
  return Boolean(lastSymbol - firstSymbol) ? permissions.slice(firstSymbol, lastSymbol).split(',') : [];
};

export const extractPermissionsFromMessage = (message: string): ConnectionInfo => {
  if (message.includes(ACCESS_DENIED)) {
    return { permissions: [], credsStatus: CredsStatus.CANT_EVALUATE };
  } else if (WRONG_CREDS.some(wrongCredMsgPart => message.includes(wrongCredMsgPart))) {
    return { permissions: [], credsStatus: CredsStatus.WRONG_CREDS };
  } else {
    return { permissions: parsePermissionsStringToArray(message), credsStatus: CredsStatus.VALID };
  }
};

const createConfigUpdateObject = (cloudProvider?: CloudProvider, configForUpdate?: Record<string, any>) => {
  if (!cloudProvider && !configForUpdate) {
    return {};
  }
  const fieldsForUpdate = Object.entries(configForUpdate);
  return fieldsForUpdate.reduce((acc, [key, value]) => ({ ...acc, [appsInfo[cloudProvider]?.config[key]]: value }), {});
};

export const getDiscoveryAppPermisionsWithoutCreds = async (
  { id, tpaId }: AutoDiscoveryAppIdsType,
  onFinally: () => void,
  cloudProvider?: CloudProvider,
  configForUpdate?: Record<string, any>,
) => {
  try {
    const toUpdate = {
      actionParamsKeyValuesMap: [
        {
          actionId: id,
          paramsKeyValuesMap: {
            [AUTO_DISCOVERY_CHECK_PERMISSION_PARAM]: 'true',
            is_iam_role: 'false',
            ...createConfigUpdateObject(cloudProvider, configForUpdate),
          },
        },
      ],
    };

    await customAppService.updateCustomAppParamValues(tpaId, toUpdate);
    const { data } = await customAppService.runCustomAppPreset(id, '', tpaId);
    if (!data || !data.data) {
      throw new Error("Can't run app actions.");
    }

    return extractPermissionsFromMessage(data.data.message);
  } catch (e) {
    throw new Error(e.message);
  } finally {
    onFinally();
  }
};

export const findAutoDiscoveryApp = async (type: CloudProvider) => {
  try {
    const customAppsData = await customAppService.getCustomApps();
    const appData = customAppsData.data.find((app: CustomApps) => app.tpa_name === appsInfo[type]?.appName);
    return appData;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const findAllAutoDiscoveryApps = async () => {
  try {
    const customAppsData = await customAppService.getCustomApps();
    const autoApps = Object.entries(appsInfo).reduce((allAutoAppsInSystem, [type, { appName, runAction }]) => {
      const autoAppData = customAppsData.data.find((app: CustomApps) => app?.tpa_name === appName);
      return autoAppData
        ? [
            ...allAutoAppsInSystem,
            {
              ...autoAppData,
              type,
              runAction,
            },
          ]
        : allAutoAppsInSystem;
    }, []);
    return autoApps;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getNewUISupportedApps = async () => {
  try {
    const apps = await findAllAutoDiscoveryApps();
    return apps?.filter(app => DISCOVERY_NEW_UI_SUPPORTED_APP_TYPES.includes(app?.type));
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getDiscoveryAppPermisions = async (
  type: CloudProvider,
  params: Record<string, string>,
  onFinally: () => void,
) => {
  try {
    const appData = await findAutoDiscoveryApp(type);
    if (!appData) {
      throw new Error(`Can't find ${appsInfo[type].appName}`);
    }
    const { data: appActions } = await customAppService.getCustomAppActions(appData._id);
    const appRunAction =
      appActions &&
      appActions.find((action: { action_name: string }) => action?.action_name === appsInfo[type]?.runAction);
    if (!appActions || !appRunAction) {
      throw new Error("Can't find app actions.");
    }
    const { _id, tpa_id } = appRunAction;

    if (type === CloudProvider.AWS) {
      const { accessKeyId, secretAccessKey, dsTypes } = params;
      await setAwsCreds(accessKeyId, secretAccessKey, dsTypes);
    }

    if (type === CloudProvider.AZURE) {
      const { clientId, clientSecret, subscriptionId, tenantId } = params;
      await setAzureCreds(clientId, clientSecret, subscriptionId, tenantId);
    }

    const { data } = await customAppService.runCustomAppPreset(_id, '', tpa_id);
    if (!data || !data.data) {
      throw new Error("Can't run app actions.");
    }

    return extractPermissionsFromMessage(data.data.message);
  } catch (e) {
    throw new Error(e.message);
  } finally {
    onFinally();
  }
};

export const autoDiscoveryErrorNotification = (e: Error, notificationMessage: string = null) => {
  notificationService.error(notificationMessage ?? DEFAULT_ERROR_MESSAGE);
  console.error(e.message);
};

export const getAutoDiscoveryInfo = async (type: CloudProvider): Promise<LastAppRunInfo> => {
  const { tpa_id, actionId } = await getAutoDiscoveryAppInfo(type);
  const { data = [] }: { data: CustomAppActionData[] } = await customAppService.getCustomAppExecutions(tpa_id);

  const dataForAction = data.filter(execution => execution.tpa_action_id === actionId);
  return { status_enum: dataForAction[0]?.status_enum, message: dataForAction[0]?.message };
};

export const getLastRunInfo = async (type: CloudProvider): Promise<LastAppRunInfo> => {
  try {
    return getAutoDiscoveryInfo(type);
  } catch (e) {
    autoDiscoveryErrorNotification(e);
  }
};

export const configureAutoDiscoveryApp = async (tpaId: string, actionId: string) => {
  try {
    const toUpdate = {
      actionParamsKeyValuesMap: [
        {
          actionId,
          paramsKeyValuesMap: {
            numberOfParsingThreads: NUM_OF_THREADS,
            check_permission: 'false',
            is_iam_role: 'false',
            differential: 'true',
            dsAclScanEnabled: 'true',
          },
        },
      ],
    };
    await customAppService.updateCustomAppParamValues(tpaId, toUpdate);
    return true;
  } catch (e) {
    autoDiscoveryErrorNotification(e);
  }
};

export const getAppLastRunStatus = async (tpa_id: string): Promise<AppRunStatus> => {
  const { data } = await customAppService.getCustomAppExecutions(tpa_id);
  let isPreviousRunExisted = false;
  let lastRunProgress, lastRunStatus;
  if (data[0]) {
    isPreviousRunExisted = true;
    lastRunProgress = Math.round(data[0].progress * 100);
    lastRunStatus = data[0].status_enum;
  }

  return { isPreviousRunExisted, lastRunProgress, lastRunStatus };
};

export const getAppRunCount = async (autodiscoveryProfileDesc: string) => {
  try {
    let runCount = 1;
    const { scanProfiles }: { scanProfiles: ScanProfile[] } = await scansService.getScanProfilesData();
    const autoDisocverProfiles = scanProfiles.filter(scanProfile =>
      scanProfile.name.includes(autodiscoveryProfileDesc),
    );
    if (autoDisocverProfiles.length) {
      autoDisocverProfiles.forEach(profile => {
        const profileName = profile.name.split(' ');
        const profileRunCount = Number(profileName[profileName.length - 1]);
        if (profileRunCount >= runCount) {
          runCount = profileRunCount + 1;
        }
      });
    }

    return runCount;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getAutoDiscoveryAppInfo = async (type: CloudProvider) => {
  try {
    const appData = await findAutoDiscoveryApp(type);
    if (!appData) {
      throw new Error(`Can't find ${appsInfo[type]?.appName}`);
    }
    const { data } = await customAppService.getCustomAppActions(appData._id);
    const appRunAction =
      data && data.find((action: { action_name: string }) => action?.action_name === appsInfo[type]?.runAction);
    if (!data || !appRunAction) {
      throw new Error("Can't find app actions.");
    }
    const { _id, tpa_id, params } = appRunAction;
    const { isPreviousRunExisted, lastRunStatus } = await getAppLastRunStatus(tpa_id);
    return { actionId: _id, tpa_id, isPreviousRunExisted, lastRunStatus, params };
  } catch (e) {
    throw new Error(e.message);
  }
};

export const registerAutoDiscoveryApp = async (type: CloudProvider) => {
  try {
    const appData = await findAutoDiscoveryApp(type);
    if (!appData) {
      const {
        status,
        data: { tpaId },
      } = await customAppService.addCustomAppBaseUrl({ appUrl: appsInfo[type]?.appUrl, isRemoteApplication: false });
      if (status !== 200) {
        throw new Error('Error with registering the autodiscovery app.');
      }
      const bigid_base_url = window.location.origin;
      const toUpdate = {
        actionParamsKeyValuesMap: {},
        paramsKeyValuesMap: {},
        generalKeyValuesMap: { bigid_base_url },
      };
      await customAppService.updateCustomAppParamValues(tpaId, toUpdate);
    }
    return true;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const startAutoDiscoveryApp = async (type: CloudProvider, allowSecondaryRun = true) => {
  try {
    await registerAutoDiscoveryApp(type);
    const { actionId, tpa_id, isPreviousRunExisted, lastRunStatus } = await getAutoDiscoveryAppInfo(type);
    if (!isPreviousRunExisted || allowSecondaryRun) {
      if (lastRunStatus !== CustomAppStatus.IN_PROGRESS) {
        // Will trigger app run only if there is no run in progress
        await configureAutoDiscoveryApp(tpa_id, actionId);
        const { status } = await customAppService.runCustomAppPreset(actionId, '', tpa_id);
        if (status !== 200) {
          throw new Error('Error with starting the autodiscovery app.');
        }
      }
    }

    return tpa_id;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const setAwsCreds = async (accessKeyId: string, secretAccessKey: string, dsTypes?: string) => {
  try {
    const { tpa_id, actionId } = await getAutoDiscoveryAppInfo(CloudProvider.AWS);
    const toUpdate = {
      actionParamsKeyValuesMap: [
        {
          actionId: actionId,
          paramsKeyValuesMap: {
            [appsInfo[CloudProvider.AWS]?.creds.accessKeyId]: accessKeyId,
            [appsInfo[CloudProvider.AWS]?.creds.secretAccessKey]: secretAccessKey,
            [AUTO_DISCOVERY_CHECK_PERMISSION_PARAM]: 'true',
            is_iam_role: 'false',
            ...(dsTypes ? { [appsInfo[CloudProvider.AWS]?.config.dsTypes]: dsTypes } : {}),
          },
        },
      ],
      paramsKeyValuesMap: {},
      generalKeyValuesMap: {},
    };

    await customAppService.updateCustomAppParamValues(tpa_id, toUpdate);
  } catch (e) {
    throw new Error(e.message);
  }
};

export const setAzureCreds = async (
  clientId: string,
  clientSecret: string,
  subscriptionId: string,
  tenantId: string,
) => {
  try {
    const { tpa_id, actionId } = await getAutoDiscoveryAppInfo(CloudProvider.AZURE);
    const toUpdate = {
      actionParamsKeyValuesMap: [
        {
          actionId: actionId,
          paramsKeyValuesMap: {
            [appsInfo[CloudProvider.AZURE]?.creds.clientId]: clientId,
            [appsInfo[CloudProvider.AZURE]?.creds.clientSecret]: clientSecret,
            [appsInfo[CloudProvider.AZURE]?.creds.subscriptionId]: subscriptionId,
            [appsInfo[CloudProvider.AZURE]?.creds.tenantId]: tenantId,
            [AUTO_DISCOVERY_CHECK_PERMISSION_PARAM]: 'true',
          },
        },
      ],
      paramsKeyValuesMap: {},
      generalKeyValuesMap: {},
    };

    await customAppService.updateCustomAppParamValues(tpa_id, toUpdate);
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getCurrentConfig = async (type: CloudProvider): Promise<Record<string, any>> => {
  if (!appsInfo[type]?.config) {
    return {};
  }
  try {
    const { params } = await getAutoDiscoveryAppInfo(type);
    return Object.keys(appsInfo[type]?.config).reduce(
      (acc, paramKey) => ({ ...acc, [paramKey]: getParamValue(params, appsInfo[type].config[paramKey]) }),
      {},
    );
  } catch (e) {
    throw new Error(e.message);
  }
};
