export enum SidePanelEvents {
  ADD_DATASOURCE_OWNER_EVENT = '_add_data_source_owner_event',
  REMOVE_DATASOURCE_OWNER_EVENT = '_remove_data_source_owner_event',
  UNSTRUCTURED_PREVIEW_ATTRIBUTE_FILTER_NAME = '_unstructured_preview_attribute_filter_name',
  VIEW_PREVIEW_TABLE = '_view_preview_table',
  ATTRIBUTE_TAB = '_attribute_tab',
  ATTRIBUTE_PREVIEW_EXPIRED_MSG = '_attribute_preview_expired_msg',
  ADD_ATTRIBUTE = '_add_attribute',
  REMOVE_ATTRIBUTE_EVENT = '_remove_attribute_event',
  ADD_TAG_EVENT = '_add_tag_event',
  REMOVE_TAG_EVENT = '_remove_tag_event',
  REMOVE_BUSINESS_ATTRIBUTE = '_remove_business_attribute',
  ADD_BUSINESS_ATTRIBUTE = '_add_business_attribute',
  VIEW_SIMILAR_COLUMNS_LINKED_COLUMNS = '_view_similar_columns_linked_columns',
  CATALOG_COLUMN_PROFILE_EVENT = '_catalog_column_profile_event',
}
