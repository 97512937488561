import { httpService } from '../../../services/httpService';
import { notificationService } from '../../../services/notificationService';
import { CorrelationSetData, ScanProfile } from '../types/CorrelationSetsTypes';
import { createScanProfileName } from '../utils/utils';

export const fetchCorrelationSetByName = async (name: string): Promise<CorrelationSetData> => {
  try {
    return (await httpService.fetch(`/id_connections/${name}`))?.data;
  } catch (e) {
    notificationService.error('Failed to fetch id_connections, see logs for more information.');
    console.log('fetchCorrelationSetByName error: ', e);
  }
};

export const duplicateCorrelationSetsByIds = (ids: string[]) =>
  httpService.post('correlation-set-connections/duplicate', {
    ids,
  });

export const updateCorrelationSets = (payload: any) => httpService.put('correlation-set-connections', payload);

export const deleteCorrelationSets = (payload: any) => httpService.delete('correlation-set-connections', payload);

export const testConnectionRequest = async (correlationSetData: CorrelationSetData) => {
  try {
    return (await httpService.post('/id_connection_test', correlationSetData))?.data;
  } catch (e) {
    notificationService.error('Failed test connection, see logs for more information.');
    console.log('testConnectionRequest error: ', e);
  }
};

export const createNewCorrelationSet = async (correlationSetData: CorrelationSetData) => {
  return (await httpService.post('/id_connections', correlationSetData))?.data;
};

export const updateNewCorrelationSet = async (correlationSetName: string, correlationSetData: CorrelationSetData) => {
  return (await httpService.put(`id_connections/${correlationSetName}`, correlationSetData))?.data;
};

export const getScanProfilesList = async (): Promise<ScanProfile[]> => {
  return (await httpService.fetch(`/scanProfiles`))?.data?.scanProfiles;
};

export const createScanProfile = async (correlationSetName: string) => {
  const payload = {
    name: createScanProfileName(correlationSetName),
    dataSourceList: [] as string[],
    idsorList: [correlationSetName],
    allEnabledDs: false,
    allEnabledIdSor: false,
    scanType: 'dsScan',
    isCustomScanProfile: true,
    skipIdScan: false,
  };

  return (await httpService.post(`scanProfiles/`, payload))?.data;
};

export const triggerScan = async (correlationSetName: string) => {
  const payload = {
    scanProfileName: createScanProfileName(correlationSetName),
    scanType: 'dsScan',
    scanOrigin: 'Correlation set',
  };

  return (await httpService.post(`scans/`, payload))?.data;
};
