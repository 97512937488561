import React from 'react';
import { DataCatalogRecord } from '../../../../../../DataCatalog/DataCatalogService';
import { styled } from '@mui/material';
import { DataExplorerColumnsProps } from '../DataExplorerColumns/DataExplorerColumns';
import { DataCatalogRelations } from '../../../../../../DataCatalog/DataCatalogRelations';
import { useGetObjectDetails } from '../hooks/useGetObjectDetails';

const Root = styled('div')`
  flex-grow: 1;
  display: flex;
  border-radius: 4px;
  border-color: ${({ theme }) => theme.vars.tokens.bigid.borderDefault};
  border-width: 1px;
`;

type RelationsProps = DataExplorerColumnsProps;

export const Relations = ({ datasource, id }: RelationsProps) => {
  const { scannerType, value: source } = datasource;
  const fqn = String(id);
  const { data } = useGetObjectDetails(fqn);
  const { fullObjectName, type, open_access } = data?.data || {};

  const relationsProps: DataCatalogRecord = {
    scannerType,
    fullyQualifiedName: fqn,
    source,
    type,
    fullObjectName,
    has_duplicates: 'No',
    id: fqn,
    objectId: fqn,
    objectName: fullObjectName,
    open_access: open_access as DataCatalogRecord['open_access'],
  };

  return <Root>{<DataCatalogRelations {...relationsProps} />}</Root>;
};
