import React, { FC, useEffect, useMemo, useState } from 'react';
import { RiskMatrix } from './components/RiskMatrix';
import {
  BigidBody1,
  BigidColorSchemeTokens,
  BigidColorSchemesVars,
  BigidDialog,
  BigidDropdown,
  BigidDropdownOption,
  BigidTextField,
  PrimaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { styled } from '@mui/material';
import { BigidChevronUpIcon, BigidChevronDownIcon } from '@bigid-ui/icons';
import { cloneDeep, isEqual } from 'lodash';
import { CellData, RiskMatrixMetadata, cellData, xAxis, yAxis } from './RiskMatrixDefaults';
import { httpService } from '../../services/httpService';
import { generateDataAid } from '@bigid-ui/utils';
import { isPermitted } from '../../services/userPermissionsService';
import { PRIVACY_RISKS_PERMISSIONS } from '@bigid/permissions';

enum ExpandedSection {
  RiskLevel,
  XAxis,
  YAxis,
  None,
}

const Sizes: BigidDropdownOption[] = [
  {
    displayValue: '3x3',
    id: '3',
    value: 3,
  },
  {
    displayValue: '4x4',
    id: '4',
    value: 4,
  },
  {
    displayValue: '5x5',
    id: '5',
    value: 5,
  },
];

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  overflowY: 'visible',
});

const Form = styled('div')({
  width: '100%',
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  overflow: 'hidden',
});

const Section = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const Preview = styled('div')({
  backgroundColor: BigidColorSchemesVars.light.gray125,
  padding: '24px',
});

const MatrixSizeContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  '& > div': {
    width: '120px',
  },
});

const Expandable = styled('div', { shouldForwardProp: prop => !['expanded'].includes(prop.toString()) })<{
  expanded: boolean;
}>(({ expanded }) => ({
  display: 'flex',
  padding: '16px 20px 16px 16px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  alignSelf: 'stretch',
  borderRadius: '8px',
  height: expanded ? 'unset' : '66px',
  backgroundColor: BigidColorSchemesVars.light.gray125,
  border: BigidColorSchemeTokens.light.borderDefault,
}));

const ExpandableHeader = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',

  '& > div': {
    width: '294px',
  },
});

const ExpandableDetails = styled('div', { shouldForwardProp: prop => !['expanded'].includes(prop.toString()) })<{
  expanded: boolean;
}>(({ expanded }) => ({
  display: 'flex',
  flexDirection: 'column',
  visibility: expanded ? 'unset' : 'hidden',
  gap: '16px',
}));

const FieldWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  '& > div': {
    width: '268px',
  },
});

export const RiskMatrixDialog: FC<{
  open: boolean;
  onClose: (data?: RiskMatrixMetadata) => void;
  data: RiskMatrixMetadata;
}> = ({ open, data, onClose }) => {
  const [expandedSection, setExpandedSection] = useState<ExpandedSection>(ExpandedSection.RiskLevel);
  const [prevSelectedGridSize, setPrevSelectedGridSize] = useState<number | undefined>();
  const [metadata, setMetadata] = useState<RiskMatrixMetadata>(cloneDeep(data));
  const [hasErrors, setHasErrors] = useState(false);
  const listOfLevel = useMemo(() => {
    const tempListOfLevel: { label: string; ids: number[] }[] = [];
    for (const key of Object.keys(metadata.cellData)) {
      const index = tempListOfLevel.findIndex(item => item.label === metadata.cellData[Number(key)].riskLevelLabel);
      if (index > -1) {
        tempListOfLevel[index].ids.push(Number(key));
      } else {
        tempListOfLevel.push({
          label: metadata.cellData[Number(key)].riskLevelLabel,
          ids: [Number(key)],
        });
      }
    }

    return tempListOfLevel;
  }, [metadata]);

  const useSeverityLabels = (setHasErrors: (hasErrors: boolean) => void) => {
    // State to manage input values
    const [inputValues, setInputValues] = useState<Record<string, string>>({});

    // State to manage errors
    const [errors, setErrors] = useState<Record<string, string>>({});

    // Update input values whenever metadata.cellData changes
    useEffect(() => {
      const initialInputValues = listOfLevel.reduce((acc, level) => {
        acc[level.ids.join('-')] = level.label;
        return acc;
      }, {} as Record<string, string>);
      setInputValues(initialInputValues);
    }, [listOfLevel]); // Depend on listOfLevel to recompute when it changes

    // Handle input change with real-time validation
    const handleInputChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      level: { label: string; ids: number[] },
    ) => {
      const newValue = event.target.value;
      if (newValue.length < 13) {
        // Update input values
        setInputValues(prev => ({
          ...prev,
          [level.ids.join('-')]: newValue,
        }));

        // Real-time validation for errors
        if (!newValue.trim()) {
          setErrors(prev => ({
            ...prev,
            [level.ids.join('-')]: 'This field cannot be empty.',
          }));
          setHasErrors(true);
        } else if (listOfLevel.some(lvl => lvl !== level && lvl.label === newValue)) {
          setErrors(prev => ({
            ...prev,
            [level.ids.join('-')]: 'Level already exists.',
          }));
          setHasErrors(true);
        } else {
          setErrors(prev => ({
            ...prev,
            [level.ids.join('-')]: '',
          }));
          setHasErrors(false);

          // Update metadata if no errors
          const metadataClone = cloneDeep(metadata);
          level.ids.forEach(id => {
            metadataClone.cellData[id].riskLevelLabel = newValue;
          });
          setMetadata(metadataClone);
        }
      }
    };

    return listOfLevel.map((level, index) => (
      <FieldWrapper key={level.ids.join('-')}>
        <BigidBody1>{`#${index + 1}`}</BigidBody1>
        <BigidTextField
          required
          value={inputValues[level.ids.join('-')] || ''}
          isError={!!errors[level.ids.join('-')]}
          errorMessage={errors[level.ids.join('-')]}
          onChange={event => handleInputChange(event, level)}
          readOnly={
            !isPermitted(PRIVACY_RISKS_PERMISSIONS.MATRIX_CONFIG_EDIT.name) ||
            (listOfLevel.some(lvl => lvl.label === '') && level.label !== '')
          }
        />
      </FieldWrapper>
    ));
  };

  const useMatrixLabels = (
    metadataField: 'probabilityLabels' | 'impactLabels',
    setHasErrors: (hasErrors: boolean) => void,
  ) => {
    // State to manage input values
    const [inputValues, setInputValues] = useState<Record<string, string>>(
      Object.entries(metadata[metadataField]).reduce((acc, [key, label]) => {
        acc[key] = label; // Keeping the key as a string
        return acc;
      }, {} as Record<string, string>),
    );

    // State to manage errors
    const [errors, setErrors] = useState<Record<string, string>>({});

    // Handle input change with real-time validation
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
      const newValue = event.target.value;
      if (newValue.length < 13) {
        // Update input values for the field
        setInputValues(prev => ({
          ...prev,
          [key]: newValue,
        }));

        // Real-time validation for errors
        if (!newValue.trim()) {
          setErrors(prev => ({
            ...prev,
            [key]: 'This field cannot be empty.',
          }));
          setHasErrors(true);
        } else if (Object.values(inputValues).some(value => value === newValue && value !== inputValues[key])) {
          setErrors(prev => ({
            ...prev,
            [key]: 'Label already exists.',
          }));
          setHasErrors(true);
        } else {
          setErrors(prev => ({
            ...prev,
            [key]: '',
          }));
          setHasErrors(false);

          // Update metadata dynamically to reflect changes in the matrix
          const metadataClone = cloneDeep(metadata);
          (metadataClone[metadataField] as Record<string, string>)[key] = newValue; // Explicit cast
          setMetadata(metadataClone);
        }
      }
    };

    return Object.entries(metadata[metadataField]).map(([key, label], index) => (
      <FieldWrapper key={key}>
        <BigidBody1>{`#${index + 1}`}</BigidBody1>
        <BigidTextField
          required
          value={inputValues[key]}
          isError={!!errors[key]}
          errorMessage={errors[key]}
          onChange={event => handleInputChange(event, key)}
          readOnly={!isPermitted(PRIVACY_RISKS_PERMISSIONS.MATRIX_CONFIG_EDIT.name)}
        />
      </FieldWrapper>
    ));
  };

  // Usage examples:
  const useProbabilityLabels = (setHasErrors: (hasErrors: boolean) => void) =>
    useMatrixLabels('probabilityLabels', setHasErrors);

  const useImpactLabels = (setHasErrors: (hasErrors: boolean) => void) => useMatrixLabels('impactLabels', setHasErrors);

  const MatrixPreview = useMemo(() => {
    return <RiskMatrix {...metadata} oldGridSize={prevSelectedGridSize} />;
  }, [metadata, prevSelectedGridSize]);

  const isValid = () => {
    if (metadata.impactLabel.length === 0 || metadata.probabilityLabel.length === 0 || metadata.levelLabel.length === 0)
      return false;

    for (const value of Object.values(metadata.impactLabels)) {
      if ((value as string).length === 0) return false;
    }

    for (const value of Object.values(metadata.probabilityLabels)) {
      if ((value as string).length === 0) return false;
    }

    for (const value of Object.values(metadata.cellData)) {
      if ((value as CellData).riskLevelLabel.length === 0) return false;
    }

    return true;
  };

  return (
    <BigidDialog
      onExited={() => onClose()}
      isOpen={open}
      borderTop={true}
      maxWidth={'xl'}
      borderBottom={true}
      onClose={() => onClose()}
      buttons={[
        {
          component: TertiaryButton,
          onClick: () => onClose(),
          text: 'Cancel',
          dataAid: generateDataAid('riskMatrixForm', ['cancel']),
        },
        ...(isPermitted(PRIVACY_RISKS_PERMISSIONS.MATRIX_CONFIG_EDIT.name)
          ? [
              {
                component: PrimaryButton,
                onClick: async () => {
                  await httpService.put('risk-matrix', {
                    cellData: metadata.cellData,
                    yAxisLabel: metadata.impactLabel,
                    yAxisLabels: metadata.impactLabels,
                    matrixSize: metadata.matrixSize,
                    xAxisLabel: metadata.probabilityLabel,
                    xAxisLabels: metadata.probabilityLabels,
                    levelLabel: metadata.levelLabel,
                  });
                  onClose(metadata);
                },
                text: 'Save',
                disabled: isEqual(data, metadata) || !isValid() || hasErrors,
                dataAid: generateDataAid('riskMatrixForm', ['save']),
              },
            ]
          : []),
      ]}
      title={'Customize risk matrix'}
    >
      <Root>
        <Form>
          <MatrixSizeContainer data-aid={generateDataAid('riskMatrixForm', ['matrix-size'])}>
            <BigidBody1>{'Matrix Size'}</BigidBody1>
            <div>
              <BigidDropdown
                dataAid={generateDataAid('riskMatrixForm', ['matrix-size'])}
                options={Sizes}
                onSelect={val => {
                  const metadataClone = cloneDeep(metadata);
                  setPrevSelectedGridSize(metadataClone.matrixSize);
                  metadataClone.matrixSize = val[0].value;
                  metadataClone.cellData = cellData.get(val[0].value);
                  metadataClone.probabilityLabels = xAxis.get(val[0].value);
                  metadataClone.impactLabels = yAxis.get(val[0].value);
                  setMetadata(metadataClone);
                }}
                listWidth={120}
                value={Sizes.filter(size => size.value === metadata.matrixSize)}
                readOnly={!isPermitted(PRIVACY_RISKS_PERMISSIONS.MATRIX_CONFIG_EDIT.name)}
              />
            </div>
          </MatrixSizeContainer>
          <Section data-aid={generateDataAid('riskMatrixForm', ['severity-level'])}>
            <BigidBody1>{'Severity level'}</BigidBody1>
            <Expandable expanded={expandedSection === ExpandedSection.RiskLevel}>
              <ExpandableHeader
                data-aid={generateDataAid('riskMatrixForm', ['severity-level', 'header'])}
                onClick={() =>
                  setExpandedSection(prev =>
                    prev === ExpandedSection.RiskLevel ? ExpandedSection.None : ExpandedSection.RiskLevel,
                  )
                }
              >
                <div
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                >
                  <BigidTextField
                    size="medium"
                    value={metadata.levelLabel}
                    required
                    onChange={event => {
                      if (event.target.value.length > 15) return;
                      const metadataClone = cloneDeep(metadata);
                      metadataClone.levelLabel = event.target.value;
                      setMetadata(metadataClone);
                    }}
                    placeholder="Enter label"
                    isError={metadata?.levelLabel?.length === 0}
                    errorMessage={metadata?.levelLabel?.length === 0 ? 'This field cannot be empty' : ''}
                  />
                </div>
                {expandedSection === ExpandedSection.RiskLevel ? (
                  <BigidChevronUpIcon data-aid={generateDataAid('riskMatrixForm', ['severity-level', 'collapse'])} />
                ) : (
                  <BigidChevronDownIcon data-aid={generateDataAid('riskMatrixForm', ['severity-level', 'expand'])} />
                )}
              </ExpandableHeader>
              <ExpandableDetails expanded={expandedSection === ExpandedSection.RiskLevel}>
                {useSeverityLabels(setHasErrors)}
              </ExpandableDetails>
            </Expandable>
          </Section>
          <Section data-aid={generateDataAid('riskMatrixForm', ['yAxis'])}>
            <BigidBody1>{'X Axis'}</BigidBody1>
            <Expandable expanded={expandedSection === ExpandedSection.YAxis}>
              <ExpandableHeader
                data-aid={generateDataAid('riskMatrixForm', ['yAxis', 'header'])}
                onClick={() =>
                  setExpandedSection(prev =>
                    prev === ExpandedSection.YAxis ? ExpandedSection.None : ExpandedSection.YAxis,
                  )
                }
              >
                <div
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                >
                  <BigidTextField
                    dataAid={generateDataAid('riskMatrixForm', ['yAxis', 'label'])}
                    value={metadata.probabilityLabel}
                    isError={metadata.probabilityLabel.length === 0}
                    required
                    onChange={event => {
                      if (event.target.value.length > 15) return;
                      const metadataClone = cloneDeep(metadata);
                      metadataClone.probabilityLabel = event.target.value;
                      setMetadata(metadataClone);
                    }}
                    readOnly={!isPermitted(PRIVACY_RISKS_PERMISSIONS.MATRIX_CONFIG_EDIT.name)}
                  />
                </div>
                {expandedSection === ExpandedSection.YAxis ? (
                  <BigidChevronUpIcon data-aid={generateDataAid('riskMatrixForm', ['yAxis', 'collapse'])} />
                ) : (
                  <BigidChevronDownIcon data-aid={generateDataAid('riskMatrixForm', ['yAxis', 'expand'])} />
                )}
              </ExpandableHeader>
              <ExpandableDetails expanded={expandedSection === ExpandedSection.YAxis}>
                {useProbabilityLabels(setHasErrors)}
              </ExpandableDetails>
            </Expandable>
          </Section>
          <Section data-aid={generateDataAid('riskMatrixForm', ['xAxis'])}>
            <BigidBody1>{'Y Axis'}</BigidBody1>
            <Expandable expanded={expandedSection === ExpandedSection.XAxis}>
              <ExpandableHeader
                data-aid={generateDataAid('riskMatrixForm', ['xAxis', 'header'])}
                onClick={() =>
                  setExpandedSection(prev =>
                    prev === ExpandedSection.XAxis ? ExpandedSection.None : ExpandedSection.XAxis,
                  )
                }
              >
                <div
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                >
                  <BigidTextField
                    dataAid={generateDataAid('riskMatrixForm', ['xAxis', 'label'])}
                    value={metadata.impactLabel}
                    isError={metadata.impactLabel.length === 0}
                    required
                    onChange={event => {
                      if (event.target.value.length > 15) return;
                      const metadataClone = cloneDeep(metadata);
                      metadataClone.impactLabel = event.target.value;
                      setMetadata(metadataClone);
                    }}
                    readOnly={!isPermitted(PRIVACY_RISKS_PERMISSIONS.MATRIX_CONFIG_EDIT.name)}
                  />
                </div>
                {expandedSection === ExpandedSection.XAxis ? (
                  <BigidChevronUpIcon data-aid={generateDataAid('riskMatrixForm', ['xAxis', 'collapse'])} />
                ) : (
                  <BigidChevronDownIcon data-aid={generateDataAid('riskMatrixForm', ['xAxis', 'expand'])} />
                )}
              </ExpandableHeader>
              <ExpandableDetails expanded={expandedSection === ExpandedSection.XAxis}>
                {useImpactLabels(setHasErrors)}
              </ExpandableDetails>
            </Expandable>
          </Section>
        </Form>
        <Preview>{MatrixPreview}</Preview>
      </Root>
    </BigidDialog>
  );
};
