import React, { FC, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material';
import { BigidBody1, BigidTooltip } from '@bigid-ui/components';
import { getDashboardMetadata } from '../services/executiveDashboardServices';
import { isToday, differenceInDays } from 'date-fns';
import { getFixedT, useLocalTranslation } from '../translations';
import { BigidInfoSmallIcon } from '@bigid-ui/icons';
import { formatToLocalDateTime, formatToShortLocalTimezone } from '../utils';

interface MetadataProps {
  dashboardMetadataPayload: any;
}

const RuntimesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
});

const ToolTipContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  lineHeight: '1.5',
  gap: '8px',
});

const IconWrapper = styled('div')`
  display: flex;
`;

const getRelativeDate = (updateDate: Date): string => {
  const t = getFixedT('dashboardMetadata');

  const formattedTime = formatToShortLocalTimezone(updateDate);

  if (isToday(updateDate)) {
    return `${t('updated')} ${t('todayAt')} ${formattedTime}`;
  }

  const now = new Date();
  const daysAgo = differenceInDays(now, updateDate);
  const dayLabel = daysAgo === 1 ? t('dayAgoAt') : t('daysAgoAt');

  return `${t('updated')} ${daysAgo} ${dayLabel} ${formattedTime}`;
};

export const DashboardUpdateInfo: FC<MetadataProps> = ({ dashboardMetadataPayload }) => {
  const [timeSinceLastUpdate, setTimeSinceLastUpdate] = useState('');
  const [lastRunTime, setLastRunTime] = useState(null);
  const [nextRunTime, setNextRunTime] = useState(null);

  const theme = useTheme();
  const { t } = useLocalTranslation('dashboardMetadata');

  useEffect(() => {
    const getLastUpdateTime = async () => {
      const {
        dashboardData: { lastUpdateTime, nextRunTime },
      } = await getDashboardMetadata(dashboardMetadataPayload);
      if (!lastUpdateTime) {
        setTimeSinceLastUpdate(null);
      } else {
        const lastUpdateTimeAsDate = new Date(lastUpdateTime);
        setTimeSinceLastUpdate(getRelativeDate(lastUpdateTimeAsDate));
        setLastRunTime(formatToLocalDateTime(lastUpdateTimeAsDate));
      }

      if (!nextRunTime) {
        setNextRunTime(null);
        return;
      }
      setNextRunTime(formatToLocalDateTime(new Date(nextRunTime)));
    };

    getLastUpdateTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toolTipInfo: React.ReactNode = (
    <ToolTipContainer>
      {lastRunTime && (
        <div>
          <BigidBody1 color={theme.vars.tokens.bigid.foregroundTertiary} fontWeight="600" size="small">
            {t('lastUpdate')}
          </BigidBody1>
          <BigidBody1 color={theme.vars.tokens.bigid.foregroundSecondary} gap="8px">
            {lastRunTime}
          </BigidBody1>
        </div>
      )}
      {nextRunTime && (
        <div>
          <BigidBody1 color={theme.vars.tokens.bigid.foregroundTertiary} fontWeight="600" size="small">
            {t('nextUpdate')}
          </BigidBody1>
          <BigidBody1 color={theme.vars.tokens.bigid.foregroundSecondary}>{nextRunTime}</BigidBody1>
        </div>
      )}
    </ToolTipContainer>
  );

  return (
    timeSinceLastUpdate && (
      <RuntimesContainer>
        <BigidTooltip title={toolTipInfo} color={theme.vars.tokens.bigid.foregroundSecondary}>
          <IconWrapper>
            <BigidInfoSmallIcon />
          </IconWrapper>
        </BigidTooltip>
        <BigidBody1 size="small" fontWeight="600" color={theme.vars.tokens.bigid.foregroundSecondary}>
          {timeSinceLastUpdate}
        </BigidBody1>
      </RuntimesContainer>
    )
  );
};
