import React, { FC } from 'react';
import { styled } from '@mui/material';
import { PrimaryButton } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { ActionsButton } from './ActionsButton';
import { useLocalTranslation } from '../translations';
import { isPermitted } from '../../../services/userPermissionsService';
import { GENERAL_SETTINGS_PERMISSIONS } from '@bigid/permissions';
import { GlossaryItem } from '../../../../administration/generalSettings/businessGlossary/businessGlossary.service';

const HeaderButtonsWrapper = styled('div')`
  display: flex;
  gap: 12px;
`;

type HeaderButtonsProps = {
  onImportDialogOpen: () => void;
  isExportInProgress: boolean;
  setIsExportInProgress: (status: boolean) => void;
  onAddItemDialogOpen: () => void;
};

export const HeaderButtons: FC<HeaderButtonsProps> = ({
  onImportDialogOpen,
  isExportInProgress,
  setIsExportInProgress,
  onAddItemDialogOpen,
}) => {
  const { t } = useLocalTranslation('BusinessGlossary.headerButtons');

  return (
    <HeaderButtonsWrapper>
      <ActionsButton
        onImportDialogOpen={onImportDialogOpen}
        isExportInProgress={isExportInProgress}
        setIsExportInProgress={setIsExportInProgress}
      />
      {isPermitted(GENERAL_SETTINGS_PERMISSIONS.CREATE_BUSINESS_GLOSSARY.name) && (
        <PrimaryButton
          text={t('addNewButtonTitle')}
          dataAid={generateDataAid('HeaderButtons', ['add-new-glossary-item'])}
          onClick={onAddItemDialogOpen}
          size="large"
          disabled={isExportInProgress}
        />
      )}
    </HeaderButtonsWrapper>
  );
};
