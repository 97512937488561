import { useCallback, useContext, useEffect, useState } from 'react';
import { SSE_EVENTS, SSEDataMessage, subscribeToRepeatedSSEEventById } from '../../../services/sseService';
import { CustomAppStatus } from '../../CustomApp/views/ActivityLog/ActivityLog';
import { AutoDiscoveryResponse } from './useGridUpdateBySseEvents';
import {
  AutoDiscoveryWizardContext,
  AutoDiscoveryWizardContextState,
} from '../AutoDiscoveryWizard/autoDiscoveryWizardContext';
import { findErrorKeyFromMessage } from '../AutoDiscoveryWizard/autoDiscoveryWizardUtils';

const DEFAULT_STATE = { status: '', msg: '' };

function updatePreset(current: AutoDiscoveryWizardContextState, message: string, status_enum: CustomAppStatus) {
  return {
    ...(current?.preset ?? {}),
    latestRun: {
      ...(current?.preset?.latestRun ?? {}),
      message,
      status_enum,
    },
  };
}

export const useHandlePresetUpdateEvents = () => {
  const { discoveryConfigData, setDiscoveryConfigData } = useContext(AutoDiscoveryWizardContext);
  const presetId = discoveryConfigData?.preset?._id;
  const [, setPrevEvent] = useState(DEFAULT_STATE);
  const handleAutoDiscoveryWizardBroadcastEventReceived = useCallback(
    async ({ results = [] }: SSEDataMessage<AutoDiscoveryResponse>) => {
      const { preset_id, message, status_enum } = results[0]?.execution || {};
      if (preset_id !== presetId) return;

      setPrevEvent(prevState => {
        if (prevState.status === status_enum && prevState.msg === message) return prevState;

        switch (status_enum) {
          case CustomAppStatus.ERROR:
            const errorMessageKey = findErrorKeyFromMessage(message);
            setDiscoveryConfigData(current => ({
              ...current,
              errorMessageKey,
              isDiscovery: false,
              preset: updatePreset(current, message, status_enum),
            }));
            break;
          case CustomAppStatus.COMPLETED:
            setDiscoveryConfigData(current => ({
              ...current,
              isDiscovery: false,
              isLoadingPreset: false,
              preset: updatePreset(current, message, status_enum),
            }));
            break;
          default:
            break;
        }
        return { id: presetId, status: status_enum, msg: message };
      });
    },
    [presetId, setDiscoveryConfigData],
  );

  useEffect(() => {
    if (!presetId) return;
    const unsubscribe = subscribeToRepeatedSSEEventById(
      SSE_EVENTS.TPA_ACTIONS_EXECUTIONS,
      handleAutoDiscoveryWizardBroadcastEventReceived,
    );

    return () => {
      unsubscribe?.();
    };
  }, [handleAutoDiscoveryWizardBroadcastEventReceived, presetId]);
};
