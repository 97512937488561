import React from 'react';
import { DataCatalogObjectDetails } from '../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { styled } from '@mui/material';
import { Preview } from '../../SearchGrid/GridComponents/sidePanels/CatalogSidePanel/Preview/Preview';
import { PagesWithSidePanel } from '../../SearchGrid/GridComponents/sidePanels/CatalogSidePanel/CatalogSidePanel';

export const Root = styled('div')`
  display: flex;
  flex-grow: 1;
  padding: 19px;
`;

type PreviewTabProps = {
  objectDetails: DataCatalogObjectDetails;
  rootPage?: PagesWithSidePanel;
};

export const PreviewTab = ({ objectDetails, rootPage }: PreviewTabProps) => {
  const { scannerType, dataSourceName, fullyQualifiedName } = objectDetails;
  return (
    <Root>
      <Preview
        id={fullyQualifiedName}
        isExtendedView
        datasource={{ scannerType, value: dataSourceName }}
        rootPage={rootPage}
      />
    </Root>
  );
};
