import { ProgressChartData } from './types';
import { getDimensionNameFilter, getDimensionValueFilter } from './utils';
import { RangeSelectionOptions } from '@bigid-ui/components';
import { ONE_DAY_MS } from './dateRangeUtils';

export const DISCOVERY = 'DISCOVERY';
export const DATA_DISCOVERY = 'DATA_DISCOVERY';
export const WIDGET_QUERY_FILTER_NAME = 'query';

export enum DashboardSubType {
  DATA_COVERAGE = 'DATA_COVERAGE',
  DATA_RISK = 'DATA_RISK',
  DATA_DUPLICATION = 'DATA_DUPLICATION',
  DASHBOARD_FILTER = 'DASHBOARD_FILTER',
  SENSITIVE_DATA = 'SENSITIVE_DATA',
  METADATA = 'METADATA',
}

export enum WidgetName {
  DATA_COVERAGE = 'data_coverage',
  DATA_RISK = 'data_risk',
  DATA_DUPLICATION = 'data_duplication',
  DASHBOARD_FILTER = 'dashboard_filter',
  SENSITIVE_DATA = 'sensitive_data',
}

export const getInitialProgressChartData = (): ProgressChartData => {
  return {
    total: 0,
    data: [],
  };
};

export enum FilterField {
  DS_TYPE = 'dsType',
  DS_NAME = 'dsName',
  DATE_RANGE = 'dateRange',
  TAGS = 'tags',
  OWNER = 'owner',
  DS_STATE = 'dsState',
  TIMESTAMP = '@timestamp',
  POLICY = 'policy',
  DIMENSION_NAME = 'dimensionName',
  DIMENSION_VALUE = 'dimensionValue',
}

export enum FilterNameInEs {
  DS_TYPE = 'dsType',
  DS_NAME = 'dsName',
  TAGS = 'tags',
  OWNER = 'owner',
  DS_STATE = 'dsState',
}

export enum DateRangeOption {
  CUSTOM = 'custom',
  LAST_YEAR = 'lastYear',
  LAST_MONTH = 'lastMonth',
  LAST_WEEK = 'lastWeek',
  NONE = 'none',
}

export enum DimensionName {
  GENERAL = 'general',
  POLICY = 'policy',
  SENSITIVITY_LEVEL = 'sensitivityLevel',
  CATEGORY = 'category',
}

export enum GeneralDimensionValue {
  COVERAGE = 'coverage',
  GENERAL = 'general',
}

export const specialFilterIds = new Set<string>([FilterField.DATE_RANGE, FilterField.TAGS]);

export const widgetNameToFilter: Map<WidgetName, string> = new Map([
  [WidgetName.SENSITIVE_DATA, `${getDimensionNameFilter(DimensionName.POLICY)}`],
  [
    WidgetName.DATA_COVERAGE,
    `${getDimensionNameFilter(DimensionName.GENERAL)} AND ${getDimensionValueFilter(GeneralDimensionValue.COVERAGE)}`,
  ],
  [
    WidgetName.DATA_RISK,
    `${getDimensionNameFilter(DimensionName.GENERAL)} AND ${getDimensionValueFilter(GeneralDimensionValue.GENERAL)}`,
  ],
  [
    WidgetName.DASHBOARD_FILTER,
    `${getDimensionNameFilter(DimensionName.GENERAL)} AND ${getDimensionValueFilter(GeneralDimensionValue.COVERAGE)}`,
  ],
  [
    WidgetName.DATA_DUPLICATION,
    `${getDimensionNameFilter(DimensionName.GENERAL)} AND ${getDimensionValueFilter(GeneralDimensionValue.GENERAL)}`,
  ],
]);

export const DEFAULT_DATE_RANGE = {
  OPTION_SELECTED: DateRangeOption.LAST_WEEK as RangeSelectionOptions,
  FROM_NUM_DAYS_IN_MILLISECONDS: 7 * ONE_DAY_MS,
};
