import { getCurrentUser } from '../../utilities/systemUsersUtils';
import { analyticsService } from '../../services/analyticsService';

export enum EaProgramEvents {
  EA_PROGRAM_CUSTOMER_INTEREST_TO_JOIN = 'EA_PROGRAM_CUSTOMER_INTEREST_TO_JOIN',
  EA_PROGRAM_DECLINE_CLICK = 'EA_PROGRAM_DECLINE_CLICK',
  EA_PROGRAM_DECLINE_NON_ADMIN = 'EA_PROGRAM_DECLINE_NON_ADMIN',
  EA_PROGRAM_OPT_OUT_CLICK = 'EA_PROGRAM_OPT_OUT_CLICK',
  EA_PROGRAM_DONT_SHOW_AGAIN_CHECKED = 'EA_PROGRAM_DONT_SHOW_AGAIN_CHECKED',
  EA_PROGRAM_OPEN_DIALOG_FROM_HEADER_BAR_CLICK = 'EA_PROGRAM_OPEN_DIALOG_FROM_HEADER_BAR_CLICK',
  EA_PROGRAM_CLOSE_DIALOG_CLICK = 'EA_PROGRAM_CLOSE_DIALOG_CLICK',
  EA_PROGRAM_CLOSE_BUTTON_CLICK = 'EA_PROGRAM_CLOSE_BUTTON_CLICK',
}

export const trackEaProgramEvent = async (eventType: EaProgramEvents, data?: Record<string, unknown>) => {
  const { firstName, lastName } = await getCurrentUser();
  const enrichedData = { ...data, firstName, lastName };
  analyticsService.trackManualEvent(eventType as any, enrichedData);
};
