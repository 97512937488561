import React, { FC, useMemo, useState } from 'react';

import { BigidToggleButton, BigidWidgetContainer, BigidWorldMap } from '@bigid-ui/components';

import { WidgetProps, PrivacyMapCategories } from '../PrivacyExecutiveDashboardTypes';
import { Menu } from './helpers/HelperComponents';
import { useFetchWidgetData } from './hooks/useFetchWidgetData';
import { generateMenuItems } from './helpers/generateMenuItems';
import { categoryColorMapperCache, fetcherFunctionMapper } from './helpers/privacyMapHelper';
import { useEmptyState } from './hooks/useEmptyState';
import { MapWidgetWrapper } from './styles/WidgetStyles';

const TABS = Object.values(PrivacyMapCategories).map(category => ({
  label: category,
  value: category,
}));

export const PrivacyMapWidget: FC<WidgetProps> = ({ title }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const fetcherFunction = fetcherFunctionMapper[currentTab];
  const { data, error, isLoading, fetchData } = useFetchWidgetData(fetcherFunction);
  const imageData = data?.[TABS[currentTab].label] || [];
  const emptyStateConfig = useEmptyState(imageData, error, fetchData);
  const categories = categoryColorMapperCache[TABS[currentTab].label];

  const menuItems = useMemo(() => {
    const onClickHandlers = {
      csv: () => console.log('Export CSV'), // TODO: add a onClick handler
      pdf: () => console.log('Download PDF'), // TODO: add a onClick handler
      hide: () => console.log('Hide Widget'), // TODO: add a onClick handler
      go: () => console.log('Go To'), // TODO: add a onClick handler
    };

    const hideMenuItemContext = {
      canExportCsv: true, // TODO: add a condition
      canDownloadPdf: true, // TODO: add a condition
      canGoToLink: true, // TODO: add a condition
    };

    const menuItems = generateMenuItems(onClickHandlers, hideMenuItemContext);
    return menuItems;
  }, []);

  return (
    <MapWidgetWrapper>
      <BigidWidgetContainer
        content={
          <BigidWorldMap
            chartId="PrivacyMapId"
            height={416}
            imageData={imageData}
            legendConfig={{
              filters: [],
              isCollapsed: false,
              isHidden: false,
              isInteractive: true,
            }}
            lineData={[]}
            mapConfig={{
              categories,
              isMapContainerOutlined: true,
              isMapSeriesGroupedIntoClusters: true,
              isMultipleSelectionEnabled: true,
              defaultZoomLevel: 2,
            }}
          />
        }
        titleConfig={{
          title,
        }}
        actionsSlot={
          <>
            <BigidToggleButton
              size="small"
              toggleButtons={TABS}
              initialSelected={TABS[currentTab].value}
              onChange={(_, newValue) => setCurrentTab(TABS.findIndex(tab => newValue === tab.value))}
            />
            <Menu menuItems={menuItems} />
          </>
        }
        isLoading={isLoading}
        emptyStateConfig={emptyStateConfig}
      />
    </MapWidgetWrapper>
  );
};
