import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { BigidGridQueryComponents, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import {
  BigidLoader,
  BigidPaper,
  EntityEvents,
  entityEventsEmitter,
  ToolbarAction,
  ToolbarActionType,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { $state } from '../../../services/angularServices';
import { getInitialSubScanFilters, getInitialSubScanGridColumns } from './SubscanGridConfiguration';
import { SubScanGridRow, SubscanStage } from './ScanInsightTypes';
import { notificationService } from '../../../services/notificationService';
import { fetchScanInsightsGridData } from './ScanInsightService';
import { queryService } from '../../../services/queryService';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { runScanActionDialog } from '../ScanService';
import {
  ActiveScanState,
  CompletedParentScanState,
  ParentScanType,
  ScanActions,
  ScansTypes,
  SSEType,
} from '../ScanTypes';
import { BigidPauseIcon, BigidResumeIcon, BigidStopIcon, BigidSyncIcon } from '@bigid-ui/icons';
import {
  DEFAULT_SORT_BY_ID,
  LIMIT_SUB_SCANS_FOR_SSE,
  isFinishedState,
  isPollingEnable,
  isScanInsightsAvailable,
  PAUSED_DISABLED_LABEL,
  shouldShowSubScanAction,
  STOP_DISABLED_GENERIC_LABEL,
  STOP_DISABLED_LABEL,
  subscribeToSSEScanUpdates,
  subscribeToSSEScanUpdatesRelatedPolling,
  subscribeToSSEScanUpdatesWrapper,
} from '../ScanUtils';
import { ScansUITrackingEvent, ScanViewEnum, trackEventScansView } from '../ScansEventTrackerUtils';
import { ScanInsightsSidePanel } from './ScanInsightsSidePanel/ScanInsightsSidePanel';
import { Theme } from '@mui/material/styles';

const pageBottomMargin = 10;
export const PADDING = 24;

interface StyleProps {
  isScanInsightsAvailable: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>({
  innerGridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    padding: PADDING,
    paddingTop: '40px',
    height: props => (props.isScanInsightsAvailable ? '100%' : `calc(100% - ${pageBottomMargin}px)`),
  },
});

const SCAN_INSIGHTS_FOR_LABELING = 'scanInsightsForLabeling';

const layoutId = 'SubScansGridLayoutId';

const requireTotalCount = (queryString: string): string =>
  queryString.replace(`requireTotalCount=false`, `requireTotalCount=true`);

export const SubScansGrid: FC<{ scanId: string; type: ParentScanType; isScanComplete: boolean }> = ({
  scanId,
  type,
  isScanComplete,
}) => {
  const classes = useStyles({ isScanInsightsAvailable: isScanInsightsAvailable() });
  const [isOpen, setIsOpen] = useState(false);
  const [row, setRow] = useState<Partial<SubScanGridRow>>({});
  const [filter, setFilter] = useState<BigidGridQueryComponents>({});
  const [isCompleted, setIsCompleted] = useState(isScanComplete);

  const isPollingEnableFF = getApplicationPreference('IS_POLLING_ENABLE');

  useEffect(() => {
    let unregister: any;

    const fetchAndSubscribe = async () => {
      if (isPollingEnableFF) {
        const gridConfigQuery = queryService.getGridConfigQuery({
          filter: [],
          sort: [],
          skip: 0,
          offset: 0,
          limit: LIMIT_SUB_SCANS_FOR_SSE,
        });
        const { totalCount } = await fetchScanInsightsGridData(requireTotalCount(gridConfigQuery), scanId);

        unregister = subscribeToSSEScanUpdatesWrapper(
          SSEType.CHILD,
          ScanViewEnum.SCAN_INSIGHTS,
          scanId,
          totalCount,
          true,
        );
      } else {
        unregister = subscribeToSSEScanUpdatesWrapper(
          SSEType.CHILD,
          ScanViewEnum.SCAN_INSIGHTS,
          scanId,
          undefined,
          false,
        );
      }
    };

    fetchAndSubscribe();

    return () => {
      if (unregister) {
        unregister();
      }
    };
  }, [scanId]);

  useEffect(() => {
    if (isCompleted || !isPollingEnableFF) {
      return;
    }

    let isUpdating = false;
    const interval = setInterval(async () => {
      if (!isUpdating) {
        isUpdating = true; // Set to true to indicate the function is running
        try {
          const sortFilter = filter?.sort || [];
          const gridConfigQuery = queryService.getGridConfigQuery({
            ...filter,
            sort: [...sortFilter, ...DEFAULT_SORT_BY_ID],
          });
          const { scanChildren } = await fetchScanInsightsGridData(requireTotalCount(gridConfigQuery), scanId);
          entityEventsEmitter.emit(EntityEvents.UPDATE, scanChildren, undefined, false);
        } catch (error) {
          console.error('Error while updating scans:', error);
        } finally {
          isUpdating = false; // Reset to false when the function finishes
        }
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [filter, isCompleted]);

  const initialGridColumns = useMemo(() => getInitialSubScanGridColumns(type, setIsCompleted), [type]);
  const getInitialFilterToolbarConfig = useCallback(() => getInitialSubScanFilters(type), [type]);

  const toolbarActions = useMemo<ToolbarAction[]>(
    () => [
      ...(isPollingEnableFF
        ? ([
            {
              placement: 'end',
              tooltip: 'Refresh sub scans',
              label: '',
              icon: BigidSyncIcon,
              execute: async () => {
                return {
                  shouldGridReload: true,
                  entityEventToEmit: EntityEvents.RELOAD,
                };
              },
              disable: () => false,
              type: ToolbarActionType.TERTIARY,
              show: () => true,
              hideActionInToolBar: false,
              isGlobal: true,
            },
          ] as ToolbarAction[])
        : []),
      {
        label: 'Retry Prediction Scan',
        icon: BigidSyncIcon,
        execute: async actionData => runScanActionDialog(actionData, ScanActions.RETRY_PREDICTION),
        disable: () => false,
        show: ({ selectedRows: [{ isRetryAvailable, isRbacPermitted, type }] }) =>
          isRetryAvailable && isRbacPermitted && type === SubscanStage.PREDICTION_SCAN,
        isInline: true,
        hideActionInToolBar: true,
      },
      {
        label: 'Pause',
        icon: BigidPauseIcon,
        execute: async actionData => runScanActionDialog(actionData, ScanActions.PAUSE),
        disable: () => false,
        show: ({ selectedRows: [{ isPauseAvailable, isRbacPermitted }] }) => isPauseAvailable && isRbacPermitted,
        isInline: true,
        hideActionInToolBar: true,
      },
      {
        label: PAUSED_DISABLED_LABEL,
        icon: () => <BigidPauseIcon staticMode={true} disabled={true} dataAid="GridInlineActions-icon-Pause" />,
        execute: async _actionData => ({}),
        disable: () => true,
        show: ({ selectedRows: [{ type, state, isPauseAvailable, isResumeAvailable, isRbacPermitted }] }) => {
          const isPauseSupported = type !== ScansTypes.ID_SUB_SCAN;
          return (
            isRbacPermitted && !isPauseAvailable && !isResumeAvailable && !isFinishedState(state) && isPauseSupported
          );
        },
        isInline: true,
        hideActionInToolBar: true,
      },
      {
        label: 'Resume',
        icon: BigidResumeIcon,
        execute: async actionData => runScanActionDialog(actionData, ScanActions.RESUME),
        disable: () => false,
        show: ({ selectedRows: [{ isResumeAvailable, isRbacPermitted }] }) => isResumeAvailable && isRbacPermitted,
        isInline: true,
        hideActionInToolBar: true,
      },
      {
        label: 'Stop',
        icon: BigidStopIcon,
        execute: async actionData => runScanActionDialog(actionData, ScanActions.STOP),
        disable: () => false,
        show: ({ selectedRows: [{ state, type, isStopAvailable, isRbacPermitted }] }) =>
          isStopAvailable &&
          isRbacPermitted &&
          shouldShowSubScanAction({
            stateCondition: [ActiveScanState.STOP_REQUESTED, ...Object.values(CompletedParentScanState)],
            type,
            state,
            notEqual: true,
          }),
        isInline: true,
        hideActionInToolBar: true,
      },
      {
        label: STOP_DISABLED_LABEL,
        icon: () => <BigidStopIcon staticMode={true} disabled={true} dataAid="GridInlineActions-icon-Stop" />,
        execute: async _actionData => ({}),
        disable: () => true,
        show: ({ selectedRows: [{ type, state, isStopAvailable, isRbacPermitted }] }) => {
          const isStopSupported = type !== ScansTypes.ID_SUB_SCAN;
          return isRbacPermitted && !isStopAvailable && !isFinishedState(state) && isStopSupported;
        },
        isInline: true,
        hideActionInToolBar: true,
      },
      {
        label: STOP_DISABLED_GENERIC_LABEL,
        icon: () => <BigidStopIcon staticMode={true} disabled={true} dataAid="GridInlineActions-icon-Stop" />,
        execute: async _actionData => ({}),
        disable: () => true,
        show: ({ selectedRows: [{ type, state, isStopAvailable, isRbacPermitted }] }) => {
          const isStopSupportedGenericLabel = type === ScansTypes.ID_SUB_SCAN;
          return isRbacPermitted && !isStopAvailable && !isFinishedState(state) && isStopSupportedGenericLabel;
        },
        isInline: true,
        hideActionInToolBar: true,
      },
    ],
    [],
  );

  const stateName = useMemo(
    () => (type === ParentScanType.DS_TAG ? SCAN_INSIGHTS_FOR_LABELING : $state.$current.name),
    [type],
  );

  const { isReady, gridColumns, preferences, updatePreferences, filterToolbarConfig } = useUserPreferences({
    stateName,
    initialGridColumns,
    getInitialFilterToolbarConfig,
  });

  const onCloseSidePanel = () => {
    setIsOpen(false);
    setRow({});
  };

  const pageSize = getApplicationPreference('PAGE_SIZE_SUB_SCANS_FF') || 25;
  const config: BigidGridWithToolbarProps<SubScanGridRow> = useMemo(
    () => ({
      gridId: layoutId,
      showSelectAllColumnChooser: true,
      pagingMode: isScanInsightsAvailable() ? (isPollingEnable() ? true : false) : true,
      pageSize: isScanInsightsAvailable() ? (isPollingEnable() ? pageSize : 20) : 10,
      showSelectionCheckboxes: false,
      showSelectionColumn: false,
      showSelectAll: false,
      entityName: 'Sub Scans',
      defaultSorting: preferences?.grid?.sort || [{ field: 'updatedAt', order: 'desc' }],
      showSortingControls: true,
      onRowClick: row => {
        setRow(row);
        setIsOpen(true);
        trackEventScansView(ScansUITrackingEvent.SUB_SCAN_INSIGHT_CLICK, { subScanId: row._id });
      },
      ...(isPollingEnable()
        ? {
            onPagingChanged: (page: number) => {
              setFilter(prevFilter => ({ ...prevFilter, skip: page * pageSize, offset: page * pageSize }));
            },
          }
        : {}),
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      filterToolbarConfig,
      fetchData: async queryComponents => {
        if (isPollingEnable()) {
          setFilter(queryComponents);
        }
        try {
          const gridConfigQuery = queryService.getGridConfigQuery({
            ...queryComponents,
            sort: [...queryComponents.sort, ...DEFAULT_SORT_BY_ID],
          });

          const { scanChildren, totalCount } = await fetchScanInsightsGridData(
            requireTotalCount(gridConfigQuery),
            scanId,
          );

          return {
            data: scanChildren,
            totalCount,
          };
        } catch (error) {
          notificationService.error(
            'An error has occurred fetching scan insight grid data. See logs for more details.',
          );
          console.error(`An error has occurred: ${error}`);
          return {
            totalCount: 0,
            data: [],
          };
        }
      },
      columns: gridColumns,
      toolbarActions,
    }),
    [filterToolbarConfig, gridColumns, preferences?.grid?.sort, scanId, updatePreferences, toolbarActions],
  );
  return (
    <div className={classes.innerGridWrapper}>
      {!isReady && <BigidLoader />}
      <BigidPaper>{isReady && <BigidGridWithToolbar {...config} />}</BigidPaper>
      <ScanInsightsSidePanel
        scanId={row._id}
        scanName={row.name}
        scanState={row.state}
        isOpen={isOpen}
        onClose={onCloseSidePanel}
      />
    </div>
  );
};
